/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by SnigdhTech Team

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "../../assets/jss/material-dashboard-react/components/footerStyle.jsx";
import Axios from "axios";
import { Hidden, Menu, MenuItem, Button, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";


function Footer({ ...props }) {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Hidden xsDown>
        <footer className={classes.footer}>
          <div className={classes.container}>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="#home" className={classes.block}>
                    Think Smart. Go Beyond.
                </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="#home" className={classes.block}>
                    Home
                </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="#company" className={classes.block}>
                    Privacy Statement
                </a>
                </ListItem>

              </List>
            </div>
            <p className={classes.right}>
              <span>
                &copy; {1900 + new Date().getYear()}{" "}
                <a href="https://www.snigdhtech.com" target="_blank" className={classes.a}>
                  SnigdhTech & Business Solution
              </a>
                . All Rights Reserved.
          </span>
            </p>
          </div>
        </footer>
      </Hidden>
      <Hidden smUp>
        <footer className={classes.footer}>
          <Grid container className={classes.container}>

            <Grid item xs={10}>

              <p style={{ fontSize: "11px" }}>
                <span>
                  &copy; {1900 + new Date().getYear()}{" "}
                  <a href="https://www.snigdhtech.com" target="_blank" className={classes.a}>
                    SnigdhTech & Business Solution
              </a>
                . All Rights Reserved.
              </span>
              </p>
            </Grid>
            <Grid Item xs={2}>
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  onClick={handleClick}
                  size="lg"
                  style={{ cursor: "pointer" }}
                />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >

                <MenuItem>
                  <a href="#home">
                    Think Smart. Go Beyond.
                </a>
                </MenuItem>
                <MenuItem>
                  <a href="#home">
                    Home
                </a>
                </MenuItem>
                <MenuItem >
                  <a href="#company" >
                    Privacy Statement
                </a>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </footer>
      </Hidden>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
