import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import PropTypes from "prop-types";

function ConfirmationDialog(props) {
  const { open, handleClose, handleConfirm, titleMessage, bodyMessage } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleMessage}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {bodyMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.bool,
  handleConfirm: PropTypes.func,
  titleMessage: PropTypes.string,
  bodyMessage: PropTypes.string
};

export default ConfirmationDialog;
