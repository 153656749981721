import React, { Component } from "react";
import {
  Card,
  CardContent,
  withStyles,
  Grid,
  TextField,
  MenuItem,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import dateFormat from "dateformat";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import Axios from "axios";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFilePdf,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import UserContext from "../utils/UserContext";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import TrialBalancePrint from "../components/PrintableComponents/TrialBalancePrint";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const styles = (theme) => ({
  tabMargin: {
    height: "auto",
    width: "100%",
    maxWidth: "1300px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "65px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class TrialBalance extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      excelData: "",
      companyName: "",
      companyAddress: "",
      companyContact: "",
      rowData: [],
      totalData: [],
      fyList: [],
      distinctFyList: [],
      distinctPeriodList: [],
      currentPeriod: "",
      periodList: [],
      currentFy: "",
      period: "",
      filterFields: {
        dateFrom: "",
        dateTo: "",
        periodFrom: "",
        periodTo: "",
      },
      filterTerm: "",
      panelExpanded: true,
      loading: false,
      showDetails: false,
    };
  }

  handleFiscalYearChange = async (e) => {
    e.stopPropagation();
    await this.setState((prevState) => ({
      ...prevState,
      currentFy: e.target.value,
    }));
  };

  handlePeriodFromChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodFrom: period,
      },
    }));
  };

  handlePeriodToChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodTo: period,
      },
    }));
  };

  handlePanelChange = (_e, isExpanded) => {
    this.setState({
      panelExpanded: isExpanded,
    });
  };

  handleFromDate = (event) => {
    var date = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: date,
      },
    }));
  };

  handleToDate = (event) => {
    var date = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateTo: date,
      },
    }));
  };

  handleFiscalYearApply = () => {
    this.setState({
      panelExpanded: false,
    });
    this.getTrialBalance({
      fiscalYear: this.state.currentFy,
      periodFrom: this.state.filterFields.periodFrom,
      periodTo: this.state.filterFields.periodTo,
    });
  };

  handleDateRangeApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.getTrialBalance({
      dateFrom: this.state.filterFields.dateFrom,
      dateTo: this.state.filterFields.dateTo,
    });
  };

  onDoubleClick = (accountCode) => {
    const token = this.context;

    let newFilterFields = this.state.filterFields;
    newFilterFields.fiscalYear = this.state.currentFy;

    token.setAccountCode({ value: accountCode });
    token.setFilterFields({ value: newFilterFields });

    setTimeout(() => {
      this.props.history.push("/booksOfAccount/view");
    }, 150);
  };

  onDoubleClickSubAccount = (accountCode, subAccountCode) => {
    const token = this.context;

    let newFilterFields = this.state.filterFields;
    newFilterFields.fiscalYear = this.state.currentFy;

    token.setAccountCode({ value: accountCode });
    token.setSubAccountCode({ value: subAccountCode });
    token.setFilterFields({ value: newFilterFields });

    setTimeout(() => {
      this.props.history.push("/booksOfAccount/view");
    }, 150);
  };

  componentDidMount() {
    this.getAllFiscalYear();
    this.getCompanyName();

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    const token = this.context;
    console.log({ token });

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: todayDate,
        dateTo: todayDate,
      },
    }));

    let filterFields = token.filterFields;

    if (filterFields.fiscalYear !== "") {
      this.getTrialBalance({
        fiscalYear: filterFields.fiscalYear,
        periodFrom: filterFields.periodFrom,
        periodTo: filterFields.periodTo,
      });

      this.setState((prevState) => ({
        ...prevState,
        filterFields: {
          ...prevState.filterFields,
          periodFrom: filterFields.periodFrom,
          periodTo: filterFields.periodTo,
        },
        currentFy: filterFields.fiscalYear,
        filterTerm: "fiscalYear",
        panelExpanded: false,
      }));
    } else if (filterFields.dateFrom !== "") {
      this.getTrialBalance({
        dateFrom: filterFields.dateFrom,
        dateTo: filterFields.dateTo,
      });

      this.setState((prevState) => ({
        ...prevState,
        filterFields: {
          ...prevState.filterFields,
          dateFrom: filterFields.dateFrom,
          dateTo: filterFields.dateTo,
        },
        filterTerm: "date",
        panelExpanded: false,
      }));
    }
  }

  expandRow = (accountCode) => {
    let newData = this.state.rowData;

    newData.map((val) =>
      val.accountCode === accountCode ? (val.expanded = !val.expanded) : null
    );

    this.setState({
      rowData: newData,
    });
  };

  getAllFiscalYear() {
    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        var data = [];
        var nonDistinctPeriodList = [];
        var nonDistinctFyList = [];
        var periodData = [];
        res.data.forEach((element) => {
          var item = {
            status: element.currentStatus,
            fiscalYear: element.fiscalYear,
            name: element.name,
          };
          var periodItem = {
            status: element.currentStatus,
            period: element.name,
          };
          data.push(item);
          nonDistinctFyList.push(item.fiscalYear);
          periodData.push(periodItem);
          nonDistinctPeriodList.push(periodItem.period);
        });

        this.setState((prevState) => ({
          ...prevState,
          fyList: data,
          distinctFyList: [...new Set(nonDistinctFyList)],
          periodList: periodData,
          distinctPeriodList: [...new Set(nonDistinctPeriodList)],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    this.getCurrentPeriod();
  }

  getCurrentPeriod = () => {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          currentFy: res.data.fiscalYear,
          filterFields: {
            ...prevState.filterFields,
            periodFrom: res.data.name,
            periodTo: res.data.name,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFormattedVal = (val) => {
    let realVal = val;

    if (isNaN(val) && val.startsWith("(")) {
      realVal = val.substring(1, val.length - 1);

      var format = new Intl.NumberFormat("ne-NP", {
        minimumFractionDigits: 2,
      });

      return "(" + format.format(realVal) + ")";
    } else {
      var formatInt = new Intl.NumberFormat("ne-NP", {
        minimumFractionDigits: 2,
      });

      return formatInt.format(realVal);
    }
  };

  getTrialBalance(obj) {
    this.setState({ loading: true, showDetails: true });
    Axios.post("/pos/trialBalance/findAllByFiscalYearAndPeriod", obj)
      .then(async (res) => {
        this.setState({ loading: false });

        let rowData = res.data;

        let totalOpeningBalance = 0;
        let totalDebitCurrentPeriod = 0;
        let totalCreditCurrentPeriod = 0;
        let totalMovementCurrentPeriod = 0;
        let totalBalanceToPeriod = 0;

        if (this.state.period === "Opening") {
          rowData.map((val) =>
            val.creditCurrentPeriod === 0
              ? (val.openingBalance = val.debitCurrentPeriod)
              : (val.openingBalance = val.creditCurrentPeriod)
          );

          rowData.forEach((element) => {
            if (element.creditCurrentPeriod === 0) {
              element.openingBalance = element.debitCurrentPeriod;

              element.debitCurrentPeriod = 0;
            } else {
              element.openingBalance = element.creditCurrentPeriod;

              element.creditCurrentPeriod = 0;
            }
            element.movementCurrentPeriod = 0;
          });
        }

        rowData.forEach((element) => {
          totalDebitCurrentPeriod += element.debitCurrentPeriod;
          totalCreditCurrentPeriod += element.creditCurrentPeriod;
          totalMovementCurrentPeriod += element.movementCurrentPeriod;
          totalBalanceToPeriod += element.balanceToPeriod;
        });

        totalOpeningBalance =
          totalDebitCurrentPeriod - totalCreditCurrentPeriod;

        rowData.forEach((element) => {
          if (element.creditCurrentPeriod !== 0) {
            element.creditCurrentPeriod =
              "(" + Math.abs(element.creditCurrentPeriod) + ")";
          }
          if (element.movementCurrentPeriod < 0) {
            element.movementCurrentPeriod =
              "(" + Math.abs(element.movementCurrentPeriod) + ")";
          }
          if (element.openingBalance < 0) {
            element.openingBalance =
              "(" + Math.abs(element.openingBalance) + ")";
          }
          if (element.balanceToPeriod < 0) {
            element.balanceToPeriod =
              "(" + Math.abs(element.balanceToPeriod) + ")";
          }

          element.expanded = false;
        });

        this.setState({
          rowData,
        });

        totalOpeningBalance =
          totalOpeningBalance < 0
            ? parseInt(totalOpeningBalance * 100) / 100
            : totalOpeningBalance;

        totalDebitCurrentPeriod =
          totalDebitCurrentPeriod < 0
            ? parseInt(totalDebitCurrentPeriod * 100) / 100
            : totalDebitCurrentPeriod;

        totalCreditCurrentPeriod =
          totalCreditCurrentPeriod < 0
            ? parseInt(totalCreditCurrentPeriod * 100) / 100
            : totalCreditCurrentPeriod;

        totalMovementCurrentPeriod =
          totalMovementCurrentPeriod < 0
            ? parseInt(totalMovementCurrentPeriod * 100) / 100
            : totalMovementCurrentPeriod;

        totalBalanceToPeriod =
          totalBalanceToPeriod < 0
            ? parseInt(totalBalanceToPeriod * 100) / 100
            : totalBalanceToPeriod;

        this.setState((prevState) => ({
          ...prevState,
          totalData: [
            {
              sn: "",
              accountCode: "",
              accountName: "Total",
              openingBalance:
                totalOpeningBalance < 0
                  ? "(" + Math.abs(totalOpeningBalance) + ")"
                  : totalOpeningBalance,
              debitCurrentPeriod: totalDebitCurrentPeriod,
              creditCurrentPeriod:
                totalCreditCurrentPeriod < 0
                  ? "(" + Math.abs(totalCreditCurrentPeriod) + ")"
                  : totalCreditCurrentPeriod,
              movementCurrentPeriod:
                totalMovementCurrentPeriod < 0
                  ? "(" + Math.abs(totalMovementCurrentPeriod) + ")"
                  : totalMovementCurrentPeriod,
              balanceToPeriod:
                totalBalanceToPeriod < 0
                  ? "(" + Math.abs(totalBalanceToPeriod) + ")"
                  : totalBalanceToPeriod,
            },
          ],
        }));

        //---------EXCE DATA ---------
        let excel = [];
        if (this.state.filterTerm === "fiscalYear") {
          let object = {
            sn: "",
            accountCode: "Fiscal Year",
            accountName: this.state.currentFy,
            openingBalance: "",
            debitCurrentPeriod: "",
            creditCurrentPeriod: "",
            movementCurrentPeriod: "",
            balanceToPeriod: "",
            period: "",
          };
          excel.push(object);

          object = {
            sn: "",
            accountCode: "Period From",
            accountName: this.state.filterFields.periodFrom,
            openingBalance: "",
            debitCurrentPeriod: "",
            creditCurrentPeriod: "",
            movementCurrentPeriod: "",
            balanceToPeriod: "",
            period: "",
          };
          excel.push(object);

          object = {
            sn: "",
            accountCode: "Period To",
            accountName: this.state.filterFields.periodTo,
            openingBalance: "",
            debitCurrentPeriod: "",
            creditCurrentPeriod: "",
            movementCurrentPeriod: "",
            balanceToPeriod: "",
            period: "",
          };
          excel.push(object);
        } else if (this.state.filterTerm === "date") {
          let object = {
            sn: "",
            accountCode: "Date Range",
            accountName: "",
            openingBalance: "",
            debitCurrentPeriod: "",
            creditCurrentPeriod: "",
            movementCurrentPeriod: "",
            balanceToPeriod: "",
            period: "",
          };
          excel.push(object);

          object = {
            sn: "",
            accountCode: "Date From",
            accountName: this.state.filterFields.dateFrom,
            openingBalance: "",
            debitCurrentPeriod: "",
            creditCurrentPeriod: "",
            movementCurrentPeriod: "",
            balanceToPeriod: "",
            period: "",
          };
          excel.push(object);

          object = {
            sn: "",
            accountCode: "Date To",
            accountName: this.state.filterFields.dateTo,
            openingBalance: "",
            debitCurrentPeriod: "",
            creditCurrentPeriod: "",
            movementCurrentPeriod: "",
            balanceToPeriod: "",
            period: "",
          };
          excel.push(object);
        }

        let object = {
          sn: "",
          accountCode: "",
          accountName: "",
          openingBalance: "",
          debitCurrentPeriod: "",
          creditCurrentPeriod: "",
          movementCurrentPeriod: "",
          balanceToPeriod: "",
          period: "",
        };
        excel.push(object);

        object = {
          sn: "S.No.",
          accountCode: "Account Code",
          accountName: "Account Name",
          openingBalance: "Opening Balance",
          debitCurrentPeriod: "Debit Current Period",
          creditCurrentPeriod: "Credit Current Period",
          movementCurrentPeriod: "Movement Current Period",
          balanceToPeriod: "Balance To Period",
          period: "Period",
        };
        excel.push(object);

        rowData.map((item, i) => {
          let object = {
            sn: i + 1,
            accountCode: item.accountCode,
            accountName: item.accountName,
            openingBalance: this.getFormattedVal(item.openingBalance),
            debitCurrentPeriod: this.getFormattedVal(item.debitCurrentPeriod),
            creditCurrentPeriod: this.getFormattedVal(item.creditCurrentPeriod),
            movementCurrentPeriod: this.getFormattedVal(
              item.movementCurrentPeriod
            ),
            balanceToPeriod: this.getFormattedVal(item.balanceToPeriod),
            period: item.period,
          };
          excel.push(object);
          return null;
        });
        object = {
          sn: "",
          accountCode: "",
          accountName: "",
          openingBalance: "",
          debitCurrentPeriod: "",
          creditCurrentPeriod: "",
          movementCurrentPeriod: "",
          balanceToPeriod: "",
          period: "",
        };
        excel.push(object);

        this.state.totalData.map((item, i) => {
          let object = {
            sn: "",
            accountCode: item.accountCode,
            accountName: item.accountName,
            openingBalance: this.getFormattedVal(item.openingBalance),
            debitCurrentPeriod: this.getFormattedVal(item.debitCurrentPeriod),
            creditCurrentPeriod: this.getFormattedVal(item.creditCurrentPeriod),
            movementCurrentPeriod: this.getFormattedVal(
              item.movementCurrentPeriod
            ),
            balanceToPeriod: this.getFormattedVal(item.balanceToPeriod),
          };
          excel.push(object);
          return null;
        });
        await this.setState({
          excelData: excel,
        });
        //--------EXCEL DATA ENDS--------
      })
      .catch((err) => {});
  }

  onFilterTermChange = (e) => {
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      filterTerm: value,
    }));
  };

  getCompanyName() {
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        res.data.forEach((element) => {
          this.setState({
            companyName: element.name,
            companyAddress: element.street + ", " + element.city,
            companyContact: element.contactNumber,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generatePdf = () => {
    let company = this.state.companyName;
    let companyAddress = this.state.companyAddress;
    let companyContactNumber = this.state.companyContact;
    function header() {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(420, 40, company, {
        align: "center",
      });
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(420, 60, companyAddress, {
        align: "center",
      });
      doc.setFontSize(12);
      doc.text(420, 80, companyContactNumber, {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("Helvetica", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = yyyy + "/" + mm + "/" + dd;
      doc.text(
        doc.internal.pageSize.width - 150,
        doc.internal.pageSize.height - 20,
        "Printed on: " + today
      );
    }

    var doc = new jsPDF("l", "pt", "a4", true);
    //current date
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);

    header();

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(40, 160, "Trial Balance", {
      align: "left",
    });

    var x = 40;
    var y = 190;

    if (this.state.filterTerm === "fiscalYear") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 7, y, "Fiscal Year:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, this.state.currentFy);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x, y, "Period From:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, this.state.filterFields.periodFrom);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 12, y, "Period To:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, this.state.filterFields.periodTo);
    } else if (this.state.filterTerm === "date") {
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(12);
      doc.text(x, y, "Date Range");

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 10, y, "Date From:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, this.state.filterFields.dateFrom);

      y = y + 25;
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(11);
      doc.text(x + 23, y, "Date To:");
      doc.setFont("Helvetica", "italic");
      doc.text(120, y, this.state.filterFields.dateTo);
    }

    let trialBalance = [];
    let i = 1;
    this.state.rowData.map((value) =>
      trialBalance.push([
        i++,
        value.accountCode,
        value.accountName,
        this.getFormattedVal(value.openingBalance),
        this.getFormattedVal(value.debitCurrentPeriod),
        this.getFormattedVal(value.creditCurrentPeriod),
        this.getFormattedVal(value.movementCurrentPeriod),
        this.getFormattedVal(value.balanceToPeriod),
      ])
    );

    this.state.totalData.map((value) =>
      trialBalance.push([
        "",
        value.accountCode,
        value.accountName,
        this.getFormattedVal(value.openingBalance),
        this.getFormattedVal(value.debitCurrentPeriod),
        this.getFormattedVal(value.creditCurrentPeriod),
        this.getFormattedVal(value.movementCurrentPeriod),
        this.getFormattedVal(value.balanceToPeriod),
      ])
    );

    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
        }
      },
      bodyStyles: { valign: "top" },
      startY: y + 30,
      theme: "grid",
      columnStyles: {
        0: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "center",
        },
        1: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        2: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "left",
        },
        3: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "right",
        },
        4: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "right",
        },
        5: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "right",
        },
        6: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "right",
        },
        7: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          cellWidth: "auto",
          halign: "right",
        },
      },
      head: [
        [
          "S.No.",
          "Account Code",
          "Account Name",
          "Opeing Balance",
          "Debit Current Period",
          "Credit Current Perid",
          "Movement Current Period",
          "Balance To Period",
        ],
      ],
      body: trialBalance,
    });
    footer();
    const pages = doc.internal.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width; //Optional
    const pageHeight = doc.internal.pageSize.height; //Optional
    doc.setFontSize(10); //Optional
    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 10; //Can be fixed number
      doc.setPage(j);
      doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
    }
    doc.save("Trial Balance");
  };

  render() {
    const { classes } = this.props;
    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      borderRight: "1px solid #EAEDEF",
      minWidth: "100px",
    };

    const numberStyledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      borderRight: "1px solid #EAEDEF",
      minWidth: "100px",
      textAlign: "right",
    };

    return (
         // <HeaderTitle title="Trial Balance" />
         <div>
        <Grid container justify="center" className={classes.root} >
        <Card className={classes.tabMargin} style={{marginBottom:"100px"}}>
              <CardContent>
              <Grid container style={{ width: "100%" }} justify="space-between">
                  <Grid item xs={12} sm={6}>
                    <ExpansionPanel
                      expanded={this.state.panelExpanded}
                      onChange={this.handlePanelChange}
                      style={{ maxWidth: "550px" }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Report By
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container>
                          <RadioGroup
                            row
                            value={this.state.filterTerm}
                            onChange={this.onFilterTermChange}
                          >
                            <FormControlLabel
                              value="fiscalYear"
                              control={<Radio color="primary" />}
                              label="Fiscal Year and Period"
                            />
                            <FormControlLabel
                              value="date"
                              control={<Radio color="primary" />}
                              label="Date Range"
                            />
                          </RadioGroup>

                          {this.state.filterTerm === "fiscalYear" ? (
                            <>
                              <Grid
                                container
                                justify="flex-start"
                                spacing={2}
                                style={{ marginTop: "20px" }}
                              >
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Fiscal Year"
                                    name="fiscalYear"
                                    onChange={this.handleFiscalYearChange}
                                    value={this.state.currentFy}
                                  >
                                    {this.state.distinctFyList.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                justify="flex-start"
                                spacing={2}
                                style={{ marginTop: "20px" }}
                              >
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Period From"
                                    name="periodFrom"
                                    value={this.state.filterFields.periodFrom}
                                    onChange={this.handlePeriodFromChange}
                                  >
                                    {this.state.distinctPeriodList.map(
                                      (item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      )
                                    )}
                                  </TextField>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Period To"
                                    name="periodTo"
                                    value={this.state.filterFields.periodTo}
                                    onChange={this.handlePeriodToChange}
                                  >
                                    {this.state.distinctPeriodList.map(
                                      (item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      )
                                    )}
                                  </TextField>
                                </Grid>
                              </Grid>

                              <Grid container style={{ marginTop: "27px" }}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleFiscalYearApply}
                                >
                                  Apply
                                </Button>
                              </Grid>
                            </>
                          ) : null}

                          {this.state.filterTerm === "date" ? (
                            <>
                              <Grid container justify="flex-start" spacing={2}>
                                <Grid item>
                                  <TextField
                                    placeholder="Date From"
                                    label="Date From"
                                    name="dateFrom"
                                    style={{
                                      width: "15vw",
                                      minWidth: "130px",
                                      marginTop: "20px",
                                    }}
                                    type="date"
                                    value={this.state.filterFields.dateFrom}
                                    onChange={this.handleFromDate}
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    placeholder="Date To"
                                    label="Date To"
                                    name="dateTo"
                                    value={this.state.filterFields.dateTo}
                                    style={{
                                      width: "15vw",
                                      minWidth: "130px",
                                      marginTop: "20px",
                                    }}
                                    type="date"
                                    onChange={this.handleToDate}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container style={{ marginTop: "27px" }}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleDateRangeApply}
                                >
                                  Apply
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid
                     container
                     justify="flex-end"
                     style={{ paddingTop: "10px", width: "100%" }}
                    >
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "3px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                          onClick={this.generatePdf}
                        >
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Pdf
                        </Button>
                      </Grid>

                      <Grid item>
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                minWidth: "100px",
                                width: "6vw",
                                margin: "3px",
                                height: "38px",
                                fontSize: "16px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPrint}
                                color="#3f51b5"
                                size="30px"
                                style={{
                                  marginRight: "10px",
                                }}
                              ></FontAwesomeIcon>
                              Print
                            </Button>
                          )}
                          content={() => this.componentRef}
                        />
                      </Grid>

                      <Grid item>
                        <ExcelFile
                          filename="TrialBalance"
                          element={
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                minWidth: "100px",
                                width: "6vw",
                                margin: "3px",
                                height: "38px",
                                fontSize: "16px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                color="#3f51b5"
                                size="30px"
                                style={{
                                  marginRight: "10px",
                                }}
                              ></FontAwesomeIcon>
                              Excel
                            </Button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.excelData}
                            name="Trial Balance"
                          >
                            <ExcelColumn value="sn" />
                            <ExcelColumn value="accountCode" />
                            <ExcelColumn value="accountName" />
                            <ExcelColumn value="openingBalance" />
                            <ExcelColumn value="debitCurrentPeriod" />
                            <ExcelColumn value="creditCurrentPeriod" />
                            <ExcelColumn value="movementCurrentPeriod" />
                            <ExcelColumn value="balanceToPeriod" />
                            <ExcelColumn value="period" />
                          </ExcelSheet>
                        </ExcelFile>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {this.state.showDetails ? (
                  <>
                    {this.state.filterTerm === "fiscalYear" ? (
                      <Grid
                        container
                        style={{ margin: "20px", maxWidth: "700px" }}
                      >
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Fiscal Year:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.currentFy}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Period From:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.periodFrom}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Period To:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.periodTo}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          container
                          style={{
                            margin: "20px",
                            marginLeft: "0px",
                            maxWidth: "500px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    marginRight: "10px",
                                    color: "#108DCD",
                                  }}
                                >
                                  Date From:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.dateFrom}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    color: "#108DCD",
                                    marginRight: "10px",
                                  }}
                                >
                                  Date To:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.dateTo}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ) : null}

                {this.state.loading ? (
                  <Grid container justify="center" style={{ margin: "50px 0px" }}>
                    <Grid item>
                      <CircularProgress thickness="2" size="50px" />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justify="center" style={{ marginTop: "30px"}}>
                  <TableContainer>
                    <Grid item>
                      <Table
                        aria-label="customized table"
                        stickyHeader
                        style={{
                          border: "1px solid #EAEDEF",
                        }}
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                backgroundColor: "#F1F8FC",
                                color: "#3F51B5",
                                borderRight: "1px solid #EAEDEF",
                              }}
                            >
                              Account Code
                            </TableCell>
                            <TableCell style={styledTableCell}>
                              Account Name
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "#F1F8FC",
                                color: "#3F51B5",
                                borderRight: "1px solid #EAEDEF",
                              }}
                            >
                              Opening Balance
                            </TableCell>
                            <TableCell style={styledTableCell}>
                              Debit Current Period
                            </TableCell>
                            <TableCell style={styledTableCell}>
                              Credit Current Period
                            </TableCell>
                            <TableCell style={styledTableCell}>
                              Movement Current Period
                            </TableCell>
                            <TableCell style={styledTableCell}>
                              Balance To Period
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.rowData.map((val) => (
                            <>
                              <TableRow
                                onDoubleClick={() =>
                                  this.onDoubleClick(val.accountCode)
                                }
                              >
                                <TableCell>{val.accountCode}</TableCell>
                                <TableCell>
                                  {val.trialBalanceDetails.length > 0 &&
                                    val.expanded && (
                                      <ExpandMoreIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.expandRow(val.accountCode)
                                        }
                                      />
                                    )}
                                  {val.trialBalanceDetails.length > 0 &&
                                    !val.expanded && (
                                      <ChevronRightIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.expandRow(val.accountCode)
                                        }
                                      />
                                    )}
                                  {val.accountName}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  {this.getFormattedVal(val.openingBalance)}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  {this.getFormattedVal(val.debitCurrentPeriod)}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  {this.getFormattedVal(
                                    val.creditCurrentPeriod
                                  )}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  {this.getFormattedVal(
                                    val.movementCurrentPeriod
                                  )}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                  {this.getFormattedVal(val.balanceToPeriod)}
                                </TableCell>
                              </TableRow>
                              {val.expanded &&
                                val.trialBalanceDetails.map((childVal) => (
                                  <TableRow
                                    style={{
                                      backgroundColor: "#EAEDEF",
                                      fontStyle: "italic",
                                    }}
                                    onDoubleClick={() =>
                                      this.onDoubleClickSubAccount(
                                        val.accountCode,
                                        childVal.subAccountCode
                                      )
                                    }
                                  >
                                    <TableCell>
                                      {childVal.subAccountCode}
                                    </TableCell>
                                    <TableCell>
                                      {childVal.subAccountName}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell style={{ textAlign: "right" }}>
                                      {val.debitCurrentPeriod !== 0
                                        ? this.getFormattedVal(childVal.amount)
                                        : null}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "right" }}>
                                      {val.creditCurrentPeriod !== 0
                                        ? "(" +
                                          this.getFormattedVal(
                                            childVal.amount
                                          ) +
                                          ")"
                                        : null}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                ))}
                            </>
                          ))}
                        </TableBody>
                        <TableHead>
                          {this.state.totalData.map((val) => (
                            <TableRow>
                              <TableCell style={styledTableCell}>
                                {val.accountCode}
                              </TableCell>
                              <TableCell style={styledTableCell}>
                                {val.accountName}
                              </TableCell>
                              <TableCell style={numberStyledTableCell}>
                                {this.getFormattedVal(val.openingBalance)}
                              </TableCell>
                              <TableCell style={numberStyledTableCell}>
                                {this.getFormattedVal(val.debitCurrentPeriod)}
                              </TableCell>
                              <TableCell style={numberStyledTableCell}>
                                {this.getFormattedVal(val.creditCurrentPeriod)}
                              </TableCell>
                              <TableCell style={numberStyledTableCell}>
                                {this.getFormattedVal(
                                  val.movementCurrentPeriod
                                )}
                              </TableCell>
                              <TableCell style={numberStyledTableCell}>
                                {this.getFormattedVal(val.balanceToPeriod)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableHead>
                      </Table>
                    </Grid>
                  </TableContainer>
                  </Grid>
                )}
                      </CardContent>
            </Card>
          </Grid>
                <div style={{ display: "none" }}>
                  <TrialBalancePrint
                    ref={(el) => (this.componentRef = el)}
                    data={this.state.rowData}
                    dateFrom={this.state.filterFields.dateFrom}
                    dateTo={this.state.filterFields.dateTo}
                    fiscalYear={this.state.currentFy}
                    periodFrom={this.state.filterFields.periodFrom}
                    periodTo={this.state.filterFields.periodTo}
                    totalData={this.state.totalData}
                    filterTerm={this.state.filterTerm}
                  />
                </div>
          </div>
    );
  }
}

export default withRouter(withStyles(styles)(TrialBalance));
