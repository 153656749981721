import React, { useState, useContext } from "react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  MenuItem,
  withStyles,
  Button,
  IconButton
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import Close from "@material-ui/icons/Close";
import UserContext from "../../utils/UserContext";

const styles = theme => ({
  cardHeight: {
    minWidth: "300px",
    width: "30vw",
    padding: "0px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "90%"
    },
    [theme.breakpoints.up("md")]: {
      height: "520px"
    },
    [theme.breakpoints.up("lg")]: {
      height: "520px"
    }
  }
});

function FiscalYearButton(props) {
  const { classes } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const { setSnackbarProps } = useContext(UserContext);

  const handleClick = () => {
    setModalOpen(true);
  };

  const changeStatus = (status, id) => {
    if (status === "INACTIVE" || status === "CLOSED") {
      Axios.put("/fy/openInactivePeriods/" + id, { id: id })
        .then(res => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000
          };
          setSnackbarProps({ snackbarProps: snackbarProps });

          props.populateTable();
          setModalOpen(false);
        })
        .catch(err => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000
          };
          setSnackbarProps({ snackbarProps: snackbarProps });
        });
    } else {
      Axios.put("fy/closeActivePeriods/" + id, { id: id })
        .then(res => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000
          };
          setSnackbarProps({ snackbarProps: snackbarProps });

          props.populateTable();
          setModalOpen(false);
        })
        .catch(err => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000
          };
          setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  const colorizedSpan = mode => {
    console.log(mode);

    if (mode === "OPEN") {
      return <span style={{ color: "#5FE3A1" }}>{mode}</span>;
    } else if (mode === "INACTIVE") {
      return <span style={{ color: "#FCA130" }}>{mode}</span>;
    } else {
      return <span style={{ color: "#FF5733" }}>{mode}</span>;
    }
  };

  const modal = {
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <span>
        <FontAwesomeIcon
          icon={faEllipsisV}
          color="#3F51B5"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        ></FontAwesomeIcon>
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={modal}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={modalOpen}>
          <div>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid container style={{ padding: "20px" }}>
                  <Grid container justify="center">
                    <Typography
                      gutterBottom
                      variant="h5"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "50px",
                        color: "#108DCD"
                      }}
                    >
                      Change Status
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h6">
                      <span style={{ fontWeight: "bold" }}>ID:</span>{" "}
                      {props.data.id}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h6">
                      <span style={{ fontWeight: "bold" }}>
                        Fiscal Year Period:
                    </span>{" "}
                      {props.data.fiscalYearPeriod}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h6">
                      <span style={{ fontWeight: "bold" }}>Date From:</span>{" "}
                      {props.data.dateFrom}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h6">
                      <span style={{ fontWeight: "bold" }}>Date To:</span>{" "}
                      {props.data.dateTo}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h6">
                      <span style={{ fontWeight: "bold" }}>Current Status:</span>{" "}
                      {colorizedSpan(props.data.periodStatus)}
                    </Typography>
                  </Grid>
                  <Grid container style={{ marginTop: "20px" }}>
                    <Grid item xs>
                      <TextField
                        id="standard-with-placeholder"
                        label="Change Status"
                        placeholder=""
                        style={{ width: "100%" }}
                        select
                        name="name"
                        variant="filled"
                        margin="dense"
                      >
                        {props.data.periodStatus === "INACTIVE" ||
                          props.data.periodStatus === "CLOSED" ? (
                            <MenuItem key={1} value="OPEN">
                              {colorizedSpan("OPEN")}
                            </MenuItem>
                          ) : (
                            <MenuItem key={2} value="CLOSED">
                              {colorizedSpan("CLOSED")}
                            </MenuItem>
                          )}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container justify="center">
                    <Button
                      onClick={() =>
                        changeStatus(props.data.periodStatus, props.data.id)
                      }
                      variant="contained"
                      size="large"
                      color="primary"
                      style={{
                        marginTop: "30px",
                        paddingLeft: "27px",
                        paddingRight: "27px",
                        paddingTop: "15px",
                        paddingBottom: "15px"
                      }}
                    >
                      Change Status
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(FiscalYearButton);
