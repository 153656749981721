import React, { Component } from "react";
import {
  Grid,
  Card,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  TextField,
  Button,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import { Dropdown, Label } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import VoucherNumberRestriction from "../../components/InterfaceElements/VoucherNumberRestriction";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { parseISO, isAfter, isBefore, format } from "date-fns";
import AddLedger from "../AccountMaster/AddLedger";
import AddSubLedger from "../AccountMaster/AddSubLedger";

const styles = (theme) => ({
  containerWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  root: {
    overflow: "visible",
    padding: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    margin: "auto",
    width: "98%",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class AddContraVoucher extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.addNewRow = this.addNewRow.bind(this);
    this.state = {
      requestData: {
        transactionDate: "",
        voucherNumber: "",
        reference: "",
        contraVoucherDetails: [],
        total: "0",
      },
      rowRefs: [],
      subRowRefs: [],
      accountList: [],
      subAccountList: [],
      costCenterList: [],
      tableList: [],
      tableRowData: {
        narration: "",
        costCenter: "",
        accountCode: "",
        subAccount: "",
        currencyAnalysis: "NPR",
        amount: "",
      },
      totalColor: "#FF8070",
      gridColor: [],
      periodList: [],
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      voucherNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      resData: [],
      masterNarration: "",
      emptyAccountCode: false,
      emptyAmount: false,
      periodDateFrom: "",
      periodDateTo: "",
      addAccountOpen: false,
      addSubAccountOpen: false,
      emptyCostCenter: false,
      emptySubAccount: false,
      saveButtonDisabled: false,
    };
  }

  resetTable = () => {
    this.setState({
      requestData: {
        voucherNumber: "",
        reference: "",
        contraVoucherDetails: [],
        total: 0,
      },
      date: "",
      subAccountList: [],
      tableList: [],
      tableRowData: {
        narration: "",
        costCenter: "",
        accountCode: "",
        subAccount: "",
        currencyAnalysis: "NPR",
        amount: "",
      },
      totalColor: "#FF8070",
      gridColor: [],
      masterNarration: "",
    });

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;
    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        transactionDate: date,
      },
    }));
    this.getAllPeriodListing();
  };

  onChanged = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        [targetName]: value,
      },
    }));
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState({
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        })
    );
  };

  handleCheck = () => {
    const token = this.context;

    var idList = [];

    if (parseInt(this.state.requestData.total) !== 0) {
      this.state.tableList.map((value, i) =>
        parseInt(value.amount) === parseInt(this.state.requestData.total)
          ? idList.push(i + 1)
          : null
      );
    }

    if (idList.length === 0 && parseInt(this.state.requestData.total) !== 0) {
      if (parseInt(this.state.requestData.total) < 0) {
        this.state.tableList.map((value, i) =>
          parseInt(value.amount) < 0 ? idList.push(i + 1) : null
        );
      } else {
        this.state.tableList.map((value, i) =>
          parseInt(value.amount) > 0 ? idList.push(i + 1) : null
        );
      }
    }

    if (idList.length === 0) {
      var snackbarProps = {
        open: true,
        variant: "success",
        message: "Credit and Debit line balanced. Safe to POST.",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: snackbarProps });
    }
    var gridColor = this.state.gridColor;

    idList.map((value) => (gridColor[value] = "#FFA99E"));

    this.setState({
      gridColor,
    });

    var newGridColors = [];

    idList.map((value) => newGridColors.push("#FFFFFF"));

    setTimeout(() => {
      this.setState({
        gridColor: newGridColors,
      });
    }, 1000);
  };

  getAllCostCenters = () => {
    Axios.get("pos/costCenter/findAll")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          costCenterList: res.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getVoucherNoList = () => {
    Axios.get("pos/voucherNumbering/getNumbering/Contra Voucher")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          requestData: {
            ...prevState.requestData,
            voucherNumber: res.data[0],
          },
        }));
        if (res.data === "Not Found") {
          this.setState({ voucherNumberError: true });
        } else {
          this.setState((prevState) => ({
            ...prevState,
            voucherNoList: res.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  customSearch = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = this.state.rowRefs;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );

    if (isNaN(queryText)) {
      newSet = newSet.sort((x, y) =>
        x.text.split("-")[1][0].toLowerCase() === queryText
          ? -1
          : y.text.split("-")[1][0].toLowerCase() === queryText
          ? 1
          : 0
      );
    } else {
      newSet = newSet.sort((x, y) =>
        x.text[0] === queryText ? -1 : y.text[0] === queryText ? 1 : 0
      );
    }

    console.log({ newSet });

    return [
      {
        key: "add",
        value: "add",
        text: "Add new account code",
      },
      { key: "none", value: "none", text: "None" },
      ...newSet,
    ];
  };

  customSearchForSub = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = this.state.subRowRefs;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );

    if (isNaN(queryText)) {
      newSet = newSet.sort((x, y) =>
        x.text.split("-")[1][0].toLowerCase() === queryText
          ? -1
          : y.text.split("-")[1][0].toLowerCase() === queryText
          ? 1
          : 0
      );
    } else {
      newSet = newSet.sort((x, y) =>
        x.text[0] === queryText ? -1 : y.text[0] === queryText ? 1 : 0
      );
    }

    console.log({ newSet });

    return [
      {
        key: "add",
        value: "add",
        text: "Add new sub-account code",
      },
      { key: "none", value: "none", text: "None" },
      ...newSet,
    ];
  };

  componentDidMount() {
    this.getAllCostCenters();
    this.getAllPeriodListing();
    var rowDetails = [];
    Axios.get("pos/ledgerMaster/getAllActiveAccountGroup")
      .then(async (res) => {
        res.data.forEach((element) => {
          var item = {
            key: element.code,
            value: element.code,
            text: element.code + "-" + element.name,
          };
          rowDetails.push(item);
        });
        await this.setState({
          accountList: res.data,
          rowRefs: rowDetails,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;
    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        transactionDate: date,
      },
    }));
  }

  getAccountGroups = () => {
    var rowDetails = [];

    Axios.get("pos/ledgerMaster/getAllActiveAccountGroup")
      .then(async (res) => {
        res.data.forEach((element) => {
          var item = {
            key: element.code,
            value: element.code,
            text: element.code + "-" + element.name,
          };
          rowDetails.push(item);
        });

        await this.setState({
          accountList: res.data,
          rowRefs: rowDetails,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      requestData:{
        ...prevState.requestData,
      voucherNumber: val
      },
      voucherNumber: val,
    }));
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });

        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name,
        }));
        this.getPeriodList();
        this.getVoucherNumbering(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getVoucherNumbering(fiscalYear){
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", { "fiscalYear":fiscalYear, "name":"Contra Voucher"})
    .then((res) =>{
      if (res.data === "Not Found") {
                this.setState({ voucherNumberError: true });
              } else {
      this.setState((prevState)=>({
        ...prevState,
        requestData:{
          ...prevState.requestData,
          voucherNumber:res.data[0]
        }
    }))
  }
  })
  }

  handleFYChange = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: Fy,
    }));
    this.getPeriodList();
    this.getVoucherNumbering(Fy);
  };

  getPeriodList() {
    var pLists = [];
    this.state.resData.map((val) =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );

    this.setState({
      periodList: pLists,
      currentPeriod:pLists[pLists.length-1]
    });
    this.getPeriodDateFromTo(this.state.fiscalYear, pLists[pLists.length-1]);
  }

  handleMasterNarrationChange = (e) => {
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      masterNarration: value,
      tableRowData: {
        ...prevState.tableRowData,
        narration: value,
      },
    }));
  };

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      currentPeriod: Py,
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear, Py);
  };

  shouldDisableDate = (date) => {
    let periodFrom = parseISO(this.state.periodDateFrom);
    let periodTo = parseISO(this.state.periodDateTo);
    let currentDate = new Date(date);

    if (isAfter(currentDate, periodTo) || isBefore(currentDate, periodFrom)) {
      return true;
    } else {
      return false;
    }
  };

  onChangedDate = (date) => {
    let formattedDate = format(new Date(date), "yyyy-MM-dd");

    console.log({ formattedDate });

    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        transactionDate: formattedDate,
      },
    }));
  };

  deleteRow = (index) => {
    var currentRows = this.state.tableList;

    currentRows.splice(
      currentRows.findIndex((e) => e.id === index),
      1
    );

    var totalAmount = 0;
    currentRows.forEach((element, i) => {
      element.id = i + 1;
      totalAmount += parseInt(element.amount);
    });

    this.setState((prevState) => ({
      ...prevState,
      tableList: currentRows,
      requestData: {
        ...prevState.requestData,
        total: totalAmount,
      },
    }));
  };

  getSubAccountList = (accountCode) => {
    let rowDetails = [];

    Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCode
    ).then((res) => {
      res.data.forEach((element) => {
        var item = {
          key: element.code,
          value: element.code,
          text: element.code + "-" + element.name,
        };
        rowDetails.push(item);
      });

      this.setState({
        subAccountList: res.data,
        subRowRefs: rowDetails,
      });
    });
  };

  onChangedTableRow = async (e) => {
    e.stopPropagation();

    var totalAmount = 0;

    var targetName = e.target.name;
    var value = e.target.value;

    var id = targetName.split(".")[1];
    var name = targetName.split(".")[0];

    var tableList = this.state.tableList;

    var arrIndex = tableList.findIndex((e) => e.id === parseInt(id));

    var currentRow = tableList[arrIndex];

    if (name === "accountCode") {
      let res = await Axios.get(
        "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + value
      );

      currentRow["subAccountList"] = res.data;
      currentRow["subAccount"] = "";
    }

    if (name === "amount") {
      var isCredit = parseInt(value) < 0;
      var debitCredit = isCredit ? "Credit" : "Debit";

      currentRow["debitCredit"] = debitCredit;
    }

    currentRow[name] = value;

    tableList[arrIndex] = currentRow;

    tableList.forEach((element) => {
      totalAmount += parseInt(element.amount);
    });

    var totalColor = "#FF8070";

    if (parseInt(totalAmount) === 0) totalColor = "#43A047";

    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        total: totalAmount,
      },
      totalColor,
    }));
  };

  handleItemSelect = (e, data) => {
    e.stopPropagation();
    const { value } = data;

    if (value === "add") {
      if (data.name === "accountCode") {
        this.setState({ addAccountOpen: true });
      } else {
        this.setState({ addSubAccountOpen: true });
      }
      return;
    }

    var targetName = data.name;

    if (targetName === "accountCode") {
      this.getSubAccountList(value);
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          subAccount: "",
          [targetName]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          [targetName]: value,
        },
      }));
    }
  };

  onChangedEntry = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    if (targetName === "accountCode") {
      this.getSubAccountList(value);
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          subAccount: "Sub Account",
          [targetName]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          [targetName]: value,
        },
      }));
    }
  };

  addNewRow = () => {
    this.textInput.current.focus();
    var tableList = this.state.tableList;
    var gridColor = this.state.gridColor;

    var totalAmount = this.state.requestData.total;

    const {
      narration,
      costCenter,
      accountCode,
      subAccount,
      currencyAnalysis,
      amount,
    } = this.state.tableRowData;

    if (
      this.state.tableRowData.accountCode === "" &&
      this.state.tableRowData.amount === "" &&
      this.state.tableRowData.costCenter === "" &&
      this.state.tableRowData.subAccount === ""
    ) {
      this.setState((prevState) => ({
        ...prevState,
        emptyAccountCode: true,
        emptyAmount: true,
        emptyCostCenter: true,
        emptySubAccount: true,
      }));
      return;
    } else if (this.state.tableRowData.accountCode === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyAccountCode: true,
        emptyAmount: false,
        emptyCostCenter: false,
        emptySubAccount: false,
      }));
      return;
    } else if (this.state.tableRowData.amount === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyAmount: true,
        emptyCostCenter: false,
        emptySubAccount: false,
        emptyAccountCode: false,
      }));
      return;
    } else if (this.state.tableRowData.costCenter === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyCostCenter: true,
        emptyAmount: false,
        emptySubAccount: false,
        emptyAccountCode: false,
      }));
      return;
    } else if (this.state.tableRowData.subAccount === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptySubAccount: true,
        emptyCostCenter: false,
        emptyAmount: false,
        emptyAccountCode: false,
      }));
      return;
    }
    else{
      this.setState((prevState) => ({
        ...prevState,
        emptySubAccount: false,
        emptyCostCenter: false,
        emptyAmount: false,
        emptyAccountCode: false,
      }));
    }

    totalAmount = parseInt(totalAmount) + parseInt(amount);

    var isCredit = parseInt(this.state.tableRowData.amount) < 0;
    var debitCredit = isCredit ? "Credit" : "Debit";

    var accountCodeSave = accountCode === "Account Code" ? "" : accountCode;
    var subAccountSave = subAccount === "Sub Account" ? "" : subAccount;
    var costCenterSave = costCenter === "Cost Center" ? "" : costCenter;

    var newRowsPrep = {
      narration: narration,
      accountCode: accountCodeSave,
      subAccount: subAccountSave,
      costCenter: costCenterSave,
      amount: amount,
    };

    console.log({ newRowsPrep });

    if (accountCodeSave !== "") {
      Axios.get(
        "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCodeSave
      ).then((res) => {
        tableList.push({
          ...newRowsPrep,
          id: this.state.tableList.length + 1,
          debitCredit: debitCredit,
          currencyAnalysis: currencyAnalysis,
          subAccountList: res.data,
        });

        gridColor[this.state.tableList.length] = "#FFFFFF";

        var totalColor = "#FF8070";

        if (parseInt(totalAmount) === 0) totalColor = "#43A047";

        this.setState((prevState) => ({
          ...prevState,
          emptySubAccount: false,
          emptyAccountCode: false,
          emptyAmount: false,
          tableList,
          tableRowData: {
            narration: this.state.masterNarration,
            costCenter: "",
            accountCode: "",
            subAccount: "",
            currencyAnalysis: "NPR",
            amount: "",
          },
          requestData: {
            ...prevState.requestData,
            total: totalAmount,
          },
          totalColor,
        }));
      });
    }
  };

  handleSubmit = () => {
    const token = this.context;
    this.setState({ saveButtonDisabled: true });

    if (parseInt(this.state.requestData.total) !== 0) {
      var snackbarProps = {
        open: true,
        variant: "error",
        message: "CREDIT and DEBIT line NOT BALANCED",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: snackbarProps });

      this.handleCheck();

      return;
    }

    var contraVoucherDetails = [];

    this.state.tableList.forEach((element) => {
      contraVoucherDetails.push({
        narration: element.narration,
        costCenter: element.costCenter,
        accountCode: element.accountCode,
        subAccount: element.subAccount,
        amount: Math.abs(element.amount),
        analysis: element.currencyAnalysis,
        voucherType: element.debitCredit,
      });
    });

    var reqData = this.state.requestData;

    reqData = {
      ...reqData,
      contraVoucherDetails,
      fiscalYear: this.state.fiscalYear,
      period: this.state.currentPeriod,
      masterNarration: this.state.masterNarration,
    };

    if (reqData.contraVoucherDetails.length === 0) {
      let snackbarProps = {
        open: true,
        variant: "error",
        message: "Voucher transactions are empty",
        autoHideDuration: 2000,
      };

      token.setSnackbarProps({ snackbarProps: snackbarProps });
      return;
    }

    console.log(reqData);

    Axios.post("/pos/contraVoucher/save", reqData)
      .then((res) => {
        this.setState({ saveButtonDisabled: false });
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.resetTable();
        this.props.getTableDataForAuth();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  render() {
    const { value, index, classes } = this.props;

    const styledTableCell = {
      padding: "10px",
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      borderRight: "1px solid #EAEDEF",
    };

    return (
      <>
        <Grid container justify="center">
          <Card
            role="tabpanel"
            hidden={value !== index}
            className={classes.root}
          >
            <Grid container spacing={2} className={classes.containerWidth}>
            <Grid item xs={12} sm={6} md={2}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Fiscal Year"
                  name="fiscalYear"
                  onChange={this.handleFYChange}
                  value={this.state.fiscalYear}
                >
                  {this.state.fyList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  value={this.state.requestData.voucherNumber}
                  name="voucherNumber"
                  fullWidth
                  onChange={this.handleChange}
                  label="Voucher Number"
                >
                </TextField>
              </Grid>

              
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Period"
                  name="period"
                  onChange={this.handlePeriodChange}
                  value={this.state.currentPeriod}
                >
                  {this.state.periodList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="Date"
                    value={this.state.requestData.transactionDate}
                    inputVariant="outlined"
                    autoOk={true}
                    format="yyyy-MM-dd"
                    margin="dense"
                    fullWidth
                    onChange={this.onChangedDate}
                    shouldDisableDate={this.shouldDisableDate}
                    animateYearScrolling
                    minDate={this.state.periodDateFrom}
                    maxDate={this.state.periodDateTo}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Master Narration"
                  onChange={this.handleMasterNarrationChange}
                  value={this.state.masterNarration}
                ></TextField>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "25px", marginBottom: "10px" }} />
            <Typography
              variant="h6"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              Entry
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={4}></Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
              <Grid item xs>
                <span style={{ color: "#43A047" }}>( + )</span>{" "}
                <span style={{ fontWeight: "bold" }}>Dr. </span>
                <span style={{ color: "#FF8070" }}>( - )</span>{" "}
                <span style={{ fontWeight: "bold" }}>Cr.</span>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={8} lg={3} style={{ marginTop: "3px" }}>
                <TextField
                  label="Narration"
                  fullWidth
                  value={this.state.tableRowData.narration}
                  variant="outlined"
                  name="narration"
                  onChange={this.onChangedEntry}
                  margin="dense"
                  inputRef={this.textInput}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={4} lg={1} style={{ marginTop: "3px" }}>
                {this.state.emptyCostCenter ? (
                  <>
                    <TextField
                      // label={this.state.tableRowData.costCenter}
                      label="Cost Center"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      select
                      value={this.state.tableRowData.costCenter}
                      name="costCenter"
                      helperText="field should not be empty"
                      error
                      onChange={this.onChangedEntry}

                    >
                       {this.state.costCenterList.map((value) => (
                        <MenuItem key={value.id} value={value.code}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                ) : (
                  <>
                    <TextField
                      // label={this.state.tableRowData.costCenter}
                      label="Cost Center"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      select
                      value={this.state.tableRowData.costCenter}
                      name="costCenter"
                      onChange={this.onChangedEntry}
                    >
                      {this.state.costCenterList.map((value) => (
                        <MenuItem key={value.id} value={value.code}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
                {this.state.emptyAccountCode ? (
                  <>
                    <Dropdown
                      placeholder="Account Code"
                      name="accountCode"
                      fluid
                      fullWidth
                      //error
                      select
                      selectOnBlur={false}
                      variant="outlined"
                      search={this.customSearch}
                      style={{ marginTop: "8px", border: "1px solid red" }}
                      ref={(search) => {
                        this.searchInput = search;
                      }}
                      selection
                      value={this.state.tableRowData.accountCode}
                      onChange={this.handleItemSelect}
                      options={[
                        {
                          key: "add",
                          value: "add",
                          text: "Add new account code",
                        },
                        ...this.state.rowRefs,
                      ]}
                    ></Dropdown>
                    <Label
                      basic
                      style={{
                        border: "0px",
                        fontWeight: 400,
                        fontSize: "0.75rem",
                        font: "Helvetica",
                        color: "#f44336",
                        letterSpacing: "0.03333em",
                      }}
                    >
                      field should not be empty
                    </Label>
                  </>
                ) : (
                  <Dropdown
                    placeholder="Account Code"
                    name="accountCode"
                    floating
                    fluid
                    fullWidth
                    selectOnBlur={false}
                    search={this.customSearch}
                    style={{ marginTop: "8px" }}
                    ref={(search) => {
                      this.searchInput = search;
                    }}
                    selection
                    value={this.state.tableRowData.accountCode}
                    // onKeyDown={this.onKeyPress}
                    onChange={this.handleItemSelect}
                    options={[
                      {
                        key: "add",
                        value: "add",
                        text: "Add new account code",
                      },
                      ...this.state.rowRefs,
                    ]}
                  ></Dropdown>
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
                {this.state.emptySubAccount ? (
                  <>
                    <Dropdown
                      placeholder="Sub Account"
                      name="subAccount"
                      floating
                      fluid
                      fullWidth
                      selectOnBlur={false}
                      search={this.customSearchForSub}
                      ref={(search) => {
                        this.searchInput = search;
                      }}
                      selection
                      value={this.state.tableRowData.subAccount}
                      // onKeyDown={this.onKeyPress}
                      onChange={this.handleItemSelect}
                      style={{ marginTop: "8px", border: "1px solid red" }}
                      options={[
                        {
                          key: "add",
                          value: "add",
                          text: "Add new sub-account code",
                        },
                        { key: "none", value: "none", text: "None" },
                        ...this.state.subRowRefs,
                      ]}
                    ></Dropdown>
                    <Label
                      basic
                      style={{
                        border: "0px",
                        fontWeight: 400,
                        fontSize: "0.75rem",
                        font: "Helvetica",
                        color: "#f44336",
                        letterSpacing: "0.03333em",
                      }}
                    >
                      field should not be empty
                    </Label>
                  </>
                ) : (
                  <>
                    <Dropdown
                      placeholder="Sub Account"
                      name="subAccount"
                      floating
                      fluid
                      fullWidth
                      selectOnBlur={false}
                      search={this.customSearchForSub}
                      style={{ marginTop: "8px" }}
                      ref={(search) => {
                        this.searchInput = search;
                      }}
                      selection
                      value={this.state.tableRowData.subAccount}
                      // onKeyDown={this.onKeyPress}
                      onChange={this.handleItemSelect}
                      options={[
                        {
                          key: "add",
                          value: "add",
                          text: "Add new sub-account code",
                        },
                        { key: "none", value: "none", text: "None" },
                        ...this.state.subRowRefs,
                      ]}
                    ></Dropdown>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={1} style={{ marginTop: "3px" }}>
                <TextField
                  label="Currency"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.tableRowData.currencyAnalysis}
                  name="currencyAnalysis"
                  onChange={this.onChangedEntry}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
                {this.state.emptyAmount ? (
                  <TextField
                    label="Amount"
                    type="number"
                    fullWidth
                    error
                    helperText="Field should not be empty"
                    variant="outlined"
                    margin="dense"
                    value={this.state.tableRowData.amount}
                    name="amount"
                    onChange={this.onChangedEntry}
                  ></TextField>
                ) : (
                  <TextField
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={this.state.tableRowData.amount}
                    name="amount"
                    onChange={this.onChangedEntry}
                  ></TextField>
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={1} style={{ marginTop: "3px" }}>
                <Button
                  style={{
                    marginTop: "8px",
                    padding: "7px",
                    width: "100px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={this.addNewRow}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "25px", marginBottom: "10px" }} />

            {this.state.tableList.length > 0 ? (
              <div>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Transaction List
                </Typography>

                <Grid
                  container
                  justify="flex-end"
                  style={{ paddingRight: "10px" }}
                  spacing={4}
                >
                  <Grid item>
                    <Button
                      style={{
                        marginTop: "8px",
                        paddingTop: "10px",
                        paddingRight: "25px",
                        paddingLeft: "25px",
                        paddingBottom: "10px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={this.handleCheck}
                    >
                      Check
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        paddingTop: "6px",
                        paddingBottom: "11px",
                      }}
                    >
                      Total:{" "}
                      <span style={{ color: this.state.totalColor }}>
                        {this.state.requestData.total}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table
                    aria-label="customized table"
                    //   fixedHeader={false} style={{ width: "auto", tableLayout: "auto",  marginTop: "10px", marginBottom: "15px"  }}
                    fixedHeader={false}
                    style={{
                      marginTop: "10px",
                      marginBottom: "15px",
                      border: "1px solid #EAEDEF",
                    }}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={styledTableCell} align="center">
                          {" "}
                          S. No.{" "}
                        </TableCell>
                        <TableCell style={styledTableCell} align="center">
                          Dr./Cr.
                        </TableCell>
                        <TableCell style={styledTableCell}>Narration</TableCell>
                        <TableCell style={styledTableCell}>
                          Cost Center
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          Account Code
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          Sub Account
                        </TableCell>
                        <TableCell style={styledTableCell}>Analysis</TableCell>
                        <TableCell style={styledTableCell}>Amount</TableCell>
                        <TableCell style={styledTableCell} align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.tableList.map((value) => (
                        <TableRow
                          style={{
                            backgroundColor: this.state.gridColor[value.id],
                            transition: "background-color 1s",
                          }}
                        >
                          <TableCell
                            align="center"
                            style={{
                              width: "50px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            {value.id}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "60px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                            align="center"
                          >
                            {value.debitCredit}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              value={value.narration}
                              name={"narration." + value.id}
                              onChange={this.onChangedTableRow}
                              fullWidth
                              style={{ minWidth: "160px" }}
                              variant="outlined"
                              margin="dense"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "80px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              label={value.costCenter}
                              value={value.costCenter}
                              name={"costCenter." + value.id}
                              fullWidth
                              style={{
                                width: "80px",
                              }}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              select
                              margin="dense"
                            >
                              {this.state.costCenterList.map((value) => (
                                <MenuItem key={value.id} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "130px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              label={value.accountCode}
                              value={value.accountCode}
                              select
                              fullWidth
                              style={{ width: "130px" }}
                              name={"accountCode." + value.id}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            >
                              {this.state.accountList.map((value) => (
                                <MenuItem key={value.id} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "130px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              label={value.subAccount}
                              value={value.subAccount}
                              fullWidth
                              select
                              style={{ width: "130px" }}
                              name={"subAccount." + value.id}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            >
                              {value.subAccountList.map((value) => (
                                <MenuItem key={value.id} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "65px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              value={value.currencyAnalysis}
                              name={"currencyAnalysis." + value.id}
                              fullWidth
                              style={{ width: "65px" }}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "130px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              value={value.amount}
                              fullWidth
                              style={{ width: "130px" }}
                              type="number"
                              name={"amount." + value.id}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: "30px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faMinusCircle}
                                size="lg"
                                style={{ color: "#FF8070", cursor: "pointer" }}
                                onClick={() =>
                                  this.deleteRow(value.id, "debit")
                                }
                              ></FontAwesomeIcon>
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            <Grid container justify="center">
              <Button
                onClick={this.handleSubmit}
                disabled={this.state.saveButtonDisabled}
                variant="contained"
                size="large"
                color="primary"
                style={{
                  marginBottom: "20px",
                  marginTop: "0px",
                  paddingLeft: "47px",
                  paddingRight: "47px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                Add Voucher
              </Button>
            </Grid>
          </Card>
        </Grid>
        <AddLedger
          open={this.state.addAccountOpen}
          handleClose={() => this.setState({ addAccountOpen: false })}
          getAccountGroups={this.getAccountGroups}
        />
        <AddSubLedger
          open={this.state.addSubAccountOpen}
          handleClose={() => this.setState({ addSubAccountOpen: false })}
        />
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </>
    );
  }
}

export default withStyles(styles)(AddContraVoucher);
