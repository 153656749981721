import React, { Component } from "react";
import {
  Card,
  Typography,
  Grid,
  TextField,
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  Button,
  TablePagination,
  MenuItem
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "semantic-ui-react";
import { Animated } from "react-animated-css";
import ViewContraVoucherList from "./ViewContraVoucherList";
import Axios from "axios";

const styles = theme => ({
  root: {
    padding: 20,
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95vw",
      margin: "auto",
      marginBottom: "170px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
      margin: "auto",
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "70vw",
      margin: "auto",
      marginBottom: "100px"
    }
  }
});

class ContraVoucherList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorArr: {},
      filterShow: false,
      page: 0,
      rowsPerPage: 5,
      rowData: [],
      dataToSend: {},
      auth: {
        visible: false,
        hidden: true,
        secondVisible: false,
        secondHidden: true,
        animationIn: ""
      },
      fyList: [],
      fiscalYear: ""
    };
  }

  onHovered = index => {
    var newColors = this.state.colorArr;
    newColors[index] = "#F5F5F5";

    this.setState({
      colorArr: newColors
    });
  };

  onLeft = index => {
    var newColors = this.state.colorArr;
    newColors[index] = "#FFFFFF";

    this.setState({
      colorArr: newColors
    });
  };

  componentDidMount() {
    this.getAllFYListing();
  }

  handleFyChange = e => {
    var val = e.target.value;
    this.setState({fiscalYear : val})
    this.props.handleFyChange(val, "list");
  };

  handleDateFrom = e => {
    var val = e.target.value;

    this.props.handleDateFrom(val, "list");
  };

  handleDateTo = e => {
    var val = e.target.value;

    this.props.handleDateTo(val, "list");
  };

  handleVoucherChange = (_e, { value }) => {
    this.props.handleVoucherCodeChange(value, "list");
  };

  getAllFYListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async res => {
        var fiscalYearLists = [];
        res.data.forEach(element => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          fiscalYear: fiscalYearLists[0]
        });
        this.props.handleFyChange(this.state.fiscalYear, "list");
      })
      .catch(err => {
        console.log(err);
      });
  };

  generateList() {
    let arr = [];
    let data = this.props.dataList;

    for (let index = 0; index < data.length; index++) {
      arr.push(
        <TableRow hover>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            {" "}
            <span style={{ fontWeight: "bold" }}>{data[index].period}</span>
          </TableCell>
          <TableCell>{data[index].voucherNumber}</TableCell>
          <TableCell>{data[index].transactionDate}</TableCell>
          <TableCell>{data[index].approvedDate}</TableCell>
          <TableCell>{data[index].masterNarration}</TableCell>
          <TableCell
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => this.props.animate(data[index].id)}
          >
            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
          </TableCell>
        </TableRow>
      );
    }
    return arr;
  }

  handleRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  render() {
    const {
      hidden,
      secondHidden,
      animationIn,
      visible,
      secondVisible
    } = this.props.visible;
    const inputStyles = {
      fontSize: 14
    };

    const { classes } = this.props;

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;

    const textFieldStyle = {
      marginBottom: "23px",
      marginTop: "23px",
      width: "100%"
    };

    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5"
    };

    return (
      <div>
        <Grid container justify="center">
          <Grid item>
            <Animated
              animationIn={animationIn}
              animationOut="zoomOut"
              isVisible={visible}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <Card
                role="tabpanel"
                hidden={hidden}
                className={classes.root}
              >
                <Typography
                  gutterButtom
                  variant="h5"
                  style={{ fontWeight: "bolder", marginBottom: "10px" }}
                >
                  Authorized Contra Voucher List
                </Typography>
                <Grid container spacing={1}>
                  <Grid item>
                    <TextField
                      select
                      style={{ width: "130px" }}
                      label="Fiscal Year"
                      name="fiscalYear"
                      onChange={this.handleFyChange}
                      value={this.state.fiscalYear}
                    >
                      {this.state.fyList.map(item => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      style={{ marginTop: "15px" }}
                      onClick={() => {
                        this.setState({ filterShow: !this.state.filterShow });
                      }}
                    >
                      <Typography variant="button">
                        <FontAwesomeIcon
                          icon={faSlidersH}
                          style={{ marginRight: "5px" }}
                        ></FontAwesomeIcon>
                        Filter
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                {this.state.filterShow ? (
                  <Grid
                    container
                    spacing={4}
                    style={{
                      marginLeft: "0px",
                      marginTop: 20,
                      marginBottom: 20,
                      border: "#87CEFA 2px solid",
                      borderRadius: "10px",
                      width: "67vw"
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        marginLeft: "2em",
                        height: "10px",
                        display: "inline",
                        backgroundColor: "#fff",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingBottom: "15px"
                      }}
                    >
                      {" "}
                    </div>
                    <Grid container spacing={4} style={{ padding: 20 }}>
                      <Grid item xs={7} md={6} sm={6}>
                        <Dropdown
                          placeholder="Search by Voucher Code"
                          fluid
                          search
                          ref={search => {
                            this.searchInput = search;
                          }}
                          selection
                          value={this.props.customer}
                          options={this.props.voucherList}
                          onChange={this.handleVoucherChange}
                          style={{ marginTop: "33px" }}
                        ></Dropdown>
                      </Grid>
                      <Grid item xs sm={3}>
                        <TextField
                          id="date-picker-inline"
                          label="Date From"
                          name="dateFrom"
                          defaultValue={date}
                          type="date"
                          onChange={this.handleDateFrom}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs sm={3}>
                        <TextField
                          id="date-picker-inline"
                          label="Date To"
                          name="dateTo"
                          defaultValue={date}
                          type="date"
                          onChange={this.handleDateFrom}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                <TableContainer>
                  <Table
                    aria-label="customized table"
                    style={{ marginTop: "20px", marginBottom: "15px" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={styledTableCell}> S. No.</TableCell>
                        <TableCell style={styledTableCell}> Period</TableCell>
                        <TableCell style={styledTableCell}>
                          {" "}
                          Voucher Number
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          {" "}
                          Transaction Date{" "}
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          {" "}
                          Post Date{" "}
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          {" "}
                          Narration{" "}
                        </TableCell>
                        <TableCell align="center" style={styledTableCell}>
                          {" "}
                          View
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.generateList().slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={this.props.dataList.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={(event, newPage) => {
                    this.setState({ page: newPage });
                  }}
                  onChangeRowsPerPage={this.handleRowsPerPage}
                />
              </Card>
            </Animated>
          </Grid>
          <Grid item>
            <Animated
              animationIn="zoomIn"
              animationOut="zoomOut"
              isVisible={secondVisible}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <ViewContraVoucherList
                hidden={secondHidden}
                animateOut={this.props.animateOut}
                data={this.props.data}
              />
            </Animated>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default (withStyles(styles)(ContraVoucherList));
