import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  withStyles,
} from "@material-ui/core";
import ViewButton from "../InterfaceElements/ViewButton";
import UserContext from "../../utils/UserContext";

const styles = (theme) => ({
  root: {
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class SupplierDetails extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
          maxWidth: 80,
        },
        {
          headerName: "Id Number",
          field: "supplierIdNumber",
          resizable: true,
          minWidth: 80,
          maxWidth: 100,
        },
        {
          headerName: "Name",
          field: "fullName",
          sortable: true,
          resizable: true,
          minWidth: 250,
        },
        {
          headerName: "Address",
          field: "address",
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "Mobile No.",
          field: "mobileNumber",
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "Email",
          field: "emailAddress",
          resizable: true,
          minWidth: 140,
        },
        {
          headerName: "PAN Number",
          field: "panNumber",
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "View",
          cellRendererFramework: ViewButton,
          cellRendererParams: {
            updateData: this.props.getTableData,
            showSnackbar: this.props.showSnackbar,
          },
          minWidth: 50,
        },
      ],
      rowData: [],
      open: false,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  componentDidUpdate() {
    if (this.gridApi !== undefined) {
      this.gridApi.setRowData(this.props.supplierData);
    }
  }

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.props.setGridApi(params.api);
  };

  render(params) {
    const { children, value, classes, index, ...other } = this.props;

    return (
      <div>
        <Card
          className={classes.root}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center">
              <Typography variant="h6" component="h2">
                Supplier List
              </Typography>
            </Grid>

            <Grid container justify="center">
              <div
                className="ag-theme-balham"
                style={{
                  height: "465px",
                  width: "100%",
                  padding: "10px",
                }}
              >
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onFirstDataRendered={this.firstDataRendered}
                  onGridReady={this.onGridReady}
                  overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                  overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                ></AgGridReact>
              </div>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(SupplierDetails);
