import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Backdrop,
  withStyles,
  MenuItem,
  IconButton,
  Typography
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  cardHeight: {
    minWidth: "300px",
    padding: "5px",
    overflowY: "auto",
    height: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    [theme.breakpoints.up("md")]: {
      width: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "66%"
    }
  },
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%"
    }
  }
});

class ViewCustomerButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      buttonColor: "#108dcd",
      data: {
        fullName: "",
        mobileNumber: "",
        emailAddress: "",
        city: "",
        country: "",
        state: "",
        street: "",
        customerIdNumber: "",
        effectiveDate: "",
        customerType: "Regular Customer",
        paymentMethod: "Cash",
        creditTerm: "",
        otherReference: "",
        editable: false
      }
    };
  }

  componentDidMount() {
 
  }

  handleOpen = () => {
    this.setState({ open: true, editable: false });
    this.getData();
  };

  getData(){
    this.setState({
      data: {
        fullName: this.props.node.data.name,
        mobileNumber: this.props.node.data.mobileNum,
        emailAddress: this.props.node.data.email,
        city: this.props.node.data.city,
        country: this.props.node.data.country,
        state: this.props.node.data.state,
        street: this.props.node.data.street,
        customerIdNumber: this.props.node.data.customerIdNumber,
        effectiveDate: this.props.node.data.effectiveDate,
        customerType: this.props.node.data.customerType,
        paymentMethod: this.props.node.data.paymentMethod,
        creditTerm: this.props.node.data.creditTerm,
        otherReference: this.props.node.data.otherReference
      }
    });
  }

  handleClose = () => {
    this.setState({ open: false, buttonColor: "#108dcd" });
  };

  handleUpdate = () => {
    var id = this.props.node.data.id;
    Axios.put("pos/customer/updateCustomer/" + id, this.state.data)
      .then(res => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };
        this.props.updateData();
        this.props.showSnackbar(snackbarProps);
      })
      .catch(err => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };

        this.props.showSnackbar(snackbarProps);
      });
  };

  handleDelete = () => {
    var id = this.props.node.data.id;
    Axios.put("/pos/customer/deleteCustomer/" + id, this.state.data)
      .then(res => {

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };
        this.props.updateData();
        this.props.showSnackbar(snackbarProps);
      })
      .catch(err => {
        console.log(err)
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000
        };
        this.props.showSnackbar(snackbarProps);
      })
  }

  onChanged = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;

    if (!this.state.editable) return;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  render() {
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const textFieldStyle = {
      width: "100%"
    };

    const inputStyles = {
      fontSize: 14
    };

    const { classes } = this.props;
    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.setState({ editable: true, buttonColor: "#1044CD" });
                    }}
                    style={{
                      margin: "10px",
                      marginBottom: "0px",
                      backgroundColor: this.state.buttonColor
                    }}
                  >
                    <Typography style={{ color: "white" }}> Edit</Typography>
                  </Button>
                </Grid>
                <Grid container className={classes.containerWidth}>
                  <Grid container justify="center" spacing={2} style={{ marginTop: "0px" }}>
                    <Grid item xs={12} >
                      <Grid
                        container
                        justify="center"
                        style={{ height: "10px", marginBottom: "50px" }}
                      >
                        <h6>Customer Details</h6>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Full Name"
                        name="fullName"
                        onChange={this.onChanged}
                        value={this.state.data.fullName}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Customer ID Number"
                        name="customerIdNumber"
                        onChange={this.onChanged}
                        value={this.state.data.customerIdNumber}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Effective Date"
                        name="effectiveDate"
                        onChange={this.onChanged}
                        value={this.state.data.effectiveDate}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Mobile Number"
                        name="mobileNumber"
                        onChange={this.onChanged}
                        value={this.state.data.mobileNumber}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Email Address"
                        name="emailAddress"
                        onChange={this.onChanged}
                        value={this.state.data.emailAddress}
                        placeholder=""
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>


                  <Grid
                    container
                    justify="center"
                    spacing={2}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        justify="center"
                        style={{ height: "20px", marginBottom: "50px" }}
                      >
                        <h6>Customer Type Details</h6>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label={"Customer Type"}
                        style={textFieldStyle}
                        name="customerType"
                        onChange={this.onChanged}
                        value={this.state.data.customerType}
                        InputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="normal"
                      >
                        <MenuItem key={1} value={"Regular Customer"}>
                          Regular Customer
                        </MenuItem>
                        <MenuItem key={2} value={"New Customer"}>
                          New Customer
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Payment Method"
                        name="paymentMethod"
                        onChange={this.onChanged}
                        value={this.state.data.paymentMethod}
                        style={textFieldStyle}
                        InputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="normal"
                      >
                        <MenuItem key={1} value={"Cash"}>
                          Cash
                        </MenuItem>
                        <MenuItem key={2} value={"Cheque"}>
                          Cheque
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Credit Term "
                        onChange={this.onChanged}
                        value={this.state.data.creditTerm}
                        placeholder=""
                        name="creditTerm"
                        style={textFieldStyle}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Other Reference"
                        onChange={this.onChanged}
                        value={this.state.data.otherReference}
                        placeholder=""
                        name="otherReference"
                        style={textFieldStyle}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    spacing={2}
                    style={{ marginTop: "10px", marginBottom: "50px" }}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        justify="center"
                        style={{ height: "10px" }}
                      >
                        <h6>Full Address</h6>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Country"
                        onChange={this.onChanged}
                        value={this.state.data.country}
                        placeholder=""
                        name="country"
                        style={textFieldStyle}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="State"
                        onChange={this.onChanged}
                        value={this.state.data.state}
                        placeholder=""
                        name="state"
                        style={textFieldStyle}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="City"
                        onChange={this.onChanged}
                        value={this.state.data.city}
                        placeholder=""
                        name="city"
                        style={textFieldStyle}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Street"
                        onChange={this.onChanged}
                        value={this.state.data.street}
                        placeholder=""
                        name="street"
                        style={textFieldStyle}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item>
                    {this.state.editable ? (
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={this.handleUpdate}
                        style={{
                          margin: "20px",
                          marginTop: "45px",
                          marginRight: "25px"
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          disabled
                          onClick={this.handleUpdate}
                          style={{
                            margin: "20px",
                            marginTop: "45px",
                            marginRight: "25px"
                          }}
                        >
                          Update
                        </Button>
                      )}
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleDelete}
                      style={{
                        margin: "20px",
                        marginTop: "45px",
                        marginRight: "25px"
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewCustomerButton);
