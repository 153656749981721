import React, { Component } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../../utils/UserContext";

export default class AddSubCategoryModal extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      id: "",
      data: {
        subCategoryName: "",
        category: {
          id: ""
        }
      }
    };
  }

  handleSubmit = () => {
    const token = this.context;
    var obj = {
      subCategoryName: this.state.data.subCategoryName,
      category: {
        id: this.state.data.category.id
      }
    };

    Axios.post("/pos/subCategory/add", obj)
      .then(res => {
        console.log(res);
        this.props.handleClose();
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.setState({
          data: []
        });
      })
      .catch(err => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  handleValueChange = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  handleCategoryChange = e => {
    e.stopPropagation();

    var id = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        id: id,
        category: {
          ...prevState.data.category,
          id: id
        }
      }
    }));
  };

  render() {
    const { open, handleClose, data } = this.props;

    var newSubCategory = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].id !== "COPEN") {
        newSubCategory.push(data[i]);
      }
    }

    const inputStyles = {
      fontSize: 13
    };

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const textFieldStyle = {
      marginLeft: "10px",
      marginRight: "10px",
      width: 200
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div>
              <Card>
                <CardContent style={{ padding: "40px" }}>
                  <Grid container justify="center">
                    <Typography variant="h5">Add Sub Category</Typography>
                  </Grid>
                  <Grid container justify="center">
                    <TextField
                      id="standard-select-currency"
                      select
                      style={textFieldStyle}
                      name="category"
                      value={this.state.data.id}
                      onChange={this.handleCategoryChange}
                      InputProps={{ style: { fontSize: 13 } }}
                      InputLabelProps={{ style: inputStyles }}
                      label="Category"
                      SelectProps={{
                        MenuProps: {
                          style: { width: 200 }
                        }
                      }}
                      margin="normal"
                    >
                      {newSubCategory.map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                          {item.categoryName}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      id="standard-with-placeholder"
                      label="Sub Category"
                      name="subCategoryName"
                      variant="outlined"
                      onChange={this.handleValueChange}
                      placeholder=""
                      style={textFieldStyle}
                      margin="normal"
                    />
                  </Grid>

                  <Grid container justify="center">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleSubmit}
                      style={{
                        margin: "20px",
                        marginTop: "25px"
                      }}
                    >
                      Add Sub Category
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}
