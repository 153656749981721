import React, { Component } from "react";
import {
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { Label } from "semantic-ui-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

class ProfitAndLossAccountPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const snRow = {
      borderRight: "1px solid ",
      borderLeft: "1px solid ",
      borderBottom: "0.01em solid #838587 ",
      width: "80px",
      minWidth: "80px",
    };

    const particularRow = {
      borderRight: "1px solid ",
      borderLeft: "1px solid ",
      borderBottom: "0.01em solid #838587 ",
      width: "400px",
      minWidth: "270px",
    };

    const amountRow = {
      borderRight: "1px solid ",
      borderLeft: "1px solid ",
      borderBottom: "0.01em solid #838587 ",
      width: "150px",
      minWidth: "130px",
      textAlign: "end",
    };

    const totalRow = {
      border: "2px solid ",
    };

    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    return (
      <div>
        <Grid
          container
          justify="center"
          style={{ marginTop: 50, fontSize: 18 }}
        >
          <Label>Profit And Loss Account</Label>
        </Grid>
        {this.props.filterTerm === "fiscalYear" ? (

        <>
        <Grid container
            style={{ marginTop: 30, fontSize: 18 }}
        >
          {/*  <Grid item xs={12} >
                <Grid container >
                        <Typography style={{ fontWeight: "bold", }}>
                            Fiscal Year and Periods
                    </Typography>
                </Grid>
            </Grid> */}
          
          <Grid item xs={8}  >
              <Grid container justify="space-between" spacing={2} >
                    <Grid item  >
                        <Typography style={{ fontWeight: "bold",}}>
                            Fiscal Year: <span>   {this.props.currentFy} </span>
                    </Typography>
                    </Grid>
              
                    <Grid item >
                        <Typography style={{ fontWeight: "bold",}}>
                            Period From: <span>  {this.props.filterFields.periodFrom}</span>
                </Typography>
                    </Grid>
                
                    <Grid item >
                        <Typography style={{ fontWeight: "bold", }}>
                            Period To: <span>   {this.props.filterFields.periodTo}</span>
                </Typography>
                    </Grid>
                    </Grid>
                    </Grid>
        </Grid>
        </>
    ):null}
    {this.props.filterTerm === "date" ? 
      <>
        <Grid container
            style={{ marginTop: 30, fontSize: 18 }}
        >
          {/*  <Grid item xs={12} >
                <Grid container >
                        <Typography style={{ fontWeight: "bold", }}>
                            Fiscal Year and Periods
                    </Typography>
                </Grid>
            </Grid> */}
          
          <Grid item xs={8}  >
              <Grid container justify="space-between" spacing={2} >
                    <Grid item >
                        <Typography style={{ fontWeight: "bold",}}>
                            Date From: <span>    {this.props.filterFields.dateFrom}</span>
                </Typography>
                    </Grid>                          
                    <Grid item >
                        <Typography style={{ fontWeight: "bold", }}>
                            Date To: <span>     {this.props.filterFields.dateTo}</span>
                </Typography>
                    </Grid>
                    </Grid>
                    </Grid>
        </Grid>
      </>
      :null}

        <Grid
          container
          justify="center"
          style={{ marginTop: 30, fontSize: 18 }}
        >
          <TableContainer>
            <Grid item>
              <Table
                aria-label="customized table"
                fixedHeader={false}
                style={{
                  marginTop: "10px",
                  border: "1px solid ",
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: "#F1F8FC",
                        borderBottom: "1px solid ",
                        width: "100px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#F1F8FC",
                        borderBottom: "1px solid ",
                        borderRight: "1px solid ",
                        width: "3000px",
                        minWidth: "300px",
                        fontSize: "15px ",
                        fontWeight: "bold",
                      }}
                    >
                      Particulars
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#F1F8FC",
                        borderBottom: "1px solid ",
                        width: "100px",
                        fontSize: "15px ",
                        fontWeight: "bold",
                      }}
                    >
                      Amounts
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={snRow}
                      onDoubleClick={() => this.onDoubleClick(300)}
                    >
                      300
                    </TableCell>
                    <TableCell
                      style={particularRow}
                      onDoubleClick={() => this.onDoubleClick(300)}
                    >
                      Sales
                    </TableCell>
                    <TableCell
                      style={amountRow}
                      onDoubleClick={() => this.onDoubleClick(300)}
                    >
                      {format.format(this.props.staticValues[1].amount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={snRow}
                      onDoubleClick={() => this.onDoubleClick(120)}
                    >
                      120
                    </TableCell>
                    <TableCell
                      style={particularRow}
                      onDoubleClick={() => this.onDoubleClick(120)}
                    >
                      Stock in Hand(Opening)
                    </TableCell>
                    <TableCell
                      style={amountRow}
                      onDoubleClick={() => this.onDoubleClick(120)}
                    >
                      {format.format(this.props.staticValues[0].amount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={snRow}
                      onDoubleClick={() => this.onDoubleClick(400)}
                    >
                      400
                    </TableCell>
                    <TableCell
                      style={particularRow}
                      onDoubleClick={() => this.onDoubleClick(400)}
                    >
                      Purchase
                    </TableCell>
                    <TableCell
                      style={amountRow}
                      onDoubleClick={() => this.onDoubleClick(400)}
                    >
                      {format.format(this.props.staticValues[2].amount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={snRow}
                      onDoubleClick={() => this.onDoubleClick(120)}
                    >
                      120
                    </TableCell>
                    <TableCell
                      style={particularRow}
                      onDoubleClick={() => this.onDoubleClick(120)}
                    >
                      Stock in Hand (Closing)
                    </TableCell>
                    <TableCell
                      style={amountRow}
                      onDoubleClick={() => this.onDoubleClick(120)}
                    >
                      {format.format(this.props.staticValues[3].amount)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={snRow}></TableCell>
                    <TableCell style={particularRow}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Direct Expenses
                      </Typography>
                    </TableCell>
                    <TableCell style={amountRow}></TableCell>
                  </TableRow>

                  {this.props.directExpenses.map((val) => (
                    <>
                      <TableRow
                        onDoubleClick={() =>
                          this.onDoubleClick(val.accountCode)
                        }
                      >
                        <TableCell style={snRow}>{val.accountCode}</TableCell>
                        <TableCell style={particularRow}>
                          {val.profitAndLossSubAccounts.length > 0 &&
                            val.expanded && (
                              <ExpandMoreIcon
                                onClick={() =>
                                  this.expandDirectExpenses(val.accountCode)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          {val.profitAndLossSubAccounts.length > 0 &&
                            !val.expanded && (
                              <ChevronRightIcon
                                onClick={() =>
                                  this.expandDirectExpenses(val.accountCode)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          {val.accountName}
                        </TableCell>
                        <TableCell style={amountRow}>
                          {format.format(val.amount)}
                        </TableCell>
                      </TableRow>
                      {val.expanded &&
                        val.profitAndLossSubAccounts.map((childVal) => (
                          <TableRow
                            onDoubleClick={() =>
                              this.onDoubleClickSubAccount(
                                val.accountCode,
                                childVal.subAccountCode
                              )
                            }
                            style={{
                              backgroundColor: "#EAEDEF",
                              fontStyle: "italic",
                            }}
                          >
                            <TableCell style={snRow}>
                              {childVal.subAccountCode}
                            </TableCell>
                            <TableCell style={particularRow}>
                              {childVal.subAccountName}
                            </TableCell>
                            <TableCell style={amountRow}>
                              {format.format(childVal.amount)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ))}

                  {/* for gross total */}

                  <TableRow
                    style={{
                      borderBottomStyle: "double",
                      borderTop: "2px solid",
                    }}
                  >
                    <TableCell
                      style={{
                        borderRight: "2px solid ",
                        borderLeft: "2px solid ",
                        width: "80px",
                        minWidth: "80px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        borderRight: "2px solid ",
                        borderLeft: "2px solid ",
                        width: "400px",
                        minWidth: "270px",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {this.props.toGrossProfit<0? "To Gross Loss":  "To Gross Profit" }
                      
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "2px solid",
                        borderLeft: "2px solid ",
                        width: "150px",
                        minWidth: "130px",
                        textAlign: "end",
                      }}
                    >
                      {format.format(this.props.toGrossProfit)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={snRow}></TableCell>
                    <TableCell style={particularRow}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Indirect Expenses
                      </Typography>
                    </TableCell>
                    <TableCell style={amountRow}></TableCell>
                  </TableRow>

                  {this.props.indirectExpenses.map((val) => (
                                  <>
                                    <TableRow>
                                     
                                      <TableCell
                                        style={snRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {val.accountCode}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={particularRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {val.profitAndLossSubAccounts.length >
                                            0 &&
                                            val.expanded && (
                                              <ExpandMoreIcon
                                                onClick={() =>
                                                  this.expandIndirectExpenses(
                                                    val.accountCode
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            )}
                                          {val.profitAndLossSubAccounts.length >
                                            0 &&
                                            !val.expanded && (
                                              <ChevronRightIcon
                                                onClick={() =>
                                                  this.expandIndirectExpenses(
                                                    val.accountCode
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            )}
                                          {val.accountName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={amountRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {format.format(val.amount)}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    {val.expanded &&
                                      val.profitAndLossSubAccounts.map(
                                        (childVal) => (
                                          <TableRow
                                            style={{
                                              backgroundColor: "#EAEDEF",
                                            }}
                                          >
                                            <TableCell
                                              style={snRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {childVal.subAccountCode}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              style={particularRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {childVal.subAccountName}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              style={amountRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {format.format(childVal.amount)}
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </>
                                ))}

                  <TableRow>


                    {/* <TableCell style={actionRow}></TableCell> */}

                    <TableCell style={snRow}></TableCell>
                    <TableCell style={particularRow}>
                      <Typography style={{ fontWeight: "bold" }}>
                        Indirect Income
                      </Typography>
                    </TableCell>
                    <TableCell style={amountRow}></TableCell>
                  </TableRow>


                  {this.props.otherIncome.map((val) => (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        style={snRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {val.accountCode}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={particularRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {val.profitAndLossSubAccounts.length >
                                            0 &&
                                            val.expanded && (
                                              <ExpandMoreIcon
                                                onClick={() =>
                                                  this.expandIndirectIncome(
                                                    val.accountCode
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            )}
                                          {val.profitAndLossSubAccounts.length >
                                            0 &&
                                            !val.expanded && (
                                              <ChevronRightIcon
                                                onClick={() =>
                                                  this.expandIndirectIncome(
                                                    val.accountCode
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />
                                            )}
                                          {val.accountName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        style={amountRow}
                                        onDoubleClick={() =>
                                          this.onDoubleClick(val.accountCode)
                                        }
                                      >
                                        <Typography>
                                          {format.format(val.amount)}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    {val.expanded &&
                                      val.profitAndLossSubAccounts.map(
                                        (childVal) => (
                                          <TableRow
                                            style={{
                                              backgroundColor: "#EAEDEF",
                                            }}
                                          >
                                            <TableCell
                                              style={snRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {childVal.subAccountCode}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              style={particularRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {childVal.subAccountName}
                                              </span>
                                            </TableCell>
                                            <TableCell
                                              style={amountRow}
                                              onDoubleClick={() =>
                                                this.onDoubleClickSubAccount(
                                                  val.accountCode,
                                                  childVal.subAccountCode
                                                )
                                              }
                                            >
                                              <span
                                                style={{ fontStyle: "italic" }}
                                              >
                                                {format.format(childVal.amount)}
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </>
                                ))}

                  

                  <TableRow style={totalRow}>
                    {/* <TableCell style={actionRow}></TableCell> */}
                    <TableCell style={snRow}></TableCell>
                    <TableCell style={particularRow}>
                      <Typography style={{ fontWeight: "bold" }}>
                        {this.props.netProfit < 0 ? "Net Loss" : "Net Profit"}
                      </Typography>
                    </TableCell>
                    <TableCell style={amountRow}>
                      {format.format(this.props.netProfit)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </TableContainer>
        </Grid>
      </div>
    );
  }
}
export default ProfitAndLossAccountPrint;
