import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
  },
  info: {
    fontSize: 14,
    paddingBottom: "10px",
  },
});

function MyProfile(props) {
  const { value, index, data, classes, ...other } = props;

  return (
    <Grid container justify="center">
      <Grid item xs={11} lg={6} md={7} sm={10}>
        <Card
          role="tabpanel"
          style={{ width: "100%" }}
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Typography variant="h6" component="h2">
              {data.firstName + " " + data.middleName + " " + data.surname}
            </Typography>
            <Typography
              className={classes.info}
              color="textSecondary"
              gutterBottom
            >
              {data.roles.map((value, i) => (
                <span key={i}>{value.type + " "}</span>
              ))}
            </Typography>

            <Divider />

            <Grid container spacing={4} style={{ marginTop: "25px" }}>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.info} color="textSecondary">
                  Mobile:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography className={classes.info}>
                  {data.phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.info} color="textSecondary">
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography className={classes.info}>{data.email}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.info} color="textSecondary">
                  Position:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography className={classes.info}>
                  {data.position}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.info} color="textSecondary">
                  Department:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography className={classes.info}>
                  {data.department}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.info} color="textSecondary">
                  Address:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography className={classes.info}>{data.address}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className={classes.info} color="textSecondary">
                  Citizenship:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography className={classes.info}>
                  {data.identificationNumber}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(MyProfile);
