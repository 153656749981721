import React, { useState, useEffect } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TextField,
  MenuItem,
  TableContainer,
  Grid,
  Card,
  CardContent,
  TableBody,
  withStyles,
  Button,
} from "@material-ui/core";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import {
  faChevronDown,
  faChevronRight,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

const styles = (theme) => ({
  tabMargin: {
    padding: "5px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "5px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

function AssetsRegister(props) {
  const { classes } = props;

  const styledTableCell = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    border: "0px",
    padding: "5px",
    paddingBottom: "10px",
  };

  const borderBottom = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    borderColor: "#3F51B5",
  };

  const boldFont = {
    fontWeight: "bold",
  };

  const [tableData, setTableData] = useState([]);
  const [classList, setClassList] = useState([]);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [distinctFyList, setDistinctFyList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [currentFy, setCurrentFy] = useState("");

  useEffect(() => {
    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        console.log(res.data);
        var data = [];
        var nonDistinctFyList = [];
        var nonDistinctPeriodList = [];

        res.data.forEach((element) => {
          var item = {
            name: element.name,
            status: element.currentStatus,
            fiscalYear: element.fiscalYear,
          };
          data.push(item);
          nonDistinctFyList.push(item.fiscalYear);
          nonDistinctPeriodList.push(item.name);
        });

        setDistinctFyList([...new Set(nonDistinctFyList)]);

        let fiscalYear = "";

        data.forEach((element) => {
          if (element.status === true) {
            setCurrentFy(element.fiscalYear);
            fiscalYear = element.fiscalYear;
          }
        });

        Axios.post("pos/capitalAssets/getCurrentFiscalYearDepreciationReport", {
          fiscalYear: fiscalYear,
        })
          .then((res) => {
            generateSegragatedLists(res.data);
            setTableData(res.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getCurrentFyReport = (fiscalYear) => {
    Axios.post("pos/capitalAssets/getCurrentFiscalYearDepreciationReport", {
      fiscalYear: fiscalYear,
    })
      .then((res) => {
        generateSegragatedLists(res.data);
        setTableData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleFiscalYearChange = (e) => {
    let value = e.target.value;
    setCurrentFy(value);

    getCurrentFyReport(value);
  };

  const changeCollapsed = (subCategory) => {
    var newSubCat = Object.assign([], SubCategoryList);

    newSubCat.map((val) =>
      val.subCategory === subCategory ? (val.collapsed = !val.collapsed) : null
    );

    setSubCategoryList(newSubCat);
  };

  const generateSegragatedLists = (data) => {
    let classList = [];

    //to store class names

    data.map((val) =>
      classList.push({
        className: val.className,
        depRate: val.depreciationRate,
        openingValue: 0,
        saunPoush: 0,
        maghChaitra: 0,
        baisakhAsar: 0,
        totalValue: 0,
        absorbed: 0,
        unabsorbed: 0,
        depreciationBase: 0,
        depreciationValue: 0,
        closingValue: 0,
      })
    );

    //to make class names array distinct

    classList = classList.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.className === thing.className && t.className === thing.className
        )
    );

    setClassList(classList);

    //to store sub category names

    var SubCategoryList = [];

    classList.map((val) => {
      data.map((dataVal) => {
        if (dataVal.className === val.className) {
          console.log("matched");
          SubCategoryList.push({
            className: dataVal.className,
            subCategory: dataVal.subCategory,
            openingValue: 0,
            saunPoush: 0,
            maghChaitra: 0,
            baisakhAsar: 0,
            totalValue: 0,
            absorbed: 0,
            unabsorbed: 0,
            depreciationBase: 0,
            depreciationRate: dataVal.depreciationRate,
            depreciationValue: 0,
            closingValue: 0,
            collapsed: false,
          });
        }
        return null;
      });
      return null;
    });

    //to make sub category list array distinct

    SubCategoryList = SubCategoryList.filter(
      (thing, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.subCategory === thing.subCategory &&
            t.subCategory === thing.subCategory
        )
    );

    setSubCategoryList(SubCategoryList);

    //to store assets list and calculate total for the sub categories

    var assetList = [];

    SubCategoryList.map((val) => {
      data.map((dataVal) => {
        if (dataVal.subCategory === val.subCategory) {
          assetList.push({
            ...dataVal,
          });
          val.openingValue += dataVal.openingValue;
          val.saunPoush += dataVal.saunPoush;
          val.maghChaitra += dataVal.maghChaitra;
          val.baisakhAsar += dataVal.baisakhAsar;
          val.totalValue += dataVal.totalValue;
          val.absorbed += dataVal.absorbed;
          val.unabsorbed += dataVal.unabsorbed;
          val.depreciationBase += dataVal.depreciationBase;
          val.depreciationValue += dataVal.depreciationValue;
          val.closingValue += dataVal.closingValue;
        }
        return null;
      });
      return null;
    });

    //to calculate total for class level

    SubCategoryList.map((val) => {
      classList.map((classVal) => {
        if (val.className === classVal.className) {
          classVal.openingValue += val.openingValue;
          classVal.saunPoush += val.saunPoush;
          classVal.maghChaitra += val.maghChaitra;
          classVal.baisakhAsar += val.baisakhAsar;
          classVal.totalValue += val.totalValue;
          classVal.absorbed += val.absorbed;
          classVal.unabsorbed += val.unabsorbed;
          classVal.depreciationBase += val.depreciationBase;
          classVal.depreciationValue += val.depreciationValue;
          classVal.closingValue += val.closingValue;
        }
        return null;
      });
      return null;
    });

    setAssetList(assetList);

    let excelData = [];

    // const clone = (({ b, c, ...o }) => o)(element);

    classList.forEach((classElement) => {
      excelData.push({
        className:
          classElement.className +
          " - Depreciable Rate " +
          classElement.depRate +
          "%",
      });

      SubCategoryList.forEach((subElement) => {
        if (subElement.className === classElement.className)
          excelData.push({
            ...subElement,
            className: subElement.subCategory,
            depRate: subElement.depreciationRate,
          });

        assetList.map((val) =>
          val.subCategory === subElement.subCategory
            ? excelData.push({
                ...val,
                className: val.assetName,
                depRate: subElement.depreciationRate,
              })
            : null
        );
      });

      excelData.push({
        ...classElement,
        className: "Total",
      });
    });

    setExcelData(excelData);

    console.log("`/`.`/`./`.`/`./");
    console.log(classList);
    console.log("`/`.`/`./`.`/`./");
  };

  return (
    <>
      <HeaderTitle />
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={11}>
          <Card className={classes.tabMargin}>
            <CardContent>
              <Grid container spacing={1} justify="space-between">
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    style={{
                      width: "120px",
                      minWidth: "120px",
                    }}
                    label="Period"
                    name="period"
                    onChange={handleFiscalYearChange}
                    value={currentFy}
                  >
                    {distinctFyList.map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={12} sm={6} item style={{ marginTop: "25px" }}>
                  <Grid container justify="flex-end">
                    <ExcelFile
                      filename="Assets Register"
                      element={
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            margin: "auto",
                            height: "38px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Excel
                        </Button>
                      }
                    >
                      <ExcelSheet data={excelData} name="Balance Sheet">
                        <ExcelColumn label="Class" value="className" />
                        <ExcelColumn
                          label="Opening Value"
                          value="openingValue"
                        />
                        <ExcelColumn label="Saun to Poush" value="saunPoush" />
                        <ExcelColumn
                          label="Magh to Chaitra"
                          value="maghChaitra"
                        />
                        <ExcelColumn
                          label="Baisakh to Asadh"
                          value="baisakhAsar"
                        />
                        <ExcelColumn label="Total Value" value="totalValue" />
                        <ExcelColumn
                          label="Absorbed Addition"
                          value="absorbed"
                        />
                        <ExcelColumn
                          label="Unabsorbed Addition"
                          value="unabsorbed"
                        />
                        <ExcelColumn
                          label="Depreciation Base"
                          value="depreciationBase"
                        />
                        <ExcelColumn
                          label="Depreciation Rate"
                          value="depRate"
                        />
                        <ExcelColumn
                          label="Depreciation Value"
                          value="depreciationValue"
                        />
                        <ExcelColumn
                          label="Closing Value"
                          value="closingValue"
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </Grid>
                </Grid>
              </Grid>
              <TableContainer>
                <Table
                  aria-label="customized table"
                  style={{ marginTop: "20px", marginBottom: "15px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={styledTableCell}> </TableCell>
                      <TableCell style={styledTableCell}>Class</TableCell>
                      <TableCell style={styledTableCell} align="center">
                        Opening Value <br />{" "}
                        {tableData.length > 0 ? tableData[0].openingDate : ""}
                      </TableCell>
                      <TableCell
                        style={borderBottom}
                        colSpan={3}
                        align="center"
                      >
                        Addition
                      </TableCell>
                      <TableCell
                        style={borderBottom}
                        align="center"
                      >
                        Less
                      </TableCell>
                      <TableCell style={styledTableCell} align="center">
                        Total Value
                      </TableCell>
                      <TableCell style={styledTableCell} align="center">
                        Absorbed Addition
                      </TableCell>
                      <TableCell style={styledTableCell} align="center">
                        Unabsorbed Addition
                      </TableCell>
                      <TableCell align="center" style={styledTableCell}>
                        Depreciation Base
                      </TableCell>
                      <TableCell align="center" style={styledTableCell}>
                        Depreciation Rate
                      </TableCell>
                      <TableCell align="center" style={styledTableCell}>
                        Depreciation Value
                      </TableCell>
                      <TableCell align="center" style={styledTableCell}>
                        Closing Value <br />{" "}
                        {tableData.length > 0 ? tableData[0].TodaysDate : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={styledTableCell}> </TableCell>
                      <TableCell style={styledTableCell}></TableCell>
                      <TableCell style={styledTableCell}></TableCell>
                      <TableCell style={styledTableCell}>
                        Saun to Poush
                      </TableCell>
                      <TableCell style={styledTableCell} align="center">
                        Magh to Chaitra
                      </TableCell>
                      <TableCell style={styledTableCell} align="center">
                        Baisakh to Asadh
                      </TableCell>
                      <TableCell style={styledTableCell} align="center">
                        Disposal During the Year
                      </TableCell>
                      <TableCell
                        style={styledTableCell}
                        align="center"
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={styledTableCell}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={styledTableCell}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={styledTableCell}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={styledTableCell}
                        colSpan={3}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {classList.map((val) => (
                      <>
                        <TableRow>
                          <TableCell> </TableCell>
                          <TableCell style={boldFont}>
                            {val.className} - Depreciable Rate {val.depRate}%
                          </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                        {SubCategoryList.map((SubCategoryVal) =>
                          SubCategoryVal.className === val.className ? (
                            <>
                              <TableRow>
                                {SubCategoryVal.collapsed ? (
                                  <TableCell
                                    align="center"
                                    style={{ backgroundColor: "#F3F3F3" }}
                                    onClick={() =>
                                      changeCollapsed(
                                        SubCategoryVal.subCategory
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faChevronDown}
                                      style={{ cursor: "pointer" }}
                                    ></FontAwesomeIcon>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="center"
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    onClick={() =>
                                      changeCollapsed(
                                        SubCategoryVal.subCategory
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faChevronRight}
                                      style={{ cursor: "pointer" }}
                                    ></FontAwesomeIcon>
                                  </TableCell>
                                )}
                                <TableCell>
                                  {SubCategoryVal.subCategory}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.openingValue}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.saunPoush}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.maghChaitra}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.baisakhAsar}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.totalValue}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.absorbed}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.unabsorbed}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.depreciationBase}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.depreciationRate}%{" "}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.depreciationValue}
                                </TableCell>
                                <TableCell align="center">
                                  {SubCategoryVal.closingValue}
                                </TableCell>
                              </TableRow>

                              {assetList.map((assetVal) =>
                                assetVal.subCategory ===
                                  SubCategoryVal.subCategory &&
                                SubCategoryVal.collapsed ? (
                                  <TableRow
                                    style={{ backgroundColor: "#F3F3F3" }}
                                  >
                                    <TableCell align="center"></TableCell>
                                    <TableCell>{assetVal.assetName}</TableCell>
                                    <TableCell align="center">
                                      {assetVal.openingValue}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.saunPoush}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.maghChaitra}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.baisakhAsar}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.totalValue}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.absorbed}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.unabsorbed}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.depreciationBase}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.depreciationRate}%{" "}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.depreciationValue}
                                    </TableCell>
                                    <TableCell align="center">
                                      {assetVal.closingValue}
                                    </TableCell>
                                  </TableRow>
                                ) : null
                              )}
                            </>
                          ) : null
                        )}
                        <TableRow>
                          <TableCell align="center"></TableCell>
                          <TableCell style={boldFont}>Total</TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.openingValue}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.saunPoush}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.maghChaitra}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.baisakhAsar}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.totalValue}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.absorbed}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.unabsorbed}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.depreciationBase}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.depRate + "%"}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.depreciationValue}
                          </TableCell>
                          <TableCell style={boldFont} align="center">
                            {val.closingValue}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(AssetsRegister);
