import React, { Component } from "react";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import Axios from "axios";
import UserDetails from "../components/UserMaster/UserDetails";
import AddRole from "../components/RoleMaster/AddRole";
import UserContext from "../utils/UserContext";
import RoleDetails from "../components/RoleMaster/RoleDetails";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";

const styles = theme => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px"
    }
  }
});

class RoleMaster extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      data: [],
      origData: [],
      rowRefs: []
    };
  }

  onChanged = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleSubmit = () => {
    console.log(this.state.data);
    console.log("i am here");
    const token = this.context;
    Axios.post("pos/customer/addCustomer", this.state.data)
      .then(res => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });

        UserDetails.getCustomerListing();
        console.log(res);
      })
      .catch(err => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  populateData = () => {
    Axios.get("role/findAll")
      .then(res => {
        console.log(res);

        this.setState({ data: res.data, origData: res.data });

        var roleNameArr = [
          { key: "0", value: "All Roles....", text: "All Roles...." }
        ];

        res.data.forEach(element => {
          var item = {
            key: element.id,
            value: element.type,
            text: element.type
          };

          roleNameArr.push(item);
        });

        this.setState({ rowRefs: roleNameArr });
      })
      .catch(err => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.populateData();
  }

  changeData = data => {
    this.setState({ data });
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Role Master" />

          <Grid container>
            <Grid container justify="center" className={classes.tabMargin}>
              <Tabs
                variant="scrollable"
                value={this.state.value}
                onChange={this.handleChange}
                aria-label="Vertical tabs example"
                indicatorColor="primary"
              >
                <Tab
                  style={{ fontSize: "12px" }}
                  label="Manage Role"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{ fontSize: "12px" }}
                  label="Add Role"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <RoleDetails
                  value={this.state.value}
                  index={0}
                  origData={this.state.origData}
                  data={this.state.data}
                  rowRefs={this.state.rowRefs}
                  changeData={this.changeData}
                  populateData={this.populateData}
                ></RoleDetails>
                <AddRole
                  value={this.state.value}
                  index={1}
                  populateData={this.populateData}
                ></AddRole>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RoleMaster);
