import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  withStyles,
} from "@material-ui/core";
import ViewCustomerButton from "../InterfaceElements/ViewCustomerButton";
import UserContext from "../../utils/UserContext";
import { Input, Form } from "semantic-ui-react";

const styles = (theme) => ({
  root: {
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class CustomerDetails extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: "ID Number",
          field: "customerIdNumber",
          resizable: true,
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          resizable: true,
          minWidth: 150,
        },
        {
          headerName: "Address",
          field: "address",
          resizable: true,
          minWidth: 150,
        },
        {
          headerName: "Mobile No.",
          field: "mobileNum",
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "Email",
          field: "email",
          resizable: true,
          minWidth: 130,
        },
        {
          headerName: "View",
          cellRendererFramework: ViewCustomerButton,
          minWidth: 50,
          cellRendererParams: {
            updateData: this.props.getCustomerListing,
            showSnackbar: this.showAlert,
          },
        },
      ],
      rowData: [],
      open: false,
    };
  }

  componentDidUpdate() {
    try {
      this.gridApi.setRowData(this.props.customerData);
    } catch (e) {}
  }

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  showAlert = (snackbarProps) => {
    const token = this.context;

    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.props.setGridApi(params.api);
  };

  render(params) {
    const {
      children,
      value,
      index,
      classes,
      handleSearch,
      ...other
    } = this.props;

    return (
      <div>
        <Card
          role="tabpanel"
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center">
              <Typography variant="h6" component="h2">
                Customer List
              </Typography>
            </Grid>

            <Grid container justify="space-between" style={{ padding: "15px" }}>
              <Grid item xs={5}>
                <Form>
                  <Form.Field>
                    <label>Search By Customer Name or ID</label>
                    <Input
                      fluid
                      value={this.state.search}
                      style={{
                        minWidth: "190px",
                        height: "38px",
                        marginTop: "5px",
                      }}
                      onChange={handleSearch}
                    ></Input>
                  </Form.Field>
                </Form>
              </Grid>
              <Grid item>
                <h6 style={{ margin: "5px" }}>
                  Total Records : {this.props.totalItemCount}
                </h6>
              </Grid>
            </Grid>

            <Grid container justify="center">
              <div
                className="ag-theme-balham"
                style={{
                  height: "465px",
                  width: "100%",
                  padding: "10px",
                }}
              >
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onFirstDataRendered={this.firstDataRendered}
                  onGridReady={this.onGridReady}
                  overlayLoadingTemplate={this.props.overlayLoadingTemplate}
                  overlayNoRowsTemplate={this.props.overlayNoRowsTemplate}
                ></AgGridReact>
              </div>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(CustomerDetails);
