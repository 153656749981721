import React, { Component } from "react";
import { Tabs, Tab, Grid, withStyles, Badge } from "@material-ui/core";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import AddGoodsReceivedNote from "../components/GoodsReceivedNote/AddGoodsReceivedNote";
import GoodsReceivedNoteAuthorization from "../components/GoodsReceivedNote/GoodsReceivedNoteAuthorization";
import GoodReceivedNoteList from "../components/GoodsReceivedNote/GoodReceivedNoteList";
import Axios from "axios";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },
});

class GoodsReceivedNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      listData: [],
      grnDataForAuth: [],
      warehouseList: [],
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getAllWarehouse = async () => {
    let allWarehouse = [];
    Axios.get("/pos/warehouse/getAllWarehouses").then(async (res) => {
      res.data.forEach((element) => {
        let obj = {
          id: element.id,
          name: element.name,
        };
        allWarehouse.push(obj);
      });
      await this.setState((prevState) => ({
        ...prevState,
        warehouseList: allWarehouse,
      }));
    });
  };

  componentDidMount() {
    this.getListData();
    this.getGrnDataForAuth();
    this.getAllWarehouse();
  }

  setGridApi = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  getGrnDataForAuth = () => {
    console.log("grn update called");

    Axios.get("/pos/grnMaster/findMyRequests")
      .then((res) => {
        // console.log("=========response========")
        // console.log(res)
        this.setState((prevState) => ({
          ...prevState,
          grnDataForAuth: res.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getListData = () => {
    console.log("update called");

    setTimeout(() => {
      Axios.get("/pos/grnMaster/findAllApproved")
        .then((res) => {

          this.setState((prevState) => ({
            ...prevState,
            listData: res.data,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 100);
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Account Master" />

          <Grid container justify="center">
            <Tabs
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
              className={classes.tabMargin}
              style={{ marginBottom: "7px" }}
            >
              <Tab
                style={{ fontSize: "12px" }}
                label="GRN ENTRY"
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Badge
                    badgeContent={this.state.grnDataForAuth.length}
                    color="primary"
                    style={{ paddingRight: "11px" }}
                  >
                    GRN AUTHORIZATION
                  </Badge>
                }
                style={{ fontSize: "12px" }}
                {...a11yProps(1)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="AUTHORIZED LIST"
                {...a11yProps(2)}
              />
            </Tabs>
          </Grid>
          <Grid
            container
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            justify="center"
          >
            <Grid item style={{ width: "100%", maxWidth: "1500px" }}>
              <AddGoodsReceivedNote
                value={this.state.value}
                index={0}
                getGrnDataForAuth={this.getGrnDataForAuth}
                warehouseList={this.state.warehouseList}
              ></AddGoodsReceivedNote>

              <GoodsReceivedNoteAuthorization
                value={this.state.value}
                index={1}
                data={this.state.grnDataForAuth}
                getListData={this.getListData}
                getGrnDataForAuth={this.getGrnDataForAuth}
                warehouseList={this.state.warehouseList}
              ></GoodsReceivedNoteAuthorization>

              <GoodReceivedNoteList
                value={this.state.value}
                data={this.state.listData}
                index={2}
              ></GoodReceivedNoteList>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GoodsReceivedNote);
