import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  withStyles,
  Checkbox,
} from "@material-ui/core";
import Axios from "axios";
import { Dropdown } from "semantic-ui-react";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const styles = (theme) => ({
  tabMargin: {
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "5px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class ChartOfAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefsAll: [
        {
          headerName: "Account Code",
          field: "code",
          resizable: true,
          sortable: true,
          width: 200,
        },
        {
          headerName: "Account Name",
          field: "name",
          resizable: true,
        },
        {
          headerName: "Account Group Code",
          field: "accountGroupCode",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Account Group Name",
          field: "accountGroupName",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Primary Group Code",
          field: "primaryGroupCode",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Primary Group Name",
          field: "primaryGroup",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Status",
          field: "status",
          resizable: true,
          sortable: true,
        },
      ],
      columnDefsCustom: {
        accountGroupCode: {
          headerName: "Account Group Code",
          field: "accountGroupCode",
          resizable: true,
          sortable: true,
        },
        accountGroupName: {
          headerName: "Account Group Name",
          field: "accountGroupName",
          resizable: true,
          sortable: true,
        },
        primaryGroupCode: {
          headerName: "Primary Group Code",
          field: "primaryGroupCode",
          resizable: true,
          sortable: true,
        },
        primaryGroup: {
          headerName: "Primary Group Name",
          field: "primaryGroup",
          resizable: true,
          sortable: true,
        },
        status: {
          headerName: "Status",
          field: "status",
          resizable: true,
          sortable: true,
        },
      },
      columnDefsSmall: [
        {
          headerName: "Account Code",
          field: "code",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Account Name",
          field: "name",
          resizable: true,
        },
      ],
      columnDefs: [],
      rowData: [],
      origData: [],
      rowRefs: [],
      open: false,
      expanded: false,
      checkBoxesState: {
        selectAll: false,
        accountGroupCode: false,
        accountGroupName: false,
        primaryGroupCode: false,
        primaryGroup: false,
        status: false,
      },
      buttonMsg: "View more",
      search: "",
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }
  componentDidMount() {
    this.setState({ columnDefs: this.state.columnDefsSmall });

    Axios.get("pos/ledgerMaster/getAllLedgerMasters")
      .then((res) => {
        console.log(res);
        this.setState({ rowData: res.data, origData: res.data });

        var rowData = [];
        rowData.push({
          key: 0,
          value: "all",
          text: "Select All Account Groups...",
        });

        res.data.forEach((element, i) => {
          rowData.push({
            key: i + 1,
            value: element.code,
            text: element.name + " #" + element.code,
            label: { content: "#" + element.code, tag: true },
          });
        });

        this.setState({
          rowRefs: rowData,
        });

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  handleViewMoreDetails = () => {
    if (this.state.rowData.length < 1) {
      return;
    }

    if (this.state.expanded) {
      this.setState({
        expanded: false,
        buttonMsg: "View more",
        checkBoxesState: {
          accountGroupCode: false,
          accountGroupName: false,
          primaryGroupCode: false,
          primaryGroup: false,
          status: false,
        },
        columnDefs: this.state.columnDefsSmall,
      });
      this.removeTableColumns();
    } else {
      this.setState({
        expanded: true,
        buttonMsg: "View less",
      });
    }
  };

  handleFilter = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "all") {
      this.setState({ rowData: this.state.origData });
    } else {
      this.state.origData.forEach((element) => {
        if (element.code === value) rowAllData.push(element);
      });

      this.setState({
        rowData: rowAllData,
      });
    }
  };

  removeTableColumns = () => {
    if (!this.gridApi) return;

    var newTableData = [
      {
        headerName: "Ledger Code",
        field: "code",
        resizable: true,
        sortable: true,
      },
      {
        headerName: "Ledger Name",
        field: "name",
        resizable: true,
      },
    ];

    this.gridApi.setColumnDefs(newTableData);
    this.setState({
      columnDefs: newTableData,
    });

    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  handleCheckboxChange = (value) => {
    var status = this.state.checkBoxesState[value];

    // if (status === "selectAll") {
    //   this.state.checkBoxesState.forEach(element)
    //   this.setState(
    //     prevState => ({
    //       ...prevState,
    //       checkBoxesState: {
    //         ...prevState.checkBoxesState,
    //         [value]: !status
    //       }
    //     }),
    //     () => this.repopulateTable(value)
    //   );
    // }
    this.setState(
      (prevState) => ({
        ...prevState,
        checkBoxesState: {
          ...prevState.checkBoxesState,
          [value]: !status,
        },
      }),
      () => this.repopulateTable(value)
    );
  };

  repopulateTable = (value) => {
    console.log(value);

    var newTableData = this.state.columnDefs;

    if (newTableData.length > 6) newTableData = this.state.columnDefsSmall;

    if (value === "selectAll") {
      if (this.state.checkBoxesState[value]) {
        this.setState({ columnDefs: this.state.columnDefsAll });
      } else {
        this.setState({ columnDefs: this.state.columnDefsSmall });
      }

      if (!this.gridApi) return;
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 100);
      return;
    }

    var currentCol = this.state.columnDefsCustom[value];

    if (this.state.checkBoxesState[value]) {
      newTableData.push(currentCol);
    } else {
      var length = newTableData.length;

      for (var i = 0; i < length; i++) {
        if (newTableData[i].field === value) {
          newTableData.splice(i, 1);
          break;
        }
      }
    }

    this.setState({ columnDefs: newTableData });

    this.setState({ columnDefs: newTableData });

    this.gridApi.setColumnDefs(newTableData);

    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render(params) {
    const { classes } = this.props;
    return (
      <div
        style={{
          backgroundColor: "#EAEDEF",
          marginTop: "0px",
        }}
      >
        <HeaderTitle title="Charts Of Accounts" />

        <Grid container className={classes.root} justify="center">
          <Grid item xs={11} lg={9}>
            <Card className={classes.tabMargin}>
              <CardContent>
                <Grid
                  container
                  style={{
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    marginTop: "10px",
                  }}
                  justify="space-between"
                >
                  <Grid item>
                    <Dropdown
                      placeholder="Search by Account Code or Account Name"
                      fluid
                      style={{ minWidth: "220px", width: "44vw" }}
                      search
                      ref={(search) => {
                        this.searchInput = search;
                      }}
                      selection
                      onChange={this.handleFilter}
                      options={this.state.rowRefs}
                    ></Dropdown>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{ margin: "5px" }}
                      onClick={this.handleViewMoreDetails}
                      color="primary"
                    >
                      {this.state.buttonMsg}
                    </Button>
                  </Grid>
                </Grid>

                {this.state.expanded ? (
                  <Grid
                    container
                    justify="flex-end"
                    style={{
                      marginTop: "0px",
                      paddingRight: "23px",
                      paddingLeft: "23px",
                    }}
                  >
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkBoxesState.selectAll}
                            onChange={() =>
                              this.handleCheckboxChange("selectAll")
                            }
                            value="selectAll"
                            color="primary"
                          />
                        }
                        label="Select All"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.checkBoxesState.accountGroupCode
                            }
                            onChange={() =>
                              this.handleCheckboxChange("accountGroupCode")
                            }
                            value="accountGroupCode"
                            color="primary"
                          />
                        }
                        label="Account Group Code"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.checkBoxesState.accountGroupName
                            }
                            onChange={() =>
                              this.handleCheckboxChange("accountGroupName")
                            }
                            value="accountGroupName"
                            color="primary"
                          />
                        }
                        label="Account Group Name"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.checkBoxesState.primaryGroupCode
                            }
                            onChange={() =>
                              this.handleCheckboxChange("primaryGroupCode")
                            }
                            value="primaryGroupCode"
                            color="primary"
                          />
                        }
                        label="Primary Group Code"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkBoxesState.primaryGroup}
                            onChange={() =>
                              this.handleCheckboxChange("primaryGroup")
                            }
                            value="primaryGroup"
                            color="primary"
                          />
                        }
                        label="Primary Group Name"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkBoxesState.status}
                            onChange={() => this.handleCheckboxChange("status")}
                            value="status"
                            color="primary"
                          />
                        }
                        label="Status"
                      />
                    </FormGroup>
                  </Grid>
                ) : null}
                <Grid container justify="flex-end">
                  <Grid item>
                    <ExcelFile
                      filename="ChartOfAccounts.xlsx"
                      element={
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            width: "6vw",
                            marginRight: "15px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          Excel
                        </Button>
                      }
                    >
                      <ExcelSheet data={this.state.rowData} name="Sheet A">
                        {this.state.columnDefs.map((element) => (
                          <ExcelColumn
                            label={element.headerName}
                            value={element.field}
                          />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  </Grid>
                </Grid>

                <Grid container justify="center">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "470px",
                      width: "100%",
                      padding: "15px",
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      onGridReady={this.onGridReady}
                      onFirstDataRendered={this.firstDataRendered}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                    ></AgGridReact>
                  </div>
                </Grid>
              </CardContent>
            </Card>

            {/* <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={modal}
              open={true}
              onClose={this.handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={true}>
                <div style={{ backgroundColor: "#fff" }}>
                  <Card>
                    <CardContent>
                      <Grid container justify="center">
                        <Typography gutterBottom>
                          Choose Account Details to display
                        </Typography>

                      </Grid>
                    </CardContent>
                    
                  </Card>
                </div>
              </Fade>
            </Modal> */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ChartOfAccounts);
