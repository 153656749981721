import React, { PureComponent } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import {
  Modal,
  Fade,
  Grid,
  TextField,
  Button,
  Backdrop,
  Divider,
  Typography,
  Card,
  CardContent,
  withStyles,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import Close from "@material-ui/icons/Close";
import UserContext from "../../utils/UserContext";
import _ from "lodash";
import { Search } from "semantic-ui-react";
import DeleteButton from "./DeleteButton";
import { format } from "date-fns/esm";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SpaOutlined } from "@material-ui/icons";

const styles = (theme) => ({
  cardHeight: {
    minWidth: "300px",
    padding: "10px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "auto",
      maxHeight: "98%",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      height: "auto",
      maxHeight: "98%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      height: "auto",
      maxHeight: "98%",
    },
  },
});

class ViewGoodsIssuedButton extends PureComponent {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      resData:[],
      periodList:[],
      columnDefs: [
        {
          headerName: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          width: 60,
          minWidth: 50,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          sortable: true,
          resizable: true,
          filter:true,
          width: 100,
          minWidth: 90,
        },
        {
          headerName: "Item Description",
          field: "itemName",
          sortable: true,
          width: 127,
          minWidth: 127,
          resizable: true,
        },
        {
          headerName: "unit",
          field: "unit",
          sortable: true,
          resizable: true,
          width: 100,
          minWidth: 100,
        },
        {
          headerName: "Purchase Price",
          field: "price",
          width: 120,
          minWidth: 70,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Selling Price",
          field: "sellingPrice",
          width: 120,
          minWidth: 70,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Ordered Quantity",
          field: "orderQuantity",
          sortable: true,
          resizable: true,
          width: 100,
          minWidth: 80,
        },
        {
          headerName: "Sales Quantity",
          field: "salesQuantity",
          resizable: true,
          sortable: true,
          width: 100,
          minWidth: 80,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          resizable: true,
          sortable: true,
          width: 120,
          minWidth: 120,
        },
        {
          headerName: "Comment",
          field: "comments",
          editable: true,
          resizable: true,
          sortable: true,
          width: 150,
          minWidth: 127,
        },
        {
          headerName: "Delete",
          width: 50,
          resizable: true,
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
          },
        },
      ],
      orderQuantity: 1,
      salesQuantity: 1,
      customerList: [],
      rowData: [],
      salesDetails: {
        id: "",
        fiscalYear:"",
        period:"",
        ginDate: "",
        ginNumber: "",
        salesOrderNumber: "",
        soldByCompany: "",
        issuedTo: "",
        companyAddress: "",
        companyPan: "",

        grandTotal: 0,
        customerAddress: "",
        customerPan: "",
        warehouse: "",
        deliveryLocation: "",
        invoiceNumber: "",
        attention: "",
        soldByUser: "",
        customerContact: "",
        comment: "",
        chalanNumber: "",
        transportNumber: "",
        ginDetails: [],
      },
      sn:1,
      totalColor: "#FF8070",
      gridColor: [],
      editable: false,
      clicked: "#0b5f8a",
      buttonColor: "#4aa5d8",
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  getGINByID() {
    Axios.get("pos/ginMaster/findById/" + this.props.node.data.id)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          rowData: res.data.ginDetails,
          sn:(Math.max.apply(Math, res.data.ginDetails.map(function(o) { return o.sn; })))+1,
          salesDetails: {
            ...prevState.salesDetails,
            id: res.data.id,
            ginDate: res.data.ginDate === null ? "" : res.data.ginDate,
            ginNumber: res.data.ginNumber === null ? "" : res.data.ginNumber,
            status: res.data.status,
            salesOrderNumber:
              res.data.salesOrderNumber === null
                ? ""
                : res.data.salesOrderNumber,
            soldByCompany:
              res.data.soldByCompany === null ? "" : res.data.soldByCompany,
            issuedTo: res.data.issuedTo === null ? "" : res.data.issuedTo,

            companyAddress:
              res.data.companyAddress === null ? "" : res.data.companyAddress,
            companyPan: res.data.companyPan === null ? "" : res.data.companyPan,

            customerAddress:
              res.data.customerAddress === null ? "" : res.data.customerAddress,

            customerPan:
              res.data.customerPan === null ? "" : res.data.customerPan,
            customerContact:
              res.data.customerContact === null ? "" : res.data.customerContact,

            warehouse: res.data.warehouse === null ? "" : res.data.warehouse,
            deliveryLocation:
              res.data.deliveryLocation === null ? "" : res.data.deliveryLocation,

            invoiceNumber:
              res.data.invoiceNumber === null ? "" : res.data.invoiceNumber,
            attention: res.data.attention === null ? "" : res.data.attention,

            soldByUser: res.data.soldByUser === null ? "" : res.data.soldByUser,
            fiscalYear: res.data.fiscalYear === null ? "" : res.data.fiscalYear,
            period: res.data.period === null ? "" : res.data.period,
            chalanNumber:
              res.data.chalanNumber === null ? "" : res.data.chalanNumber,
            transportNumber:
              res.data.transportNumber === null ? "" : res.data.transportNumber,

            grandTotal: res.data.grandTotal,
          },
        }));
       
        this.setState((prevState)=>({
          overlayNoRowsTemplate: "<span>No rows to show</span>"
        }))
        try {
          if (this.gridApi !== undefined) this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
    this.getCustomerList();
    this.getAllPeriodListing();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleOpen = () => {
    this.setState({ open: true });
    this.getGINByID();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChecked = () => {
    var obj = {
      status: "Checked",
    };

    Axios.put("pos/ginMaster/update/" + this.state.salesDetails.id, obj)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        this.props.showSnackbar(snackbarProps);
        this.props.getGINData();
        this.props.getListData();
        this.handleClose();
      })
      .catch((err) => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        this.props.showSnackbar(snackbarProps);
      });
  };

  handleApproved = () => {
    var obj = {
      status: "Approved",
    };
    Axios.put("pos/ginMaster/update/" + this.state.salesDetails.id, obj)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        this.props.showSnackbar(snackbarProps);
        this.props.getGINData();
        this.props.getListData();
        this.handleClose();
      })
      .catch((err) => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        this.props.showSnackbar(snackbarProps);
      });
  };

  /*  handleRejected = () => {
    var obj = {
      comment: this.state.salesDetails.comment,
      status: "Rejected",
    };
    console.log(obj);
    if (obj.comment !== "" && obj.comment !== undefined) {
      console.log(obj.comment);
      Axios.put("pos/ginMaster/update/" + this.state.salesDetails.id, obj)
        .then((res) => {
          console.log(res);

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          this.props.showSnackbar(snackbarProps);
          this.props.getGINData();
          this.props.getListData();
          this.handleClose();
        })
        .catch((err) => {
          console.log(err);
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
        });
    } else {
      var snackbarProps = {
        open: true,
        variant: "error",
        message: "comment Field is required",
        autoHideDuration: 2000,
      };
      this.props.showSnackbar(snackbarProps);
    }
  }; */

  onChanged = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      salesDetails: {
        ...prevState.salesDetails,
        [targetName]: value,
      },
    }));
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handle0Index = (rowAllData, itemData) => {
    var rowItemData = {
      sn: this.state.sn,
      id: itemData.id,
      itemName: itemData.itemName,
      itemCode: itemData.itemCode,
      salesQuantity: this.state.salesQuantity,
      orderQuantity: this.state.orderQuantity,
      lineTotal:
        Math.round(
          parseFloat(itemData.rate) * parseFloat(this.state.salesQuantity) * 100
        ) / 100,
      price: itemData.rate,
      sellingPrice: itemData.markedPrice,
      comments: "",
      unit: itemData.unit,
    };
    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.itemName === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
    }

    this.setState({ sn: this.state.sn + 1 });
  };

  handleItemAdd = () => {
    var rows = this.getAllRows();
    var itemData = this.state.itemDetails;

    if (
      this.state.salesQuantity === "0" ||
      this.state.orderQuantity === "0" ||
      itemData.itemName === undefined ||
      this.state.salesQuantity === 0 ||
      this.state.orderQuantity === 0
    ) {
      return;
    }

    var rowAllData = this.state.rowData;

    if (parseInt(rows.length) === 0) {
      this.handle0Index(rowAllData, itemData);
    } else {
      for (let index = 0; index < rows.length; index++) {
        const element = rowAllData[index];

        if (element.itemName === itemData.itemName) {
          var rowSpecificData = {
            ...element,
            salesQuantity:
              parseFloat(element.salesQuantity) +
                parseFloat(this.state.salesQuantity) >
              itemData.quantity
                ? itemData.quantity
                : parseFloat(element.salesQuantity) +
                  parseFloat(this.state.salesQuantity),
            orderQuantity:
              parseFloat(element.orderQuantity) +
              parseFloat(this.state.orderQuantity),
            lineTotal:
              Math.round(
                parseFloat(element.price) *
                  (parseFloat(element.salesQuantity) +
                    parseFloat(this.state.salesQuantity)) *
                  100
              ) / 100,
            price: element.price,
            comments: "",
            unit: element.unit,
          };

          this.setState((prevState) => ({
            ...prevState,
            salesDetails: {
              ...prevState.salesDetails,
              grandTotal: parseFloat(
                Math.round(
                  this.state.salesDetails.grandTotal +
                    element.price * this.state.salesQuantity * 100
                ) / 100
              ),
            },
          }));

          rowAllData[index] = rowSpecificData;
        } else {
          this.handle0Index(rowAllData, itemData);
        }
      }
    }

    this.setState({ rowData: rowAllData });

    let grandTotal = 0;

    rowAllData.forEach((element) => {
      grandTotal += element.lineTotal;
    });

    this.setState((prevState) => ({
      ...prevState,
      salesDetails: {
        ...prevState.salesDetails,
        grandTotal,
      },
    }));

    this.gridApi.setRowData(rowAllData);
  };

  getCustomerList = () => {
    Axios.get("pos/customer/getAllCustomersList").then((res) => {
      let customers = [];
      res.data.forEach((element) => {
        var item = {
          key: element.id,
          value: element.customerName,
          text: element.customerName,
        };
        customers.push(item);
      });
      this.setState({
        customerList: customers,
      });
    });
  };

  handleChanged = (e) => {
    var value = e.target.value;
    var targetName = e.target.name;
    this.setState((prevState) => ({
      ...prevState,
      salesDetails: {
        ...prevState.salesDetails,
        [targetName]: value,
      },
    }));
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then((res) => {
        // await this.setState((prevState) => ({
        //   ...prevState,
        //   salesDetails: {
        //     ...prevState.salesDetails,
        //     fiscalYear: res.data.fiscalYear,
        //     period: res.data.name,
        //   },
        // }));
        this.getPeriodList();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getPeriodList() {
    var pLists = [];

    this.state.resData.map((val) =>
      Object.keys(val).includes(this.state.salesDetails.fiscalYear)
        ? (pLists = val[this.state.salesDetails.fiscalYear])
        : null
    );

    this.setState({
      periodList: pLists,
    });
  }

  handlePeriodChange = async (e) => {
    let py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      salesDetails: {
        ...prevState.salesDetails,
        period: py,
      },
    }));
  };
  handleDateChange = (date) => {
    let formattedDate = format(new Date(date), "yyyy-MM-dd");
    this.setState((prevState) => ({
      ...prevState,
      salesDetails: {
        ...prevState.salesDetails,
        ginDate: formattedDate,
      },
    }));
  };

  handleTextChangeIssuedTo = (e, data) => {
    var value = data.value;
    this.setState((prevState) => ({
      ...prevState,
      salesDetails: {
        ...prevState.salesDetails,
        issuedTo: value,
      },
    }));

    if (value.fullName === "None") {
      this.setState((prevState) => ({
        ...prevState,
        salesDetails: {
          ...prevState.salesDetails,
          customerAddress: "None",
          customerPan: "None",
        },
      }));
    } else {
      Axios.get("pos/customer/getAllCustomers").then((res) => {
        res.data.map((val) => {
          if (val.fullName === value) {
            this.setState((prevState) => ({
              ...prevState,
              salesDetails: {
                ...prevState.salesDetails,
                customerAddress: val.street + "-" + val.city,
                customerPan: val.pan,
              },
            }));
          }
          return null;
        });
      });
    }
  };
  handleQuantityChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var tempValue = parseFloat(Math.round(e.target.value * 100) / 100);
    var value = 0.0;

    if (targetName === "salesQuantity") {
      if (tempValue > this.state.itemDetails.quantity) {
        value = parseFloat(
          Math.round(this.state.itemDetails.quantity * 100) / 100
        );
      } else {
        value = tempValue;
      }
    } else value = tempValue;

    this.setState((prevState) => ({
      ...prevState,
      [targetName]: value,
    }));
  };

  handleItemSelect = (event, data) => {
    const { result } = data;

    this.setState({
      itemValue: result.title,
    });

    Axios.get("pos/inventory/getInventoryByItemCode/" + result.title)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          itemDetails: res.data,
        }));
      })
      .catch((err) => console.log(err));
  };

  handleSearch = (e, { value }) => {
    this.setState({ isLoading: true, itemValue: value });

    let newOptions = [];

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      Axios.get("pos/inventory/searchItems/" + value)
        .then((res) => {
          res.data.map((val) =>{
            if(val.quantity>0){
              newOptions.push({
                title: val.itemCode,
                description: val.itemName,
              })
            }
           }
          )
       

          this.setState({
            isLoading: false,
            rowRefs: newOptions,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };

  deleteRow = (data) => {
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      var obj = rowAllData[i];

      if (obj.id === data.id) {
        finalRows.splice(i, 1);

        break;
      }
    }

    this.setState({
      rowData: finalRows,
    });
    this.updateGrandTotal();
  };

  updateGrandTotal() {
    var gt = 0.0;
    this.state.rowData.forEach((element) => {
      gt += element.lineTotal;
    });

    this.setState((prevState) => ({
      ...prevState,
      salesDetails: {
        ...prevState.salesDetails,
        grandTotal: gt,
      },
    }));
  }

  handleUpdate = async () => {
    let tableData = [];
    this.state.rowData.forEach((val) => {
      var obj = {
        sn:val.sn,
        itemName: val.itemName,
        itemCode: val.itemCode,
        lineTotal: val.lineTotal,
        orderQuantity: val.orderQuantity,
        salesQuantity: val.salesQuantity,
        price: val.price,
        sellingPrice: val.sellingPrice,
        unit: val.unit,
        comments: val.comments,
      };
      tableData.push(obj);
    });

    await this.setState((prevState) => ({
      ...prevState,
      salesDetails: {
        ...prevState.salesDetails,
        ginDetails: tableData,
      },
    }));

    if (this.state.salesDetails.ginDetails.length === 0) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No item selected",
        autoHideDuration: 2000,
      };
      this.props.showSnackbar(errorSnackbarProps);
      return;
    }

    Axios.put(
      "pos/ginMaster/updateGin/" + this.props.node.data.id,
      this.state.salesDetails
    )
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        this.props.showSnackbar(snackbarProps);
        this.setState((prevState) => ({
          ...prevState,
          editable: false,
        }));
        this.props.getGINData();
        this.props.getListData();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };

        this.props.showSnackbar(snackbarProps);
      });
  };

  render() {
    const buttonStyle = {
      margin: "20px",
      marginTop: "25px",
      marginRight: "50px",
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingRight: "50px",
      paddingLeft: "50px",
    };

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const { classes } = this.props;

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid container justify="flex-end">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        editable: !this.state.editable,
                        buttonColor: this.state.clicked,
                      });
                    }}
                    style={{
                      backgroundColor: this.state.buttonColor,
                      marginBottom: "15px",
                    }}
                  >
                    Edit
                  </Button>
                </Grid>

                {this.state.editable ? (
                  <>
                    <Grid
                      container
                      spacing={2}
                      style={{ paddingRight: "10px", paddingLeft: "10px" }}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          label=" GIN Voucher No."
                          fullWidth
                          value={this.state.salesDetails.ginNumber}
                          name="ginNumber"
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="outlined-dense"
                          label="Fiscl Year"
                          name="fiscalYear"
                          value={this.state.salesDetails.fiscalYear}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                        id="outlined-dense"
                        variant="outlined"
                        select
                        margin="dense"
                        fullWidth
                        label="Period"
                        name="period"
                        value={this.state.salesDetails.period}
                        onChange={this.handlePeriodChange}
                      >
                        {this.state.periodList.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                          id="outlined-dense"
                          label="GIN Date"
                          name="ginDate"
                          value={this.state.salesDetails.ginDate}
                          onChange={this.handleDateChange}
                          InputLabelProps={{shrink: true}}
                          autoOk={true}
                          format="yyyy-MM-dd"
                          fullWidth
                          margin="dense"
                          inputVariant="outlined"
                          animateYearScrolling
                        />
                      </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Sold By - Company"
                          name="soldByCompany"
                          value={this.state.salesDetails.soldByCompany}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Company Address"
                          name="companyAddress"
                          value={this.state.salesDetails.companyAddress}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Company PAN"
                          name="companyPan"
                          value={this.state.salesDetails.companyPan}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                          <Dropdown
                            label="Issued To"
                            fluid
                            search
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              marginBottom: "5px",
                              fontSize: "14px",
                            }}
                            ref={(search) => {
                              this.searchInput = search;
                            }}
                            selection
                            value={this.state.salesDetails.issuedTo}
                            onChange={this.handleTextChangeIssuedTo}
                            options={[...this.state.customerList]}
                          ></Dropdown>

                        {/* <TextField
                          select
                          id="outlined-dense"
                          label="Issued To"
                          name="issuedTo"
                          value={this.state.salesDetails.issuedTo}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleTextChangeIssuedTo}
                        >
                          {this.state.customerList.map((value) => (
                            <MenuItem key={value.id} value={value.customerName}>
                              {value.customerName}
                            </MenuItem>
                          ))}
                        </TextField> */}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Customer Address"
                          name="customerAddress"
                          value={this.state.salesDetails.customerAddress}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Customer PAN"
                          name="customerPan"
                          value={this.state.salesDetails.customerPan}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Customer Contact"
                          name="customerContact"
                          value={this.state.salesDetails.customerContact}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChanged}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="outlined-dense"
                          label="Sales Invoice Number"
                          name="invoiceNumber"
                          value={this.state.salesDetails.invoiceNumber}
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          onChange={this.handleChanged}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="outlined-dense"
                          label="Sales Order Number"
                          name="salesOrderNumber"
                          value={this.state.salesDetails.salesOrderNumber}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChanged}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="outlined-dense"
                          label="Transport No."
                          name="transportNumber"
                          value={this.state.salesDetails.transportNumber}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChanged}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="outlined-dense"
                          label="Chalan Number"
                          name="chalanNumber"
                          value={this.state.salesDetails.chalanNumber}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChanged}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Delivery Location"
                          name="deliveryLocation"
                          value={this.state.salesDetails.deliveryLocation}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChanged}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Attention"
                          name="attention"
                          value={this.state.salesDetails.attention}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChanged}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Warehouse"
                          name="warehouse"
                          value={this.state.salesDetails.warehouse}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChanged}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          id="outlined-dense"
                          label="Sold By - User"
                          name="soldByUser"
                          value={this.state.salesDetails.soldByUser}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChanged}
                        />
                      </Grid>
                    </Grid>

                    <Divider style={{ marginTop: "20px" }} />
                    <Grid container justify="center" spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Search
                          input={{ fluid: true }}
                          loading={this.state.isLoading}
                          placeholder="Search by Item Code or Item Name"
                          style={{ marginTop: "15px", maxHeight: "500px" }}
                          onSearchChange={_.debounce(this.handleSearch, 500, {
                            leading: true,
                          })}
                          results={this.state.rowRefs}
                          onResultSelect={this.handleItemSelect}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4} md={2}>
                        <TextField
                          placeholder="Order Quantity"
                          style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingBottom: "0px",
                          }}
                          type="number"
                          label="Order Qty"
                          onChange={this.handleQuantityChange}
                          name="orderQuantity"
                          //  onChange={this.handleQuantityChange}
                          value={this.state.orderQuantity}
                          // min={1}
                        ></TextField>
                      </Grid>

                      <Grid item xs={4} sm={4} md={2}>
                        <TextField
                          placeholder="Sales Quantity"
                          style={{ width: "100%", marginTop: "10px" }}
                          type="number"
                          name="salesQuantity"
                          label="Sales Qty"
                          onChange={this.handleQuantityChange}
                          value={this.state.salesQuantity}
                          // min={1}
                        ></TextField>
                      </Grid>

                      <Grid item xs={4} sm={4} md={2}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          onClick={this.handleItemAdd}
                          style={{
                            minWidth: "60px",
                            margin: "auto",
                            height: "38px",
                            marginTop: "15px",
                          }}
                        >
                          +Add
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={2}
                      style={{ paddingRight: "10px", paddingLeft: "10px" }}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          margin="dense"
                          label=" GIN Voucher No."
                          fullWidth
                          value={this.state.salesDetails.ginNumber}
                          name="ginNumber"
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Fiscl Year"
                          name="fiscalYear"
                          value={this.state.salesDetails.fiscalYear}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Period"
                          name="period"
                          value={this.state.salesDetails.period}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="GIN Date"
                          name="ginDate"
                          format="yyyy-MM-dd"
                          value={this.state.salesDetails.ginDate}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Sold By - Company"
                          name="soldByCompany"
                          value={this.state.salesDetails.soldByCompany}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Company Address"
                          name="companyAddress"
                          value={this.state.salesDetails.companyAddress}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Company PAN"
                          name="companyPan"
                          value={this.state.salesDetails.companyPan}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Issued To"
                          name="issuedTo"
                          value={this.state.salesDetails.issuedTo}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Customer Address"
                          name="customerAddress"
                          value={this.state.salesDetails.customerAddress}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Customer PAN"
                          name="customerPan"
                          value={this.state.salesDetails.customerPan}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Customer Contact"
                          name="customerContact"
                          value={this.state.salesDetails.customerContact}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Sales Invoice Number"
                          name="invoiceNumber"
                          value={this.state.salesDetails.invoiceNumber}
                          margin="dense"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Sales Order Number"
                          name="salesOrderNumber"
                          value={this.state.salesDetails.salesOrderNumber}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Transport No."
                          name="transportNumber"
                          value={this.state.salesDetails.transportNumber}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Chalan Number"
                          name="chalanNumber"
                          value={this.state.salesDetails.chalanNumber}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Delivery Location"
                          name="deliveryLocation"
                          value={this.state.salesDetails.deliveryLocation}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Attention"
                          name="attention"
                          value={this.state.salesDetails.attention}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Warehouse"
                          name="warehouse"
                          value={this.state.salesDetails.warehouse}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          id="outlined-dense"
                          label="Sold By - User"
                          name="soldByUser"
                          value={this.state.salesDetails.soldByUser}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: "20px" }} />
                  </>
                )}

                <Grid container style={{ width: "98%", margin: "auto" }}>
                  <Typography
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontSize: "19px",
                    }}
                  >
                    Item List
                  </Typography>

                  <Grid container justify="center">
                    <div
                      className="ag-theme-balham"
                      style={{
                        height: "25vh",
                        width: "100%",
                      }}
                    >
                      <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        animateRows={true}
                        rowSelection={this.state.rowSelection}
                        onGridReady={this.onGridReady}
                        overlayLoadingTemplate={
                          this.state.overlayLoadingTemplate
                        }
                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                      ></AgGridReact>
                    </div>
                    <Grid container justify="flex-end">
                      <Typography
                        style={{ fontSize: "17px", fontWeight: "bold" }}
                      >
                        Grand Total:{this.state.salesDetails.grandTotal}
                      </Typography>
                    </Grid>
                    <Grid container justify="center">
                      <TextField
                        id="standard-with-placeholder"
                        label="Comment"
                        name="comment"
                        variant="outlined"
                        fullWidth
                        onChange={this.onChanged}
                        value={this.state.salesDetails.comment}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container justify="center">
                    {this.state.salesDetails.status === "Pending" ? (
                      <Button
                        variant="outlined"
                        size="large"
                        color="inherit"
                        onClick={this.handleChecked}
                        style={buttonStyle}
                      >
                        Check
                      </Button>
                    ) : null}

                    {this.state.salesDetails.status === "Checked" ? (
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={this.handleApproved}
                        style={buttonStyle}
                      >
                        Approve
                      </Button>
                    ) : null}

                    {this.state.editable ? (
                      <Button
                        variant="outlined"
                        size="large"
                        color="secondary"
                        onClick={this.handleUpdate}
                        style={buttonStyle}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        size="large"
                        color="secondary"
                        disabled
                        style={buttonStyle}
                      >
                        Update
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewGoodsIssuedButton);
