import React, { Component } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  CardContent,
  Card,
  Grid,
  TextField,
  MenuItem,
  Button,
  withStyles,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../utils/UserContext";

const styles = (theme) => ({
  root: {
    paddingTop: "5px",
    paddingBottom: "5px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
      width: "80%",
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
      width: "75%",
      margin: "auto",
    },
  },

  containerWidth: {
    margin: "auto",
    width: "70vw",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80vw",
    },
  },
});

class AddManufacturerModal extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      data: {
        type: "Manufacturer",
        fullName: "",
        street: "",
        city: "",
        state: "",
        country: "",
        licenseNumber: "",
        telephone: "",
        mobile: "",
        email: "",
        poBox: "",
        fax: "",
        typeOfProduct: "",
        otherReference: "",
        website: "",
      },
      manufacturerData: [],
      errorStatus: {},
    };
  }

  onChanged = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  validateForm() {
    var toCheck = ["fullName", "street", "city", "state", "country", "mobile"];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  handleSubmit = () => {
    const token = this.context;

    this.validateForm();
    var status = this.validateForm();

    if (status) {
      Axios.post("pos/manufacturer/save", this.state.data)
        .then((res) => {
          console.log(res);

          this.setState({
            data: {
              type: "Manufacturer",
              fullName: "",
              street: "",
              city: "",
              state: "",
              country: "",
              licenseNumber: "",
              telephone: "",
              mobile: "",
              email: "",
              poBox: "",
              fax: "",
              typeOfProduct: "",
              otherReference: "",
              website: "",
            },
          });
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          this.props.handleClose();
        })
        .catch((err) => {
          var snackbarProps = {
            open: true,
            variant: "error",
            message: err.message,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  render() {
    const { open, handleClose, classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const longTextFieldStyle = {
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "0px",
      width: 520,
    };

    const inputStyles = {
      fontSize: 11,
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div>
              <Card justify="center">
                <CardContent>
                  <Grid
                    container
                    justify="center"
                    style={{ marginTop: "20px" }}
                    spacing={2}
                    className={classes.containerWidth}
                  >
                    <Grid item>
                      <Grid container justify="center">
                        <Typography component="h6">
                          Manufacturer/Distributor Details
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item sm={12} xs={12} style={{ marginTop: "20px" }}>
                        <TextField
                          size="small"
                          fullWidth
                          select
                          value={this.state.data.type}
                          label="Select Manufacturer or Distributor"
                          name="type"
                          onChange={this.onChanged}
                        >
                          <MenuItem value={"Manufacturer"}>
                            Manufacturer
                          </MenuItem>
                          <MenuItem value={"Distributor"}>Distributor</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          label="Full Name"
                          name="fullName"
                          error={this.state.errorStatus.fullName}
                          onChange={this.onChanged}
                          value={this.state.data.fullName}
                          placeholder=""
                          fullWidth
                          textFieldStyle={longTextFieldStyle}
                          inputStyles={inputStyles}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item lg={3} sm={6} xs={12}>
                        <TextField
                          label="Street"
                          placeholder=""
                          error={this.state.errorStatus.street}
                          onChange={this.onChanged}
                          value={this.state.data.street}
                          name="street"
                          fullWidth
                          textFieldStyle={longTextFieldStyle}
                          inputStyles={inputStyles}
                          margin="dense"
                        />
                      </Grid>

                      <Grid item lg={3} sm={6} xs={12}>
                        <TextField
                          label="City"
                          error={this.state.errorStatus.city}
                          placeholder=""
                          value={this.state.data.city}
                          name="city"
                          onChange={this.onChanged}
                          fullWidth
                          textFieldStyle={longTextFieldStyle}
                          inputStyles={inputStyles}
                          margin="dense"
                        />
                      </Grid>

                      <Grid item lg={3} sm={6} xs={12}>
                        <TextField
                          label="State"
                          placeholder=""
                          fullWidth
                          value={this.state.data.state}
                          error={this.state.errorStatus.state}
                          onChange={this.onChanged}
                          name="state"
                          textFieldStyle={longTextFieldStyle}
                          inputStyles={inputStyles}
                          margin="dense"
                        />
                      </Grid>

                      <Grid item lg={3} sm={6} xs={12}>
                        <TextField
                          label="Country"
                          placeholder=""
                          name="country"
                          value={this.state.data.country}
                          error={this.state.errorStatus.country}
                          onChange={this.onChanged}
                          fullWidth
                          textFieldStyle={longTextFieldStyle}
                          inputStyles={inputStyles}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "25px" }}
                    >
                      <Typography component="h6"> Supplier Details </Typography>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="License No."
                        placeholder=""
                        fullWidth
                        value={this.state.data.licenseNumber}
                        // error={errorStatus.liscenseNo}
                        name="licenseNumber"
                        onChange={this.onChanged}
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Telephone"
                        placeholder=""
                        // error={errorStatus.telephone}
                        fullWidth
                        value={this.state.data.telephone}
                        name="telephone"
                        onChange={this.onChanged}
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Mobile"
                        placeholder=""
                        fullWidth
                        value={this.state.data.mobile}
                        name="mobile"
                        error={this.state.errorStatus.mobile}
                        onChange={this.onChanged}
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="P.O.Box"
                        placeholder=""
                        fullWidth
                        name="poBox"
                        value={this.state.data.poBox}
                        // error={errorStatus.poBox}
                        onChange={this.onChanged}
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Fax"
                        placeholder=""
                        fullWidth
                        // error={errorStatus.fax}
                        name="fax"
                        value={this.state.data.fax}
                        onChange={this.onChanged}
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Email"
                        // error={errorStatus.email}
                        placeholder=""
                        onChange={this.onChanged}
                        fullWidth
                        value={this.state.data.email}
                        name="email"
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Website"
                        placeholder=""
                        fullWidth
                        value={this.state.data.website}
                        // error={errorStatus.liscenseNo}
                        name="website"
                        onChange={this.onChanged}
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "25px" }}
                    >
                      <Typography component="h6"> Product Details </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Product Type"
                        placeholder=""
                        fullWidth
                        name="typeOfProduct"
                        // error={errorStatus.productType}
                        onChange={this.onChanged}
                        value={this.state.data.typeOfProduct}
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Other Reference"
                        placeholder=""
                        fullWidth
                        // error={errorStatus.otherReference}
                        name="otherReference"
                        value={this.state.data.otherReference}
                        onChange={this.onChanged}
                        textFieldStyle={longTextFieldStyle}
                        inputStyles={inputStyles}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>

                  <Grid container justify="center">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleSubmit}
                      style={{
                        margin: "20px",
                        marginTop: "45px",
                        marginRight: "15px",
                      }}
                    >
                      Add Manufacturer
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={handleClose}
                      style={{
                        margin: "20px",
                        marginTop: "45px",
                        marginRight: "25px",
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(AddManufacturerModal);
