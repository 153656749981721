import React, { Component } from "react";
import { Tabs, Tab, Grid, withStyles, Badge } from "@material-ui/core";

import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import GoodsIssuedNoteAuthorization from "../components/GoodsIssuedNote/GoodsIssuedNoteAuthorization";
import AddGoodsIssuedNote from "../components/GoodsIssuedNote/AddGoodsIssuedNote";
import GoodIssuedNoteList from "../components/GoodsIssuedNote/GoodIssuedNoteList";
import Axios from "axios";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class GoodsIssuedNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      listData: [],
      ginDataForAuth: [],
      rowDataOriginal: [],
      maxNumber: 0,
      invoiceFrom: "",
      warehouseList: [],
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getAllWarehouse = async () => {
    let allWarehouse = [];
    Axios.get("/pos/warehouse/getAllWarehouses").then(async (res) => {
      res.data.forEach((element) => {
        let obj = {
          id: element.id,
          name: element.name,
        };
        allWarehouse.push(obj);
      });
      await this.setState((prevState) => ({
        ...prevState,
        warehouseList: allWarehouse,
      }));
    });
  };

  componentDidMount() {
    this.getListData();
    this.getGinDataForAuth();
    this.getAllWarehouse();
  }

  setGridApi = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  getGinDataForAuth = () => {
    Axios.get("/pos/ginMaster/findAllDesc")
      .then((res) => {
        /*  res.data.forEach((element) => {
          this.setState({ maxNumber: element.invoiceNumber });
        }); */
        this.setState((prevState) => ({
          ...prevState,
          ginDataForAuth: res.data,
          rowDataOriginal: res.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getListData = () => {
    setTimeout(() => {
      Axios.get("/pos/ginMaster/findAllApproved")
        .then((res) => {
          this.setState((prevState) => ({
            ...prevState,
            listData: res.data,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 100);
  };

  /*  handleInvoiceFrom = (val) => {
    var dataList = this.state.rowDataOriginal;

    var newDataList = [];

    dataList.forEach((element) => {
      if (element.invoiceNumber >= val) {
        newDataList.push(element);
      }
    });
    this.setState({
      rowData: newDataList,
    });
  };

  handleInvoiceTo = (val) => {
    var dataList = this.state.rowDataOriginal;

    var newDataList = [];

    dataList.forEach((element) => {
      if (element.invoiceNumber <= val) {
        newDataList.push(element);
      }
    });
    this.setState({
      rowData: newDataList,
    });
  };

  handleMinimumAmount = (val) => {
    var dataList = this.state.rowDataOriginal;

    var newDataList = [];

    dataList.forEach((element) => {
      if (element.grandTotal >= val) {
        newDataList.push(element);
      }
    });
    this.setState({
      rowData: newDataList,
    });
  };

  handleMaximumAmount = (val) => {
    var dataList = this.state.rowDataOriginal;

    var newDataList = [];

    dataList.forEach((element) => {
      if (element.grandTotal <= val) {
        newDataList.push(element);
      }
    });
    this.setState({
      rowData: newDataList,
    });
  };

  handleDateFrom = (formattedDate, dateTo) => {
    var newDate = new Date(formattedDate);

    var dataList = this.state.rowDataOriginal;

    var newDataList = [];

    dataList.forEach((element) => {
      var elementDate = new Date(element.ginDate);

      if (
        elementDate.getTime() >= newDate.getTime() &&
        elementDate.getTime() <= new Date(dateTo).getTime()
      ) {
        newDataList.push(element);
      }
    });

    this.setState({ rowData: newDataList });
  };

  handleDateTo = (formattedDate, dateFrom) => {
    var newDate = new Date(formattedDate);

    var dataList = this.state.rowDataOriginal;

    var newDataList = [];

    dataList.forEach((element) => {
      var elementDate = new Date(element.ginDate);

      if (
        elementDate.getTime() <= newDate.getTime() &&
        elementDate.getTime() >= new Date(dateFrom).getTime()
      ) {
        newDataList.push(element);
      }
    });

    this.setState({ rowData: newDataList });
  }; */

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Account Master" />

          <Grid container justify="center">
            <Tabs
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
              className={classes.tabMargin}
              style={{ marginBottom: "7px" }}
            >
              <Tab
                style={{ fontSize: "12px" }}
                label="GIN Entry"
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Badge
                    badgeContent={this.state.ginDataForAuth.length}
                    color="primary"
                    style={{ paddingRight: "11px" }}
                  >
                    GIN Authorization
                  </Badge>
                }
                style={{ fontSize: "12px" }}
                {...a11yProps(1)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Authorized GIN"
                {...a11yProps(2)}
              />
            </Tabs>
          </Grid>
          <Grid
            container
            style={{
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
            justify="center"
          >
            <Grid item xs={12} sm={11} md={10}>
              <AddGoodsIssuedNote
                value={this.state.value}
                index={0}
                getGinDataForAuth={this.getGinDataForAuth}
                warehouseList={this.state.warehouseList}
              ></AddGoodsIssuedNote>
              <GoodsIssuedNoteAuthorization
                value={this.state.value}
                index={1}
                data={this.state.ginDataForAuth}
                getListData={this.getListData}
                getGinDataForAuth={this.getGinDataForAuth}
                warehouseList={this.state.warehouseList}
                // rowDataOriginal={this.state.rowDataOriginal}

                /*  maxNumber={this.state.maxNumber}
                handleInvoiceFrom={this.handleInvoiceFrom}
                handleInvoiceTo={this.handleInvoiceTo}
                handleMinimumAmount={this.handleMinimumAmount}
                handleMaximumAmount={this.handleMaximumAmount}
                handleDateFrom={this.handleDateFrom}
                handleDateTo={this.handleDateTo} */
              ></GoodsIssuedNoteAuthorization>
              <GoodIssuedNoteList
                value={this.state.value}
                index={2}
                data={this.state.listData}
              ></GoodIssuedNoteList>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GoodsIssuedNote);
