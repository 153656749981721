import React, { Component } from "react";
import {
  Typography,
  CardContent,
  Grid,
  Card,
  MenuItem,
  withStyles
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import { withRouter } from "react-router-dom";

const styles = theme => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "7px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
      marginRight: "10px",
      marginLeft: "10px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  }
});

class AddScheme extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      data: {
        fillCharacter: 0,
        fiscalYear: "",
        name: "Sales",
        numberLength: 4,
        prefix: "",
        status: "INACTIVE",
        suffix: "",
        totalCharacter: 0,
        voucherDescription: ""
      },
      fiscalYears: []
    };
  }

  resetData = () => {
    this.setState({
      data: {
        fillCharacter: 0,
        fiscalYear: "",
        name: "",
        numberLength: 4,
        prefix: "",
        suffix: "",
        totalCharacter: 0,
        voucherDescription: ""
      }
    });
  };

  componentDidMount() { }

  onChanged = async e => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    console.log(targetName);
    console.log(value);

    await new Promise(accept =>
      this.setState(
        prevState => ({
          ...prevState,
          data: {
            ...prevState.data,
            [targetName]: value
          }
        }),
        accept
      )
    );

    let suffix = "";

    try {
      //for suffix
      let fiscalYear = this.state.data.fiscalYear;

      let splitFiscalYear = fiscalYear.split("/");
      suffix = splitFiscalYear[0].slice(1) + "/" + splitFiscalYear[1].slice(1);
    } catch (e) { }

    let totalCharacter = 0;

    try {
      totalCharacter =
        parseInt(this.state.data.prefix.length) +
        1 +
        parseInt(this.state.data.numberLength) +
        1 +
        parseInt(suffix.length);
    } catch (e) { }

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        suffix: suffix,
        totalCharacter: totalCharacter
      }
    }));
  };

  handleSubmit = () => {
    const token = this.context;

    Axios.post("pos/voucherNumbering/save", this.state.data)
      .then(res => {
        console.log(res);

        this.resetData();
        this.props.populateTable();

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      })
      .catch(err => {
        var message = err.message;

        if (err.response !== undefined) {
          message = err.response.data;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  render() {
    const {
      children,
      value,
      index,
      customerType,
      paymentMode,
      onChange,
      handleSubmit,
      classes,
      ...other
    } = this.props;

    const inputStyles = {
      fontSize: 14
    };

    return (
      <div>
        <Grid container style={{ width: "100vw" }} justify="center">
          <Grid item xs={12} lg={6} md={7} sm={10}>
            <Card
              className={classes.root}
              role="tabpanel"
              hidden={value !== index}
              id={`vertical-tabpanel-${index}`}
              aria-labelledby={`vertical-tab-${index}`}
              {...other}
            >
              <CardContent>
                <Grid container>
                  <Typography variant="h6" component="h2" style={{ fontSize: "18px" }}>
                    {" "}
                    Add Numbering Scheme{" "}
                  </Typography>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                  <Grid item xs>
                    <TextField
                      id="standard-with-placeholder"
                      label="Name of Document"
                      placeholder=""
                      style={{ width: "100%" }}
                      select
                      name="name"
                      variant="filled"
                      value={this.state.data.name}
                      onChange={this.onChanged}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    >
                      <MenuItem key={0} value="Sales">
                        Sales
                      </MenuItem>
                      <MenuItem key={1} value="Purchase">
                        Purchase
                      </MenuItem>
                      <MenuItem key={2} value="Sales Return">
                        Sales Return
                      </MenuItem>
                      <MenuItem key={3} value="Purchase Return">
                        Purchase Return
                      </MenuItem>
                      <MenuItem key={4} value="Replacement">
                        Replacement
                      </MenuItem>
                      <MenuItem key={5} value="Sales Order">
                        Sales Order
                      </MenuItem>
                      <MenuItem key={6} value="Purchase Order">
                        Purchase Order
                      </MenuItem>
                      <MenuItem key={7} value="Quotation">
                        Quotation
                      </MenuItem>
                      <MenuItem key={7} value="Good Received Note">
                        Good Received Note
                      </MenuItem>
                      <MenuItem key={9} value="Good Issued Note">
                        Good Issued Note
                      </MenuItem>
                      <MenuItem key={9} value="Purchase Voucher">
                        Purchase Voucher
                      </MenuItem>
                      <MenuItem key={10} value="Sales Voucher">
                        Sales Voucher
                      </MenuItem>
                      <MenuItem key={11} value="Payment Voucher">
                        Payment Voucher
                      </MenuItem>
                      <MenuItem key={12} value="Received Voucher">
                        Received Voucher
                      </MenuItem>
                      <MenuItem key={13} value="Journal Voucher">
                        Journal Voucher
                      </MenuItem>
                      <MenuItem key={14} value="Contra Voucher">
                        Contra Voucher
                      </MenuItem>
                      <MenuItem key={15} value="Debit Note Voucher">
                        Debit Note Voucher
                      </MenuItem>
                      <MenuItem key={16} value="Credit Note Voucher">
                        Credit Note Voucher
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs>
                    <TextField
                      id="standard-with-placeholder"
                      label="Document Description"
                      placeholder=""
                      value={this.state.data.voucherDescription}
                      variant="filled"
                      name="voucherDescription"
                      onChange={this.onChanged}
                      style={{ width: "100%" }}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    />
                  </Grid>

                  {/* <Grid item xs>
                <TextField
                  id="standard-with-placeholder"
                  label="Status"
                  select
                  value={this.state.data.status}
                  variant="filled"
                  name="status"
                  onChange={this.onChanged}
                  style={{ width: "100%" }}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                >
                  <MenuItem key={0} value="Inactive">
                    Inactive
                  </MenuItem>
                  <MenuItem key={1} value="Active">
                    Active
                  </MenuItem>
                  <MenuItem key={2} value="Closed">
                    Closed
                  </MenuItem>
                </TextField>
              </Grid> */}
                </Grid>
                <Grid container>
                  {console.log("props bata aayeko")}
                  {console.log(this.props.fiscalYearRefs)}
                  <Grid item xs>
                    <TextField
                      id="standard-with-placeholder"
                      label="Fiscal Year"
                      select
                      value={this.state.data.fiscalYear}
                      variant="filled"
                      name="fiscalYear"
                      onChange={this.onChanged}
                      style={{ width: "100%" }}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    >
                      {this.props.fiscalYearRefs.map(element => (
                        <MenuItem key={element.key} value={element.value}>
                          {element.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Prefix"
                      name="prefix"
                      onChange={this.onChanged}
                      value={this.state.data.prefix}
                      variant="filled"
                      placeholder=""
                      style={{ width: "100%" }}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Suffix"
                      name="suffix"
                      value={this.state.data.suffix}
                      variant="filled"
                      placeholder=""
                      style={{ width: "100%" }}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Number Length"
                      name="numberLength"
                      type="number"
                      value={this.state.data.numberLength}
                      variant="filled"
                      onChange={this.onChanged}
                      placeholder=""
                      style={{ width: "100%" }}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Total Character"
                      name="totalChararcter"
                      value={this.state.data.totalCharacter}
                      variant="filled"
                      onChange={this.onChanged}
                      placeholder=""
                      style={{ width: "100%" }}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Fill character"
                      name="fillCharacter"
                      value={this.state.data.fillCharacter}
                      variant="filled"
                      placeholder=""
                      style={{ width: "100%" }}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <Grid container justify="center">
                  <Button
                    onClick={this.handleSubmit}
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{
                      marginTop: "15px",
                      height: "50px",
                      width: "150px",
                      minWidth: "115px",
                      fontSize: "13px"
                    }}
                  >
                    Add Scheme
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(AddScheme));
