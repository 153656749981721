import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import Card from "../components/Card/Card.jsx";
import CardHeader from "../components/Card/CardHeader.jsx";
import CardBody from "../components/Card/CardBody.jsx";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import { Hidden, InputBase, fade } from "@material-ui/core";

import img from "../assets/img/sidebar-4.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";

import InputAdornment from "@material-ui/core/InputAdornment";

import { MdEmail } from "react-icons/md";

import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import Footer from "../components/Footer/Footer.jsx";

import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import UserContext from "../utils/UserContext";

import axios from "axios";
import { Modal, Backdrop, Fade, withStyles,Box } from "@material-ui/core";
import Axios from "axios";

const styles = (theme) => ({
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
    resMargin: {
      [theme.breakpoints.up("xs")]: {
        width: "95%",
      },
      [theme.breakpoints.up("md")]: {
        width: "60%",
      },
    },
  },
  resModal: {
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      width: "600px",
      margin: "auto",
    },
  },
});
//AIzaSyA3kHG1SXeEQV2QUzMKRIxkOA98qnLy9eE;

class Login extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      emailHelperText: "",
      passwordHelperText: "",
      emailVal: true,
      passwordVal: true,
      open: false,
      errorOpen: false,
      loading: false,
      redirectToDashboard: false,
      modalOpen: false,
      verificationModal: false,
      resetPasswordModal: false,
      resetPassword: {
        showPassword: false,
        showConfirmPassword: false,
        password: "",
        confirmPassword: "",
        passwordError: false,
        passwordErrorText: "",
      },
      verificationCode: {
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      forgotEmail: "",
    };
  }

  handleResetPasswordChange = (e) => {
    let targetName = e.target.name;
    let targetValue = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      resetPassword: {
        ...prevState.resetPassword,
        [targetName]: targetValue,
      },
    }));
  };

  componentDidMount() {
    const user = this.context;
    console.log(user);
  }
  handleChange = () => (event) => {
    this.setState({ password: event.target.value });
  };

  handleEmailChange = () => (event) => {
    this.setState({
      email: event.target.value,
      forgotEmail: event.target.value,
    });
  };

  handleClickShowPassword = () => (event) => {
    var value = this.state.showPassword;

    this.setState({ showPassword: !value });
  };

  handleMouseDownPassword = () => (event) => {
    event.preventDefault();
  };

  onForgotButtonClicked = () => {
    this.setState({ modalOpen: true });
  };

  handleForgot = () => {
    Axios.put("api/auth/forgotPassword", { email: this.state.forgotEmail })
      .then((res) => {
        console.log(res);

        this.setState({
          verificationModal: true,
        });
      })
      .catch((err) => {
        console.log(err);

        const tokenObj = this.context;

        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data.message;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };

        tokenObj.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  handleCode = () => {
    var code =
      this.state.verificationCode.first +
      this.state.verificationCode.second +
      this.state.verificationCode.third +
      this.state.verificationCode.fourth;

    console.log(code);

    Axios.post("api/auth/getResetCode", { email: this.state.forgotEmail })
      .then((res) => {
        if (parseInt(code) === parseInt(res.data)) {
          console.log("true");

          this.setState({
            resetPasswordModal: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePasswordReset = () => {
    let password = this.state.resetPassword.password;
    let confirmPassword = this.state.resetPassword.confirmPassword;

    if (password !== confirmPassword) {
      this.setState((prevState) => ({
        ...prevState,
        resetPassword: {
          ...prevState.resetPassword,
          passwordError: true,
          passwordErrorText: "Passwords do not match",
        },
      }));
    } else if (confirmPassword === "") {
      this.setState((prevState) => ({
        ...prevState,
        resetPassword: {
          ...prevState.resetPassword,
          passwordError: true,
          passwordErrorText: "Password cannot be empty",
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        resetPassword: {
          ...prevState.resetPassword,
          passwordError: false,
          passwordErrorText: "",
        },
      }));

      Axios.put("api/auth/resetPassword", {
        email: this.state.forgotEmail,
        password: password,
      })
        .then((res) => {
          const tokenObj = this.context;

          var message = res.data;

          var snackbarProps = {
            open: true,
            variant: "success",
            message: message,
            autoHideDuration: 2000,
          };

          tokenObj.setSnackbarProps({ snackbarProps: snackbarProps });

          this.setState({
            resetPasswordModal: false,
            verificationModal: false,
            modalOpen: false,
          });
        })
        .catch((err) => {
          const tokenObj = this.context;

          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data.message;
          }

          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };

          tokenObj.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  handleTextChange = (e) => {
    //FIXME: text change implementation
    var value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      forgotEmail: value,
    }));
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ errorOpen: false });
  };

  onCodeChanged = (e) => {
    let targetName = e.target.name;
    let targetValue = e.target.value;

    if (!isNaN(targetValue)) {
      this.setState((prevState) => ({
        ...prevState,
        verificationCode: {
          ...prevState.verificationCode,
          [targetName]: targetValue[targetValue.length - 1],
        },
      }));
    }
  };

  handleSubmit = () => {
    this.setState({ loading: true });

    var emailVal,
      passwordVal = false;

    if (this.state.email === "") {
      emailVal = false;

      this.setState({ emailHelperText: "Email cannot be empty" });
      this.setState({ emailVal: emailVal, loading: false });
    } else {
      emailVal = true;

      this.setState({ emailHelperText: "" });
      this.setState({ emailVal: emailVal });
    }

    if (this.state.password === "") {
      passwordVal = false;

      this.setState({ passwordHelperText: "Password cannot be empty" });
      this.setState({ passwordVal: passwordVal, loading: false });
    } else {
      passwordVal = true;

      this.setState({ passwordHelperText: "" });
      this.setState({ passwordVal: passwordVal, loading: false });
    }

    let indexAt = this.state.email.indexOf("@");
    let indexPoint = this.state.email.lastIndexOf(".");

    if (indexAt && indexPoint !== -1 && indexAt < indexPoint) {
      const user = {
        email: this.state.email,
        password: this.state.password,
      };

      if (emailVal && passwordVal) {
        axios
          .post("api/auth/signin", user)
          .then((res) => {
            console.log("signin res")
            console.log(res);
            console.log("signin res")

            const tokenObj = this.context;

            tokenObj.setCompanyInfoSet({ value: true });
            tokenObj.setToken({ value: res.statusText });

            this.setState({ loading: false });
            this.setState({ open: true });
            localStorage.setItem(
              "Authorization",
              res.data.tokenType + " " + res.data.accessToken
            );

            axios.interceptors.request.use(
              function (config) {
                config.headers.Authorization =
                  res.data.tokenType + " " + res.data.accessToken;
                return config;
              },
              function (error) {
                return Promise.reject(error);
              }
            );

            Axios.get("/hrm/getStartupStatus")
              .then((res) => {
                tokenObj.setCompanyInfoSet({ value: res.data });
              })
              .catch((err) => {
                console.log(err);
              });

            Axios.get("/role/findMyRoles")
              .then((res) => {
                tokenObj.setRoles({ value: res.data });
              })
              .catch((err) => {
                console.log(err);
              });

            Axios.get("pos/notification/findMyAllNotification")
              .then((res) => {
                let total = 0;

                res.data.map((val) => (val.status === true ? total++ : null));

                tokenObj.setMyNotifications({ value: res.data });
                tokenObj.setUnseenCount({ value: total });
              })
              .catch((err) => {
                console.log(err);
              });

            Axios.get("hrm/findEmployeeDetails")
              .then((res) => {
                tokenObj.setEmployeeInfo({ value: res.data });
              })
              .catch((err) => {
                console.log(err);
              });

            tokenObj.setLoggedIn({ value: true });
          })
          .catch((err) => {
            console.log(err.response);

            const tokenObj = this.context;

            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data.message;
            }

            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000,
            };

            tokenObj.setSnackbarProps({ snackbarProps: snackbarProps });
          });
      }
    } else {
      emailVal = false;
      this.setState({ emailHelperText: "Please provide a valid email format" });
      this.setState({ emailVal: emailVal, loading: false });
      return;
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") this.handleSubmit();
  };

  render() {
    const { classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    if (this.state.redirectToDashboard) {
      return <Redirect from="/login" to="/dashboard" push />;
    }

    const BootstrapInput = withStyles((theme) => ({
      root: {
        "label + &": {
          marginTop: theme.spacing(3),
        },
      },
      input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.common.white,
        border: "1px solid #ced4da",
        fontSize: 29,
        width: "50px",
        textAlign: "center",
        height: "60px",
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    }))(InputBase);

    return (
      <div>
        <Hidden xsDown>
          <div>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{ height: "85vh" }}
            >
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.containerWidth}
              >
                <Grid item xs={6}>
                  <Card
                    style={{
                      width: "100%",
                      minWidth: "150px",
                      height: "400px",
                    }}
                  >
                    <CardMedia
                      style={{ height: 140 }}
                      image={img}
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{ height: "208px" }}>
                      <Typography variant="h5" component="h2">
                      SnigdhTech And Business Solution Pvt Ltd
                      </Typography>
                      <Typography
                        style={{ marginBottom: 12 }}
                        color="textSecondary"
                      >
                        Address: Jwagal, Lalitpur, Nepal
                      </Typography>
                      PAN: XXXXXXXX
                    </CardContent>
                    <Box>

                      <a href="https://snigdhtech.com/"><label className="login_learnmore">Learn More</label></a>

                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card
                    style={{
                      width: "100%",
                      minWidth: "150px",
                      height: "400px",
                    }}
                  >
                    <CardHeader color="success">
                      <h4 className="cardTitleWhite">SNIGDH ERP | LOGIN</h4>
                      <p className="cardTitleWhite">
                        Sign-In to start using the ASPIRE Software
                      </p>
                    </CardHeader>
                    <CardBody>
                      <div>
                        <FormControl fullWidth style={{ margin: "auto" }}>
                          {this.state.emailVal ? (
                            <TextField
                              id="outlined-adornment-password"
                              variant="outlined"
                              label={"Email"}
                              style={{ marginTop: "20px" }}
                              value={this.state.email}
                              helperText={this.state.emailHelperText}
                              onChange={this.handleEmailChange("email")}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      tabIndex="-1"
                                      aria-label="toggle password visibility"
                                      onMouseDown={this.handleMouseDownPassword()}
                                    >
                                      <MdEmail />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : (
                            <TextField
                              error
                              id="outlined-adornment-password"
                              variant="outlined"
                              style={{ marginTop: "20px" }}
                              label={"Email"}
                              value={this.state.email}
                              helperText={this.state.emailHelperText}
                              onChange={this.handleEmailChange("email")}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      tabIndex="-1"
                                      aria-label="toggle password visibility"
                                      onMouseDown={this.handleMouseDownPassword()}
                                    >
                                      <MdEmail />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </FormControl>
                      </div>
                      <div>
                        <FormControl fullWidth style={{ margin: "auto" }}>
                          {this.state.passwordVal ? (
                            <TextField
                              id="outlined-adornment-password"
                              variant="outlined"
                              style={{ marginTop: "20px" }}
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              helperText={this.state.passwordHelperText}
                              label={"Password"}
                              value={this.state.password}
                              onKeyDown={this.handleKeyDown}
                              onChange={this.handleChange("password")}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      tabIndex="-1"
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword()}
                                      onMouseDown={this.handleMouseDownPassword()}
                                    >
                                      {this.state.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : (
                            <TextField
                              error
                              id="outlined-adornment-password"
                              variant="outlined"
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              label={"Password"}
                              style={{ marginTop: "20px" }}
                              value={this.state.password}
                              helperText={this.state.passwordHelperText}
                              onChange={this.handleChange("password")}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      edge="end"
                                      tabIndex="-1"
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword()}
                                      onMouseDown={this.handleMouseDownPassword()}
                                    >
                                      {this.state.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </FormControl>

                        {this.state.loading ? (
                          <CircularProgress style={{ margin: "10px" }} />
                        ) : (
                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            style={{
                              marginTop: "20px",
                            }}
                            onClick={this.handleSubmit}
                          >
                            Sign In
                          </Button>
                        )}
                      </div>
                      <div>
                        <Button
                          color="secondary"
                          onClick={this.onForgotButtonClicked}
                          style={{
                            marginTop: "20px",
                            marginBottom: "52px",
                          }}
                        >
                          I forgot my password
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Hidden>
        <Hidden smUp>
          <div
            style={{
              backgroundImage: `url(${img})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{
                height: "100vh",
              }}
            >
              <Grid item>
                <Card style={{ width: "96%", margin: "auto" }}>
                  <CardHeader color="success">
                    <h4 className="cardTitleWhite">ASPIRE | Login</h4>
                    <p className="cardTitleWhite">
                      Sign-In to start using the system
                    </p>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <FormControl fullWidth style={{ margin: "auto" }}>
                        {this.state.emailVal ? (
                          <TextField
                            id="outlined-adornment-password"
                            variant="outlined"
                            label={"Email"}
                            style={{ marginTop: "20px" }}
                            value={this.state.email}
                            helperText={this.state.emailHelperText}
                            onChange={this.handleEmailChange("email")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    tabIndex="-1"
                                    aria-label="toggle password visibility"
                                    onMouseDown={this.handleMouseDownPassword()}
                                  >
                                    <MdEmail />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <TextField
                            error
                            id="outlined-adornment-password"
                            variant="outlined"
                            label={"Email"}
                            style={{ marginTop: "20px" }}
                            value={this.state.email}
                            helperText={this.state.emailHelperText}
                            onChange={this.handleEmailChange("email")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    tabIndex="-1"
                                    aria-label="toggle password visibility"
                                    onMouseDown={this.handleMouseDownPassword()}
                                  >
                                    <MdEmail />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth style={{ margin: "auto" }}>
                        {this.state.passwordVal ? (
                          <TextField
                            id="outlined-adornment-password"
                            variant="outlined"
                            type={this.state.showPassword ? "text" : "password"}
                            helperText={this.state.passwordHelperText}
                            label={"Password"}
                            style={{ marginTop: "20px" }}
                            value={this.state.password}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.handleChange("password")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    tabIndex="-1"
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword()}
                                    onMouseDown={this.handleMouseDownPassword()}
                                  >
                                    {this.state.showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <TextField
                            error
                            id="outlined-adornment-password"
                            variant="outlined"
                            type={this.state.showPassword ? "text" : "password"}
                            label={"Password"}
                            style={{ marginTop: "20px" }}
                            value={this.state.password}
                            helperText={this.state.passwordHelperText}
                            onChange={this.handleChange("password")}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    tabIndex="-1"
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword()}
                                    onMouseDown={this.handleMouseDownPassword()}
                                  >
                                    {this.state.showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </FormControl>

                      {this.state.loading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          style={{
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                          onClick={this.handleSubmit}
                        >
                          Sign In
                        </Button>
                      )}
                    </div>
                    <div>
                      <Button
                        color="secondary"
                        onClick={this.onForgotButtonClicked}
                      >
                        I forgot my password
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Hidden>

        {/* modal to send verification to email */}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          className={classes.resModal}
          open={this.state.modalOpen}
          onClose={() => {
            this.setState({ modalOpen: false });
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <Card>
              <CardHeader color="danger">
                <h4 className="cardTitleWhite">Forgot Password</h4>
              </CardHeader>
              <CardBody>
                <Typography variant="h6" gutterBottom>
                  Enter your email to receive your verification code!
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={this.state.forgotEmail}
                  onChange={this.handleTextChange}
                  style={{ marginTop: "20px" }}
                  label="Email"
                ></TextField>
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    onClick={this.handleForgot}
                    style={{
                      marginTop: "20px",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      paddingRight: "40px",
                      paddingLeft: "40px",
                      marginBottom: "20px",
                    }}
                    color="primary"
                  >
                    Send Code
                  </Button>
                </Grid>
              </CardBody>
            </Card>
          </Fade>
        </Modal>

        {/* modal to enter verification code */}

        <Modal
          style={modal}
          className={classes.resModal}
          open={this.state.verificationModal}
          onClose={() => {
            this.setState({ verificationModal: false });
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.verificationModal}>
            <Card>
              <CardHeader color="danger">
                <h4 className="cardTitleWhite">Verification</h4>
              </CardHeader>
              <CardBody>
                <Grid container justify="center">
                  <Typography variant="h6" gutterBottom>
                    Enter the verification code that you received in your email
                  </Typography>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  justify="center"
                  style={{ padding: "25px" }}
                >
                  <Grid item>
                    <BootstrapInput
                      name="first"
                      value={this.state.verificationCode.first}
                      onChange={this.onCodeChanged}
                    />
                  </Grid>
                  <Grid item>
                    <BootstrapInput
                      name="second"
                      onChange={this.onCodeChanged}
                      value={this.state.verificationCode.second}
                    />
                  </Grid>
                  <Grid item>
                    <BootstrapInput
                      name="third"
                      onChange={this.onCodeChanged}
                      value={this.state.verificationCode.third}
                    />
                  </Grid>
                  <Grid item>
                    <BootstrapInput
                      name="fourth"
                      onChange={this.onCodeChanged}
                      value={this.state.verificationCode.fourth}
                    />
                  </Grid>
                  <Grid container justify="center">
                    <Button
                      variant="contained"
                      onClick={this.handleCode}
                      style={{
                        marginTop: "40px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        paddingRight: "40px",
                        paddingLeft: "40px",
                        marginBottom: "0px",
                      }}
                      color="primary"
                    >
                      Verify Code
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Fade>
        </Modal>

        {/* modal to reset password */}

        <Modal
          style={modal}
          className={classes.resModal}
          open={this.state.resetPasswordModal}
          onClose={() => {
            this.setState({ resetPasswordModal: false });
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.resetPasswordModal}>
            <Card>
              <CardHeader color="danger">
                <h4 className="cardTitleWhite">Reset Password</h4>
              </CardHeader>
              <CardBody>
                <Typography variant="h6" gutterBottom>
                  Enter a new password for your account
                </Typography>
                {this.state.resetPassword.passwordError ? (
                  <TextField
                    variant="outlined"
                    type={
                      this.state.resetPassword.showPassword
                        ? "text"
                        : "password"
                    }
                    fullWidth
                    style={{ marginTop: "20px" }}
                    error
                    helperText={this.state.resetPassword.passwordErrorText}
                    label={"Password"}
                    name="password"
                    value={this.state.resetPassword.password}
                    onChange={this.handleResetPasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState((prevState) => ({
                                ...prevState,
                                resetPassword: {
                                  ...prevState.resetPassword,
                                  showPassword: !prevState.resetPassword
                                    .showPassword,
                                },
                              }))
                            }
                          >
                            {this.state.resetPassword.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <TextField
                    variant="outlined"
                    type={
                      this.state.resetPassword.showPassword
                        ? "text"
                        : "password"
                    }
                    fullWidth
                    label={"Password"}
                    name="password"
                    style={{ marginTop: "20px" }}
                    value={this.state.resetPassword.password}
                    onChange={this.handleResetPasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState((prevState) => ({
                                ...prevState,
                                resetPassword: {
                                  ...prevState.resetPassword,
                                  showPassword: !prevState.resetPassword
                                    .showPassword,
                                },
                              }))
                            }
                          >
                            {this.state.resetPassword.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                {this.state.resetPassword.passwordError ? (
                  <TextField
                    variant="outlined"
                    type={
                      this.state.resetPassword.showConfirmPassword
                        ? "text"
                        : "password"
                    }
                    fullWidth
                    style={{ marginTop: "20px" }}
                    error
                    helperText={this.state.resetPassword.passwordErrorText}
                    label={"Confirm Password"}
                    name="confirmPassword"
                    value={this.state.resetPassword.confirmPassword}
                    onChange={this.handleResetPasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState((prevState) => ({
                                ...prevState,
                                resetPassword: {
                                  ...prevState.resetPassword,
                                  showConfirmPassword: !prevState.resetPassword
                                    .showConfirmPassword,
                                },
                              }))
                            }
                          >
                            {this.state.resetPassword.showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <TextField
                    variant="outlined"
                    type={
                      this.state.resetPassword.showConfirmPassword
                        ? "text"
                        : "password"
                    }
                    fullWidth
                    style={{ marginTop: "20px" }}
                    label={"Confirm Password"}
                    name="confirmPassword"
                    value={this.state.resetPassword.confirmPassword}
                    onChange={this.handleResetPasswordChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.setState((prevState) => ({
                                ...prevState,
                                resetPassword: {
                                  ...prevState.resetPassword,
                                  showConfirmPassword: !prevState.resetPassword
                                    .showConfirmPassword,
                                },
                              }))
                            }
                          >
                            {this.state.resetPassword.showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                <Grid container justify="center">
                  <Button
                    variant="contained"
                    onClick={this.handlePasswordReset}
                    style={{
                      marginTop: "20px",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      paddingRight: "40px",
                      paddingLeft: "40px",
                      marginBottom: "20px",
                    }}
                    color="primary"
                  >
                    Reset Password
                  </Button>
                </Grid>
              </CardBody>
            </Card>
          </Fade>
        </Modal>

        <Footer />
      </div>
    );
  }
}

Login.propTypes = {};

export default withRouter(withStyles(styles)(Login));
