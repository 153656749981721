import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Backdrop,
  MenuItem,
  withStyles,
  IconButton,
  Typography
} from "@material-ui/core";
import Axios from "axios";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  cardHeight: {
    minWidth: "300px",
    padding: "5px",
    overflowY: "auto",
    height: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    [theme.breakpoints.up("md")]: {
      width: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "66%"
    }
  },
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%"
    }
  },
  smMargin: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "60px"
    }
  },
  mdMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px"
    }
  }
});

class ViewButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editable: false,
      deleteId: "",
      data: {
        id: "",
        city: "",
        contactPerson: "",
        country: "",
        creditTerm: "",
        emailAddress: "",
        faxNumber: "",
        fullName: "",
        supplierIdNumber: "",
        mobileNumber: "",
        otherReference: "",
        panNumber: "",
        postBoxNumber: "",
        productType: "",
        supplierType: "",
        telephoneNumber: "",
        registrationNumber: "",
        state: "",
        street: "",
        supplierBankDetails: {
          bankName: ""
        }
      },
      buttonColor: "#108dcd"
    };
  }

  componentDidMount() {

    if (this.props.node.data.supplierBankDetails !== null) {
      this.setState({
        data: this.props.node.data,
      });
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, editable: false, buttonColor: "#108dcd" });
  };

  handleUpdate = () => {
    var id = this.props.node.data.id;
    Axios.put("pos/supplier/updateSupplier/" + id, this.state.data)
      .then(res => {
        console.log(res);
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };
        this.props.updateData();
        this.props.showSnackbar(snackbarProps);
      })
      .catch(err => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };
        this.props.showSnackbar(snackbarProps);
      });
  };

  handleDelete = () => {
    var id = this.state.data.id;
    Axios.put("/pos/supplier/deleteSupplier/" + id)
      .then(res => {

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };
        this.props.updateData();
        this.props.showSnackbar(snackbarProps);
      })
      .catch(err => {
        console.log(err)
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000
        };
        this.props.showSnackbar(snackbarProps);
      })
  }

  onChangedBasic = e => {
    e.stopPropagation();

    if (!this.state.editable) return;

    var targetName = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  onChangedBank = e => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    if (!this.state.editable) return;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        supplierBankDetails: {
          ...prevState.data.supplierBankDetails,
          [targetName]: value
        }
      }
    }));
  };

  render() {
    const { classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const textFieldStyle = {
      width: "100%"
    };

    const inputStyles = {
      fontSize: 14
    };

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.setState({ editable: true, buttonColor: "#1044CD" });
                    }}
                    style={{
                      margin: "15px",
                      marginBottom: "60px",
                      backgroundColor: this.state.buttonColor,
                    }}
                  >
                    <Typography style={{ color: "white" }}>Edit</Typography>
                  </Button>
                </Grid>
                <Grid container spacing={10} className={classes.containerWidth}>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <Grid spacing={2} container justify="center">
                          <h6>Supplier Details</h6>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Full Name"
                            value={this.state.data.fullName}
                            name="fullName"
                            onChange={this.onChangedBasic}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Supplier ID Number"
                            value={this.state.data.supplierIdNumber}
                            name="supplierIdNumber"
                            onChange={this.onChangedBasic}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Mobile Number"
                            name="mobileNumber"
                            onChange={this.onChangedBasic}
                            value={this.state.data.mobileNumber}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Telephone Number"
                            name="telephoneNumber"
                            onChange={this.onChangedBasic}
                            value={this.state.data.telephoneNumber}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Fax Number"
                            name="faxNumber"
                            onChange={this.onChangedBasic}
                            value={this.state.data.faxNumber}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Post Box Number"
                            name="postBoxNumber"
                            onChange={this.onChangedBasic}
                            value={this.state.data.postBoxNumber}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Registration Number"
                            name="registrationNumber"
                            onChange={this.onChangedBasic}
                            value={this.state.data.registrationNumber}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="PAN Number"
                            name="panNumber"
                            onChange={this.onChangedBasic}
                            value={this.state.data.panNumber}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Email Address"
                            name="emailAddress"
                            onChange={this.onChangedBasic}
                            value={this.state.data.emailAddress}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Contact Person"
                            name="contactPerson"
                            onChange={this.onChangedBasic}
                            value={this.state.data.contactPerson}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2} className={classes.mdMargin}>
                      <Grid item xs>
                        <Grid spacing={2} container justify="center">
                          <h6>Supplier Bank Details</h6>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Bank Name"
                            name="bankName"
                            onChange={this.onChangedBank}
                            value={this.state.data.supplierBankDetails.bankName}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Bank Account Name"
                            name="accountName"
                            onChange={this.onChangedBank}
                            value={
                              this.state.data.supplierBankDetails.accountName
                            }
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Bank Account Number"
                            name="accountNumber"
                            onChange={this.onChangedBank}
                            value={
                              this.state.data.supplierBankDetails.accountNumber
                            }
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Bank Code"
                            name="bankCode"
                            onChange={this.onChangedBank}
                            value={this.state.data.supplierBankDetails.bankCode}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Branch Code"
                            name="branchCode"
                            onChange={this.onChangedBank}
                            value={
                              this.state.data.supplierBankDetails.branchCode
                            }
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Account Type"
                            name="accountType"
                            onChange={this.onChangedBank}
                            value={
                              this.state.data.supplierBankDetails.accountType
                            }
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="SWIFT"
                            name="swiftCode"
                            onChange={this.onChangedBank}
                            value={
                              this.state.data.supplierBankDetails.swiftCode
                            }
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Currency"
                            name="currency"
                            onChange={this.onChangedBank}
                            value={this.state.data.supplierBankDetails.currency}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="IBAN"
                            name="ibanNumber"
                            onChange={this.onChangedBank}
                            value={
                              this.state.data.supplierBankDetails.ibanNumber
                            }
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2} className={classes.smMargin}>
                      <Grid item xs>
                        <Grid spacing={2} container justify="center" npm>
                          <h6>Full Address</h6>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Country"
                            name="country"
                            onChange={this.onChangedBasic}
                            value={this.state.data.country}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="State"
                            name="state"
                            onChange={this.onChangedBasic}
                            value={this.state.data.state}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="City"
                            name="city"
                            onChange={this.onChangedBasic}
                            value={this.state.data.city}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Street"
                            name="street"
                            onChange={this.onChangedBasic}
                            value={this.state.data.street}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-select-currency"
                            select
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Product Type"
                            name="productType"
                            onChange={this.onChangedBasic}
                            value={this.state.data.productType}
                            style={textFieldStyle}
                            margin="normal"
                          >
                            <MenuItem key={1} value={"Product Type 1"}>
                              Product Type 1
                            </MenuItem>
                            <MenuItem key={2} value={"Product Type 2"}>
                              Product type 2
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-select-currency"
                            label="Supplier Type"
                            name="supplierType"
                            onChange={this.onChangedBasic}
                            value={this.state.data.supplierType}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          >
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Credit Term "
                            name="creditTerm"
                            onChange={this.onChangedBasic}
                            value={this.state.data.creditTerm}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Other Reference"
                            name="otherReference"
                            onChange={this.onChangedBasic}
                            value={this.state.data.otherReference}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2} className={classes.smMargin}>
                      <Grid item xs>
                        <Grid spacing={2} container justify="center">
                          <h6>Bank Address</h6>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Country"
                            name="country"
                            onChange={this.onChangedBank}
                            value={this.state.data.supplierBankDetails.country}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="State"
                            name="state"
                            onChange={this.onChangedBank}
                            value={this.state.data.supplierBankDetails.state}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="City"
                            name="city"
                            onChange={this.onChangedBank}
                            value={this.state.data.supplierBankDetails.city}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            placeholder=""
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Street"
                            name="street"
                            onChange={this.onChangedBank}
                            value={this.state.data.supplierBankDetails.street}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justify="center">
                  <Grid>
                    {this.state.editable ? (
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={this.handleUpdate}
                        style={{
                          margin: "25px",
                          marginTop: "45px",
                          marginRight: "25px"
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          disabled
                          onClick={this.handleUpdate}
                          style={{
                            margin: "25px",
                            marginTop: "45px",
                            marginRight: "25px"
                          }}
                        >
                          Update
                        </Button>
                      )}
                  </Grid>
                  <Grid>
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleDelete}
                      style={{
                        margin: "25px",
                        marginTop: "45px",
                        marginRight: "25px"
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewButton);
