import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { Card, CardContent, Grid, withStyles, Button } from "@material-ui/core";
import UserContext from "../../utils/UserContext";
import ViewAuthorizedGoodIssued from "./ViewAuthorizedGoodIssued";
import Axios from "axios";
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "100%",
    height: "70vh",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class GoodIssuedNoteList extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      excelData: [],
      columnDefs: [
        {
          headerName: "S.No.",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 60,
          width: 60,
        },
        {
          headerName: "GIN No.",
          field: "ginNumber",
          sortable: true,
          resizable: true,
          minWidth: 125,
          filter:true,
        },
        {
          headerName: "GIN Date",
          field: "ginDate",
          sortable: true,
          resizable: true,
          minWidth: 100,
          filter:true
        },
        {
          headerName: "SO Number",
          field: "salesOrderNumber",
          sortable: true,
          resizable: true,
          minWidth: 90,
          filter:true
        },
        {
          headerName: "Issued To",
          field: "issuedTo",
          resizable: true,
          sortable: true,
          minWidth: 130,
          filter:true
        },
        {
          headerName: "Sold By",
          field: "soldByUser",
          sortable: true,
          resizable: true,
          minWidth: 130,
          filter:true
        },
        {
          headerName: "Invoice No.",
          field: "invoiceNumber",
          resizable: true,
          sortable: true,
          minWidth: 115,
          filter:true
        },
        {
          headerName: "Grand Total",
          field: "grandTotal",
          resizable: true,
          sortable: true,
          minWidth: 90,
          filter:true
        },
        {
          headerName: "View",
          cellRendererFramework: ViewAuthorizedGoodIssued,
          cellRendererParams: {
            showSnackbar: this.showSnackbar,
            getGINData: this.getGINData,
          },
          minWidth: 53,
          width: 53,
        },
      ],
      rowData: [],
      overlayLoadingTemplate:
      '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    overlayNoRowsTemplate:
      '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  onGridReady = (params) => {
   
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  componentDidMount() {
    this.setState({
      overlayLoadingTemplate: "<span></span>"
    })
    Axios.get("/pos/ginMaster/findAllApproved")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          rowData: res.data,
        }));
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) {
           this.setState({
            //overlayLoadingTemplate: "<span></span>" 
            overlayNoRowsTemplate: "<span>No rows to show</span>",
           })
           this.gridApi.showNoRowsOverlay();
          } 
        } catch (error) {
          console.log(error);
        }
        //this.gridApi.sizeColumnsToFit();
      })
      .catch((err) => {
        console.log(err);
        
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  componentDidUpdate() {
    if (!this.gridApi) return;
    this.gridApi.sizeColumnsToFit();
  }

  componentWillReceiveProps(nextProps) {
    this.getExcelData(nextProps.data);
  }

  getExcelData = async (data) => {
    let excel = [];

    let object = {
      sn: "S.No.",
      ginNumber: "GIN Number",
      ginDate: "GIN Date",
      salesOrderNumber: "SO Number",
      issuedTo: "Issued To",
      soldByUser: "Sold By User",
      invoiceNumber: "Invoice Number",
      grandTotal: "Total",
    };
    excel.push(object);

    await data.map((el, i) => {
      let obj = {
        sn: i + 1,
        ginNumber: el.ginNumber,
        ginDate: el.ginDate,
        salesOrderNumber: el.salesOrderNumber,
        issuedTo: el.issuedTo,
        soldByUser: el.soldByUser,
        invoiceNumber: el.invoiceNumber,
        grandTotal: el.grandTotal,
      };
      excel.push(obj);

      return null;
    });

    await this.setState({
      excelData: excel,
    });
  };

  render() {
    const { children, value, index, classes, ...other } = this.props;

    return (
      <Grid container>
        <Card
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
          className={classes.root}
        >
          <CardContent>
            <Grid
              container
              spacing={2}
              justify="flex-end"
              style={{ marginBottom: "7px" }}
            >
              <Grid item>
                <ExcelFile
                  filename="GINAuthorizedList"
                  element={
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        minWidth: "100px",
                        width: "6vw",
                        margin: "auto",
                        marginTop: "10px",
                        marginLeft: "20px",
                        height: "40px",
                        fontSize: "16px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        color="#3f51b5"
                        size="30px"
                        style={{
                          marginRight: "10px",
                        }}
                      ></FontAwesomeIcon>
                      Excel
                    </Button>
                  }
                >
                  <ExcelSheet data={this.state.excelData} name="Sheet A">
                    <ExcelColumn value="sn" />
                    <ExcelColumn value="ginNumber" />
                    <ExcelColumn value="ginDate" />
                    <ExcelColumn value="salesOrderNumber" />
                    <ExcelColumn value="issuedTo" />
                    <ExcelColumn value="soldByUser" />
                    <ExcelColumn value="invoiceNumber" />
                    <ExcelColumn value="grandTotal" />
                  </ExcelSheet>
                </ExcelFile>
              </Grid>
            </Grid>

            <div
              className="ag-theme-balham"
              style={{
                height: "60vh",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={this.props.data}
                animateRows={true}
                rowSelection={this.state.rowSelection}
                onGridReady={this.onGridReady}
                //onCellValueChanged={this.onCellValueChanged.bind(this)}
                enableCellChangeFlash={true}
                onFirstDataRendered={this.onFirstDataRendered}
               // overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              ></AgGridReact>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(GoodIssuedNoteList);
