import React, { Component } from "react";
import {
  Grid,
  Card,
  Typography,
  Divider,
  TextField,
  Button,
  MenuItem,
  withStyles,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
} from "@material-ui/core";
import { Dropdown, Label } from "semantic-ui-react";
import Axios from "axios";
import VoucherNumberRestriction from "../../components/InterfaceElements/VoucherNumberRestriction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../utils/UserContext";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { parseISO, isAfter, isBefore, format } from "date-fns";
import AddLedger from "../AccountMaster/AddLedger";
import AddSubLedger from "../AccountMaster/AddSubLedger";
import arraySearchSubAccount from '../../utils/ArraySearch'

const styles = (theme) => ({
  containerWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  root: {
    overflow: "visible",
    padding: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    margin: "auto",
    width: "98%",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class AddPurchaseVoucher extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.addNewRow = this.addNewRow.bind(this);
    this.state = {
      currentAccountCode: "",
      data: {
        supplier: {
          fullName: "",
          supplierIdNumber: "",
        },
        voucherNumber: "",
        invoiceNumber: "",
        totalCheck: 0,
        total: "",
        grnReference: "",
        paymentReferenceNumber: "",
        paymentMode: "Cash",
        date: "",
        fiscalYear: "",
        period: "",
        transportNumber: "",
        chalanNumber: "",
      },
      supplierOptionList: [],
      idOptionList: [],
      mySubAccount: [],
      supplierData: [],
      periodList: [],
      fyList: [],
      resData: [],
      tableRowData: {
        narration: "",
        costCenter: "",
        accountCode: "",
        subAccount: "",
        currencyAnalysis: "NPR",
        amount: "",
      },
      rowRefs: [],
      subRowRefs: [],
      accountList: [],
      subAccountList: [],
      costCenterList: [],
      totalColor: "#FF8070",
      gridColor: [],
      tableList: [],
      voucherNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      masterNarration: "",
      emptyAccountCode: false,
      emptyAmount: false,
      periodDateFrom: "",
      periodDateTo: "",
      emptyInvoiceNumber: false,
      emptyTotal: false,
      addAccountOpen: false,
      addSubAccountOpen: false,
      emptyCostCenter: false,
      emptySubAccount: false,
      saveButtonDisabled: false,
    };
  }

  getAllCostCenters = () => {
    Axios.get("pos/costCenter/findAll")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          costCenterList: res.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleItemSelect = (e, data) => {
    e.stopPropagation();
    const { value } = data;

    if (value === "add") {
      if (data.name === "accountCode") {
        this.setState({ addAccountOpen: true });
      } else {
        this.setState({ addSubAccountOpen: true });
      }
      return;
    }

    var targetName = data.name;

    if (targetName === "accountCode") {
      this.getSubAccountList(value);
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          subAccount: "",
          [targetName]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          [targetName]: value,
        },
      }));
    }
  };

  customSearch = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = this.state.rowRefs;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );

    return [
      {
        key: "add",
        value: "add",
        text: "Add new account code",
      },
      ...newSet,
    ];
  };

  customSearchForSub = (_options, data) => {
    if (data.searchQuery?.length > 0) {
      let searchReasult = arraySearchSubAccount(this.state.subRowRefs, data.searchQuery);
      console.log("The search resluts", searchReasult)
      this.setState((prevState) => ({
        ...prevState,
        mySubAccount: searchReasult.slice(0, 20)
      }))
    } else return
  };

  getSubAccountList = (accountCode) => {
    let rowDetails = [];

    Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCode
    ).then((res) => {
      res.data.forEach((element) => {
        var item = {
          id: element.code,
          value: element.code,
          text: element.code + "-" + element.name,
        };
        rowDetails.push(item);
      });

      this.setState({
        subAccountList: [{ key: "None", code: "None", name: "None" },...res.data],
        subRowRefs: [
          {
            id: "None",
            code: "None",
            name: "None",
          },
          ...rowDetails
        ],
          
        mySubAccount: [{
          id: "None",
          value: "None",
          text: "None"
        },
      ...rowDetails.slice(0,15)] ,
      });
    });
  };

  componentDidMount() {
    this.getAllCostCenters();
    this.getAllSuppliers();
    this.getAllPeriodListing();

    var rowDetails = [];
    Axios.get("pos/ledgerMaster/getAllActiveAccountGroup")
      .then(async (res) => {
        res.data.forEach((element) => {
          var item = {
            key: element.code,
            value: element.code,
            text: element.code + "-" + element.name,
          };
          rowDetails.push(item);
        });

       this.setState({
          accountList: [{id: "None", code: "None",name: "None"},...res.data],
          rowRefs: [{key: "None",value: "None", text: "None"},...rowDetails],
        });
      })
      .catch((err) => {
        console.log(err);
      });
    var today = new Date();

    var month = (today.getMonth() + 1).toString();

    var day = today.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: date,
      },
    }));
  }

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      voucherNumber: val,
      data: {
        ...prevState.data,
        voucherNumber: val,
      }
    }));
  };

  handleMasterNarrationChange = (e) => {
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      masterNarration: value,
      tableRowData: {
        ...prevState.tableRowData,
        narration: value,
      },
    }));
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setTodayDate() {
    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: date,
      },
    }));
  }

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            fiscalYear: res.data.fiscalYear,
            period: res.data.name,
          },
        }));
        this.getPeriodList();
        this.getVoucherNumbering(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getVoucherNumbering(fiscalYear){
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", { "fiscalYear":fiscalYear, "name":"Purchase Voucher"})
    .then((res) =>{
      if (res.data === "Not Found") {
                this.setState({ voucherNumberError: true });
              } else {
      this.setState((prevState)=>({
        ...prevState,
        data:{
          ...prevState.data,
          voucherNumber:res.data[0]
        }
    }))
  }
  })
  }

  getPeriodDateFromTo = (fiscalYear, period) => {

    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState({
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        })
    );
  };

  handleFYChange = async (e) => {
    let fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        fiscalYear: fy,
      },
    }));
    this.getVoucherNumbering(fy);
    this.getPeriodList();
  };

  getPeriodList() {
    var pLists = [];
    this.state.resData.map((val) =>
      Object.keys(val).includes(this.state.data.fiscalYear)
        ? (pLists = val[this.state.data.fiscalYear])
        : null
    );
    this.setState((prevState)=>({
      ...prevState,
      periodList: pLists,
      data:{
        ...prevState.data,
      period: pLists[pLists.length-1]

      }
    }));
     this.getPeriodDateFromTo(this.state.data.fiscalYear,pLists[pLists.length-1]);
  }

  handlePeriodChange = async (e) => {
    let py = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        period: py,
      },
    }));
    this.getPeriodDateFromTo(this.state.data.fiscalYear, py);
  };

  shouldDisableDate = (date) => {
    let periodFrom = parseISO(this.state.periodDateFrom);
    let periodTo = parseISO(this.state.periodDateTo);
    let currentDate = new Date(date);

    if (isAfter(currentDate, periodTo) || isBefore(currentDate, periodFrom)) {
      return true;
    } else {
      return false;
    }
  };

  handleCheck = () => {
    const token = this.context;

    var idList = [];

    if (parseInt(this.state.data.totalCheck) !== 0) {
      this.state.tableList.map((value, i) =>
        parseInt(value.amount) === parseInt(this.state.data.totalCheck)
          ? idList.push(i + 1)
          : null
      );
    }

    if (idList.length === 0 && parseInt(this.state.data.totalCheck) !== 0) {
      if (parseInt(this.state.data.totalCheck) < 0) {
        this.state.tableList.map((value, i) =>
          parseInt(value.amount) < 0 ? idList.push(i + 1) : null
        );
      } else {
        this.state.tableList.map((value, i) =>
          parseInt(value.amount) > 0 ? idList.push(i + 1) : null
        );
      }
    }

    if (idList.length === 0) {
      var snackbarProps = {
        open: true,
        variant: "success",
        message: "Credit and Debit line balanced. Safe to POST.",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: snackbarProps });
    }

    var gridColor = this.state.gridColor;

    idList.map((value) => (gridColor[value] = "#FFA99E"));

    this.setState({
      gridColor,
    });

    var newGridColors = [];

    idList.map((value) => newGridColors.push("#FFFFFF"));

    setTimeout(() => {
      this.setState({
        gridColor: newGridColors,
      });
    }, 1000);
  };

  resetTable() {
    this.setState({
      data: {
        supplier: {
          fullName: "",
          supplierIdNumber: "",
        },
        voucherNumber: "",
        invoiceNumber: "",
        totalCheck: "0",
        total: "",
        grnReference: "",
        paymentReferenceNumber: "",
        paymentMode: "Cash",
        date: "",
        fiscalYear: "",
        period: "",
      },
      tableRowData: {
        narration: "",
        costCenter: "",
        accountCode: "",
        subAccount: "",
        currencyAnalysis: "NPR",
        amount: "",
      },
      subAccountList: [],
      totalColor: "#FF8070",
      gridColor: [],
      tableList: [],
      voucherNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      masterNarration: "",
      emptyAccountCode: false,
      emptyAmount: false,
      emptyInvoiceNumber: false,
      emptyTotal: false,
    });

    this.getAllCostCenters();
    this.setTodayDate();
    this.getAllSuppliers();
    this.getAllPeriodListing();
  }

  onChanged = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  getAllSuppliers() {
    Axios.get("/pos/supplier/getAllSuppliers")
      .then((res) => {
        var optionList = [
          {
            key: "None",
            value: "None",
            text: "None",
          },
        ];
        var idOptionList = [
          {
            key: "None",
            value: "None",
            text: "None",
          },
        ];
        try {
          res.data.forEach((element) => {
            const item = {
              key: element.supplierIdNumber,
              value: element.fullName,
              text: element.fullName,
            };
            const idList = {
              key: element.supplierIdNumber,
              value: element.supplierIdNumber,
              text: element.supplierIdNumber,
            };

            optionList.push(item);
            idOptionList.push(idList);
          });
        } catch {
          console.log("error setting suppleir")
        }


        this.setState((prevState) => ({
          ...prevState,
          supplierData: res.data,
          supplierOptionList: optionList,
          idOptionList: idOptionList,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChangedTableRow = async (e) => {
    e.stopPropagation();

    var totalAmount = 0;

    var targetName = e.target.name;
    var value = e.target.value;

    var id = targetName.split(".")[1];
    var name = targetName.split(".")[0];

    var tableList = this.state.tableList;

    var arrIndex = tableList.findIndex((e) => e.id === parseInt(id));

    var currentRow = tableList[arrIndex];

    if (name === "accountCode") {
      let rowDetails = []
      let res = await Axios.get(
        "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + value
      );
      res.data.forEach((element) => {
        var item = {
          id: element.code,
          value: element.code,
          text: element.code + "-" + element.name,
        };
        rowDetails.push(item);
      });
      this.setState({
        ...this.state,
        subRowRefs: rowDetails
      })

      currentRow["subAccountList"] = [{ key: "None", value: "None", text: "None" },...rowDetails.slice(0,10)]

      currentRow["subAccount"] = "";
    }

    if (name === "amount") {
      var isCredit = parseInt(value) < 0;
      var debitCredit = isCredit ? "Credit" : "Debit";

      currentRow["debitCredit"] = debitCredit;
    }

    currentRow[name] = value;

    tableList[arrIndex] = currentRow;

    tableList.forEach((element) => {
      totalAmount += parseInt(element.amount);
    });

    var totalColor = "#FF8070";

    if (parseInt(totalAmount) === 0) totalColor = "#43A047";

    console.log(totalAmount);

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        totalCheck: totalAmount,
      },
      totalColor,
    }));
  };

  onChangedTableRowSubAccount = async (e,data) => {
    e.stopPropagation();

    var targetName = data.name
    var id = targetName.split(".")[1];
    var name = targetName.split(".")[0];
    var value = data.value;
    var totalAmount = 0;

    var tableList = this.state.tableList;

    var arrIndex = tableList.findIndex((e) => e.id === parseInt(id));

    var currentRow = tableList[arrIndex];

    currentRow[name] = value;

    tableList[arrIndex] = currentRow;

    tableList.forEach((element) => {
      totalAmount += parseInt(element.amount);
    });

    var totalColor = "#FF8070";

    if (parseInt(totalAmount) === 0) totalColor = "#43A047";

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        totalCheck: totalAmount,
      },
      totalColor,
    }));
  };

  onChangedEntry = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    if (targetName === "accountCode") {
      this.getSubAccountList(value);
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          subAccount: "Sub Account",
          [targetName]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          [targetName]: value,
        },
      }));
    }
  };

  addNewRow = () => {
    this.textInput.current.focus();
    var tableList = this.state.tableList;
    var gridColor = this.state.gridColor;

    var totalAmount = this.state.data.totalCheck;

    const {
      narration,
      costCenter,
      accountCode,
      subAccount,
      currencyAnalysis,
      amount,
    } = this.state.tableRowData;

    if (
      this.state.tableRowData.accountCode === "" &&
      this.state.tableRowData.amount === "" &&
      this.state.tableRowData.costCenter === "" &&
      this.state.tableRowData.subAccount === ""
    ) {
      this.setState((prevState) => ({
        ...prevState,
        emptyAccountCode: true,
        emptyAmount: true,
        emptyCostCenter: true,
        emptySubAccount: true,
      }));
      return;
    } else if (this.state.tableRowData.accountCode === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyAccountCode: true,
        emptyAmount: false,
        emptyCostCenter: false,
        emptySubAccount: false,
      }));
      return;
    } else if (this.state.tableRowData.amount === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyAmount: true,
        emptyCostCenter: false,
        emptySubAccount: false,
        emptyAccountCode: false,
      }));
      return;
    } else if (this.state.tableRowData.costCenter === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyCostCenter: true,
        emptyAmount: false,
        emptySubAccount: false,
        emptyAccountCode: false,
      }));
      return;
    } else if (this.state.tableRowData.subAccount === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptySubAccount: true,
        emptyCostCenter: false,
        emptyAmount: false,
        emptyAccountCode: false,
      }));
      return;
    }
    else {
      this.setState((prevState) => ({
        ...prevState,
        emptySubAccount: false,
        emptyCostCenter: false,
        emptyAmount: false,
        emptyAccountCode: false,
      }));
    }

    totalAmount = parseInt(totalAmount) + parseInt(amount);

    var isCredit = parseInt(this.state.tableRowData.amount) < 0;
    var debitCredit = isCredit ? "Credit" : "Debit";

    var accountCodeSave = accountCode === "Account Code" ? "" : accountCode;
    var subAccountSave = subAccount === "Sub Account" ? "" : subAccount;
    var costCenterSave = costCenter === "Cost center" ? "" : costCenter;

    var newRowsPrep = {
      narration: narration,
      costCenter: costCenterSave,
      accountCode: accountCodeSave,
      subAccount: subAccountSave,
      amount: amount,
    };

    if (accountCodeSave !== "") {
      let rowDetails = []
      Axios.get(
        "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCodeSave
      ).then((res) => {

        res.data.forEach((element) => {
          var item = {
            id: element.code,
            value: element.code,
            text: element.code + "-" + element.name,
          };
          rowDetails.push(item);
        });
        let mySubAccountData = rowDetails.filter((item)=> item.value === subAccountSave)
        let mySubAccountWithoutData = rowDetails.filter((item)=> item.value !== subAccountSave)
        let finalValue = mySubAccountData.concat(mySubAccountWithoutData)

        this.setState({
          ...this.state,
          subRowRefs: rowDetails
        })

        tableList.push({
          ...newRowsPrep,
          id: this.state.tableList.length + 1,
          debitCredit: debitCredit,
          currencyAnalysis: currencyAnalysis,
          subAccountList: [
            { id: "None", value: "None", text: "None" },
            ...finalValue.slice(0,10)
          ],
        });

        gridColor[this.state.tableList.length] = "#FFFFFF";

        var totalColor = "#FF8070";

        if (parseInt(totalAmount) === 0) totalColor = "#43A047";

        this.setState((prevState) => ({
          ...prevState,
          currentAccountCode: accountCodeSave,
          emptySubAccount: false,
          emptyAccountCode: false,
          emptyAmount: false,
          tableList,
          tableRowData: {
            narration: this.state.masterNarration,
            costCenter: "",
            accountCode: "",
            subAccount: "",
            currencyAnalysis: "NPR",
            amount: "",
          },
          data: {
            ...prevState.data,
            totalCheck: totalAmount,
          },
          totalColor,
        }));
      });
    }
  };

  onChangedDate = (date) => {
    let formattedDate = format(new Date(date), "yyyy-MM-dd");

    console.log({ formattedDate });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: formattedDate,
      },
    }));
  };

  handleSubmit = () => {
    const token = this.context;

    if (this.state.data.total === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyTotal: true,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        emptyTotal: false,
      }));
    }

    if (this.state.data.invoiceNumber === "") {
      this.setState((prevState) => ({
        ...prevState,
        emptyInvoiceNumber: true,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        emptyInvoiceNumber: false,
      }));
    }

    if (parseInt(this.state.data.totalCheck) !== 0) {
      var snackbarProps = {
        open: true,
        variant: "error",
        message: "CREDIT and DEBIT line NOT BALANCED",
        autoHideDuration: 2000,
      };

      
      token.setSnackbarProps({ snackbarProps: snackbarProps });

      this.handleCheck();

      return;
    }
    
    else if (parseInt(this.state.data.total) === 0){
      this.setState({ saveButtonDisabled: true });
    }
    var purchaseVoucherCredit = [];
    var purchaseVoucherDebit = [];

    this.state.tableList.forEach((element) => {
      var dataPrep = {
        narration: element.narration,
        costCenter: element.costCenter,
        accountCode: element.accountCode,
        subAccount: element.subAccount,
        amount: element.amount,
      };

      if (element.debitCredit === "Credit") {
        purchaseVoucherCredit.push({
          ...dataPrep,
          analysis: element.currencyAnalysis,
          amount: Math.abs(element.amount),
        });
      } else {
        purchaseVoucherDebit.push({
          ...dataPrep,
          currency: element.currencyAnalysis,
          amount: Math.abs(element.amount),
        });
      }
    });

    var reqData = this.state.data;

    reqData = {
      ...reqData,
      supplierIdNumber: this.state.data.supplier.supplierIdNumber,
      supplierName: this.state.data.supplier.fullName,
      purchaseVoucherCredit,
      purchaseVoucherDebit,
      masterNarration: this.state.masterNarration,
    };
    if (
      reqData.purchaseVoucherCredit.length === 0 &&
      reqData.purchaseVoucherDebit.length === 0
    ) {
      let snackbarProps = {
        open: true,
        variant: "error",
        message: "Voucher transactions are empty",
        autoHideDuration: 2000,
      };

      token.setSnackbarProps({ snackbarProps: snackbarProps });
      return;
    }

    Axios.post("/pos/purchaseVoucher/save", reqData)
      .then((res) => {
        this.setState({ saveButtonDisabled: false });
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.props.getTableDataForAuth();
        this.resetTable();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  deleteRow = (index) => {
    var currentRows = this.state.tableList;

    currentRows.splice(
      currentRows.findIndex((e) => e.id === index),
      1
    );
    var totalAmount = 0;
    currentRows.forEach((element, i) => {
      element.id = i + 1;
      totalAmount += parseInt(element.amount);
    });

    this.setState((prevState) => ({
      ...prevState,
      tableList: currentRows,
      data: {
        ...prevState.data,
        totalCheck: totalAmount,
      },
    }));
  };

  handleSupplierSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    if (value === "None") {
      var supplierData = {
        fullName: "None",
        supplierIdNumber: "None",
      };
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          supplier: supplierData,
        },
      }));
    } else {
      this.state.supplierOptionList.forEach((element) => {
        if (element.key === key) {
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              supplier: {
                fullName: element.value,
                supplierIdNumber: element.key,
              },
            },
          }));
        }
      });
    }
  };

  handleSupplierIdSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    if (value === "None") {
      var supplierData = {
        fullName: "None",
        supplierIdNumber: "None",
      };
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          supplier: supplierData,
        },
      }));
    } else {
      this.state.supplierData.forEach((element) => {
        if (element.supplierIdNumber === key) {
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              supplier: {
                fullName: element.fullName,
                supplierIdNumber: element.supplierIdNumber,
              },
            },
          }));
        }
      });
    }
  };

  customSearchForSubAccountTable = (e,data,id,accountParent)=> {
    if (data.searchQuery?.length > 0) {
      if(this.state.currentAccountCode === accountParent) {
        let searchReasult = arraySearchSubAccount(this.state.subRowRefs, data.searchQuery);
        console.log("Theresut", searchReasult)
  
        let xx = this.state.tableList.map((item)=> {
          var temp = Object.assign({}, item);
          if(temp.id === id) {
            temp.subAccountList = searchReasult.slice(0,10)
          }
          return temp
        })
        this.setState({
          ...this.state,
          tableList: xx
        })
      } else {
        this.setState({
          ...this.state,
          currentAccountCode: accountParent
        })
        this.getSubAccountList(accountParent)
      }

    } else return
  }


  render() {
    const { classes } = this.props;

    const styledTableCell = {
      padding: "10px",
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      borderRight: "1px solid #EAEDEF",
    };

    return (
      <>
        <Grid container justify="center">
          <Card
            className={classes.root}
            role="tabpanel"
            hidden={this.props.value !== this.props.index}
          >
            <Grid container spacing={2} className={classes.containerWidth}>
            <Grid item md={2} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Fiscal Year"
                  name="fiscalYear"
                  onChange={this.handleFYChange}
                  value={this.state.data.fiscalYear}
                >
                  {this.state.fyList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Voucher Number"
                  fullWidth
                  value={this.state.data.voucherNumber}
                  onChange={this.handleChange}
                  name="voucherNumber"
                >
                </TextField>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Period"
                  name="period"
                  onChange={this.handlePeriodChange}
                  value={this.state.data.period}
                >
                  {this.state.periodList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label="Date"
                    value={this.state.data.date}
                    inputVariant="outlined"
                    autoOk={true}
                    format="yyyy-MM-dd"
                    margin="dense"
                    fullWidth
                    onChange={this.onChangedDate}
                    shouldDisableDate={this.shouldDisableDate}
                    animateYearScrolling
                    minDate={this.state.periodDateFrom}
                    maxDate={this.state.periodDateTo}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  placeholder="Supplier Name"
                  fluid
                  search
                  style={{ marginTop: "8px", width: "100%" }}
                  selection
                  value={this.state.data.supplier.fullName}
                  onChange={this.handleSupplierSelect}
                  options={this.state.supplierOptionList}
                ></Dropdown>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Dropdown
                  placeholder="Search by SID"
                  fluid
                  fullWidth
                  search
                  style={{ marginTop: "8px" }}
                  selection
                  value={this.state.data.supplier.supplierIdNumber}
                  onChange={this.handleSupplierIdSelect}
                  options={this.state.idOptionList}
                ></Dropdown>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Transport Number"
                  name="transportNumber"
                  value={this.state.data.transportNumber}
                  onChange={this.onChanged}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Chalan Number"
                  name="chalanNumber"
                  value={this.state.data.chalanNumber}
                  onChange={this.onChanged}
                ></TextField>
              </Grid>

              <Grid item md={3} sm={4} xs={12}>
                {this.state.emptyInvoiceNumber ? (
                  <TextField
                    error
                    helperText="Field should not be empty"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    label="Invoice Number"
                    onChange={this.onChanged}
                    value={this.state.data.invoiceNumber}
                    name="invoiceNumber"
                  ></TextField>
                ) : (
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    label="Invoice Number"
                    onChange={this.onChanged}
                    value={this.state.data.invoiceNumber}
                    name="invoiceNumber"
                  ></TextField>
                )}
              </Grid>
              <Grid item md={2} sm={4} xs={12}>
                {this.state.emptyTotal ? (
                  <TextField
                    error
                    helperText="Field should not be empty"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    type="number"
                    label="Invoice Amount"
                    name="total"
                    value={this.state.data.total}
                    onChange={this.onChanged}
                  ></TextField>
                ) : (
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    type="number"
                    label="Invoice Amount"
                    name="total"
                    value={this.state.data.total}
                    onChange={this.onChanged}
                  ></TextField>
                )}
              </Grid>
              <Grid item md={2} sm={4} xs={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  select
                  label="Payment Method"
                  name="paymentMode"
                  value={this.state.data.paymentMode}
                  onChange={this.onChanged}
                >
                  <MenuItem key={1} value={"Cash"}>
                    Cash
                  </MenuItem>
                  <MenuItem key={2} value={"Cheque"}>
                    Cheque
                  </MenuItem>
                  <MenuItem key={3} value={"Credit"}>
                    Credit
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Payment Reference Number"
                  name="paymentReferenceNumber"
                  value={this.state.data.paymentReferenceNumber}
                  onChange={this.onChanged}
                ></TextField>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Master Narration"
                  value={this.state.masterNarration}
                  onChange={this.handleMasterNarrationChange}
                ></TextField>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="GRN Reference"
                  name="grnReference"
                  value={this.state.data.grnReference}
                  onChange={this.onChanged}
                ></TextField>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "25px", marginBottom: "10px" }} />

            <Typography
              variant="h6"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              Entry
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={4}></Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
              <Grid item xs>
                <span style={{ color: "#43A047" }}>( + )</span>{" "}
                <span style={{ fontWeight: "bold" }}>Dr. </span>
                <span style={{ color: "#FF8070" }}>( - )</span>{" "}
                <span style={{ fontWeight: "bold" }}>Cr.</span>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={8} lg={3} style={{ marginTop: "3px" }}>
                <TextField
                  label="Narration"
                  fullWidth
                  variant="outlined"
                  value={this.state.tableRowData.narration}
                  name="narration"
                  onChange={this.onChangedEntry}
                  margin="dense"
                  inputRef={this.textInput}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={4} lg={1} style={{ marginTop: "3px" }}>
                {this.state.emptyCostCenter ? (
                  <>
                    <TextField
                      // label={this.state.tableRowData.costCenter}
                      label="Cost Center"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      select
                      value={this.state.tableRowData.costCenter}
                      name="costCenter"
                      helperText="field should not be empty"
                      error
                      onChange={this.onChangedEntry}
                    >
                      {this.state.costCenterList.map((value) => (
                        <MenuItem key={value.id} value={value.code}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                ) : (
                  <>
                    <TextField
                      // label={this.state.tableRowData.costCenter}
                      label="Cost Center"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      select
                      value={this.state.tableRowData.costCenter}
                      name="costCenter"
                      onChange={this.onChangedEntry}
                    >
                      {this.state.costCenterList.map((value) => (
                        <MenuItem key={value.id} value={value.code}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
                {this.state.emptyAccountCode ? (
                  <>
                    <Dropdown
                      placeholder="Account Code"
                      name="accountCode"
                      fluid
                      fullWidth
                      //error
                      select
                      selectOnBlur={false}
                      variant="outlined"
                      search={this.customSearch}
                      style={{ marginTop: "8px", border: "1px solid red" }}
                      ref={(search) => {
                        this.searchInput = search;
                      }}
                      selection
                      value={this.state.tableRowData.accountCode}
                      onChange={this.handleItemSelect}
                      options={[
                        {
                          key: "add",
                          value: "add",
                          text: "Add new account code",
                        },
                        ...this.state.rowRefs,
                      ]}
                    ></Dropdown>
                    <Label
                      basic
                      style={{
                        border: "0px",
                        fontWeight: 400,
                        fontSize: "0.75rem",
                        font: "Helvetica",
                        color: "#f44336",
                        letterSpacing: "0.03333em",
                      }}
                    >
                      field should not be empty
                    </Label>
                  </>
                ) : (
                  <Dropdown
                    placeholder="Account Code"
                    name="accountCode"
                    floating
                    fluid
                    fullWidth
                    selectOnBlur={false}
                    search={this.customSearch}
                    style={{ marginTop: "8px", zIndex: 200 }}
                    ref={(search) => {
                      this.searchInput = search;
                    }}
                    selection
                    value={this.state.tableRowData.accountCode}
                    onChange={this.handleItemSelect}
                    options={[
                      {
                        key: "add",
                        value: "add",
                        text: "Add new account code",
                      },
                      ...this.state.rowRefs,
                    ]}
                  ></Dropdown>
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
              {this.state.emptySubAccount ? (
                  <>
                    <Dropdown
                      placeholder="Sub Account"
                      name="subAccount"
                      floating
                      fluid
                      fullWidth
                      selectOnBlur={false}
                      search={this.customSearchForSub}
                      // ref={(search) => {
                      //   this.searchInput = search;
                      // }}
                      selection
                      value={this.state.tableRowData.subAccount}
                      // onKeyDown={this.onKeyPress}
                      onChange={this.handleItemSelect}
                      style={{ marginTop: "8px", border: "1px solid red" }}
                      options={this.state.mySubAccount}
                    ></Dropdown>
                    <Label
                      basic
                      style={{
                        border: "0px",
                        fontWeight: 400,
                        fontSize: "0.75rem",
                        font: "Helvetica",
                        color: "#f44336",
                        letterSpacing: "0.03333em",
                      }}
                    >
                      field should not be empty
                    </Label>
                  </>
                ) : (
                  <>
                  <Dropdown
                  placeholder="Sub Account"
                  name="subAccount"
                  fluid
                  fullWidth
                  search
                  style={{ marginTop: "8px" }}
                  selection
                  onSearchChange={this.customSearchForSub}
                  value={this.state.tableRowData.subAccount}
                  onChange={this.handleItemSelect}
                  options={this.state.mySubAccount}
                ></Dropdown>
                    {/* <Dropdown
                      placeholder="Sub Account"
                      name="subAccount"
                      floating
                      fluid
                      fullWidth
                      selectOnBlur={false}
                      onSearchChange={this.customSearchForSub}
                      style={{ marginTop: "8px" }}
                      // ref={(search) => {
                      //   this.searchInput = search;
                      // }}
                      selection
                      value={this.state.tableRowData.subAccount}
                      // onKeyDown={this.onKeyPress}
                      onChange={this.handleItemSelect}
                      options={[
                        {
                          key: "add",
                          value: "add",
                          text: "Add new sub-account code",
                        },

                        ...this.state.mySubAccount,
                      ]}
                    ></Dropdown> */}
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={1} style={{ marginTop: "3px" }}>
                <TextField
                  label="Currency"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.tableRowData.currencyAnalysis}
                  name="currencyAnalysis"
                  onChange={this.onChangedEntry}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} lg={2} style={{ marginTop: "3px" }}>
                {this.state.emptyAmount ? (
                  <TextField
                    label="Amount"
                    type="number"
                    error
                    helperText="Field should not be empty"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={this.state.tableRowData.amount}
                    name="amount"
                    onChange={this.onChangedEntry}
                  ></TextField>
                ) : (
                  <TextField
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={this.state.tableRowData.amount}
                    name="amount"
                    onChange={this.onChangedEntry}
                  ></TextField>
                )}
              </Grid>
              <Grid item xs={12} sm={6} lg={1} style={{ marginTop: "3px" }}>
                <Button
                  style={{
                    marginTop: "8px",
                    padding: "7px",
                    width: "100px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={this.addNewRow}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "25px", marginBottom: "10px" }} />

            {this.state.tableList.length > 0 ? (
              <>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", marginTop: "10px" }}
                >
                  Transaction List
                </Typography>

                <Grid
                  container
                  justify="flex-end"
                  style={{ paddingRight: "10px" }}
                  spacing={4}
                >
                  <Grid item>
                    <Button
                      style={{
                        marginTop: "8px",
                        paddingTop: "10px",
                        paddingRight: "25px",
                        paddingLeft: "25px",
                        paddingBottom: "10px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={this.handleCheck}
                    >
                      Check
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        paddingTop: "6px",
                        paddingBottom: "11px",
                      }}
                    >
                      Total:{" "}
                      <span style={{ color: this.state.totalColor }}>
                        {this.state.data.totalCheck}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table
                    aria-label="customized table"
                    //   fixedHeader={false} style={{ width: "auto", tableLayout: "auto",  marginTop: "10px", marginBottom: "15px"  }}
                    fixedHeader={false}
                    style={{
                      marginTop: "10px",
                      marginBottom: "15px",
                      border: "1px solid #EAEDEF",
                    }}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={styledTableCell} align="center">
                          {" "}
                          S. No.{" "}
                        </TableCell>
                        <TableCell style={styledTableCell} align="center">
                          Dr./Cr.
                        </TableCell>
                        <TableCell style={styledTableCell}>Narration</TableCell>
                        <TableCell style={styledTableCell}>
                          Cost Center
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          Account Code
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          Sub Account
                        </TableCell>
                        <TableCell style={styledTableCell}>Currency</TableCell>
                        <TableCell style={styledTableCell}>Amount</TableCell>
                        <TableCell style={styledTableCell} align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.tableList.map((value) => (
                        <TableRow
                          style={{
                            backgroundColor: this.state.gridColor[value.id],
                            transition: "background-color 1s",
                          }}
                        >
                          <TableCell
                            align="center"
                            style={{
                              width: "50px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            {value.id}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "60px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                            align="center"
                          >
                            {value.debitCredit}
                          </TableCell>
                          <TableCell
                            style={{
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              value={value.narration}
                              name={"narration." + value.id}
                              onChange={this.onChangedTableRow}
                              fullWidth
                              style={{ minWidth: "160px" }}
                              variant="outlined"
                              margin="dense"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "80px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              label={value.costCenter}
                              value={value.costCenter}
                              name={"costCenter." + value.id}
                              fullWidth
                              style={{
                                width: "80px",
                              }}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              select
                              margin="dense"
                            >
                              {this.state.costCenterList.map((value) => (
                                <MenuItem key={value.id} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "130px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              label={value.accountCode}
                              value={value.accountCode}
                              select
                              fullWidth
                              style={{ width: "130px" }}
                              name={"accountCode." + value.id}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            >
                              {this.state.accountList.map((value) => (
                                <MenuItem key={value.id} value={value.code}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "260px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                              position: "relative"
                            }}
                            >
                            <label style={{position: "absolute",top: "8px", padding: "0 5px",zIndex: "1",background: "white",fontSize: "11px",left: "20px"}}>{value.subAccount}</label>
                            <Dropdown

                              placeholder="Sub Account"
                              name={"subAccount." + value.id}
                              fluid
                              fullWidth
                              search
                              selection
                              onSearchChange={(e,data)=> this.customSearchForSubAccountTable(e,data,value.id,value.accountCode)}

                              style={{ width: "260px",maxHeight: "40px",minHeight: "38px" }}
                              value={value.subAccount}
                              // onKeyDown={this.onKeyPress}
                              onChange={this.onChangedTableRowSubAccount}
                              options={[
                                ...value.subAccountList,
                              ]}
                            ></Dropdown>
                            {/* <TextField
                              label={value.subAccount}
                              value={value.subAccount}
                              fullWidth
                              select
                              style={{ width: "130px" }}
                              name={"subAccount." + value.id}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            >
                              {value.subAccountList.map((value) => {
                                console.log("the myValue", value)
                                return (
                                  <MenuItem key={value.id} value={value.code}>
                                    {value.name}
                                  </MenuItem>
                                )
                              })}
                            </TextField> */}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "65px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              value={value.currencyAnalysis}
                              name={"currencyAnalysis." + value.id}
                              fullWidth
                              style={{ width: "65px" }}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "130px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <TextField
                              value={value.amount}
                              fullWidth
                              style={{ width: "130px" }}
                              type="number"
                              name={"amount." + value.id}
                              onChange={this.onChangedTableRow}
                              variant="outlined"
                              margin="dense"
                            ></TextField>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: "30px",
                              padding: "10px",
                              borderRight: "1px solid #EAEDEF",
                              borderBottom: "1px solid #EAEDEF",
                            }}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={faMinusCircle}
                                size="lg"
                                style={{ color: "#FF8070", cursor: "pointer" }}
                                onClick={() =>
                                  this.deleteRow(value.id, "debit")
                                }
                              ></FontAwesomeIcon>
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : null}

            <Grid container justify="center">
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={this.handleSubmit}
                disabled={this.state.saveButtonDisabled}
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  paddingLeft: "47px",
                  paddingRight: "47px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                Add Voucher
              </Button>
            </Grid>
          </Card>
        </Grid>
        <AddLedger
          open={this.state.addAccountOpen}
          handleClose={() => this.setState({ addAccountOpen: false })}
          getAccountGroups={this.getAccountGroups}
        />
        <AddSubLedger
          open={this.state.addSubAccountOpen}
          handleClose={() => this.setState({ addSubAccountOpen: false })}
        />
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </>
    );
  }
}

export default withStyles(styles)(AddPurchaseVoucher);
