import React, { Component } from "react";
import {
  Grid,
  Card,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TextField,
  Button,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import { Input, Label, Dropdown } from "semantic-ui-react";
import Close from "@material-ui/icons/Close";
import { v4 as uuidv4 } from "uuid";

class VieReceivedVoucherAuthorization extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      currentAccountCode: "",
      myTableList: [],
      rowRefs: [],
      subRowRefs: [],
      hold: false,
      customerOptionList: [],
      idOptionList: [],
      customerData: [],
      requestData: {
        customer: {
          fullName: "",
          id: "",
        },
        date: "",
        invoiceNumber: "",
        invoiceTotal: "",
        receivedVoucherCredit: [],
        receivedVoucherDebit: [],
        receivedReference: "",
        receivedMethod: "",
        receivedReferenceNumber: "",
        total: "0",
        voucherNumber: "",
      },
      accountList: [],
      subAccountList: [],
      costCenterList: [],
      tableRowData: {
        narration: "",
        costCenter: "",
        accountCode: "Account Code",
        subAccount: "Sub Account",
        currencyAnalysis: "",
        amount: "",
      },
      accountOptions: [],
      subAccountOptions: [],
      costCenterOptions: [],
      totalColor: "#FF8070",
      gridColor: [],
      editable: false,
      clicked: "#0b5f8a",
      buttonColor: "#4aa5d8",
    };
  }

  handleCheck = () => {
    const token = this.context;

    var idList = [];

    if (parseInt(this.state.requestData.total) !== 0) {
      this.props.data.tableList.map((value, i) =>
        parseInt(value.amount) === parseInt(this.state.requestData.total)
          ? idList.push(i + 1)
          : null
      );
    }

    if (idList.length === 0 && parseInt(this.state.requestData.total) !== 0) {
      if (parseInt(this.state.requestData.total) < 0) {
        this.props.data.tableList.map((value, i) =>
          parseInt(value.amount) < 0 ? idList.push(i + 1) : null
        );
      } else {
        this.props.data.tableList.map((value, i) =>
          parseInt(value.amount) > 0 ? idList.push(i + 1) : null
        );
      }
    }

    if (idList.length === 0) {
      var snackbarProps = {
        open: true,
        variant: "success",
        message: "Credit and Debit line balanced. Safe to POST.",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: snackbarProps });
    }

    var gridColor = this.state.gridColor;

    idList.map((value) => (gridColor[value] = "#FFA99E"));

    this.setState({
      gridColor,
    });

    var newGridColors = [];

    idList.map((value) => newGridColors.push("#FFFFFF"));

    setTimeout(() => {
      this.setState({
        gridColor: newGridColors,
      });
    }, 1000);
  };

  getAllCostCenters = () => {
    Axios.get("pos/costCenter/findAll")
      .then((res) => {
        var optionList = [];

        res.data.map((val) =>
          optionList.push({ key: val.id, text: val.name, value: val.code })
        );

        this.setState((prevState) => ({
          ...prevState,
          costCenterList: res.data,
          costCenterOptions: optionList,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  arraySearchSubAccount = (array, keyword) => {
    const searchTerm = keyword?.toLowerCase()
    console.log("the arryArr", array, searchTerm)
    return array.filter(item => {
      return item.text?.toLowerCase().match(new RegExp(searchTerm, ''))
    })
  }

  componentDidMount() {
    this.getAllCostCenters();

    var rowDetails = [];
    Axios.get("pos/ledgerMaster/getAllActiveAccountGroup")
      .then(async (res) => {
        res.data.forEach((element) => {
          var item = {
            key: element.code,
            value: element.code,
            text: element.code + "-" + element.name,
          };
          rowDetails.push(item);
        });

        this.setState({
          accountList: [{id:"None",code: "None",name: "None"},...res.data],
          rowRefs: [{key: "None",value: "None",text: "None"},...rowDetails],
        });
      })
      .catch((err) => {
        console.log(err);
      });

    var today = new Date();

    var month = (today.getMonth() + 1).toString();

    var day = today.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    var date = today.getFullYear() + "-" + month + "-" + day;

    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        date: date,
      },
    }));
  }

  deleteRow = (index) => {
    var currentRows = this.props.data.tableList;

    currentRows.splice(
      currentRows.findIndex((e) => e.id === index),
      1
    );

    var totalAmount = 0;
    currentRows.forEach((element, i) => {
      element.id = i + 1;
      totalAmount += parseInt(element.amount);
    });

    this.setState((prevState) => ({
      ...prevState,
      tableList: currentRows,
      requestData: {
        ...prevState.requestData,
        total: totalAmount,
      },
    }));
  };

  onChangedTableRow = (e) => {
    e.stopPropagation();

    if (!this.state.editable) return;

    var totalAmount = 0;

    var targetName = e.target.name;
    var value = e.target.value;

    var id = targetName.split(".")[1];
    var name = targetName.split(".")[0];

    var tableList = this.state.myTableList;



    var arrIndex = tableList.findIndex((e) => e.id === id);

    var currentRow = tableList[arrIndex];

    if (name === "accountCode") {
      this.getSubAccountList(value);
    }

    if (name === "amount") {
      var isCredit = Number(value).toFixed(2) < 0;

      var debitCredit = isCredit ? "Credit" : "Debit";

      currentRow["debitCredit"] = debitCredit;
      currentRow[name] = Number(value).toFixed(2);
    }

    currentRow[name] = value;

    tableList[arrIndex] = currentRow;

    tableList.forEach((element) => {
      totalAmount += Number(element.amount);
    });

    var totalColor = "#FF8070";

    if (Number(totalAmount) === 0) totalColor = "#43A047";

    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        total: totalAmount.toFixed(2),
      },
      totalColor,
    }));
  };

  onChangedTableRowSubAccount = (e,data) => {
    e.stopPropagation();

    if (!this.state.editable) return;

    var targetName = data.name
    var id = targetName.split(".")[1];
    var name = targetName.split(".")[0];
    var value = data.value;

    var totalAmount = 0;

    var tableList = this.state.myTableList;

    var arrIndex = tableList.findIndex((e) => e.id === id);

    var currentRow = tableList[arrIndex];

    currentRow[name] = value;

    tableList[arrIndex] = currentRow;

    tableList.forEach((element) => {
      totalAmount += Number(element.amount);
    });

    var totalColor = "#FF8070";

    if (Number(totalAmount) === 0) totalColor = "#43A047";

    this.setState((prevState) => ({
      ...prevState,
      requestData: {
        ...prevState.requestData,
        total: totalAmount.toFixed(2),
      },
      totalColor,
    }));
  };

  handleTableInputChange= async(e,v)=> {
    e.stopPropagation();

    if (!this.state.editable) return;
    let subAccountList = [];
    let rowDetails = [];

    var value = e.target.value;

    await Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + value
    ).then((res) => {
      res.data.forEach((element) => {
        var item = {
          id: element.code,
          value: element.code,
          text: element.code + "-" + element.name,
        };
        rowDetails.push(item);
      });

      subAccountList = [{ id: "None", value: "None", text: "None" }, ...rowDetails.slice(0, 10)]

  })

  let newMyTableList = this.state.myTableList.map((item,index)=> {
    if(index === v) {
        
        item.subAccount = ""
        item.accountCode = value
        item.subAccountList = subAccountList
        return item
    } else {
      return item
    }
  })
  this.setState((prevState)=> ({
    ...prevState,
    myTableList: newMyTableList
  }))
}


  onChangedAccountEntry = (e, data) => {

    if (e.target.name === "costCenter") {
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          "costCenter": e.target.value,
        },
      }));
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      tableRowData: {
        ...prevState.tableRowData,
        [data.name]: data.value,
      },
    }));

    if (data.name === "accountCode") this.getSubAccountList(data.value);
  };

  onChangedEntry = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    if (targetName === "accountCode") {
      this.getSubAccountList(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      tableRowData: {
        ...prevState.tableRowData,
        [targetName]: value,
      },
    }));
  };

  getSubAccountList = async(accountCode) => {
    let rowDetails = [];

   await Axios.get(
      "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCode
    ).then(async (res) => {
      res.data.forEach((element) => {
        var item = {
          key: element.code,
          value: element.code,
          text: element.code + "-" + element.name,
        };
        rowDetails.push(item);
      });

      this.setState({
        subAccountList: [{ key: "None", code: "None", name: "None" },...res.data],
        subRowRefs: [
          {
            id: "None",
            code: "None",
            name: "None",
          },
          ...rowDetails
        ],
          
        mySubAccount: [{
          id: "None",
          value: "None",
          text: "None"
        },
      ...rowDetails.slice(0,15)] ,
      });
    });
  };


  handleItemSelect = (e, data) => {
    e.stopPropagation();
    const { value } = data;

    if (value === "add") {
      if (data.name === "accountCode") {
        this.setState({ addAccountOpen: true });
      } else {
        this.setState({ addSubAccountOpen: true });
      }
      return;
    }

    var targetName = data.name;

    if (targetName === "accountCode") {
      this.getSubAccountList(value);
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          subAccount: "",
          [targetName]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        tableRowData: {
          ...prevState.tableRowData,
          [targetName]: value,
        },
      }));
    }
  };

  componentWillReceiveProps(nextProps) {

    this.setState({
      myTableList: this.props.data.tableList
    })
  }



  customSearch = (_options, query) => {
    let queryText = query.toLowerCase();

    let arr = this.state.rowRefs;

    let newSet = arr.filter(
      (val) => val.text.toLowerCase().indexOf(queryText.toLowerCase()) !== -1
    );


    return [
      {
        key: "add",
        value: "add",
        text: "Add new account code",
      },
      ...newSet,
    ];
  };

  customSearchForSub = (_options, data) => {
    if (data.searchQuery?.length > 0) {
      let searchReasult = this.arraySearchSubAccount(this.state.subRowRefs, data.searchQuery);
      console.log("The search resluts", searchReasult)
      this.setState((prevState) => ({
        ...prevState,
        mySubAccount: searchReasult.slice(0, 20)
      }))
    } else return
  };

  addNewRow = () => {
    var tableList = this.state.myTableList;
    var gridColor = this.state.gridColor;

    var totalAmount = this.state.requestData.total;

    const {
      narration,
      costCenter,
      accountCode,
      subAccount,
      currencyAnalysis,
      amount,
    } = this.state.tableRowData;
    let rowDetails = []

    totalAmount = (parseFloat(totalAmount) + parseFloat(amount)).toFixed(2);

    var isCredit = parseFloat(this.state.tableRowData.amount).toFixed(2) < 0;
    var debitCredit = isCredit ? "Credit" : "Debit";

    var accountCodeSave = accountCode === "Account Code" ? "" : accountCode;
    var subAccountSave = subAccount === "" ? "" : subAccount;
    var costCenterSave = costCenter === "Cost center" ? "" : costCenter;

    let subAccountList = this.state.subAccountList

    const foundItem = subAccountList.find(el => el.code == subAccount)
    const foundIdx = subAccountList.findIndex(el => el.code == subAccount)
    subAccountList.splice(foundIdx, 1)
    subAccountList.unshift(foundItem)
    subAccountList.slice(0, 10).forEach((element) => {
      var item = {
        id: element.code,
        value: element.code,
        text: element.code + "-" + element.name,
      };
      rowDetails.push(item);
    });

    var newRowsPrep = {
      narration: narration,
      costCenter: costCenterSave,
      accountCode: accountCodeSave,
      subAccount: subAccountSave,
      subAccountList: rowDetails,
      amount: amount,
    };

    tableList.push({
      ...newRowsPrep,
      id: uuidv4(),
      debitCredit: debitCredit,
      currencyAnalysis: currencyAnalysis,
    });

    gridColor[this.props.data.tableList.length] = "#FFFFFF";

    var totalColor = "#FF8070";
    var toCheckTotal = 0;
    if (
      parseFloat(totalAmount).toFixed(2) === parseFloat(toCheckTotal).toFixed(2)
    )
      totalColor = "#43A047";

    this.setState((prevState) => ({
      ...prevState,
      myTableList: tableList,
      tableRowData: {
        narration: "",
        costCenter: "",
        accountCode: "Account Code",
        subAccount: "",
        currencyAnalysis: "",
        amount: "",
      },
      requestData: {
        ...prevState.requestData,
        total: totalAmount,
      },
      totalColor,
    }));
  };

  handleStatus = (status) => {
    const token = this.context;

    var id = this.props.data.id;
    if(status === "Checked"){
      this.setState({ hold: true})
    }
    if(status === "Approved"){
      this.setState({ hold: false})
    }
    Axios.put("pos/paymentVoucher/updateStatus/" + id, { status: status })
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.props.handleViewCounter(id);
        this.props.getTableDataForAuth();
        this.props.getTableDataForList();
        if (status === "Approved" || status === "Rejected") {
          this.props.animateOut();
        }
      })
      .catch((err) => {
        console.log(err);

        var message = err.message;

        if (err.response !== undefined) {
          message = err.response.data;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  handleUpdate = async () => {
    const token = this.context;

    var supplierName = this.props.data.supplierName;
    var supplierIdNumber = this.props.data.supplierIdNumber;
    var invoiceNumber = this.props.data.invoiceNumber;
    var total = this.props.data.total;
    var paymentReferenceNumber = this.props.data.paymentReferenceNumber;
    var paymentMethod = this.props.data.paymentMethod;
    var fiscalYear = this.props.data.fiscalYear;
    var period = this.props.data.period;
    var date = this.props.data.date;
    var masterNarration = this.props.data.masterNarration;

    var toCheckTotal = 0;
    if (this.state.requestData.total === "-0.00") {
      await this.setState((prevState) => ({
        ...prevState,
        requestData: {
          ...prevState.requestData,
          total: parseFloat(toCheckTotal).toFixed(2),
        },
      }));
    }

    if (parseFloat(this.state.requestData.total).toFixed(2) !== parseFloat(toCheckTotal).toFixed(2)) {
      var snackbarProps = {
        open: true,
        variant: "error",
        message: "CREDIT and DEBIT line NOT BALANCED",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: snackbarProps });

      this.handleCheck();

      return;
    }

    var paymentVoucherCredit = [];
    var paymentVoucherDebit = [];

    this.state.myTableList.forEach((element) => {
      var dataPrep = {
        narration: element.narration,
        costCenter: element.costCenter,
        accountCode: element.accountCode,
        subAccount: element.subAccount,
        amount: element.amount,
      };

      if (element.debitCredit === "Credit") {
        paymentVoucherCredit.push({
          ...dataPrep,
          currency: element.currencyAnalysis,
          amount: Math.abs(element.amount),
        });
      } else {
        paymentVoucherDebit.push({
          ...dataPrep,
          analysis: element.currencyAnalysis,
        });
      }
    });

    var reqData = this.props.data;

    reqData = {
      ...reqData,
      supplierName,
      supplierIdNumber,
      paymentReferenceNumber,
      paymentMethod,
      invoiceNumber,
      total,
      fiscalYear,
      period,
      date,
      masterNarration,
      paymentVoucherCredit,
      paymentVoucherDebit,
    };

    var id = reqData.id;

    delete reqData.tableList;
    delete reqData.id;

    Axios.put("pos/paymentVoucher/updateVoucher/" + id, reqData)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });

        this.props.handleViewCounter(id);
        this.props.getTableDataForAuth();
        this.setState((prevState) => ({
          ...prevState,
          editable: false,
        }));
      })
      .catch((err) => {
        console.log(err);

        var message = err.message;

        if (err.response !== undefined) {
          message = err.response.data;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  customSearchForSubAccountTable = (e, data, id, accountParent) => {
    if (data.searchQuery?.length > 0) {
      if (this.state.currentAccountCode === accountParent) {

        let searchReasult = this.arraySearchSubAccount(this.state.subRowRefs, data.searchQuery);
        console.log("Theresut", searchReasult)

        let xx = this.state.myTableList.map((item) => {
          var temp = Object.assign({}, item);
          if (temp.id === id) {
            temp.subAccountList = searchReasult.slice(0, 10)
          }
          return temp
        })
        this.setState({
          ...this.state,
          myTableList: xx
        })
      } else {
        this.setState({
          ...this.state,
          currentAccountCode: accountParent
        })
        this.getSubAccountList(accountParent)

      }

    } else return
  }

  render() {
    const styledTableCell = {
      padding: "10px",
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      borderRight: "1px solid #EAEDEF",
    };

    const buttonStyle = {
      margin: "20px",
      marginTop: "25px",
      marginRight: "50px",
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingRight: "50px",
      paddingLeft: "50px",
    };

    const {
      date,
      voucherNumber,
      invoiceNumber,
      paymentMethod,
      paymentReferenceNumber,
      total,
      tableList,
      status,
      supplierIdNumber,
      supplierName,
      fiscalYear,
      period,
      masterNarration,
    } = this.props.data;

    const {
      fyList,
      periodList,
      handleFyChangeView,
      handlePeriodChange,
      idOptionList,
      supplierOptionList,
      handleTextChange,
      handleSupplierIdSelect,
      handleSupplierSelect,
    } = this.props;

    /*  var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    }); */

    return (
      <div>
        <Grid container justify="center">
          <Card
            role="tabpanel"
            hidden={this.props.hidden}
            style={{
              width: "98vw",
              padding: "0px",
              marginBottom: "100px",
            }}
          >
            <Grid container justify="space-between">
              <Grid item>
                <Typography
                  variant="h6"
                  onClick={this.props.animateOut}
                  style={{ cursor: "pointer", padding: "10px" }}
                >
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>{" "}
                  Voucher List
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={this.props.animateOut}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid style={{ padding: "20px" }}>
              <Grid
                container
                justify="flex-end"
                style={{ marginBottom: "40px" }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        editable: !this.state.editable,
                        buttonColor: this.state.clicked,
                      });
                    }}
                    style={{ backgroundColor: this.state.buttonColor }}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {this.state.editable ? (
                  <>
                    <Grid item xs={10}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Voucher Number"
                            name="voucerNumber"
                            value={voucherNumber}
                            margin="dense"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Fiscal Year"
                            name="fiscalYear"
                            value={fiscalYear}
                            margin="dense"
                            onChange={handleFyChangeView}
                          >
                            {fyList.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            label="period"
                            name="period"
                            value={period}
                            margin="dense"
                            onChange={handlePeriodChange}
                          >
                            {periodList.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Date"
                            name="date"
                            type="date"
                            value={date}
                            margin="dense"
                            onChange={handleTextChange}
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Dropdown
                            placeholder="Search by Customer name"
                            fluid
                            fullWidth
                            search
                            style={{ marginTop: "8px" }}
                            selection
                            value={supplierName}
                            onChange={handleSupplierSelect}
                            options={supplierOptionList}
                          ></Dropdown>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Dropdown
                            placeholder="Search by Customer Id Number"
                            fluid
                            fullWidth
                            search
                            style={{ marginTop: "8px" }}
                            selection
                            value={supplierIdNumber}
                            onChange={handleSupplierIdSelect}
                            options={idOptionList}
                          ></Dropdown>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Invoice Number"
                            name="invoiceNumber"
                            value={invoiceNumber}
                            margin="dense"
                            onChange={handleTextChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Invoice Total"
                            name="total"
                            value={total}
                            margin="dense"
                            onChange={handleTextChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Payment Reference Number"
                            name="paymentReferenceNumber"
                            value={paymentReferenceNumber}
                            margin="dense"
                            onChange={handleTextChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            name="paymentMethod"
                            value={paymentMethod}
                            margin="dense"
                            onChange={handleTextChange}
                          >
                            <MenuItem key={1} value={"Cash"}>
                              Cash
                            </MenuItem>
                            <MenuItem key={2} value={"Cheque"}>
                              Cheque
                            </MenuItem>
                            <MenuItem key={3} value={"Credit"}>
                              Credit
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Master Narration"
                            name="masterNarration"
                            value={masterNarration}
                            margin="dense"
                            onChange={handleTextChange}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Voucher Number"
                        value={voucherNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Fiscal Year"
                        value={fiscalYear}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Period"
                        value={period}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Date"
                        value={date}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Supplier ID"
                        value={supplierIdNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Supplier Name"
                        value={supplierName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Invoice Number"
                        value={invoiceNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Invoice Total"
                        value={total}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Payment Reference Number"
                        value={paymentReferenceNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Payment Method"
                        value={paymentMethod}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Input
                        fluid
                        style={{ marginTop: "5px" }}
                        label="Master Narration"
                        value={masterNarration}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <Divider style={{ marginTop: "25px", marginBottom: "10px" }} />

              {this.state.editable ? (
                <>
                  <Grid container>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", marginTop: "15px" }}
                    >
                      Entry
                    </Typography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      lg={3}
                      style={{ marginTop: "3px" }}
                    >
                      <TextField
                        label="Narration"
                        fullWidth
                        variant="outlined"
                        onChange={this.onChangedEntry}
                        value={this.state.tableRowData.narration}
                        name="narration"
                        margin="dense"
                      // inputRef={this.textInput}
                      ></TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      lg={1}
                      style={{ marginTop: "3px" }}
                    >
                      <TextField
                        label="Cost Center"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        select
                        name="costCenter"
                        onChange={this.onChangedAccountEntry}
                        value={this.state.tableRowData.costCenter}
                      >
                        {this.state.costCenterList.map((value) => (
                          <MenuItem key={value.id} value={value.code}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={2}
                      style={{ marginTop: "3px" }}
                    >
                      {this.state.emptyAccountCode ? (
                        <>
                          <Dropdown
                            placeholder="Account Code"
                            name="accountCode"
                            fluid
                            fullWidth
                            //error
                            select
                            selectOnBlur={false}
                            variant="outlined"
                            search={this.customSearch}
                            style={{
                              marginTop: "8px",
                              border: "1px solid red",
                            }}
                            ref={(search) => {
                              this.searchInput = search;
                            }}
                            selection
                            onChange={this.handleItemSelect}
                            value={this.state.tableRowData.accountCode}
                            options={[
                              {
                                key: "add",
                                value: "add",
                                text: "Add new account code",
                              },
                              ...this.state.rowRefs,
                            ]}
                          ></Dropdown>
                          <Label
                            basic
                            style={{
                              border: "0px",
                              fontWeight: 400,
                              fontSize: "0.75rem",
                              font: "Helvetica",
                              color: "#f44336",
                              letterSpacing: "0.03333em",
                            }}
                          >
                            field should not be empty
                          </Label>
                        </>
                      ) : (
                        <Dropdown
                          placeholder="Account Code"
                          name="accountCode"
                          floating
                          fluid
                          fullWidth
                          selectOnBlur={false}
                          search={this.customSearch}
                          style={{ marginTop: "8px", zIndex: 200 }}
                          ref={(search) => {
                            this.searchInput = search;
                          }}
                          selection
                          onChange={this.handleItemSelect}
                          value={this.state.tableRowData.accountCode}
                          options={[
                            {
                              key: "add",
                              value: "add",
                              text: "Add new account code",
                            },
                            ...this.state.rowRefs,
                          ]}
                        ></Dropdown>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={2}
                      style={{ marginTop: "3px" }}
                    >
                       <Dropdown
                        placeholder="Sub Account"
                        name="subAccount"

                        fluid
                        fullWidth
                        search
                        onSearchChange={this.customSearchForSub}
                        style={{ marginTop: "8px" }}
                        selection
                        onChange={this.handleItemSelect}
                        value={this.state.tableRowData.subAccount}
                        options={this.state.mySubAccount}
                      ></Dropdown>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={1}
                      style={{ marginTop: "3px" }}
                    >
                      <TextField
                        label="Currency"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="currencyAnalysis"
                        onChange={this.onChangedEntry}
                        value={this.state.tableRowData.currencyAnalysis}
                      ></TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={2}
                      style={{ marginTop: "3px" }}
                    >
                      {this.state.emptyAmount ? (
                        <TextField
                          label="Amount"
                          type="number"
                          name="amount"
                          fullWidth
                          error
                          helperText="field should not be empty"
                          variant="outlined"
                          margin="dense"
                          onChange={this.onChangedEntry}
                          value={this.state.tableRowData.amount}
                        ></TextField>
                      ) : (
                        <TextField
                          label="Amount"
                          type="number"
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          name="amount"
                          onChange={this.onChangedEntry}
                          value={this.state.tableRowData.amount}
                        ></TextField>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={1}
                      style={{ marginTop: "3px" }}
                    >
                      <Button
                        style={{
                          marginTop: "8px",
                          padding: "7px",
                          width: "100px",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={this.addNewRow}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: "25px", marginBottom: "10px" }}
                  />
                </>
              ) : null}

              {this.state.myTableList.length > 0 ? (
                <div>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bold", marginTop: "10px" }}
                  >
                    Transaction List
                  </Typography>

                  <Grid
                    container
                    justify="flex-end"
                    style={{ paddingRight: "10px" }}
                    spacing={4}
                  >
                    <Grid item>
                      <Button
                        style={{
                          marginTop: "8px",
                          paddingTop: "10px",
                          paddingRight: "25px",
                          paddingLeft: "25px",
                          paddingBottom: "10px",
                          marginBottom: "20px",
                          width: "100%",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={this.handleCheck}
                      >
                        Check
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          paddingTop: "6px",
                          paddingBottom: "11px",
                        }}
                      >
                        Total:{" "}
                        <span style={{ color: this.state.totalColor }}>
                          {this.state.requestData.total}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <TableContainer>
                    <Table
                      aria-label="customized table"
                      //   fixedHeader={false} style={{ width: "auto", tableLayout: "auto",  marginTop: "10px", marginBottom: "15px"  }}
                      fixedHeader={false}
                      style={{
                        marginTop: "10px",
                        marginBottom: "15px",
                        border: "1px solid #EAEDEF",
                      }}
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell style={styledTableCell} align="center">
                            {" "}
                            S. No.{" "}
                          </TableCell>
                          <TableCell style={styledTableCell} align="center">
                            Dr./Cr.
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Narration
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Cost Center
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Account Code
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Sub Account
                          </TableCell>
                          <TableCell style={styledTableCell}>
                            Currency
                          </TableCell>
                          <TableCell style={styledTableCell}>Amount</TableCell>
                          <TableCell style={styledTableCell} align="center">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.myTableList.map((value, i) => (
                          <TableRow
                            style={{
                              backgroundColor: this.state.gridColor[value.id],
                              transition: "background-color 1s",
                            }}
                          >
                            <TableCell
                              align="center"
                              style={{
                                width: "50px",
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                              }}
                            >
                              {i + 1}
                            </TableCell>
                            <TableCell
                              style={{
                                width: "60px",
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                              }}
                              align="center"
                            >
                              {value.debitCredit}
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                              }}
                            >
                              <TextField
                                value={value.narration}
                                name={"narration." + value.id}
                                onChange={this.onChangedTableRow}
                                fullWidth
                                style={{ minWidth: "160px" }}
                                variant="outlined"
                                margin="dense"
                              ></TextField>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "60px",
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                              }}
                            >
                              <TextField
                                label={value.costCenter}
                                value={value.costCenter}
                                name={"costCenter." + value.id}
                                select
                                fullWidth
                                style={{
                                  width: "80px",
                                }}
                                onChange={this.onChangedTableRow}
                                variant="outlined"
                                margin="dense"
                              >
                                {this.state.costCenterList.map((value) => (
                                  <MenuItem key={value.id} value={value.code}>
                                    {value.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "130px",
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                              }}
                             >
                              <TextField
                                label={value.accountCode}
                                value={value.accountCode}
                                select
                                fullWidth
                                style={{ width: "130px" }}
                                name={"accountCode." + value.id}
                                onChange={(e)=> this.handleTableInputChange(e,i)}
                                variant="outlined"
                                margin="dense"
                              >
                                {this.state.accountList.map((value) => (
                                  <MenuItem key={value.id} value={value.code}>
                                    {value.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "260px",
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                                position: "relative"
                              }}
                              >
                              <label style={{ position: "absolute", top: "8px", padding: "0 5px", zIndex: "1", background: "white", fontSize: "11px", left: "20px" }}>{value.subAccount}</label>
                              <Dropdown

                                placeholder="Sub Account"
                                name={"subAccount." + value.id}
                                fluid
                                fullWidth
                                search
                                selection
                                onSearchChange={(e, data) => this.customSearchForSubAccountTable(e, data, value.id, value.accountCode)}

                                style={{ width: "260px", maxHeight: "40px", minHeight: "38px" }}
                                value={value.subAccount}
                                // onKeyDown={this.onKeyPress}
                                onChange={this.onChangedTableRowSubAccount}
                                options={[
                                  ...value.subAccountList,
                                ]}
                              ></Dropdown>
                              {/* <TextField
                                label={value.subAccount}
                                value={value.subAccount}
                                fullWidth
                                style={{ width: "130px" }}
                                select
                                name={"subAccount." + value.id}
                                onChange={this.onChangedTableRow}
                                variant="outlined"
                                margin="dense"
                              >
                                {value.subAccountList.map((value) => (
                                  <MenuItem key={value.id} value={value.code}>
                                    {value.name}
                                  </MenuItem>
                                ))}
                              </TextField> */}
                            </TableCell>
                            <TableCell
                              style={{
                                width: "65px",
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                              }}
                            >
                              <TextField
                                value={value.currencyAnalysis}
                                name={"currencyAnalysis." + value.id}
                                style={{ width: "65px" }}
                                fullWidth
                                onChange={this.onChangedTableRow}
                                variant="outlined"
                                margin="dense"
                              ></TextField>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "130px",
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                              }}
                            >
                              <TextField
                                // value={format.format(value.amount)}
                                // value={this.getFormattedVal(value.amount)}
                                value={value.amount}
                                style={{ minWidth: "130px" }}
                                fullWidth
                                name={"amount." + value.id}
                                onChange={this.onChangedTableRow}
                                variant="outlined"
                                margin="dense"
                                type="number"
                              ></TextField>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                width: "30px",
                                padding: "10px",
                                borderRight: "1px solid #EAEDEF",
                                borderBottom: "1px solid #EAEDEF",
                              }}
                            >
                              <span>
                                {this.state.editable ? (
                                  <FontAwesomeIcon
                                    icon={faMinusCircle}
                                    size="lg"
                                    style={{
                                      color: "#FF8070",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.deleteRow(value.id, "debit")
                                    }
                                  ></FontAwesomeIcon>
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faMinusCircle}
                                    size="lg"
                                    style={{
                                      color: "#FF8070",
                                      cursor: "pointer",
                                    }}
                                  ></FontAwesomeIcon>
                                )}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : null}

              <Grid container justify="center">
                {this.state.editable ? (
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleUpdate}
                    style={buttonStyle}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => { }}
                    style={buttonStyle}
                    disabled
                  >
                    Update
                  </Button>
                )}
                {(status === "Pending" && this.state.hold === false) ? (
                <Button
                  variant="outlined"
                  size="large"
                  color="inherit"
                  onClick={() => this.handleStatus("Checked")}
                  style={buttonStyle}
                >
                  Check
                </Button>
              ) : null}
              {
                (this.state.hold === true && status === "Pending") ? (
                  <Button
                  variant="outlined"
                  size="large"
                  color="inherit"
                  disable
                  style={buttonStyle}
                >
                  Processing...
                </Button>
                ): null
              }
              {status === "Checked" ? (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => this.handleStatus("Approved")}
                  style={buttonStyle}
                >
                  Approve
                </Button>
              ) : null}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default VieReceivedVoucherAuthorization;
