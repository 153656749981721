import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Card,
  CardContent,
  FormControl,
  FormLabel,
  withStyles,
  Select,
  List,
} from "@material-ui/core";

import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import $ from "jquery";
import ValidatedTextField from "../InterfaceElements/ValidatedTextField";
import ValidatedSelect from "../InterfaceElements/ValidatedSelect";
import { Dropdown } from "semantic-ui-react";

window.jQuery = require("jquery");

const styles = (theme) => ({
  root: {
    paddingTop: "10px",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "170px",
      width: "95%",
      margin: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
      width: "90%",
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
      width: "80%",
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
  smMargin: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "50px",
    },
  },
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "90%",
    },
  },
});

class AddInventory extends Component {
  componentDidUpdate() { }

  componentDidMount() {
    $(".transactionDateNepali").nepaliDatePicker({
      dateFormat: "%D, %M %d, %y",
      closeOnDateSelect: true,
    });

    $(".invoiceDateNepali").nepaliDatePicker({
      dateFormat: "%D, %M %d, %y",
      closeOnDateSelect: true,
    });

    $(".transactionDateNepali").on("click", function (event) {
      $(".transactionDateNepaliLabel").css({ color: "#3f51b5" });
      $(".transactionDateNepali").css({ borderBottom: "2px solid #3f51b5" });
    });

    $(".invoiceDateNepali").on("click", function (event) {
      $(".invoiceDateNepaliLabel").css({ color: "#3f51b5" });
      $(".invoiceDateNepali").css({ borderBottom: "2px solid #3f51b5" });
    });

    $(".transactionDateNepali").focusout((params) => {
      $(".transactionDateNepaliLabel").css({ color: "rgba(0, 0, 0, 0.54)" });
      $(".transactionDateNepali").css({
        borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
      });
      setTimeout(() => {
        var str = $(".transactionDateNepali").val();

        this.props.onTransactionNepaliDateChange(str);
      }, 100);
    });

    $(".invoiceDateNepali").focusout((params) => {
      $(".invoiceDateNepaliLabel").css({ color: "rgba(0, 0, 0, 0.54)" });
      $(".invoiceDateNepali").css({
        borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
      });
      setTimeout(() => {
        var str = $(".invoiceDateNepali").val();

        this.props.onInvoiceDateNepaliChange(str);
      }, 100);
    });

    $(".transactionDateNepali").on("dateChange", (event) => {
      // var dateObj = event.datePickerData;

      // var date = new Date(dateObj.bsYear, dateObj.bsMonth, dateObj.bsDate);

      var str = $(".transactionDateNepali").val();

      this.props.onTransactionNepaliDateChange(str);
    });

    $(".invoiceDateNepali").on("dateChange", (event) => {
      // var dateObj = event.datePickerData;

      // var date = new Date(dateObj.bsYear, dateObj.bsMonth, dateObj.bsDate);

      var str = $(".invoiceDateNepali").val();

      this.props.onInvoiceDateNepaliChange(str);
    });
  }

  render() {
    const {
      children,
      value,
      index,
      errorStatus,
      onChange,
      onInvoiceDateEnglishChange,
      onInvoiceDateNepaliChange,
      onTransactionEnglishDateChange,
      onTransactionNepaliDateChange,
      onMfgDateChange,
      onExpiryDateChange,
      categoryList,
      propertyList,
      majorCategoryList,
      subCategoryList,
      unitList,
      supplierOptionList,
      warehouseList,
      manufacturerList,
      warehouse,
      category,
      propertyType,
      handleCalculation,
      handleVatCalculation,
      handleSupplierChange,
      handleCategoryChange,
      handleSubCategoryChange,
      total,
      vatAmount,
      totalWithVat,
      quantity,
      rate,
      mrp,
      vatPercentage,
      subCategory,
      supplierName,
      majorCategory,
      handleSubmit,
      invoiceDateEnglish,
      invoiceDateNepali,
      transactionDateEnglish,
      transactionDateNepali,
      manufactureDate,
      expiryDate,
      data,
      companyInvoicingMethod,
      classes,
      ...other
    } = this.props;

    const textFieldStyle = {
      width: "100%",
    };

    const inputStyles = {
      fontSize: 15,
    };

    return (
      <div>
        <Card
          role="tabpanel"
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <div style={{ marginTop: "20px" }}>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.containerWidth}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <ValidatedTextField
                    label="Name of Material"
                    placeholder=""
                    name="itemName"
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    value={data.itemName}
                    onChange={onChange}
                    error={errorStatus.itemName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ValidatedSelect
                    error={errorStatus.propertyType}
                    id="standard-select-currency"
                    name="propertyType"
                    label="Property Type"
                    value={data.propertyType}
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    onChange={onChange}
                    optionList={propertyList}
                    identifier={"propertyType"}
                    valueIdentifier={"propertyType"}
                  ></ValidatedSelect>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ValidatedSelect
                    error={errorStatus.majorCategory}
                    name="majorCategory"
                    label="Major Category"
                    value={data.majorCategory}
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    onChange={onChange}
                    optionList={majorCategoryList}
                    identifier={"majorCategoryName"}
                    valueIdentifier={"id"}
                  ></ValidatedSelect>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Dropdown
                    placeholder="Category"
                    fluid
                    search
                    style={{
                      marginTop: "25px",
                      borderTop: "0px",
                      borderRight: 0,
                      borderLeft: 0,
                      borderBottom: "1px solid ",
                      borderRadius: "0px",
                      borderColor: "#8c8a84",
                    }}
                    selection
                    value={data.category}
                    name="category"
                    onChange={handleCategoryChange}
                    options={categoryList}
                  ></Dropdown>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Dropdown
                    placeholder="Brand"
                    fluid
                    search
                    style={{
                      marginTop: "25px",
                      borderTop: "0px",
                      borderRight: 0,
                      borderLeft: 0,
                      borderBottom: "1px solid ",
                      borderRadius: "0px",
                      borderColor: "#8c8a84",
                    }}
                    selection
                    value={data.subCategory}
                    name="subCategory"
                    onChange={handleSubCategoryChange}
                    options={subCategoryList}
                  ></Dropdown>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <ValidatedTextField
                    label="Item Code"
                    name="itemCode"
                    error={errorStatus.itemCode}
                    value={data.itemCode}
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    readOnly={true}
                  ></ValidatedTextField>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Transaction Number"
                    name="transactionNumber"
                    value={data.inventorySubMaster.transactionNumber}
                    onChange={onChange}
                    placeholder=""
                    style={textFieldStyle}
                    InputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Transaction Date English"
                    name="transactionDateEnglish"
                    type="date"
                    value={data.inventorySubMaster.transactionDateEnglish}
                    onChange={onTransactionEnglishDateChange}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    style={textFieldStyle}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl
                    style={{
                      width: "100%",
                      marginTop: "19px",
                    }}
                  >
                    <FormLabel
                      style={{ fontSize: 10 }}
                      className="transactionDateNepaliLabel"
                    >
                      Transaction Date - Nepali
                    </FormLabel>
                    <input
                      type="text"
                      value={transactionDateNepali}
                      name="transactionDateNepali"
                      className="transactionDateNepali"
                      style={{
                        font: "inherit",
                        border: 0,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
                        margin: 0,
                        paddingBottom: "6px",
                        display: "block",
                        background: "none",
                        boxSizing: "content-box",
                      }}
                    ></input>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className={classes.smMargin}>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.containerWidth}
              >
                <Grid item xs={12} sm={6} md={2}>
                  <ValidatedSelect
                    id="standard-with-placeholder"
                    label="Unit"
                    name="unit"
                    value={data.unit}
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    optionList={unitList}
                    onChange={onChange}
                    error={errorStatus.unit}
                    identifier={"name"}
                    valueIdentifier={"name"}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Material Control ID"
                    name="materialControlId"
                    placeholder=""
                    value={data.inventorySubMaster.materialControlId}
                    onChange={onChange}
                    style={textFieldStyle}
                    InputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={7}>
                  <TextField
                    fullWidth
                    id="standard-with-placeholder"
                    label="Item Description"
                    name="itemDescription"
                    value={data.itemDescription}
                    onChange={onChange}
                    inputStyles={inputStyles}
                    textFieldStyle={{ textFieldStyle }}
                    InputLabelProps={{ style: inputStyles }}
                    placeholder=""
                    InputProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.smMargin}>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.containerWidth}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Invoice Number"
                    name="invoiceNumber"
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    value={data.inventorySubMaster.invoiceNumber}
                    onChange={onChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Invoice Date -English"
                    name="invoiceDateEnglish"
                    type="date"
                    value={invoiceDateEnglish}
                    onChange={onInvoiceDateEnglishChange}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    style={textFieldStyle}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl
                    style={{
                      width: "100%",
                      marginTop: "19px",
                    }}
                  >
                    <FormLabel
                      style={{ fontSize: 10 }}
                      className="invoiceDateNepaliLabel"
                    >
                      Invoice Date - Nepali
                    </FormLabel>
                    <input
                      type="text"
                      value={invoiceDateNepali}
                      name="invoiceDateNepali"
                      className="invoiceDateNepali"
                      style={{
                        font: "inherit",
                        border: 0,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
                        margin: 0,
                        display: "block",
                        paddingBottom: "6px",
                        background: "none",
                        boxSizing: "content-box",
                      }}
                    ></input>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Quantity"
                    name="quantity"
                    type="number"
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    value={quantity}
                    onChange={handleCalculation}
                    //error={errorStatus.quantity}
                    disabled
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ValidatedTextField
                    id="standard-with-placeholder"
                    label="Purchase Price"
                    type="number"
                    value={rate}
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    onChange={handleCalculation}
                    name="rate"
                    error={errorStatus.rate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ValidatedTextField
                    id="standard-with-placeholder"
                    label="Selling Price"
                    name="markedPrice"
                    type="number"
                    value={data.markedPrice}
                    onChange={onChange}
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    error={errorStatus.markedPrice}
                  />
                </Grid>

                {companyInvoicingMethod === "PAN" ? (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Total without vat"
                        placeholder=""
                        name="total"
                        type="number"
                        value={total}
                        onChange={onChange}
                        InputProps={{
                          //readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              NRs.
                            </InputAdornment>
                          ),
                          style: inputStyles,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-with-placeholder"
                        label="VAT Percent"
                        placeholder=""
                        value={vatPercentage}
                        onChange={handleVatCalculation}
                        name="vatPercentage"
                        type="number"
                        /*   InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                      style: inputStyles
                    }} */
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                        disabled
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-with-placeholder"
                        label="VAT Amount"
                        type="number"
                        name="vatAmount"
                        value={vatAmount}
                        placeholder=""
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              NRs.
                            </InputAdornment>
                          ),
                          style: inputStyles,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Total with VAT"
                        placeholder=""
                        name="totalWithVat"
                        value={totalWithVat}
                        type="number"
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              NRs.
                            </InputAdornment>
                          ),
                          style: inputStyles,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                        disabled
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Total without vat"
                        placeholder=""
                        name="total"
                        type="number"
                        value={total}
                        onChange={onChange}
                        InputProps={{
                          //readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              NRs.
                            </InputAdornment>
                          ),
                          style: inputStyles,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-with-placeholder"
                        label="VAT Percent"
                        placeholder=""
                        value={vatPercentage}
                        onChange={handleVatCalculation}
                        name="vatPercentage"
                        type="number"
                        select
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      >
                        <MenuItem key={0} value="0">
                          0 %
                        </MenuItem>
                        <MenuItem key={1} value="6">
                          6 %
                        </MenuItem>
                        <MenuItem key={2} value="13">
                          13 %
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-with-placeholder"
                        label="VAT Amount"
                        type="number"
                        name="vatAmount"
                        value={vatAmount}
                        placeholder=""
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              NRs.
                            </InputAdornment>
                          ),
                          style: inputStyles,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Total with VAT"
                        placeholder=""
                        name="totalWithVat"
                        value={totalWithVat}
                        type="number"
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              NRs.
                            </InputAdornment>
                          ),
                          style: inputStyles,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>

            <div className={classes.smMargin}>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.containerWidth}
              >
                <Grid item xs={12} sm={6} md={4}>
                  {/* <TextField
                        id="standard-with-placeholder"
                        label="Supplier"
                        placeholder=""
                        value={data.supplier}
                        onChange={onChange}
                        name="supplierName"
                        select
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      >
                   {supplierList.map(supplier=>(
                     <option value={supplier.name}>{supplier.name}</option>
                   ))}
                  </TextField> */}
                  <Dropdown
                    placeholder="Received From - Supplier"
                    fluid
                    search
                    // style={{ marginTop: "8px", width: "100%" }}
                    style={{
                      marginTop: "25px",
                      borderTop: "0px",
                      borderRight: 0,
                      borderLeft: 0,
                      borderBottom: "1px solid ",
                      borderRadius: "0px",
                      borderColor: "#8c8a84",
                    }}
                    selection
                    value={data.supplier}
                    onChange={handleSupplierChange}
                    options={supplierOptionList}
                  ></Dropdown>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Goods Received Note"
                    placeholder=""
                    name="goodsReceivedNote"
                    value={data.inventorySubMaster.goodsReceivedNote}
                    onChange={onChange}
                    style={textFieldStyle}
                    InputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Batch Number"
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    name="batchNumber"
                    value={data.inventorySubMaster.batchNumber}
                    onChange={onChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Manufacturer Name"
                    name="manufacturerName"
                    select
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    value={data.manufacturerName}
                    onChange={onChange}
                    // optionList={manufacturerList}
                    identifier={"fullName"}
                    fullWidth
                    margin="normal"
                  >
                    {manufacturerList.map((List) => (
                      <option value={List.fullName}>{List.fullName}</option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Manufacturing Date"
                    name="manufactureDate"
                    type="date"
                    value={manufactureDate}
                    onChange={onMfgDateChange}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    style={textFieldStyle}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Expiry Date"
                    name="expiryDate"
                    type="date"
                    value={expiryDate}
                    onChange={onExpiryDateChange}
                    inputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    style={textFieldStyle}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.smMargin}>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.containerWidth}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Reference Number"
                    placeholder=""
                    name="referenceNumber"
                    value={data.inventorySubMaster.referenceNumber}
                    onChange={onChange}
                    style={textFieldStyle}
                    InputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Model"
                    name="model"
                    textFieldStyle={textFieldStyle}
                    inputStyles={inputStyles}
                    value={data.inventorySubMaster.model}
                    onChange={onChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Specification Number"
                    placeholder=""
                    name="specificationNumber"
                    value={data.inventorySubMaster.specificationNumber}
                    onChange={onChange}
                    style={textFieldStyle}
                    InputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.smMargin}>
              <Grid
                container
                spacing={2}
                justify="center"
                className={classes.containerWidth}
              >
                <Grid item xs={12} sm={12} md={6}>
                  <ValidatedSelect
                    label="Location/Warehouse"
                    name="warehouse"
                    onChange={onChange}
                    value={warehouse}
                    textFieldStyle={{
                      width: "100%",
                      marginTop: "19px",
                    }}
                    inputStyles={inputStyles}
                    error={errorStatus.warehouse}
                    optionList={warehouseList}
                    identifier={"name"}
                  ></ValidatedSelect>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    id="standard-with-placeholder"
                    label="Custodian"
                    name="custodian"
                    onChange={onChange}
                    value={data.custodian}
                    placeholder=""
                    style={textFieldStyle}
                    InputProps={{ style: inputStyles }}
                    InputLabelProps={{ style: inputStyles }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    id="standard-select-currency"
                    select
                    style={textFieldStyle}
                    name="priority"
                    value={data.priority}
                    onChange={onChange}
                    InputProps={{ style: { fontSize: 13 } }}
                    InputLabelProps={{ style: inputStyles }}
                    label="Priority"
                    SelectProps={{
                      MenuProps: {
                        style: { width: 200 },
                      },
                    }}
                    margin="normal"
                  >
                    <MenuItem key="LOW" value="LOW">
                      Low
                    </MenuItem>
                    <MenuItem key="MEDIUM" value="MEDIUM">
                      Medium
                    </MenuItem>
                    <MenuItem key="HIGH" value="HIGH">
                      High
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </div>
            <Grid container justify="center">
              <Button
                variant="outlined"
                size="large"
                onClick={handleSubmit}
                color="primary"
                style={{
                  minWidth: "130px",
                  margin: "auto",
                  marginTop: "20px",
                }}
              >
                Add Inventory
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(AddInventory);
