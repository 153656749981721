import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Modal,
  Backdrop,
  Fade,
  withStyles,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import Axios from "axios";
import Close from "@material-ui/icons/Close";
import UserContext from "../../utils/UserContext";
import ValidatedTextField from "../InterfaceElements/ValidatedTextField";
import { Input, Form } from "semantic-ui-react";
import ViewSubCategoryButton from "../InterfaceElements/ViewSubCategoryButton";

const styles = (theme) => ({
  root: {
    paddingTop: "5px",
    paddingBottom: "23px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
  smButtonPosition: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: "10px",
      marginTop: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "0px",
      marginBottom: "10px",
      marginTop: "10px",
    },
  },
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "600px",
    },
  },
});

class SubCategory extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      data: {
        subCategoryName: "",
        status: true,
        category: {
          id: ""
        },
        under: "",
      },
      subCategoryData: [],
      columnDefs: [
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: "Name",
          field: "subCategoryName",
          sortable: true,
          resizable: true,
          minWidth: 180,
        },

        {
          headerName: "Under",
          field: "under",
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "Status",
          field: "status",
        },
        {
          headerName: "Actions",
          cellRendererFramework: ViewSubCategoryButton,
          cellRendererParams: {
            updateData: this.getTableData,
            setSnackbar: this.setSnackBar,
          },
          minWidth: 50,
        },
      ],
      under: "",
      underCode: "",
      rowData: [],
      open: false,
      errorStatus: {},
      totalItemCount: 0,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  componentWillUpdate() {
    this.daListener();
  }

  setSnackBar = (snackbarProps) => {
    const token = this.context;

    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  getTableData = () => {
    var rowData = [];

    Axios.get("pos/subCategory/getAllSubCategories")
      .then((res) => {
        res.data.forEach(element => {
          let obj = {
            id: element.id,
            subCategoryName: element.subCategoryName,
            under: element.category.categoryName,
            status: element.status === true ? "OPEN" : "CLOSE"
          }
          rowData.push(obj)
        });

        this.setState({
          rowData: rowData,
          totalItemCount: res.data.length,
        });
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });

        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  };

  componentDidMount() {
    this.getTableData();
  }

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleCloseModal() {
    this.setState({ open: false });
  }

  handleSearch = (evt) => {
    let search = evt.target.value;

    let newData = [];

    this.state.rowData.forEach((element) => {
      if ((element.code !== null) & (element.name !== null)) {
        if (
          element.code.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          element.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        )
          newData.push(element);
      }
    });

    this.gridApi.setRowData(newData);
  };

  validateForm() {
    var toCheck = ["subCategoryName"];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  handleSubmit = () => {
    const token = this.context;

    var status = this.validateForm();

    if (status) {
      Axios.post("pos/subCategory/add", this.state.data)
        .then((res) => {
          console.log(res);

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          console.log(token);
          this.handleClose();
          this.getTableData();
          this.setState({
            data: {
              subCategoryName: "",
              category: {
                id: ""
              },
              under: "",
            },
          });
        })
        .catch((err) => {
          var snackbarProps = {
            open: true,
            variant: "error",
            message: err.response.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  onChanged = async (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    var underId = "";

    if (targetName === "under") {
      this.props.categoryData.forEach((element) => {
        if (element.categoryName === value) {
          underId = element.id;
        }
      });

      await this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [targetName]: value,
          category: {
            ...prevState.category,
            id: underId
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [targetName]: value,
        },
      }));
    }
  };


  render() {
    const { children, value, index, classes, ...other } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const inputStyles = {
      fontSize: 14,
    };

    const textFieldStyle = {
      width: "100%",
    };

    return (
      <div>
        <Card
          className={classes.root}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center">
              <div
                className="ag-theme-balham"
                style={{
                  width: "100%",
                }}
              >
                <Grid
                  container
                  justify="flex-end"
                  style={{
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleOpen}
                    className={classes.smButtonPosition}
                  >
                    Add Entry
                  </Button>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  style={{ padding: "15px" }}
                >
                  <Grid item xs={5}>
                    <Form>
                      <Form.Field>
                        <label>Search By Sub-Ledger Code or Name</label>
                        <Input
                          fluid
                          value={this.state.search}
                          style={{
                            minWidth: "190px",
                            height: "38px",
                            marginTop: "5px",
                          }}
                          onChange={this.handleSearch}
                        ></Input>
                      </Form.Field>
                    </Form>
                  </Grid>
                  <Grid item>
                    <h6 style={{ margin: "5px" }}>
                      Total Records : {this.state.totalItemCount}
                    </h6>
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "51vh",
                      width: "100%",
                      padding: "15px",
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      onFirstDataRendered={this.firstDataRendered}
                      paginationAutoPageSize={true}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                      onGridReady={this.onGridReady}
                    ></AgGridReact>
                  </div>
                </Grid>
              </div>
            </Grid>
          </CardContent>
        </Card>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container spacing={2} justify="center">
                  <Grid item xs={12} >
                    <ValidatedTextField
                      label="Name"
                      name="subCategoryName"
                      error={this.state.errorStatus.name}
                      value={this.state.data.subCategoryName}
                      onChange={this.onChanged}
                      textFieldStyle={textFieldStyle}
                      inputStyles={inputStyles}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    {this.state.errorStatus.under ? (
                      <TextField
                        name="under"
                        label="Under"
                        error
                        select
                        fullWidth
                        value={this.state.data.under}
                        helperText={"Under should be selected"}
                        style={textFieldStyle}
                        onChange={this.onChanged}
                        InputProps={{ style: { fontSize: 13 } }}
                        InputLabelProps={{ style: inputStyles }}
                        SelectProps={{
                          MenuProps: {
                            style: { width: 200 },
                          },
                        }}
                        margin="normal"
                      >
                        {this.props.categoryData.map((item, i) => (
                          <MenuItem key={i} value={item.categoryName}>
                            {item.id + " - " + item.categoryName}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                        <TextField
                          name="under"
                          label="Under"
                          select
                          value={this.state.data.under}
                          style={textFieldStyle}
                          onChange={this.onChanged}
                          InputProps={{ style: { fontSize: 13 } }}
                          InputLabelProps={{ style: inputStyles }}
                          SelectProps={{
                            MenuProps: {
                              style: { width: 200 },
                            },
                          }}
                          margin="normal"
                        >
                          {this.props.categoryData.map((item, i) => (
                            <MenuItem key={i} value={item.categoryName}>
                              {item.id + " - " + item.categoryName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleSubmit}
                    style={{
                      margin: "20px",
                      marginTop: "25px",
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(SubCategory);
