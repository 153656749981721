import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Button,
  Typography,
  withStyles,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FiscalYearButton from "../components/InterfaceElements/FiscalYearButton";
import Axios from "axios";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "semantic-ui-react";

const styles = theme => ({
  tabMargin: {
    width: "100%",
    maxWidth: "1000px",
    margin: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "105px",
      marginBottom: "170px"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
      marginBottom: "100px"
    }
  }
});

function PeriodMaster(props) {
  const [user] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [origTableData, setOrigTableData] = useState([]);
  const [periodSearchList, setPeriodSearchList] = useState([]);
  const [filterShow, SetFilterShow] = useState(false);
  const [statusRefs, setStatusRefs] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const { classes } = props;

  useEffect(() => {
    populateTable();
  }, [user]);


  const handleRowsPerPage = (event) => {
    console.log("-------")
    console.log(rowsPerPage);
    console.log(event.target.value);
    setRowsPerPage(
      parseInt(event.target.value, 10)
    )
    setPage(0)
  };

  const populateTable = () => {
    Axios.get("fy/findTillCurrentPeriod")
      .then(res => {
        var distinctfiscalYear = [];
        var periodName = "";
        var fyList = [];
        var data = [];
        var distinctList = [];

        var periodSearchData = [
          { key: "", text: "All Periods...", value: "All Periods..." }
        ];

        res.data.forEach(element => {
          periodName = element.fiscalYear + "-" + element.name;

          var item = {
            id: element.id,
            fiscalYearPeriod: periodName,
            dateFrom: element.dateFrom,
            dateTo: element.dateTo,
            periodStatus: element.periodStatus,
            currentStatus: element.currentStatus,
            fiscalYear: element.fiscalYear,
            name: element.name
          };
          data.push(item);
          fyList.push(item.fiscalYear);
        });

        distinctList = [...new Set(fyList)];
        distinctfiscalYear = distinctList[distinctList.length - 1];

        distinctList.map((val, i) =>
          periodSearchData.push({ key: i, text: val, value: val })
        );

        setPeriodSearchList(periodSearchData);
        setOrigTableData(data);
        var newData = [];
        data.map(val =>
          val.fiscalYear === distinctfiscalYear ? newData.push(val) : null
        );

        setTableData(newData);

        var statusList = [];

        let statusValues = ["All Status...", "OPEN", "CLOSED", "INACTIVE"];

        statusValues.forEach((element, i) => {
          statusList.push({
            key: i,
            text: element,
            value: element
          });
        });
        setStatusRefs(statusList);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const generateList = () => {
    return (
      tableData.map((value, i) => (
        <TableRow>
          <TableCell>{i + 1}</TableCell>
          <TableCell>{value.fiscalYearPeriod}</TableCell>
          <TableCell>{value.dateFrom}</TableCell>
          <TableCell>{value.dateTo}</TableCell>
          <TableCell>{colorizedSpan(value.periodStatus)}</TableCell>
          <TableCell>
            <FiscalYearButton
              data={value}
              populateTable={populateTable}
            ></FiscalYearButton>
          </TableCell>
        </TableRow>
      ))
    )

  }

  const colorizedSpan = mode => {
    console.log(mode);
    if (mode === "OPEN") {
      return <span style={{ color: "#5FE3A1" }}>{mode}</span>;
    } else if (mode === "INACTIVE") {
      return <span style={{ color: "#FCA130" }}>{mode}</span>;
    } else {
      return <span style={{ color: "#FF5733" }}>{mode}</span>;
    }
  };

  const handleStatusSelect = (event, data) => {
    const { value } = data;
    console.log(value);
    var newTableData = [];
    console.log("-----ccccc--------");
    console.log(origTableData);
    if (value === "All Status...") {
      setTableData(origTableData);

    } else {
      origTableData.map(val =>
        val.periodStatus === value ? newTableData.push(val) : null
      );
      setPage(0);
      setTableData(newTableData);
    }
  };

  const handlePeriodSelect = (event, data) => {
    const { value } = data;
    console.log(value);
    var newTableData = [];
    console.log(origTableData);
    if (value === "All Periods...") {
      setTableData(origTableData);
    } else {
      origTableData.map(val =>
        val.fiscalYear === value ? newTableData.push(val) : null
      );
      setPage(0);
      setTableData(newTableData);
    }
  };
  return (
    <div>
      <Grid container justify="center">
        <Card className={classes.tabMargin}>
          <CardContent>
            <Grid item xs>
              <Button
                color="primary"
                onClick={() => {
                  SetFilterShow(!filterShow);
                }}
              >
                <Typography variant="button">
                  <FontAwesomeIcon
                    icon={faSlidersH}
                    style={{ marginRight: "5px" }}
                  ></FontAwesomeIcon>
                  Filter
                </Typography>
              </Button>
            </Grid>
            {filterShow ? (
              <Grid container>
                <Grid
                  container
                  spacing={2}
                  style={{
                    border: "#87CEFA 2px solid",
                    marginTop: "10px",
                    marginLeft: "1px",
                    borderRadius: "10px",
                    paddingRight: "5px",
                    width: "60%",
                    minWidth: "250px"
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <div
                      style={{
                        width: "100%",
                        marginTop: "15px",
                        marginBottom: "15px",
                        fontSize: "14px"
                      }}
                    >
                      <Dropdown
                        placeholder="Filter By Fiscal Year"
                        fluid
                        search
                        selection
                        onChange={handlePeriodSelect}
                        options={periodSearchList}
                      ></Dropdown>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div
                      style={{
                        width: "100%",
                        marginTop: "15px",
                        marginBottom: "15px",
                        fontSize: "14px"
                      }}
                    >
                      <Dropdown
                        placeholder="Filter By Status"
                        fluid
                        search
                        selection
                        onChange={handleStatusSelect}
                        options={statusRefs}
                      ></Dropdown>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid container justify="center" style={{ marginTop: "20px" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5",
                          width: "50px"
                        }}
                      >
                        Id
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5"
                        }}
                      >
                        Fiscal Year Period
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5"
                        }}
                      >
                        From
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5"
                        }}
                      >
                        To
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5"
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5",
                          width: "125px"
                        }}
                      >
                        Change Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {generateList().slice(
                      page * rowsPerPage,
                      page * rowsPerPage +
                      rowsPerPage
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(event, newPage) => {
                  setPage(newPage);
                }}
                onChangeRowsPerPage={handleRowsPerPage}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(PeriodMaster);
