import React from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem } from "@material-ui/core";

function ValidatedSelect(props) {
  const {
    error,
    name,
    label,
    textFieldStyle,
    inputStyles,
    value,
    identifier,
    valueIdentifier,
    optionList,
    onChange
  } = props;

  var errorText = label + " should be selected";

  var toShow = value;

  if (
    identifier === "majorCategoryName" ||
    identifier === "categoryName" ||
    identifier === "subCategoryName" || identifier === "code"
  ) {
    optionList.forEach(element => {
      if (element[identifier] === value) {
        toShow = element.id;
      }
    });

    if (error) {
      return (
        <TextField
          id="standard-select-currency"
          select
          style={textFieldStyle}
          name={name}
          label={label}
          error
          helperText={errorText}
          value={toShow}
          onChange={onChange}
          InputProps={{ style: { fontSize: 13 } }}
          InputLabelProps={{ style: inputStyles }}
          SelectProps={{
            MenuProps: {
              style: { width: 200 }
            }
          }}
          margin="normal"
        >
          {optionList.map((item, i) => (
            <MenuItem key={i} value={item[valueIdentifier]}>
              {item[identifier]}
            </MenuItem>
          ))}
        </TextField>
      );
    } else {
      return (
        <TextField
          id="standard-select-currency"
          select
          style={textFieldStyle}
          name={name}
          label={label}
          value={toShow}
          onChange={onChange}
          InputProps={{ style: { fontSize: 13 } }}
          InputLabelProps={{ style: inputStyles }}
          SelectProps={{
            MenuProps: {
              style: { width: 200 }
            }
          }}
          margin="normal"
        >
          {optionList.map((item, i) => (
            <MenuItem key={i} value={item[valueIdentifier]}>
              {item[identifier]}
            </MenuItem>
          ))}
        </TextField>
      );
    }
  } else {
    if (error) {

      return (
        <TextField
          id="standard-select-currency"
          select
          style={textFieldStyle}
          name={name}
          label={label}
          error
          helperText={errorText}
          value={value}
          onChange={onChange}
          InputProps={{ style: { fontSize: 13 } }}
          InputLabelProps={{ style: inputStyles }}
          SelectProps={{
            MenuProps: {
              style: { width: 200 }
            }
          }}
          margin="normal"
        >
          {optionList.map((item, i) => (
            <MenuItem key={i} value={item[identifier]}>
              {item[identifier]}
            </MenuItem>
          ))}
        </TextField>
      );
    } else {
      return (
        <TextField
          id="standard-select-currency"
          select
          style={textFieldStyle}
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          InputProps={{ style: { fontSize: 13 } }}
          InputLabelProps={{ style: inputStyles }}
          SelectProps={{
            MenuProps: {
              style: { width: 200 }
            }
          }}
          margin="normal"
        >
          {optionList.map((item, i) => (
            <MenuItem key={i} value={item[identifier]}>
              {item[identifier]}
            </MenuItem>
          ))}
        </TextField>
      );
    }
  }
}

ValidatedSelect.propTypes = {
  error: PropTypes.bool,
  optionList: PropTypes.array
};

export default ValidatedSelect;
