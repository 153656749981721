import React, { Component } from "react";
import {
  Typography,
  CardContent,
  Grid,
  Card,
  TextField,
  Button,
  withStyles
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../utils/UserContext";

const styles = theme => ({
  root: {
    padding: 20,
    paddingTop: "5px",
    paddingBottom: "5px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: "100px",
      
    },
  },
});

class AddUser extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      rolesSelect: [],
      data: {
        address: "",
        email: "",
        firstName: "",
        identificationNumber: "",
        middleName: "",
        phoneNumber: "",
        surname: ""
      }
    };
  }

  componentDidMount() {

    Axios.get("role/findAll")
      .then(res => {
        console.log(res);

        var roles = [];
        res.data.forEach(element => {
          roles.push(element.type);
        });

        this.setState({ roles });
      })
      .catch(err => {
        console.log(err);
      });


  }

  onChanged = e => {
    e.stopPropagation();
    console.log(e.target.value);
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  render() {
    const {
      children,
      value,
      index,
      customerType,
      paymentMode,
      employeeData,
      onChanged,
      classes,
      handleSubmit,
      ...other
    } = this.props;


    const inputStyles = {
      fontSize: 14
    };

    return (
        <Grid container justify="center" >
          <Grid item xs={11} lg={6} md={7} sm={10}>
          <Card
          className={classes.root}
          role="tabpanel"
          style={{width: "100%"}}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container>
              <Typography variant="h6" component="h2">
                {" "}
                Update User Details{" "}
              </Typography>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ marginTop: "20px" }}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="First Name"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="firstName"
                  variant="filled"
                  value={employeeData.firstName}
                  onChange={onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Middle Name"
                  style={{ width: "100%" }}
                  placeholder=""
                  name="middleName"
                  variant="filled"
                  value={employeeData.middleName}
                  onChange={onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Last Name"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="surname"
                  variant="filled"
                  value={employeeData.surname}
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-with-placeholder"
                  label="Mobile number"
                  placeholder=""
                  name="phoneNumber"
                  variant="filled"
                  value={employeeData.phoneNumber}
                  onChange={onChanged}
                  style={{width: "100%"}}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="normal"
                />
              </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-with-placeholder"
                label="Email address"
                type="email"
                placeholder=""
                variant="filled"
                name="email"
                value={employeeData.email}
                onChange={onChanged}
                style={{width: "100%"}}
                inputProps={{ style: inputStyles }}
                InputLabelProps={{ style: inputStyles }}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="standard-with-placeholder"
                label="Citizenship Number"
                placeholder=""
                name="identificationNumber"
                variant="filled"
                value={employeeData.identificationNumber}
                onChange={onChanged}
                style={{width: "100%"}}
                inputProps={{ style: inputStyles }}
                InputLabelProps={{ style: inputStyles }}
                margin="normal"
              />
            </Grid>

            <Grid container justify="center">
              <Button
                onClick={handleSubmit}
                variant="contained"
                size="large"
                color="primary"
                style={{
                  margin: "20px",
                  marginTop: "50px",
                  paddingLeft: "27px",
                  paddingRight: "27px",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                Update
              </Button>
            </Grid>
            </Grid>
          </CardContent>
        </Card>
          </Grid>
        </Grid>  
        
    );
  }
}

export default (withStyles(styles)(AddUser));
