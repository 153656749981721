import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import {
  Modal,
  Fade,
  Grid,
  TextField,
  Button,
  Backdrop,
  Divider,
  Typography,
  CardContent,
  withStyles,
  Card,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import UserContext from "../../utils/UserContext";
import Close from "@material-ui/icons/Close";
import _ from "lodash";
import { Search } from "semantic-ui-react";
import DeleteButton from "./DeleteButton";
import { format } from "date-fns/esm";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme) => ({
  cardHeight: {
    minWidth: "300px",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      maxHeight: "98%",
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      maxHeight: "98%",
      height: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90%",
      maxHeight: "98%",
      height: "auto",
    },
  },
});

class ViewGoodsReceivedButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      resData:[],
      periodList:[],
      columnDefs: [
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          width: 50,
          minWidth: 50,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          sortable: true,
          resizable: true,
          width: 90,
          filter:true,
          minWidth: 90,
        },
        {
          headerName: "Item Description",
          field: "itemName",
          sortable: true,
          resizable: true,
          minWidth: 127,
          width: 127,
        },
        {
          headerName: "unit",
          field: "unit",
          sortable: true,
          resizable: true,
          width: 70,
          minWidth: 70,
        },
        {
          headerName: "Price",
          field: "price",
          width: 70,
          minWidth: 70,
          resizable: true,
          sortable: true,
          type: "numericColumn",
          editable: true,
        },
        {
          headerName: "Ordered Quantity",
          field: "orderQuantity",
          sortable: true,
          resizable: true,
          width: 120,
          minWidth: 120,
          type: "numericColumn",
        },
        {
          headerName: "Recieved Quantity",
          field: "receivedQuantity",
          resizable: true,
          sortable: true,
          width: 120,
          minWidth: 120,
          type: "numericColumn",
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          resizable: true,
          sortable: true,
          width: 120,
          minWidth: 120,
          type: "numericColumn",
        },
        {
          headerName: "Comments",
          field: "comments",
          editable: true,
          resizable: true,
          sortable: true,
          minWidth: 127,
          width: 127,
        },
        {
          headerName: "Delete",
          resizable: true,
          width: 50,
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
          },
        },
      ],
      rowData: [],
      supplierList: [],
      purchaseDetails: {
        id: "",
        fiscalYear:"",
        period:"",
        grnDate: "",
        grnNumber: "",
        status: "",
        purchaseOrderNumber: "",
        grandTotal: 0,

        receivedBy: "",
        receivedAddress: "",
        receivedPan: "",

        receivedFrom: "",
        supplierAddress: "",
        supplierPan: "",
        warehouse: "",
        chalanNumber: "",
        invoiceNumber: "",
        comment: "",
        transportNumber: "",
        receivedByName: "",
        grnDetails: [],
      },
      editable: false,
      clicked: "#0b5f8a",
      buttonColor: "#4aa5d8",
      commentError: false,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  getGRNByID() {
    Axios.get("pos/grnMaster/findById/" + this.props.node.data.id)
      .then((res) => {
        // console.log("@@@@@@@@@@@@@@@")
        // console.log(res.data.period)
        this.setState((prevState) => ({
          ...prevState,
          rowData: res.data.grnDetails,

          purchaseDetails: {
            id: res.data.id,
            fiscalYear: res.data.fiscalYear === null ? "" : res.data.fiscalYear,
            period: res.data.period === null ? "" : res.data.period,
            grnDate: res.data.grnDate === null ? "" : res.data.grnDate,
            status: res.data.status,
            grnNumber: res.data.grnNumber === null ? "" : res.data.grnNumber,
            purchaseOrderNumber:
              res.data.purchaseOrderNumber === null
                ? ""
                : res.data.purchaseOrderNumber,
            receivedBy: res.data.receivedBy === null ? "" : res.data.receivedBy,
            receivedByName:
              res.data.receivedByName === null ? "" : res.data.receivedByName,
            receivedAddress:
              res.data.receivedAddress === null ? "" : res.data.receivedAddress,
            receivedPan:
              res.data.receivedPan === null ? "" : res.data.receivedPan,
            chalanNumber:
              res.data.chalanNumber === null ? "" : res.data.chalanNumber,
            receivedFrom:
              res.data.receivedFrom === null ? "" : res.data.receivedFrom,
            supplierAddress:
              res.data.supplierAddress === null ? "" : res.data.supplierAddress,
            supplierPan:
              res.data.supplierPan === null ? "" : res.data.supplierPan,
            warehouse: res.data.warehouse === null ? "" : res.data.warehouse,
            invoiceNumber:
              res.data.invoiceNumber === null ? "" : res.data.invoiceNumber,
            transportNumber:
              res.data.transportNumber === null ? "" : res.data.transportNumber,

            grandTotal: res.data.grandTotal,
          },
        }));

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        if (this.gridApi !== undefined) {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        }
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        if (this.gridApi !== undefined) {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        }
      });
    this.getSupplierList();
    this.getAllPeriodListing();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleSubmit = (targetName) => {
    var obj = {
      comment: this.state.purchaseDetails.comment,
      status: targetName,
    };

    if (targetName === "Checked") {
      Axios.put("pos/grnMaster/update/" + this.state.purchaseDetails.id, obj)
        .then((res) => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
          this.props.getGRNData();
          this.props.getListData();
          this.handleClose();
        })
        .catch((err) => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
        });
    } else if (targetName === "Approved") {
      Axios.put("pos/grnMaster/update/" + this.state.purchaseDetails.id, obj)
        .then((res) => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
          this.props.getGRNData();
          this.handleClose();
          this.props.getListData();
        })
        .catch((err) => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          this.props.showSnackbar(snackbarProps);
        });
    } else if (targetName === "Rejected") {
      if (obj.comment !== "" && obj.comment !== undefined) {
        Axios.put("pos/grnMaster/update/" + this.state.purchaseDetails.id, obj)
          .then((res) => {
            var snackbarProps = {
              open: true,
              variant: "success",
              message: res.data,
              autoHideDuration: 2000,
            };
            this.props.showSnackbar(snackbarProps);
            this.props.getGRNData();
            this.props.getListData();
            this.handleClose();
          })
          .catch((err) => {
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000,
            };
            this.props.showSnackbar(snackbarProps);
          });
      } else {
        this.setState({ commentError: true });
      }
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
    this.getGRNByID();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChanged = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;

    if (targetName === "comment" && value !== "") {
      this.setState((prevState) => ({
        ...prevState,
        commentError: false,
        purchaseDetails: {
          ...prevState.purchaseDetails,
          [targetName]: value,
        },
      }));
    }
  };

  getSupplierList = () => {
    //
    Axios.get("pos/supplier/getAllSupplierList").then((res) => {
      let suppliers = [{ id: 0, name: "None" }];
      res.data.forEach((element) => {
        suppliers.push(element);
      });
      this.setState({
        supplierList: suppliers,
      });
    });
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handle0Index = (rowAllData, itemData) => {
    var rowItemData = {
      sn: this.state.sn,
      id: itemData.id,
      itemName: itemData.itemName,
      itemCode: itemData.itemCode,
      receivedQuantity: this.state.receivedQuantity,
      orderQuantity: this.state.orderQuantity,
      lineTotal:
        Math.round(
          itemData.rate * parseFloat(this.state.receivedQuantity) * 100
        ) / 100,
      price: itemData.rate,
      comments: "",
      unit: itemData.unit,
    };
    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.itemName === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
    }

    this.setState({ sn: this.state.sn + 1 });
  };

  handleItemAdd = () => {
    var rows = this.getAllRows();
    var itemData = this.state.itemDetails;

    if (
      this.state.receivedQuantity === "0" ||
      this.state.orderQuantity === "0" ||
      itemData.itemName === undefined ||
      this.state.receivedQuantity === 0 ||
      this.state.orderQuantity === 0
    ) {
      return;
    }

    var rowAllData = this.state.rowData;

    if (rows.length === 0) {
      this.handle0Index(rowAllData, itemData);
    } else {
      for (let index = 0; index < rows.length; index++) {
        const element = rowAllData[index];

        if (element.itemName === itemData.itemName) {
          var rowSpecificData = {
            ...element,
            receivedQuantity:
              parseFloat(Math.round(element.receivedQuantity * 100) / 100) +
              parseFloat(Math.round(this.state.receivedQuantity * 100) / 100),
            orderQuantity:
              parseFloat(Math.round(element.orderQuantity * 100) / 100) +
              parseFloat(Math.round(this.state.orderQuantity * 100) / 100),
            lineTotal:
              Math.round(
                parseFloat(element.price) *
                  (parseFloat(element.receivedQuantity) +
                    parseFloat(this.state.receivedQuantity)) *
                  100
              ) / 100,
            price: element.price,
            comments: "",
            unit: element.unit,
          };
          this.setState((prevState) => ({
            ...prevState,
            purchaseDetails: {
              ...prevState.purchaseDetails,
              grandTotal: parseFloat(
                Math.round(
                  this.state.purchaseDetails.grandTotal +
                    element.price * this.state.receivedQuantity * 100
                ) / 100
              ),
            },
          }));

          rowAllData[index] = rowSpecificData;
        } else {
          this.handle0Index(rowAllData, itemData);
        }
      }
    }

    this.setState({ rowData: rowAllData });

    let grandTotal = 0;

    rowAllData.forEach((element) => {
      grandTotal += parseFloat(Math.round(element.lineTotal * 100) / 100);
    });

    this.setState((prevState) => ({
      ...prevState,
      purchaseDetails: {
        ...prevState.purchaseDetails,
        grandTotal: grandTotal,
      },
    }));

    this.gridApi.setRowData(rowAllData);
  };

  handleSearch = (e, { value }) => {
    this.setState({ isLoading: true, itemValue: value });

    let newOptions = [];

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      Axios.get("pos/inventory/searchItems/" + value)
        .then((res) => {
          res.data.map((val) =>
            newOptions.push({
              title: val.itemCode,
              description: val.itemName,
            })
          );

          this.setState({
            isLoading: false,
            rowRefs: newOptions,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };

  handleItemSelect = (event, data) => {
    const { result } = data;

    this.setState({
      itemValue: result.title,
    });

    Axios.get("pos/inventory/getInventoryByItemCode/" + result.title)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          itemDetails: res.data,
        }));
      })
      .catch((err) => console.log(err));
  };
  handleQuantityChange = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = parseFloat(Math.round(e.target.value * 100) / 100);

    this.setState((prevState) => ({
      ...prevState,
      [targetName]: value,
    }));
  };

  handleChanged = (e) => {
    var value = e.target.value;
    var targetName = e.target.name;
    this.setState((prevState) => ({
      ...prevState,
      purchaseDetails: {
        ...prevState.purchaseDetails,
        [targetName]: value,
      },
    }));
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        // await this.setState((prevState) => ({
        //   ...prevState,
        //   purchaseDetails: {
        //     ...prevState.purchaseDetails,
        //     period: res.data.name,
        //   },
        // }));

        this.getPeriodList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      purchaseDetails: {
        ...prevState.purchaseDetails,
        period: Py,
      },
    }));
  };

  handleDateChange = (date) => {
   
    let formattedDate = format(new Date(date), "yyyy-MM-dd");
    //alert(formattedDate)
    this.setState((prevState) => ({
      ...prevState,
      purchaseDetails: {
        ...prevState.purchaseDetails,
        grnDate: formattedDate,
      },
    }));
  };

  getPeriodList() {
    var pLists = [];

    // this.state.resData.map(val => Object.keys(val) === this.state.fiscalYear ? pLists = val : null);
    this.state.resData.map((val) =>
      Object.keys(val).includes(this.state.purchaseDetails.fiscalYear)
        ? (pLists = val[this.state.purchaseDetails.fiscalYear])
        : null
    );
    this.setState({
      periodList: pLists,
    });
  }

  handleTextChangeSupplier = (e) => {
    e.stopPropagation();
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      purchaseDetails: {
        ...prevState.purchaseDetails,
        receivedFrom: value,
      },
    }));
    if (value === "None") {
      this.setState((prevState) => ({
        ...prevState,
        purchaseDetails: {
          ...prevState.purchaseDetails,
          supplierAddress: "None",
          supplierPan: "None",
        },
      }));
    } else {
      Axios.get("pos/supplier/getAllSuppliers").then((res) => {
        res.data.map((val) => {
          if (val.fullName === value) {
            this.setState((prevState) => ({
              ...prevState,
              purchaseDetails: {
                ...prevState.purchaseDetails,
                supplierAddress: val.street + "-" + val.city,
                supplierPan: val.panNumber,
              },
            }));
          }

          return null;
        });
      });
    }
  };

  deleteRow = (data) => {
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      var obj = rowAllData[i];

      if (obj.id === data.id) {
        finalRows.splice(i, 1);
        break;
      }
    }

    this.setState({
      rowData: finalRows,
    });
    this.updateGrandTotal();
  };

  updateGrandTotal() {
    var gTotal = 0.0;
    this.state.rowData.forEach((element) => {
      gTotal += parseFloat(Math.round(element.lineTotal * 100) / 100);
    });
    this.setState((prevState) => ({
      ...prevState,
      purchaseDetails: {
        ...prevState.purchaseDetails,
        grandTotal: gTotal,
      },
    }));
  }

  handleUpdate = async () => {
    // const token = this.context;

    let tableData = [];
    this.state.rowData.forEach((val) => {
      var obj = {
        id: val.id,
        itemName: val.itemName,
        itemCode: val.itemCode,
        lineTotal: val.lineTotal,
        orderQuantity: val.orderQuantity,
        receivedQuantity: val.receivedQuantity,
        price: val.price,
        unit: val.unit,
        comments: val.comments,
      };
      tableData.push(obj);
    });

    await this.setState((prevState) => ({
      ...prevState,
      purchaseDetails: {
        ...prevState.purchaseDetails,
        grnDetails: tableData,
      },
    }));

    if (this.state.purchaseDetails.grnDetails.length === 0) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No item selected",
        autoHideDuration: 2000,
      };
      this.props.showSnackbar(errorSnackbarProps);
      return;
    }

    Axios.put(
      "/pos/grnMaster/updateGrn/" + this.props.node.data.id,
      this.state.purchaseDetails
    )
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        this.props.showSnackbar(snackbarProps);

        this.props.getGRNData();
        this.props.getListData();
        this.setState((prevState) => ({
          ...prevState,
          editable: false,
        }));
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        this.props.showSnackbar(snackbarProps);
      });
  };

  onCellValueChanged = (params) => {
    var rowAllData = this.state.rowData;

    let grandTotal = 0;

    rowAllData.forEach((element) => {
      if (element.sn === params.data.sn) {
        var obj = element;

        if (isNaN(params.data.price)) obj.price = 0;

        obj.lineTotal = parseFloat(
          Math.round(obj.price * obj.receivedQuantity * 100) / 100
        );
      }

      grandTotal += parseFloat(Math.round(element.lineTotal * 100) / 100);
    });

    this.setState({ rowData: rowAllData });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grandTotal,
      },
    }));

    this.gridApi.setRowData(rowAllData);
  };

  render() {
    const { classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const buttonStyle = {
      marginTop: "20px",
      marginBottom: "20px",
      marginRight: "50px",
      paddingTop: "15px",
      paddingBottom: "15px",
      paddingRight: "50px",
      paddingLeft: "50px",
    };

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid
                  container
                  justify="center"
                  style={{ width: "98%", margin: "auto" }}
                >
                  <Grid container justify="flex-end">
                    <IconButton onClick={this.handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          editable: !this.state.editable,
                          buttonColor: this.state.clicked,
                        });
                      }}
                      style={{
                        backgroundColor: this.state.buttonColor,
                        marginBottom: "15px",
                      }}
                    >
                      Edit
                    </Button>
                  </Grid>

                  {this.state.editable ? (
                    <>
                      <Grid container spacing={2} style={{ padding: "20px" }}>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            margin="dense"
                            label=" GRN Voucher No."
                            fullWidth
                            value={this.state.purchaseDetails.grnNumber}
                            onChange={this.handleChanged}
                            name="voucherNumber"
                          ></TextField>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            label="Fiscal Year"
                            name="fiscalYear"
                            value={this.state.purchaseDetails.fiscalYear}
                            onChange={this.handleChanged}
                          ></TextField>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            variant="outlined"
                            select
                            margin="dense"
                            fullWidth
                            label="Period"
                            name="period"
                            value={this.state.purchaseDetails.period}
                            onChange={this.handlePeriodChange}
                          >
                            {this.state.periodList.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                            id="outlined-dense"
                            label="GRN Date"
                            name="grnDate"
                            value={this.state.purchaseDetails.grnDate}
                            onChange={this.handleDateChange}
                            InputLabelProps={{shrink: true}}
                            autoOk={true}
                            format="yyyy-MM-dd"
                            fullWidth
                            margin="dense"
                            inputVariant="outlined"
                          />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Received By - Company"
                            name="receivedBy"
                            value={this.state.purchaseDetails.receivedBy}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Company Address"
                            name="receivedAddress"
                            value={this.state.purchaseDetails.receivedAddress}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Company PAN"
                            name="reveivedPan"
                            value={this.state.purchaseDetails.receivedPan}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            select
                            id="outlined-dense"
                            label="Received From - Supplier"
                            name="receivedFrom"
                            value={this.state.purchaseDetails.receivedFrom}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            onChange={this.handleTextChangeSupplier}
                          >
                            {this.state.supplierList.map((value) => (
                              <MenuItem key={value.id} value={value.name}>
                                {value.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Supplier Address"
                            name="supplierAddress"
                            value={this.state.purchaseDetails.supplierAddress}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Supplier PAN"
                            name="supplierPan"
                            value={this.state.purchaseDetails.supplierPan}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            id="outlined-dense"
                            label="Supplier Invoice No."
                            name="invoiceNumber"
                            value={this.state.purchaseDetails.invoiceNumber}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            onChange={this.handleChanged}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            id="outlined-dense"
                            label="Purchase Order Number"
                            name="purchaseOrderNumber"
                            value={
                              this.state.purchaseDetails.purchaseOrderNumber
                            }
                            margin="dense"
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChanged}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            id="outlined-dense"
                            label="Transport No."
                            name="transportNumber"
                            value={this.state.purchaseDetails.transportNumber}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            onChange={this.handleChanged}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            id="outlined-dense"
                            fullWidth
                            label="Chalan Number"
                            name="chalanNumber"
                            value={this.state.purchaseDetails.chalanNumber}
                            margin="dense"
                            variant="outlined"
                            onChange={this.handleChanged}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="outlined-dense"
                            label="Warehouse"
                            name="warehouse"
                            value={this.state.purchaseDetails.warehouse}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            onChange={this.handleChanged}
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            id="outlined-dense"
                            label="Received By - User"
                            name="receivedByName"
                            value={this.state.purchaseDetails.receivedByName}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            onChange={this.handleChanged}
                          />
                        </Grid>
                      </Grid>

                      <Divider
                        style={{ marginTop: "15px", marginBottom: "5px" }}
                      />

                      <Grid container justify="center" spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Search
                            input={{ fluid: true }}
                            loading={this.state.isLoading}
                            placeholder="Search by Item Code or Item Name"
                            style={{ marginTop: "15px", maxHeight: "500px" }}
                            onSearchChange={_.debounce(this.handleSearch, 500, {
                              leading: true,
                            })}
                            results={this.state.rowRefs}
                            onResultSelect={this.handleItemSelect}
                          />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2}>
                          <TextField
                            placeholder="Order Quantity"
                            label="Order Qty."
                            style={{ width: "100%", marginTop: "10px" }}
                            type="number"
                            name="orderQuantity"
                            onChange={this.handleQuantityChange}
                          ></TextField>
                        </Grid>

                        <Grid item xs={4} sm={4} md={2}>
                          <TextField
                            placeholder="Received Quantity"
                            label="Received Qty."
                            style={{ width: "100%", marginTop: "10px" }}
                            type="number"
                            name="receivedQuantity"
                            onChange={this.handleQuantityChange}
                          ></TextField>
                        </Grid>

                        <Grid item xs={4} sm={4} md={2}>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={this.handleItemAdd}
                            style={{
                              minWidth: "60px",
                              margin: "auto",
                              height: "38px",
                              marginTop: "15px",
                            }}
                          >
                            +Add
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2} style={{ padding: "20px" }}>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            margin="dense"
                            label=" GRN Voucher No."
                            fullWidth
                            value={this.state.purchaseDetails.grnNumber}
                            name="voucherNumber"
                          ></TextField>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            label="Fiscal Year"
                            name="fiscalYear"
                            value={this.state.purchaseDetails.fiscalYear}
                          ></TextField>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            label="Period"
                            name="period"
                            value={this.state.purchaseDetails.period}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="GRN Date"
                            name="grnDate"
                            value={this.state.purchaseDetails.grnDate}
                            format="yyyy-MM-dd"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Received By - Company"
                            name="receivedBy"
                            value={this.state.purchaseDetails.receivedBy}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Company Address"
                            name="receivedAddress"
                            value={this.state.purchaseDetails.receivedAddress}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Company PAN"
                            name="receivedPan"
                            value={this.state.purchaseDetails.receivedPan}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Received From - Supplier"
                            name="receivedFrom"
                            value={this.state.purchaseDetails.receivedFrom}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Supplier Address"
                            name="supplierAddress"
                            value={this.state.purchaseDetails.supplierAddress}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Supplier PAN"
                            name="supplierPan"
                            value={this.state.purchaseDetails.supplierPan}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Supplier Invoice No."
                            name="invoiceNumber"
                            value={this.state.purchaseDetails.invoiceNumber}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Purchase Order Number"
                            name="purchaseOrderNumber"
                            value={
                              this.state.purchaseDetails.purchaseOrderNumber
                            }
                            margin="dense"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Transport No."
                            name="transportNumber"
                            value={this.state.purchaseDetails.transportNumber}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            fullWidth
                            label="Chalan Number"
                            name="chalanNumber"
                            value={this.state.purchaseDetails.chalanNumber}
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Warehouse"
                            name="warehouse"
                            value={this.state.purchaseDetails.warehouse}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            id="outlined-dense"
                            label="Received By - User"
                            onChange={this.handleTextChange}
                            name="receivedByName"
                            value={this.state.purchaseDetails.receivedByName}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Divider
                        style={{ marginTop: "15px", marginBottom: "5px" }}
                      />

                      {/*  <Grid container justify="center" spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Search
                            input={{ fluid: true }}
                            loading={this.state.isLoading}
                            placeholder="Search by Item Code or Item Name"
                            style={{ marginTop: "15px", maxHeight: "500px" }}
                          />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2}>
                          <TextField
                            placeholder="Order Quantity"
                            label="Order Qty."
                            style={{ width: "100%", marginTop: "10px" }}
                            type="number"
                            name="orderQuantity"
                          ></TextField>
                        </Grid>

                        <Grid item xs={4} sm={4} md={2}>
                          <TextField
                            placeholder="Received Quantity"
                            label="Received Qty."
                            style={{ width: "100%", marginTop: "10px" }}
                            type="number"
                            name="receivedQuantity"
                          ></TextField>
                        </Grid>

                        <Grid item xs={4} sm={4} md={2}>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            style={{
                              minWidth: "60px",
                              margin: "auto",
                              height: "38px",
                              marginTop: "15px",
                            }}
                          >
                            +Add
                          </Button>
                        </Grid>
                      </Grid> */}
                    </>
                  )}

                  <Grid container style={{ width: "98%", margin: "auto" }}>
                    <Typography
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        fontSize: "19px",
                      }}
                    >
                      Item List
                    </Typography>

                    <Grid container justify="center">
                      <div
                        className="ag-theme-balham"
                        style={{
                          height: "25vh",
                          width: "100%",
                        }}
                      >
                        <AgGridReact
                          columnDefs={this.state.columnDefs}
                          rowData={this.state.rowData}
                          animateRows={true}
                          rowSelection={this.state.rowSelection}
                          onGridReady={this.onGridReady}
                          // onCellValueChanged={this.onCellValueChanged.bind(
                          //   this
                          // )}
                          overlayLoadingTemplate={
                            this.state.overlayLoadingTemplate
                          }
                          overlayNoRowsTemplate={
                            this.state.overlayNoRowsTemplate
                          }
                        ></AgGridReact>
                      </div>

                      <Grid container justify="flex-end">
                        <Typography
                          style={{ fontSize: "17px", fontWeight: "bold" }}
                        >
                          Grand Total:{this.state.purchaseDetails.grandTotal}
                        </Typography>
                      </Grid>
                      <Grid container justify="center">
                        {this.state.commentError ? (
                          <TextField
                            id="standard-with-placeholder"
                            label="Comment"
                            variant="outlined"
                            name="comment"
                            fullWidth
                            error
                            helperText="Comment field is required"
                            onChange={this.onChanged}
                            value={this.state.purchaseDetails.comment}
                            margin="normal"
                          />
                        ) : (
                          <TextField
                            id="standard-with-placeholder"
                            label="Comment"
                            variant="outlined"
                            name="comment"
                            fullWidth
                            onChange={this.onChanged}
                            value={this.state.purchaseDetails.comment}
                            margin="normal"
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid container justify="center">
                      {this.state.purchaseDetails.status === "Pending" ? (
                        <Button
                          variant="outlined"
                          size="large"
                          color="inherit"
                          onClick={() => {
                            this.handleSubmit("Checked");
                          }}
                          style={buttonStyle}
                        >
                          Check
                        </Button>
                      ) : null}

                      {this.state.purchaseDetails.status === "Checked" ? (
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          onClick={() => {
                            this.handleSubmit("Approved");
                          }}
                          style={buttonStyle}
                        >
                          Approve
                        </Button>
                      ) : null}

                      {this.state.editable ? (
                        <Button
                          variant="outlined"
                          size="large"
                          color="secondary"
                          onClick={() => {
                            this.handleUpdate();
                          }}
                          style={buttonStyle}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          size="large"
                          color="secondary"
                          disabled
                          style={buttonStyle}
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewGoodsReceivedButton);
