import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography
} from "@material-ui/core";
import { Label } from "semantic-ui-react";

class CreditNoteVoucherPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
    creditNoteNumber,
      date,
      salesInvoiceNumber,
      invoiceTotal,
      salesRepresentative,
      reference,
      customerIdNumber,
      customerName,
      customerAddress,
      customerContact,
      creditNoteDebitLine,
      creditNoteCreditLine,
      } = this.props.data;
  

    return (
      <Grid style={{margin:0,padding:0}} container justify="center">
        <Card
          style={{
            width: "100vw",
            marginBottom: "100px"
          }}
        >
          <CardContent>
            <Grid
            spacing={2}
              container
              justify="center"
             
            >
              <Grid   container justify="space-between">
                <Grid item >
                  <Typography>Date: <span style={{fontStyle:"italic"}}>{date}</span> </Typography>
                 
                </Grid>

                <Grid item >
                  <Typography>Voucher Number:<span style={{fontStyle:"italic"}}>{creditNoteNumber}</span> </Typography>
                
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item>
                  <Typography>Invoice Number:<span style={{fontStyle:"italic"}}>{salesInvoiceNumber}</span> </Typography>
                </Grid>

                <Grid item >
                  <Typography>Invoice Total:<span style={{fontStyle:"italic"}}> {invoiceTotal}</span></Typography>
                </Grid>
              </Grid>
              <Grid container justify="space-between">
                <Grid item >
               <Typography> Customer Name: <span style={{fontStyle:"italic"}}>{customerName}</span></Typography> 
                </Grid>

                <Grid item >
                <Typography> Customer Id Number:<span style={{fontStyle:"italic"}}>{customerIdNumber}</span></Typography> 
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item >
               <Typography> Customer Address: <span style={{fontStyle:"italic"}}>{customerAddress}</span></Typography> 
                </Grid>

                <Grid item >
                <Typography> Customer Contact:<span style={{fontStyle:"italic"}}>{customerContact}</span></Typography> 
                </Grid>
              </Grid>

              <Grid container justify="space-between">
                <Grid item >
                  <Typography>Sales Representative :<span style={{fontStyle:"italic"}}>{salesRepresentative} </span></Typography>
                </Grid>
                <Grid item >
                  <Typography>Sales Reference:<span style={{fontStyle:"italic"}}>{reference} </span></Typography>
                </Grid>
              </Grid>

            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: 50, fontSize: 18 }}
            >
              <Label>Credit Note Voucher</Label>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: 30, fontSize: 18 }}
            >
              <TableContainer >
                <Table aria-label="simple table">
                  <TableHead style={{borderTop:"0.01em solid #949698"}} >
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell align="center">Voucher Type</TableCell>
                      <TableCell align="right">Narration</TableCell>
                      <TableCell align="right">Cost Center</TableCell>
                      <TableCell align="right">Account Code</TableCell>
                      <TableCell align="right">Sub Account</TableCell>
                      <TableCell align="right">Currency</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {creditNoteDebitLine.map((row,i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {i+1}
                        </TableCell>
                        <TableCell align="center">Debit</TableCell>
                        <TableCell align="left">{row.narration}</TableCell>
                        <TableCell align="center">{row.costCenter}</TableCell>
                        <TableCell align="center">{row.accountCode}</TableCell>
                        <TableCell align="center">{row.subAccount}</TableCell>
                        <TableCell align="center">{row.currency}</TableCell>
                        <TableCell align="right">{parseFloat(row.amount).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableBody>
                    {creditNoteCreditLine.map((row,i)=> (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                        {i + 1 + creditNoteDebitLine.length}
                        </TableCell>
                        <TableCell align="center">Credit</TableCell>
                        <TableCell align="left">{row.narration}</TableCell>
                        <TableCell align="center">{row.costCenter}</TableCell>
                        <TableCell align="center">{row.accountCode}</TableCell>
                        <TableCell align="center">{row.subAccount}</TableCell>
                        <TableCell align="center">{row.currency}</TableCell>
                        <TableCell align="right">{parseFloat(row.amount).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default CreditNoteVoucherPrint;
