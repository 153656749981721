import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  withStyles,
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../../utils/UserContext";
import AddWarehouseModal from "./AddWarehouseModal";
import EditWarehouse from "./EditWarehouse";

const styles = (theme) => ({
  root: {
    height: "540px",
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
  smButtonPosition: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: "10px",
      marginTop: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "0px",
      marginBottom: "10px",
      marginTop: "10px",
    },
  },
});

class WarehouseDetails extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: "Code",
          field: "code",
          sortable: true,
          resizable: true,
          minWidth: 80,
        },
        {
          headerName: "Name",
          field: "name",
          resizable: true,
          minWidth: 150,
        },
        {
          headerName: "Address",
          field: "address",
          resizable: true,
          minWidth: 120,
        },
        {
          headerName: "Remarks",
          field: "remarks",
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "Action",
          cellRendererFramework: EditWarehouse,
          cellRendererParams: {
            updateData: this.updateTable,
            setSnackbar: this.setSnackBar,
          },
          resizable: true,
          minWidth: 50,
        },
      ],
      rowData: [],
      open: false,
      data: {
        name: "",
        code: "",
        remarks: "",
        address: "",
      },
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  componentDidMount() {
    Axios.get("pos/warehouse/getAllWarehouses")
      .then((res) => {
        console.log(res);
        var rowAllData = res.data;

        console.log(rowAllData);

        this.setState({ rowData: rowAllData });
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  updateTable = () => {
    Axios.get("pos/warehouse/getAllWarehouses")
      .then((res) => {
        console.log(res);
        var rowAllData = res.data;

        console.log(rowAllData);

        this.setState({ rowData: rowAllData });
        this.gridApi.setRowData(rowAllData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = () => {
    var obj = {
      name: this.state.data.name,
      code: this.state.data.code,
      address: this.state.data.address,
      remarks: this.state.data.remarks,
    };

    Axios.post("/pos/warehouse/addWarehouse", obj)
      .then((res) => {
        console.log(res);
        this.updateTable();
        this.handleClose();
        this.setState({
          data: [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleValueChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  componentWillUpdate() {
    this.daListener();
  }

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  handleOpen = () => {
    Axios.get("pos/warehouse/getAutoCode").then(async (res) => {
      await this.setState({ data: { code: res.data } });
    });
    this.setState({ open: true });
    // this.getWarehouseCode();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { children, value, index, classes, ...other } = this.props;

    return (
      <div>
        <Card
          role="tabpanel"
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent style={{ padding: "0px" }}>
            <Grid container justify="center">
              <Typography variant="h6" component="h2">
                Warehouse Details
              </Typography>
            </Grid>

            <Grid container justify="center" style={{ padding: "20px" }}>
              <div
                className="ag-theme-balham"
                style={{
                  height: "410px",
                  width: "100%",
                  padding: 5,
                }}
              >
                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleOpen}
                    className={classes.smButtonPosition}
                  >
                    Add Entry
                  </Button>
                </Grid>
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onGridReady={this.onGridReady}
                  onFirstDataRendered={this.firstDataRendered}
                  overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                  overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                ></AgGridReact>
              </div>
            </Grid>
          </CardContent>
        </Card>

        <AddWarehouseModal
          open={this.state.open}
          handleClose={this.handleClose}
          updateTable={this.updateTable}
          data={this.state.data}
        />
      </div>
    );
  }
}

export default withStyles(styles)(WarehouseDetails);
