import React, { Component } from "react";
import {
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  Container,
  Button,
  TextField,
  withStyles,
  MenuItem,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { Input, Dropdown } from "semantic-ui-react";
import { parseISO, isAfter, isBefore, format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Axios from "axios";
import DeleteButton from "../components/InterfaceElements/DeleteButton";
import dateFormat from "dateformat";
import UserContext from "../utils/UserContext";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import VoucherNumberRestriction from "../components/InterfaceElements/VoucherNumberRestriction";

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "170px",
      marginTop: "40px",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
      marginTop: "10px",
      margin: 15,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "10px",
      marginBottom: "100px",
      margin: 15,
    },
  },
  containerWidth: {
    marginTop: "8px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
  },
  BottomContainerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  bottonTopMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "auto",
    },

    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
      marginBottom: "5px",
    },
  },
});

class Quotation extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          width: 50,
        },
        {
          headerName: "ItemCode",
          field: "code",
          sortable: true,
          resizable: true,
          width: 70,
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          sortable: true,
          resizable: true,
          editable: true,
          width: 70,
        },
        {
          headerName: "Price",
          field: "markedPrice",
          width: 70,
          editable: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 70,
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
          },
          width: 70,
        },
      ],
      periodList: [],
      fyList: [],
      periodDateFrom: "",
      periodDateTo: "",
      currentPeriod: "",
      fiscalYear: "",
      customerRowRefs: [],
      customerData: {
        fullName: "",
        address: "",
        pan: "",
      },
      sn: 0,
      rowRefs: [],
      rowData: [],
      itemDetails: {},
      maxQuantity: 0,
      quantity: 1,
      grandTotal: 0,
      total: 0,
      data: {
        address: "",
        creditMode: "",
        quotationNumber: "",
        customerName: "",
        date: "",
        description: "",
        grandTotal: 0,
        pan: "",
        preparedBy: "",
        quotationDetails: [],
        validUntil: "",
      },
      quotationNoList: [],
      voucherNumberError: false,
      emptyDescription: false,
      tableData: false,
      itemValue: "",
      customerValue: "",
    };
  }

  handleTextChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  onCellValueChanged = (params) => {
    var rowAllData = this.state.rowData;

    console.log(rowAllData);

    let total = 0;

    rowAllData.forEach((element) => {
      if (element.sn === params.data.sn) {
        var obj = element;
        obj.lineTotal =
          obj.quantity * obj.markedPrice - obj.discount * obj.quantity;
      }
    });

    rowAllData.map((val) => (total += parseInt(val.lineTotal)));

    this.setState({ rowData: rowAllData, total: total });
    this.gridApi.setRowData(rowAllData);
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          periodResData: res.data,
        });

        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          fiscalYear: res.data.fiscalYear,
          currentPeriod: res.data.name,
        }));
        this.getPeriodList();
        this.getQuotationList(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleFYChange = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: Fy,
    }));
    this.getPeriodList();
    this.getQuotationList(Fy);
  };

  getPeriodList() {
    var pLists = [];

    // this.state.resData.map(val => Object.keys(val) === this.state.fiscalYear ? pLists = val : null);

    this.state.periodResData.map((val) =>
      Object.keys(val).includes(this.state.fiscalYear)
        ? (pLists = val[this.state.fiscalYear])
        : null
    );

    this.setState((prevState) => ({
      ...prevState,
      periodList: pLists,
      currentPeriod:pLists[pLists.length-1]
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear,pLists[pLists.length-1])
  }

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      currentPeriod: Py,
    }));
    this.getPeriodDateFromTo(this.state.fiscalYear, Py);
  };
  
  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };

  onChangedDate = (date) => {
    let formattedDate = format(new Date(date), "yyyy-MM-dd");
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        date: formattedDate,
      },
    }));
  };

  shouldDisableDate = (date) => {
    let periodFrom = parseISO(this.state.periodDateFrom);
    let periodTo = parseISO(this.state.periodDateTo);
    let currentDate = new Date(date);

    if (isAfter(currentDate, periodTo) || isBefore(currentDate, periodFrom)) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = () => {
    const token = this.context;
    var data = this.state.data;

    if (data.description === "" || data.description === undefined) {
      this.setState({ emptyDescription: true });

      return;
    }

    if (this.state.tableData === false) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No Item Selected",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
      return;
    }

    data = {
      ...data,
      address: this.state.customerData.address,
      customerName: this.state.customerData.fullName,
      pan: this.state.customerData.pan,
      grandTotal: this.state.total,
      quotationDetails: this.state.rowData,
      fiscalYear: this.state.fiscalYear,
      period: this.state.currentPeriod,
    };

    Axios.post("pos/quotation/save", data)
      .then((res) => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });

        this.setState({
          rowData: [],
          data: {
            address: "",
            creditMode: "",
            quotationNumber: "",
            customerName: "",
            description: "",
            grandTotal: 0,
            pan: "",
            preparedBy: "",
            quotationDetails: [],
          },
          customerData: {
            fullName: "",
            address: "",
            pan: "",
          },
          quantity: 1,
          grandTotal: 0,
          total: 0,
          emptyDescription: false,
          tableData: false,
          customerValue: "",
          itemValue: "",
        });
        this.getTodaysDate();
        this.getAllPeriodListing();
      })
      .catch((err) => {
        console.log(err);

        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  getQuotationList = (fiscalYear) => {
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", { "fiscalYear":fiscalYear, "name":"Quotation"})
    .then((res) =>{
      if (res.data === "Not Found") {
        this.setState({ voucherNumberError: true });
      } else {
        this.setState((prevState)=>({
          ...prevState,
          data:{
            ...prevState.data,
            quotationNumber:res.data[0]
          }
      }))
      }
      
  })
  };

  getCustomerList = () => {
    Axios.get("pos/customer/getAllCustomersList")
      .then((res) => {
        console.log(res);
        var rows = [];
        res.data.forEach((element) => {
          const item = {
            key: element.id,
            value: element.customerName,
            text: element.customerName,
          };
          rows.push(item);
        });
        this.setState({ customerRowRefs: rows });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getItemList = () => {
    Axios.get("pos/inventory/getSelectedValues")
      .then((res) => {
        console.log(res);

        var rows = [];

        res.data.forEach((element) => {
          const item = {
            key: element.itemCode,
            value: element.itemCode + element.itemName,
            text: element.itemName + " #" + element.itemCode,
            label: { content: "#" + element.itemCode, tag: true },
          };
          rows.push(item);
        });

        this.setState({ rowRefs: rows });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getAllPeriodListing();
    //this.getCurrentPeriod();
    //this.getQuotationList();
    this.getCustomerList();
    this.getItemList();
    this.getTodaysDate();
  }

  getTodaysDate = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        validUntil: todayDate,
        date: todayDate,
      },
    }));
  };

  // handleChange = (e) => {
  //   let val = e.target.value;
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     //voucherNumber: val,
  //     data: {
  //       ...prevState.data,
  //       quotationNumber: val,
  //     },
  //   }));
  // };

  handleQuantityChange = (evt) => {
    if (evt.target.value < 0) {
      evt.target.value = 0;
    }
    if (evt.target.value > this.state.maxQuantity) {
      evt.target.value = this.state.maxQuantity;
    }

    this.setState({ quantity: parseInt(evt.target.value) });
  };

  deleteRow = (data) => {
    console.log(data);
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      console.log("pos: " + i);
      var obj = rowAllData[i];
      if (obj.sn === data.sn) {
        finalRows.splice(i, 1);

        var totalNow = this.state.total;

        var totalNew = totalNow - obj.price * obj.quantity;

        this.setState({
          total: totalNew,
        });
        break;
      }
    }
  };

  handleCustomerSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    this.setState({
      customerValue: value,
    });

    Axios.get("pos/customer/getCustomer/" + key)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          customerData: {
            ...prevState.customerData,
            fullName: res.data.fullName === null ? "" : res.data.fullName,
            address: res.data.pan === null ? "" : res.data.city,
            pan: res.data.pan === null ? "" : res.data.pan,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleItemSelect = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    this.setState({
      itemValue: value,
    });

    Axios.get("pos/inventory/getInventoryByItemCode/" + key)
      .then((res) => {
        this.setState({
          itemDetails: res.data,
          maxQuantity: res.data.quantity,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handle0Index = (rowAllData, itemData) => {
    this.setState((prevState) => ({ ...prevState, tableData: true }));
    var rowItemData = {
      sn: this.state.sn,
      code: itemData.itemCode,
      name: itemData.itemName,
      quantity: this.state.quantity,
      // taxes: itemData.taxable,
      markedPrice: itemData.markedPrice,
      discount: 0,
      lineTotal: parseInt(itemData.markedPrice) * parseInt(this.state.quantity),
    };

    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.name === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
    }
  };

  handleItemAdd = () => {
    var itemData = this.state.itemDetails;

    if (itemData.itemName === undefined || this.state.quantity === 0) {
      return;
    }

    var rows = this.getAllRows();

    var rowAllData = this.state.rowData;

    var total = this.state.total + itemData.markedPrice * this.state.quantity;

    this.setState({ total });

    if (rows.length === 0) {
      this.handle0Index(rowAllData, itemData);
    } else {
      for (let i = 0; i < rows.length; i++) {
        const element = rowAllData[i];
        if (element.name === itemData.itemName) {
          var newQuantity =
            parseInt(element.quantity) + parseInt(this.state.quantity);
          // var newDiscount = element.discount * newQuantity;
          var rowSpecificData = {
            ...element,
            quantity: newQuantity,
            //  discount: newDiscount,
            lineTotal: element.lineTotal * newQuantity,
          };

          this.setState({
            total: this.state.total + element.lineTotal * this.state.quantity,
          });

          rowAllData[i] = rowSpecificData;
        } else {
          this.handle0Index(rowAllData, itemData);
        }
      }
    }

    this.setState({ sn: this.state.sn + 1 });

    this.setState({ rowData: rowAllData });

    console.log(rowAllData);

    this.gridApi.setRowData(rowAllData);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Quotation" />

          <Container>
            <Card className={classes.root}>
              <CardContent>
                <Grid
                  container
                  justify="center"
                  className={classes.containerWidth}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Dropdown
                      placeholder="Search by Customer Name"
                      fluid
                      value={this.state.customerValue}
                      search
                      ref={(search) => {
                        this.searchInput = search;
                      }}
                      selection
                      onChange={this.handleCustomerSelect}
                      options={this.state.customerRowRefs}
                    ></Dropdown>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      variant="outlined"
                      select
                      margin="dense"
                      fullWidth
                      label="Fiscal Year"
                      name="fiscalYear"
                      onChange={this.handleFYChange}
                      value={this.state.fiscalYear}
                    >
                      {this.state.fyList.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      //select
                      id="outlined-dense"
                      label="Quotation No"
                      name="quotationNumber"
                      fullWidth
                      value={this.state.data.quotationNumber}
                      //onChange={this.handleChange}
                      margin="dense"
                      variant="outlined"
                    >
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      variant="outlined"
                      select
                      margin="dense"
                      fullWidth
                      label="Period"
                      name="period"
                      onChange={this.handlePeriodChange}
                      value={this.state.currentPeriod}
                    >
                      {this.state.periodList.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="Date"
                      value={this.state.data.date}
                      inputVariant="outlined"
                      autoOk={true}
                      format="yyyy-MM-dd"
                      margin="dense"
                      fullWidth
                      minDate={this.state.periodDateFrom}
                      maxDate={this.state.periodDateTo}
                      onChange={this.onChangedDate}
                      shouldDisableDate={this.shouldDisableDate}
                      animateYearScrolling
                    />
                  </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="outlined-dense"
                      label="Prepared By"
                      name="preparedBy"
                      fullWidth
                      value={this.state.data.preparedBy}
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="outlined-dense"
                      label="Customer Address"
                      name="address"
                      fullWidth
                      value={this.state.customerData.address}
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="outlined-dense"
                      label="Customer PAN"
                      name="pan"
                      fullWidth
                      value={this.state.customerData.pan}
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="outlined-dense"
                      label="Valid Until"
                      name="validUntil"
                      fullWidth
                      value={this.state.data.validUntil}
                      type="date"
                      onChange={this.handleTextChange}
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    {this.state.emptyDescription ? (
                      <TextField
                        label="Description"
                        error
                        helperText="Description should not be empty"
                        name="description"
                        value={this.state.data.description}
                        onChange={this.handleTextChange}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        label="Description"
                        name="description"
                        value={this.state.data.description}
                        onChange={this.handleTextChange}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="outlined-dense"
                      label="Credit Mode"
                      name="creditMode"
                      fullWidth
                      value={this.state.data.creditMode}
                      onChange={this.handleTextChange}
                      margin="dense"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />
                <Grid
                  container
                  justify="center"
                  spacing={2}
                  className={classes.BottomContainerWidth}
                >
                  <Grid item xs={12} sm={12} md={7}>
                    <Dropdown
                      placeholder="Search by Item Code or Item Name"
                      fluid
                      fullWidth
                      style={{ marginTop: "15px" }}
                      search
                      value={this.state.itemValue}
                      ref={(search) => {
                        this.searchInput = search;
                      }}
                      selection
                      onChange={this.handleItemSelect}
                      options={this.state.rowRefs}
                    ></Dropdown>
                  </Grid>

                  <Grid item xs={7} sm={8} md={3}>
                    <Input
                      placeholder="Quantity"
                      style={{ marginTop: "15px", width: "100%" }}
                      type="number"
                      //   onChange={this.handleQuantityChange}
                      value={this.state.quantity}
                      onChange={this.handleQuantityChange}
                    ></Input>
                  </Grid>
                  <Grid item xs={5} sm={4} md={2}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={this.handleItemAdd}
                      style={{
                        minWidth: "60px",
                        margin: "auto",
                        height: "38px",
                        marginTop: "15px",
                      }}
                    >
                      +Add
                    </Button>
                  </Grid>

                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "240px",
                      width: "100%",
                      margin: "10px",
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      animateRows={true}
                      rowSelection={this.state.rowSelection}
                      onGridReady={this.onGridReady}
                      onCellValueChanged={this.onCellValueChanged}
                      enableCellChangeFlash={true}
                    ></AgGridReact>
                  </div>
                  <Grid container justify="flex-end" style={{ margin: "10px" }}>
                    <Typography variant="h6">Grand Total:</Typography>

                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Rs. {this.state.total}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="center"
                  className={classes.bottonTopMargin}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleSubmit}
                    style={{
                      minWidth: "100px",
                      width: "20vw",
                      height: "50px",
                      margin: "auto",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </div>
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withStyles(styles)(Quotation);
