import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  withStyles
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import ValidatedSelect from "../InterfaceElements/ValidatedSelect";
import ValidatedTextField from "../InterfaceElements/ValidatedTextField";

const styles = theme => ({
  root: {
    padding: 20,
    paddingBottom: "5px",
    paddingTop: "5px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  },
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%"
    },
    [theme.breakpoints.up("md")]: {
      width: "90%"
    }
  }
});

class AddItem extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      data: {
        inventoryMaster: { id: "" },
        propertyType: "",
        sellingPrice: "",
        costPrice: "",
        majorCategory: "",
        category: "",
        subCategory: "",
        quantityInStock: "",
        warehouse: "",
        taxable: { id: "" },
        supplier: "",
        reorderLevel: "",
        minimumReorderQuantity: "",
        searchBox: ""
      },
      itemCode: "",
      rowRefs: [],
      rowData: [],
      taxCode: [],
      errorStatus: {},
    };
  }

  componentDidMount() {
    Axios.get("pos/inventory/findByItemMasterStatus")
      .then(res => {
        var rows = [];

        this.setState({ rowData: res.data });

        res.data.forEach(element => {
          const item = {
            key: element.itemCode,
            value: element.itemName,
            text: element.itemName + " #" + element.itemCode,
            label: { content: "#" + element.itemCode, tag: true }
          };
          rows.push(item);
        });
        this.setState({ rowRefs: rows });
      })
      .catch(err => {
        console.log(err);
      });

    Axios.get("pos/taxCode/findAll").then(res => {
      this.setState(prevState => ({
        ...prevState,
        taxCode: res.data
      }));
    });
  }

  onChanged = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;

    if (targetName === "minimumReorderQuantity") {
      if (parseInt(value) < 0) value = 0;
    }

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  onTaxChange = e => {
    e.stopPropagation();

    var value = e.target.value;

    console.log({ taxable: value });

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        taxable: {
          id: value
        }
      }
    }));
  };

  handleChange = (event, data) => {
    const { value } = data;
    const { key } = data.options.find(o => o.value === value);
    this.setState(prevState => ({
      ...prevState,
      itemCode: key
    }));

    Axios.get("pos/inventory/getItemDetails/" + key)
      .then(res => {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            inventoryMaster: {
              id: res.data.inventoryId === null ? "" : res.data.inventoryId
            },

            propertyType:
              res.data.propertyType === null ? "" : res.data.propertyType,
            sellingPrice:
              res.data.markedPrice === null ? "" : res.data.markedPrice,
            costPrice: res.data.costPrice === null ? "" : res.data.costPrice,
            majorCategory:
              res.data.majorCategory === null ? "" : res.data.majorCategory,
            category: res.data.category === null ? "" : res.data.category,
            subCategory:
              res.data.subCategory === null ? "" : res.data.subCategory,
            quantityInStock:
              res.data.quantityInStock === null ? "" : res.data.quantityInStock,
            warehouse: res.data.warehouse === null ? "" : res.data.warehouse,
            taxable: "",
            supplier: res.data.supplier === null ? "" : res.data.supplier,
            reorderLevel:
              res.data.reorderLevel === null ? "" : res.data.reorderLevel,
            minimumReorderQuantity: ""
          }
        }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  resetFormValues() {
    this.setState(prevState => ({
      data: {
        inventoryMaster: {
          id: ""
        },
        taxable: {
          id: ""
        },
        propertyType: "",
        sellingPrice: "",
        costPrice: "",
        majorCategory: "",
        category: "",
        subCategory: "",
        quantityInStock: "",
        warehouse: "",
        supplier: "",
        reorderLevel: "",
        minimumReorderQuantity: "",
        searchBox: ""
      }
    }));

    Axios.get("pos/inventory/findByItemMasterStatus")
      .then(res => {
        var rows = [];

        this.setState({ rowData: res.data });

        res.data.forEach(element => {
          const item = {
            key: element.itemCode,
            value: element.itemName,
            text: element.itemName + " #" + element.itemCode,
            label: { content: "#" + element.itemCode, tag: true }
          };
          rows.push(item);
        });
        this.setState({ rowRefs: rows });
      })
      .catch(err => {
        console.log(err);
      });
  }

  validateForm() {
    var toCheck = [
      "sellingPrice",
      "taxable",
      "minimumReorderQuantity"
    ];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach(element => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState(prevState => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus
      }
    }));

    return status;
  }

  handleSubmit = () => {
    const token = this.context;

    if (
      this.state.data.inventoryMaster.id === "" ||
      this.state.data.inventoryMaster.id === undefined
    ) {
      var snackbarProps = {
        open: true,
        variant: "error",
        message: "you should select item first",
        autoHideDuration: 2000
      };

      token.setSnackbarProps({ snackbarProps: snackbarProps });
      return;
    }

    this.validateForm();
    var status = this.validateForm();




    // if (
    //   this.state.data.sellingPrice === "" ||
    //   this.state.data.sellingPrice === undefined
    // ) {
    //   var sellingSnackbarProps = {
    //     open: true,
    //     variant: "error",
    //     message: "You need to specify selling price",
    //     autoHideDuration: 2000
    //   };
    //   token.setSnackbarProps({ snackbarProps: sellingSnackbarProps });
    //   return;
    // }
    // if (
    //   this.state.data.taxable === "" ||
    //   this.state.data.taxable === undefined
    // ) {
    //   var errorSnackbarProps = {
    //     open: true,
    //     variant: "error",
    //     message: "You need to specify tax ",
    //     autoHideDuration: 2000
    //   };
    //   token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
    //   return;
    // }

    if (status) {
      Axios.post("/pos/itemMaster/addItemMaster", this.state.data)
        .then(res => {
          console.log(res);
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
          this.resetFormValues();
        })
        .catch(err => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }

  };

  render() {
    const { children, value, index, classes, ...other } = this.props;

    const textFieldStyle = {
      width: "100%"
    };

    return (
      <div>
        <Card
          role="tabpanel"
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center">
              <Typography variant="h6" component="h2">
                Add Item
              </Typography>
            </Grid>

            <Grid
              container
              spacing={2}
              justify="center"
              className={classes.containerWidth}
            >
              <div
                style={{ width: "70%", minWidth: "220px", marginTop: "8px" }}
              >
                <Dropdown
                  name="searchBox"
                  placeholder="Search by Item Code or Item Name"
                  fluid
                  search
                  onChange={this.handleChange}
                  ref={search => {
                    this.searchInput = search;
                  }}
                  selection
                  options={this.state.rowRefs}
                ></Dropdown>
              </div>
            </Grid>

            <Grid
              container
              spacing={2}
              justify="center"
              className={classes.containerWidth}
            >
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Property Type"
                  type="text"
                  name="propertyType"
                  placeholder=""
                  value={this.state.data.propertyType}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ValidatedTextField
                  id="standard-with-placeholder"
                  label="Selling Price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">NRs.</InputAdornment>
                    )
                  }}
                  type="number"
                  name="sellingPrice"
                  value={this.state.data.sellingPrice}
                  onChange={this.onChanged}
                  error={this.state.errorStatus.sellingPrice}
                  placeholder=""
                  textFieldStyle={{
                    width: "100%",
                    marginTop: "16px"
                  }}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Cost Price"
                  name="costPrice"
                  value={this.state.data.costPrice}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">NRs.</InputAdornment>
                    )
                  }}
                  type="number"
                  placeholder=""
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Major Category"
                  name="majorCategory"
                  value={this.state.data.majorCategory}
                  type="text"
                  placeholder=""
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Category"
                  name="category"
                  value={this.state.data.category}
                  onChange={this.onChanged}
                  type="text"
                  placeholder=""
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Sub Category"
                  name="subCategory"
                  value={this.state.data.subCategory}
                  type="text"
                  placeholder=""
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Quantity in Stock"
                  name="quantityInStock"
                  value={this.state.data.quantityInStock}
                  type="number"
                  placeholder=""
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Warehouse"
                  name="warehouse"
                  value={this.state.data.warehouse}
                  type="text"
                  placeholder=""
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>


              <Grid item xs={12} sm={6} md={4}>
                <ValidatedSelect
                  id="standard-select-currency"
                  select
                  label="Taxable"
                  name="id"
                  onChange={this.onTaxChange}
                  textFieldStyle={{
                    width: "100%",
                    marginTop: "18px"
                  }}
                  error={this.state.errorStatus.taxable}
                  value={this.state.data.taxable.id}
                  InputProps={{ style: { fontSize: 13 } }}
                  InputLabelProps={{ style: { fontSize: 13 } }}
                  margin="normal"
                  SelectProps={{ MenuProps: { style: { width: 200 } } }}
                  optionList={this.state.taxCode}
                  identifier={"code"}
                  valueIdentifier={"id"}
                >
                </ValidatedSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Supplier"
                  name="supplier"
                  value={this.state.data.supplier}
                  type="text"
                  placeholder=""
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Reorder Level"
                  type="text"
                  value={this.state.data.reorderLevel}
                  name="reorderLevel"
                  placeholder=""
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ValidatedTextField
                  id="standard-with-placeholder"
                  label="Minimum Order Quantity"
                  inputProps={{ min: "0" }}
                  type="number"
                  error={this.state.errorStatus.minimumReorderQuantity}
                  value={this.state.data.minimumReorderQuantity}
                  onChange={this.onChanged}
                  name="minimumReorderQuantity"
                  placeholder=""
                  textFieldStyle={{
                    width: "100%",
                    marginTop: "16px"
                  }}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={this.handleSubmit}
                style={{
                  margin: "20px",
                  marginTop: "25px",
                  marginRight: "40px"
                }}
              >
                Add Item
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(AddItem);
