import React from 'react';
import { Button, TextField, Card, CardContent, Typography, Grid, withStyles} from "@material-ui/core";
import Axios from 'axios';

const styles = theme => ({
    root: {
      padding: 20,
      paddingTop: "5px",
      paddingBottom: "5px",
      [theme.breakpoints.down('sm')]: {
        marginBottom: "170px",
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: "100px",
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: "100px",
        
      },
    },
  });

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            newPassword: "",
            confirmPassword: "",
            oldPassword: "",
            passMatch: "",
            data: {
                email: "",
                password: ""
            }
        }
    }

    handleNewPassword = evt => {
        this.setState({ newPassword: evt.target.value })
        if (evt.target.value === "" && this.state.confirmPassword === "") {
            this.setState({ passMatch: "" });
        }
    }

    handleConfirmPassword = evt => {
        this.setState({ confirmPassword: evt.target.value });

        if (this.state.newPassword === "" && evt.target.value === "") {
            this.setState({ passMatch: "" });
        }
        if (this.state.newPassword === "" && this.state.confirmPassword === "") {
            this.setState({ passMatch: "" });
        }
        if (this.state.newPassword !== evt.target.value) {
            this.setState({ passMatch: "Password dont match!" });
        }
        else {
            this.setState({ passMatch: "" });
        }
    }

    handleOldPassword = evt => {
        this.setState({ oldPassword: evt.target.value });
    }

    handleSubmit = () => {

        if (this.state.newPassword !== this.state.confirmPassword) {
            alert("password dont match!");
        }
        else if (this.state.newPassword === this.state.oldPassword) {
            alert("You cannot use the same password as your new password!");
        }
        else
        {

            var obj = {
                email: this.state.oldPassword,
                password: this.state.newPassword
            }


            Axios.put("hrm/changePassword", obj).then(res=> {
                 console.log(res);
            }).catch(err=> {
                 console.log(err);
            });
        }


    }

    

    render() {
        const { value, index,classes, ...other } = this.props;

        return (
                <Grid container justify="center">
                    <Grid item xs={11} lg={6} md={7} sm={10}>
                    <Card
                    className={classes.root}
                    role="tabpanel"
                    style={{width: "100%"}}
                    hidden={value !== index}
                    id={`vertical-tabpanel-${index}`}
                    aria-labelledby={`vertical-tab-${index}`}
                    {...other}
                >
                    <CardContent >
                        <Grid container>
                            <Typography variant="h6" component="h2" paddingBottom="20px">
                                {" "}
                                Change Password{" "}
                            </Typography>
                        </Grid>
                        <Grid style={{ marginTop: "20px" }}>
                            <form name="changePassword">
                                <Grid container justify="center">
                                    <Grid item xs={12}>
                                    <TextField id="filled-basic"
                                        variant="filled"
                                        style={{ width: "100%" }}
                                        onChange={this.handleOldPassword}
                                        label="Old Password"
                                        type="Password"
                                        margin="normal"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <TextField
                                        id="filled-basic"
                                        variant="filled"
                                        style={{ width: "100%" }}
                                        onChange={this.handleNewPassword}
                                        label="New Password"
                                        type="Password"
                                        margin="normal"/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="filled-basic"
                                        variant="filled"
                                        style={{ width: "100%" }}
                                        onChange={this.handleConfirmPassword}
                                        label="Confirm Password"
                                        type="Password"
                                        margin="normal"/>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.passMatch}
                                </Grid>
                                <Grid item>
                                    <Button variant="contained"
                                        onClick={this.handleSubmit}
                                        color="primary" style={{
                                            margin: "20px",
                                            marginTop: "20px",
                                            paddingLeft: "27px",
                                            paddingRight: "27px",
                                            paddingTop: "15px",
                                            paddingBottom: "15px"
                                        }}>Change Password</Button>
                                </Grid>
                                </Grid>
                            </form>
                        </Grid>

                    </CardContent>
                </Card>
                </Grid>
                </Grid>
                


        )
    }

}
export default (withStyles(styles)(ChangePassword));