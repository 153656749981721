import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  TextField,
  Fade,
  Modal,
  Backdrop,
  withStyles,
  IconButton
} from "@material-ui/core";
import Axios from "axios";
import ValidatedTextField from "../../InterfaceElements/ValidatedTextField";
import Close from "@material-ui/icons/Close";
import ViewTaxCode from "./ViewTaxCode";

const styles = theme => ({
  root: {
    height: "540px",
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  },
  smButtonPosition: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: "10px",
      marginTop: "20px"
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "0px",
      marginBottom: "10px",
      marginTop: "10px"
    }
  },
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "90%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "500px"
    }
  }
});

class TaxCodeDetails extends Component {
  constructor(props) {
    super(props);
    const rowIndex = params => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50
        },
        {
          headerName: "Code",
          field: "code",
          sortable: true,
          resizable: true,
          minWidth: 80
        },
        {
          headerName: "Name",
          field: "name",
          resizable: true,
          minWidth: 150
        },
        {
          headerName: "Rate",
          field: "rate",
          resizable: true,
          minWidth: 100
        },
        {
          headerName: "Remarks",
          field: "remarks",
          resizable: true,
          minWidth: 100
        },
        {
          headerName: "Action",
          cellRendererFramework: ViewTaxCode,
          cellRendererParams: {
            updateData: this.updateTable,
            setSnackbar: this.setSnackBar,
          },
          resizable: true,
          minWidth: 50
        }
      ],
      rowData: [],
      open: false,
      data: {
        itemCode: "",
        itemName: "",
        rate: "",
        remarks: ""
      },
      errorStatus: {},
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>'
    };
  }

  updateTable() {
    Axios.get("pos/taxCode/findAll")
      .then(res => {
        this.setState({ rowData: res.data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidMount() {
    Axios.get("pos/taxCode/findAll")
      .then(res => {
        this.setState({ rowData: res.data });
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>"
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>"
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
    this.updateTable();
  }

  handleValueChange = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  validateForm() {
    var toCheck = ["itemName", "itemCode", "rate"];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach(element => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState(prevState => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus
      }
    }));

    return status;
  }

  componentWillUpdate() {
    this.daListener();
  }

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  firstDataRendered = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };
  handleOpen = () => {
    Axios.get("pos/taxCode/getTaxCode")
      .then(res => {
        this.setState({ data: { itemCode: res.data } })
      })
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleSubmit = () => {
    var obj = {
      code: this.state.data.itemCode,
      name: this.state.data.itemName,
      rate: this.state.data.rate,
      remarks: this.state.data.remarks
    };

    let status = this.validateForm();

    if (status) {
      Axios.post("/pos/taxCode/add", obj)
        .then(res => {
          console.log(res);
          this.updateTable();
          this.handleClose();
          this.setState(prevState => ({
            ...prevState,
            data: {
              ...prevState.data,
              itemCode: "",
              itemName: "",
              rate: "",
              remarks: ""
            }
          }))
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  render() {
    const { children, value, index, classes, ...other } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const textFieldStyle = {
      width: "100%"
    };

    return (
      <div>
        <Card
          role="tabpanel"
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center">
              <Typography variant="h6" component="h2">
                Tax Code Details
              </Typography>
            </Grid>

            <Grid container justify="center">
              <div
                className="ag-theme-balham"
                style={{
                  height: "410px",
                  width: "100%",
                  padding: 5
                }}
              >
                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleOpen}
                    className={classes.smButtonPosition}
                  >
                    Add Entry
                  </Button>
                </Grid>
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  onFirstDataRendered={this.firstDataRendered}
                  onGridReady={this.onGridReady}
                  overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                  overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                ></AgGridReact>
              </div>
            </Grid>
          </CardContent>
        </Card>
        <div >
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={modal}
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={this.state.open}>
              <Card className={classes.cardHeight}>
                <CardContent style={{ padding: "0px" }}>
                  <Grid container justify="flex-end">
                    <IconButton onClick={this.handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    justify="center"
                    style={{ padding: "20px" }}
                  >
                    <Grid item xs={12} sm={6}>
                      <ValidatedTextField
                        label="Code"
                        name="itemCode"
                        error={this.state.errorStatus.itemCode}
                        value={this.state.data.itemCode}
                        onChange={this.handleValueChange}
                        textFieldStyle={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ValidatedTextField
                        label="Name"
                        name="itemName"
                        error={this.state.errorStatus.itemName}
                        value={this.state.data.itemName}
                        onChange={this.handleValueChange}
                        textFieldStyle={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ValidatedTextField
                        label="Rate"
                        name="rate"
                        error={this.state.errorStatus.rate}
                        value={this.state.data.rate}
                        onChange={this.handleValueChange}
                        textFieldStyle={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Remarks"
                        name="remarks"
                        value={this.state.data.remarks}
                        onChange={this.handleValueChange}
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container justify="flex-end">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleSubmit}
                      style={{
                        margin: "20px",
                        marginTop: "25px"
                      }}
                    >
                      Add
                  </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Fade>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TaxCodeDetails);
