import React, { Component } from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Modal,
  Fade,
  Backdrop,
  withStyles,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import Axios from "axios";
import Close from "@material-ui/icons/Close";
import ViewAccountButton from "../InterfaceElements/ViewAccountButton";
import UserContext from "../../utils/UserContext";

import ValidatedTextField from "../InterfaceElements/ValidatedTextField";

const styles = (theme) => ({
  root: {
    paddingBottom: "23px",
    paddingTop: "5px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
  smButtonPosition: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: "10px",
      marginTop: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "0px",
      marginBottom: "10px",
      marginTop: "10px",
    },
  },
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
    },
  },
});

class AccountGroup extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      data: {
        name: "",
        code: "",
        under: "",
        underCode: "",
        description: "",
      },
      errorStatus: {},
      primaryGroupData: [],
      columnDefs: [
        {
          headerName: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: "Name",
          field: "name",
          width: 400,
          sortable: true,
          minWidth: 180,
        },
        {
          headerName: "Code",
          field: "code",
          width: 100,
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Under",
          field: "under",
          minWidth: 100,
        },
        {
          headerName: "Description",
          field: "description",
          minWidth: 150,
        },
        {
          headerName: "Status",
          field: "status",
          minWidth: 80,
        },
        {
          headerName: "View",
          cellRendererFramework: ViewAccountButton,
          cellRendererParams: {
            updateData: this.getTableData,
            setSnackbar: this.setSnackBar,
          },
          minWidth: 50,
        },
      ],
      open: false,
      dataShow: {
        under: "",
        underCode: "",
      },
      totalItemCount: 0,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  componentWillUpdate() {
    this.daListener();
  }

  setSnackBar = (snackbarProps) => {
    const token = this.context;

    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  getTableData = () => {
    console.log("Table data func called");
    var rowData = [];

    Axios.get("pos/accountGroup/getAllAccountGroups")
      .then((res) => {
        rowData = res.data;
        console.log(res);

        this.setState({
          rowData: rowData,
          totalItemCount: res.data.length,
        });
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });

        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  };

  componentDidMount() {
    this.getTableData();
  }

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleCloseModal() {
    this.setState({ open: false });
  }

  validateForm() {
    var toCheck = ["name", "code", "under"];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  handleSubmit = () => {
    const token = this.context;

    var status = this.validateForm();

    if (status) {
      Axios.post("pos/accountGroup/addAccountGroup", this.state.data)
        .then((res) => {
          console.log(res);

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          console.log(token);

          this.getTableData();
          this.props.getAccountGroupData();
          this.setState({
            data: {
              name: "",
              code: "",
              under: "",
              underCode: "",
              description: "",
            },
          });
        })
        .catch((err) => {
          var snackbarProps = {
            open: true,
            variant: "error",
            message: err.response.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  onChanged = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;
    var code = "";

    if (targetName === "under") {
      this.props.primaryGroupData.forEach((element) => {
        if (element.name === value) {
          code = element.code;
        }
      });

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          underCode: code,
          [targetName]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [targetName]: value,
        },
      }));
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render(params) {
    const { children, value, index, classes, ...other } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const inputStyles = {
      fontSize: 14,
    };

    const textFieldStyle = {
      width: "100%",
    };

    return (
      <div>
        <Card
          className={classes.root}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center">
              <div
                style={{
                  width: "100%",
                }}
              >
                <Grid
                  container
                  justify="flex-end"
                  style={{
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleOpen}
                    className={classes.smButtonPosition}
                  >
                    Add Entry
                  </Button>
                </Grid>
                <Grid container justify="flex-end">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "51vh",
                      width: "100%",
                      padding: "15px",
                    }}
                  >
                    <h6 style={{ margin: "5px" }}>
                      {" "}
                      Total Records : {this.state.totalItemCount}
                    </h6>
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      onGridReady={this.onGridReady}
                      onFirstDataRendered={this.firstDataRendered}
                      paginationAutoPageSize={true}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                    ></AgGridReact>
                  </div>
                </Grid>
              </div>
            </Grid>
          </CardContent>
        </Card>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          errorStatus={this.state.errorStatus}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ overflowY: "auto" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container spacing={2} justify="center">
                  <Grid item xs={12} sm={6}>
                    <ValidatedTextField
                      label="Name"
                      name="name"
                      error={this.state.errorStatus.name}
                      value={this.state.data.name}
                      onChange={this.onChanged}
                      textFieldStyle={textFieldStyle}
                      inputStyles={inputStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValidatedTextField
                      label="Code"
                      name="code"
                      value={this.state.data.code}
                      error={this.state.errorStatus.code}
                      onChange={this.onChanged}
                      textFieldStyle={textFieldStyle}
                      inputStyles={inputStyles}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {this.state.errorStatus.under ? (
                      <TextField
                        name="under"
                        label="Under"
                        error
                        select
                        fullWidth
                        value={this.state.data.under}
                        helperText={"Under should be selected"}
                        style={textFieldStyle}
                        onChange={this.onChanged}
                        InputProps={{ style: { fontSize: 13 } }}
                        InputLabelProps={{ style: inputStyles }}
                        SelectProps={{
                          MenuProps: {
                            style: { width: 200 },
                          },
                        }}
                        margin="normal"
                      >
                        {this.props.primaryGroupData.map((item, i) => (
                          <MenuItem key={i} value={item.name}>
                            {item.code + " - " + item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        name="under"
                        label="Under"
                        select
                        value={this.state.data.under}
                        style={textFieldStyle}
                        onChange={this.onChanged}
                        InputProps={{ style: { fontSize: 13 } }}
                        InputLabelProps={{ style: inputStyles }}
                        SelectProps={{
                          MenuProps: {
                            style: { width: 200 },
                          },
                        }}
                        margin="normal"
                      >
                        {this.props.primaryGroupData.map((item, i) => (
                          <MenuItem key={i} value={item.name}>
                            {item.code + " - " + item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Under Code"
                      name="under_code"
                      disabled={true}
                      placeholder=""
                      onChange={this.onChanged}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      style={textFieldStyle}
                      value={this.state.data.underCode}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Description"
                      placeholder=""
                      name="description"
                      onChange={this.onChanged}
                      value={this.state.data.description}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      style={textFieldStyle}
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleSubmit}
                    style={{
                      margin: "20px",
                      marginTop: "25px",
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(AccountGroup);
