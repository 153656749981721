import React, { Component } from "react";
import { Tabs, Tab, Grid, withStyles } from "@material-ui/core";
import PrimaryGroup from "../components/AccountMaster/PrimaryGroup";
import SubLedgerMaster from "../components/AccountMaster/SubLedgerMaster";
import AccountGroup from "../components/AccountMaster/AccountGroup";
import LedgerMaster from "../components/AccountMaster/LedgerMaster";
import Axios from "axios";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";

const styles = theme => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px"
    }
  }
});

class AccountMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      primaryGroupData: [],
      accountGroupData: [],
      ledgerMasterData: []
    };
  }

  getPrimaryGroupData = () => {
    Axios.get("pos/primaryGroup/getAllPrimaryGroups")
      .then(res => {
        this.primaryGroupData = res.data;
        console.log(res);
        this.setState({ primaryGroupData: this.primaryGroupData });
      })
      .catch(err => {
        console.log(err);
      });
  };

  getAccountGroupData = () => {
    Axios.get("pos/accountGroup/getAllAccountGroups")
      .then(res => {
        this.accountGroupData = res.data;
        console.log(res);
        this.setState({ accountGroupData: this.accountGroupData });
      })
      .catch(err => {
        console.log(err);
      });
  };

  getLedgerMasterData = () => {
    Axios.get("pos/ledgerMaster/getAllLedgerMasters")
      .then(res => {
        this.ledgerMasterData = res.data;
        console.log(res);
        this.setState({ ledgerMasterData: this.ledgerMasterData });
      })
      .catch(err => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getAccountGroupData();
    this.getLedgerMasterData();
    this.getPrimaryGroupData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Account Master" />

          <Grid container justify="center">
            <Tabs
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
              className={classes.tabMargin}
              style={{ marginBottom: "7px" }}
            >
              <Tab style={{ fontSize: "12px" }} label="Primary Group" {...a11yProps(0)} />
              <Tab style={{ fontSize: "12px" }} label="Account Group" {...a11yProps(1)} />
              <Tab style={{ fontSize: "12px" }} label="Ledger Master" {...a11yProps(2)} />
              <Tab style={{ fontSize: "12px" }} label="Sub-Ledger Master" {...a11yProps(3)} />
            </Tabs>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={11} sm={11} md={9}>
              <PrimaryGroup value={this.state.value} index={0}></PrimaryGroup>
              <AccountGroup
                value={this.state.value}
                index={1}
                primaryGroupData={this.state.primaryGroupData}
                getAccountGroupData={this.getAccountGroupData}
              ></AccountGroup>
              <LedgerMaster
                value={this.state.value}
                index={2}
                accountGroupData={this.state.accountGroupData}
                getLedgerMasterData={this.getLedgerMasterData}
              ></LedgerMaster>
              <SubLedgerMaster
                value={this.state.value}
                index={3}
                ledgerMasterData={this.state.ledgerMasterData}
              ></SubLedgerMaster>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AccountMaster);
