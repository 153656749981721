import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  TextField,
  withStyles,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Divider,
  Hidden,
} from "@material-ui/core";
import { Search, Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormat from "dateformat";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import adbs from "ad-bs-converter";

const styles = (theme) => ({
  tabMargin: {
    height: "auto",
    padding: 15,
    [theme.breakpoints.down("md")]: {
      marginTop: "98px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class InventoryDetails extends Component {
  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    
    this.timeout = 0;
    this.state = {
      loading: false,
      showDetails: false,
      filterShow: false,
      isLoading: false,
      rowRefs: [],
      columnDefs: [
        {
          headerName: "S.No.",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: " Date",
          field: "transactionDate",
          resizable: true,
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: " Miti",
          field: "transactionMiti",
          resizable: true,
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },

        {
          headerName: "Item Name",
          field: "itemName",
          resizable: true,
          minWidth: 100,
        },
        {
          headerName: "Fiscal Year ",
          field: "fiscalYear",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Period",
          field: "period",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Transaction Type",
          field: "transactionType",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Transaction Number",
          field: "transactionNumber",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Customer/Supplier",
          field: "customerSupplier",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Received/Sold By",
          field: "receivedSoldBy",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Invoice Number",
          field: "invoiceNumber",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Comment",
          field: "comment",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Purchase Qty",
          field: "purchaseCount",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Sales Qty",
          field: "salesCount",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "GRN Amount",
          field: "grnAmount",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "GIN Amount",
          field: "ginAmount",
          resizable: true,
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Running Balance",
          field: "runningBalance",
          resizable: true,
          sortable: true,
          minWidth: 120,
        },
      ],
      rowData: [],
      origRowData: [],
      periodList: [],
      resData: [],
      currentPeriod: "",
      fyList: [],
      currentFy: "",
      fiscalYear: "",
      itemCount: 0,
      filterTerm: "",
      panelExpanded: false,
      itemCode: "",
      itemCodeList: [],
      filterFields: {
        dateFrom: "",
        dateTo: "",
        periodFrom: "",
        periodTo: "",
      },
    };
  }

  handleFilterChange = (e) => {
    let targetName = e.target.name;
    let targetValue = e.target.value;

    let newData = [];

    this.state.origRowData.map((val) =>
      val[targetName] === targetValue ? newData.push(val) : null
    );
    this.setState((prevState) => ({
      ...prevState,
      rowData: newData,
    }));
  };

  // handlePeriodChange = async (e) => {
  //   let period = e.target.value;

  //   await this.setState((prevState) => ({
  //     ...prevState,
  //     currentPeriod: period,
  //   }));

  // };

  handleFiscalYearChange = async (e) => {
    let fiscalYear = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      //fiscalYear: fiscalYear,
      currentFy:fiscalYear
    }));
  };

  getAllPeriodListing() {
    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        console.log("listss");
        console.log(res.data);
        var data = [];
        var fyList = [];
        res.data.forEach((element) => {
          data.push(element.name);
          fyList.push(element.fiscalYear);
        });
        this.setState({
          periodList: [...new Set(data)],
          fyList: [...new Set(fyList)],
          resData: res.data,
        });
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getCurrentPeriod = () => {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          currentFy: res.data.fiscalYear,
          filterFields: {
            ...prevState.filterFields,
            periodFrom: res.data.name,
            periodTo: res.data.name,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    
    this.getAllPeriodListing();
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: todayDate,
        dateTo: todayDate,
      },
    }));

   /*  Axios.get("/pos/inventory/findItemCodeOnly").then((res)=>{
      console.log(res.data)

      this.setState((prevState)=>({
        ...prevState,
        itemCodeList: res.data,
      }));
    }) */

  }
  handleSearch = (e, { value }) => {
    this.setState({ isLoading: true, itemCode: value });

    let newOptions = [];

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(async () => {
      await Axios.get("pos/inventory/searchItems/" + value)
        .then(async (res) => {
          res.data.map((val) =>
            newOptions.push({
              title: val.itemCode,
              description: val.itemName,
            })
          );

          await this.setState((prevState) => ({
            ...prevState,
            isLoading: false,
            rowRefs: newOptions,
            //allData: res.data,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };

  onItemCodeChanged  = async (event, data) => {
    const { result } = data;

    this.setState((prevState) => ({
      ...prevState,
      itemCode: result.title,
    }));

  };

  onFilterTermChange = (e) => {
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      filterTerm: value,
    }));
  };

  handlePeriodFromChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodFrom: period,
      },
    }));
  };

  handlePeriodToChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodTo: period,
      },
    }));
  };

 /*  handleFiscalYearApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.getInventoryListing({
      fiscalYear: this.state.currentFy,
      periodFrom: this.state.filterFields.periodFrom,
      periodTo: this.state.filterFields.periodTo,
    });
  }; */

  handleFromDate = (event) => {
    var date = event.target.value;
    let formattedDateFrom = dateFormat(date, "yyyy-mm-dd");
    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: formattedDateFrom,
      },
    }));
  };

  handleToDate = (event) => {
    var date = event.target.value;
    let formattedDateTo = dateFormat(date, "yyyy-mm-dd");
    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateTo: formattedDateTo,
      },
    }));
  };

  /* handleDateRangeApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.getInventoryListing({
      dateFrom: this.state.filterFields.dateFrom,
      dateTo: this.state.filterFields.dateTo,
    });
  }; */

  handlePanelChange = (_e, isExpanded) => {
    this.setState({
      panelExpanded: isExpanded,
    });
  };

  getInventoryListing = async () => {

    let obj;

    this.setState({ 
      loading: true, 
      showDetails: true,
      panelExpanded: false,
     });

     if(this.state.itemCode !== "" ) {
     if (this.state.filterTerm === "fiscalYear") {
      obj = {
        fiscalYear: this.state.currentFy,
        periodFrom: this.state.filterFields.periodFrom,
        periodTo: this.state.filterFields.periodTo,
        itemCode: this.state.itemCode,
      };
    } else {
      obj = {
        dateFrom: this.state.filterFields.dateFrom,
        dateTo: this.state.filterFields.dateTo,
        itemCode: this.state.itemCode,
      };
    }
  } else {
    if (this.state.filterTerm === "fiscalYear") {
      obj = {
        fiscalYear: this.state.currentFy,
        periodFrom: this.state.filterFields.periodFrom,
        periodTo: this.state.filterFields.periodTo,
      };
    } else {
      obj = {
        dateFrom: this.state.filterFields.dateFrom,
        dateTo: this.state.filterFields.dateTo,
      };
    }
  }

    Axios.post("/pos/inventory/getItemTransaction", obj)
      .then((res) => {
        this.setState({ loading: false });
        var rowData = [];
        res.data.details.forEach((element) => {
          let transactionDate = element.transactionDate.split("-");

          let miti = adbs.ad2bs(
            transactionDate[0] +
              "/" +
              transactionDate[1] +
              "/" +
              transactionDate[2]
          );

          let transactionMiti =
            miti.en.year + "-" + miti.en.month + "-" + miti.en.day;

          var obj = {
            itemCode: element.itemCode,
            transactionDate: element.transactionDate,
            transactionMiti: transactionMiti,
            transactionType: element.transactionType,
            fiscalYear: element.fiscalYear,
            period: element.period,
            purchaseCount: element.purchaseCount,
            salesCount: element.sellCount,
            transactionNumber: element.transactionNumber,
            category: element.category,
            itemName: element.itemName,
            customerSupplier: element.customerSupplier,
            receivedSoldBy: element.receivedSoldBy,
            invoiceNumber:element.invoiceNumber,
            comment:element.comment,
            amount: element.amount,
            ginAmount: element.ginAmount,
            grnAmount: element.grnAmount,
            runningBalance: element.runningBalance,
          };
          rowData.push(obj);
        });

        this.setState({
          rowData: rowData,
          origRowData: rowData,
        });

        //---------EXCE DATA ---------
        let excel = [];
        if (this.state.filterTerm === "fiscalYear") {
          let object = {
            sn: "",
            transactionDate: "Fiscal Year",
            miti: this.state.fiscalYear,
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            customerSupplier: "",
            receivedSoldBy:"",
            invoiceNumber:"",
            comment:"",
            purchaseCount: "",
            salesCount: "",
            grnAmount: "",
            ginAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "Period From",
            miti: this.state.filterFields.periodFrom,
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            customerSupplier: "",
            receivedSoldBy:"",
            invoiceNumber:"",
            comment:"",
            purchaseCount: "",
            salesCount: "",
            grnAmount: "",
            ginAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "Period To",
            miti: "this.state.filterFields.periodTo",
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            customerSupplier: "",
            receivedSoldBy:"",
            invoiceNumber:"",
            comment:"",
            purchaseCount: "",
            salesCount: "",
            grnAmount: "",
            ginAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "",
            miti: "",
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            customerSupplier: "",
            receivedSoldBy:"",
            invoiceNumber:"",
            comment:"",
            purchaseCount: "",
            salesCount: "",
            grnAmount: "",
            ginAmount: "",
            runningBalance: "",
          };
          excel.push(object);
        } else if (this.state.filterTerm === "date") {
          let object = {
            sn: "",
            transactionDate: "Date Range",
            miti: "",
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            customerSupplier: "",
            receivedSoldBy:"",
            invoiceNumber:"",
            comment:"",
            purchaseCount: "",
            salesCount: "",
            grnAmount: "",
            ginAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "Date From",
            miti: this.state.filterFields.dateFrom,
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            customerSupplier: "",
            receivedSoldBy:"",
            invoiceNumber:"",
            comment:"",
            purchaseCount: "",
            salesCount: "",
            grnAmount: "",
            ginAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "Date To",
            miti: this.state.filterFields.dateTo,
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            customerSupplier: "",
            receivedSoldBy:"",
            invoiceNumber:"",
            comment:"",
            purchaseCount: "",
            salesCount: "",
            grnAmount: "",
            ginAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            transactionDate: "",
            miti: "",
            itemCode: "",
            itemName: "",
            fiscalYear: "",
            period: "",
            category: "",
            transactionType: "",
            transactionNumber: "",
            customerSupplier: "",
            receivedSoldBy:"",
            invoiceNumber:"",
            comment:"",
            purchaseCount: "",
            salesCount: "",
            grnAmount: "",
            ginAmount: "",
            runningBalance: "",
          };
          excel.push(object);
        } else {
          return null;
        }

        let object = {
          sn: "S.No",
          transactionDate: "Date",
          miti: "Miti",
          itemCode: "Item Code",
          itemName: "Item Name",
          fiscalYear: "Fiscal Year",
          period: "Period",
          category: "Category",
          transactionType: "Transaction Type",
          transactionNumber: "Transaction Number",
          customerSupplier: "Customer/Supplier",
          receivedSoldBy:"Received/Sold By",
          invoiceNumber:"Invoice Number",
          comment:"Comment",
          purchaseCount: "Purchase Qty",
          salesCount: "Sales Qty",
          grnAmount: "Grn Amount",
          ginAmount: "Gin Amount",
          runningBalance: "Running Balance",
        };
        excel.push(object);

        rowData.map((item, i) => {
          let object = {
            sn: i + 1,
            transactionDate: item.transactionDate,
            miti: item.transactionMiti,
            itemCode: item.itemCode,
            itemName: item.itemName,
            fiscalYear: item.fiscalYear,
            period: item.period,
            category: item.category,
            transactionType: item.transactionType,
            transactionNumber: item.transactionNumber,
            customerSupplier: item.customerSupplier,
            receivedSoldBy: item.receivedSoldBy,
            invoiceNumber:item.invoiceNumber,
            comment:item.comment,
            purchaseCount: item.purchaseCount,
            salesCount: item.salesCount,
            grnAmount: item.grnAmount,
            ginAmount: item.ginAmount,
            runningBalance: item.runningBalance,
          };
          excel.push(object);
          return null;
        });

        this.setState({
          excelData: excel,
        });
        //--------EXCEL DATA ENDS--------

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        /* this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        }); */
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  };

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render(params) {
    const { classes } = this.props;

    return (
      <div
        style={{
          backgroundColor: "#EAEDEF",
        }}
      >
        <Grid container justify="center" className={classes.root}>
          <Grid item xs={11} sm={11} md={9}>
            <Card className={classes.tabMargin} style={{marginBottom:"100px"}}>
              <CardContent>
                <Grid container style={{ width: "100%" }} justify="space-between">
                    <Grid item xs={12} sm={6}>
                      <ExpansionPanel
                        expanded={this.state.panelExpanded}
                        onChange={this.handlePanelChange}
                        style={{ maxWidth: "880px", marginBottom: "20px" }}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            Report By
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid container>
                          <Grid item xs={12} md={6}>
                            <RadioGroup
                              column
                              value={this.state.filterTerm}
                              onChange={this.onFilterTermChange}
                            >
                              <FormControlLabel
                                value="fiscalYear"
                                control={<Radio color="primary" />}
                                label="Fiscal Year and Period"
                              />
                              <FormControlLabel
                                value="date"
                                control={<Radio color="primary" />}
                                label="Date Range"
                              />
                            </RadioGroup>

                            {this.state.filterTerm === "fiscalYear" ? (
                              <>
                                <Grid
                                  container
                                  justify="flex-start"
                                  spacing={2}
                                  style={{ marginTop: "20px" }}
                                >
                                  <Grid item>
                                    <TextField
                                      select
                                      style={{
                                        width: "15vw",
                                        minWidth: "120px",
                                      }}
                                      label="Fiscal Year"
                                      name="fiscalYear"
                                      onChange={this.handleFiscalYearChange}
                                      value={this.state.currentFy}
                                    >
                                      {this.state.fyList.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  justify="flex-start"
                                  spacing={2}
                                  style={{ marginTop: "20px" }}
                                >
                                  <Grid item>
                                    <TextField
                                      select
                                      style={{
                                        width: "15vw",
                                        minWidth: "120px",
                                      }}
                                      label="Period From"
                                      name="periodFrom"
                                      value={this.state.filterFields.periodFrom}
                                      onChange={this.handlePeriodFromChange}
                                    >
                                      {this.state.periodList.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item>
                                    <TextField
                                      select
                                      style={{
                                        width: "15vw",
                                        minWidth: "120px",
                                      }}
                                      label="Period To"
                                      name="periodTo"
                                      value={this.state.filterFields.periodTo}
                                      onChange={this.handlePeriodToChange}
                                    >
                                      {this.state.periodList.map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </Grid>

                               {/*  <Grid container style={{ marginTop: "27px" }}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleFiscalYearApply}
                                  >
                                    Apply
                                  </Button>
                                </Grid> */}
                              </>
                            ) : null}

                            {this.state.filterTerm === "date" ? (
                              <>
                                <Grid
                                  container
                                  justify="flex-start"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <TextField
                                      placeholder="Date From"
                                      label="Date From"
                                      name="dateFrom"
                                      value={this.state.filterFields.dateFrom}
                                      style={{
                                        width: "15vw",
                                        minWidth: "130px",
                                        marginTop: "20px",
                                      }}
                                      type="date"
                                      onChange={this.handleFromDate}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <TextField
                                      placeholder="Date To"
                                      label="Date To"
                                      name="dateTo"
                                      value={this.state.filterFields.dateTo}
                                      style={{
                                        width: "15vw",
                                        minWidth: "130px",
                                        marginTop: "20px",
                                      }}
                                      type="date"
                                      onChange={this.handleToDate}
                                    />
                                  </Grid>
                                </Grid>
                                {/* <Grid container style={{ marginTop: "27px" }}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleDateRangeApply}
                                  >
                                    Apply
                                  </Button>
                                </Grid> */}
                              </>
                            ) : null}
                          </Grid>
                              
                          <Hidden smDown>
                            <Grid xs={1}>
                              <Divider
                                orientation="vertical"
                                inset
                                style={{
                                  minHeight: "100px",
                                  marginBottom: "10px",
                                }}
                              />
                            </Grid>
                          </Hidden>
                          <Hidden mdUp>
                            <Grid xs={12}>
                              <Divider
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "10px",
                                }}
                              />
                            </Grid>
                          </Hidden>

                          <Grid item md={5} xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                              <Search
                                input={{ fluid: true }}
                                loading={this.state.isLoading}
                                placeholder="Search by Item Code or Item Name"
                                style={{ marginTop: "15px", maxHeight: "500px" }}
                                onSearchChange={_.debounce(this.handleSearch, 500, {
                                  leading: true,
                                })}
                                results={this.state.rowRefs}
                                onResultSelect={this.onItemCodeChanged}
                              />
                             {/*  <TextField
                              label="Item Code"
                              fullWidth
                              variant="standard"
                              margin="dense"
                              select
                              value={this.state.itemCode}
                              name="itemCode"
                              onChange={this.onItemCodeChanged}
                            >
                              {this.state.itemCodeList.map((value) => (
                                <MenuItem key={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </TextField> */}
                            </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            justify="flex-end"
                            container
                            style={{ marginTop: "27px" }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.getInventoryListing}
                            >
                              Apply
                            </Button>
                          </Grid>
                        </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                    <Grid item xs>
                      <Grid
                        container
                        spacing={2}
                        justify="flex-end"
                        style={{ marginBottom: "7px" }}
                      >
                        <Grid item>
                          <ExcelFile
                            filename="InventoryTransactionDetails.xlsx"
                            element={
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                  minWidth: "100px",
                                  width: "6vw",
                                  margin: "auto",
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                  height: "40px",
                                  fontSize: "16px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faFileExcel}
                                  color="#3f51b5"
                                  size="30px"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                ></FontAwesomeIcon>
                                Excel
                              </Button>
                            }
                          >
                            <ExcelSheet
                              data={this.state.excelData}
                              name="Sheet A"
                            >
                              <ExcelColumn value="sn" />
                              <ExcelColumn value="transactionDate" />
                              <ExcelColumn value="miti" />
                              <ExcelColumn value="itemCode" />
                              <ExcelColumn value="itemName" />
                              <ExcelColumn value="fiscalYear" />
                              <ExcelColumn value="period" />

                              <ExcelColumn value="transactionType" />
                              <ExcelColumn value="transactionNumber" />
                              <ExcelColumn value="customerSupplier" />
                              <ExcelColumn value="receivedSoldBy"/>
                              <ExcelColumn value="invoiceNumber"/>
                              <ExcelColumn value="comment"/>
                              <ExcelColumn value="purchaseCount" />
                              <ExcelColumn value="salesCount" />
                              <ExcelColumn value="grnAmount" />
                              <ExcelColumn value="ginAmount" />
                              <ExcelColumn value="runningBalance" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>

                {this.state.showDetails ? (
                  <>
                    {this.state.filterTerm === "fiscalYear" ? (
                      <Grid
                        container
                        style={{ margin: "20px", maxWidth: "700px" }}
                      >
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Fiscal Year:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.currentFy}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Period From:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.periodFrom}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Period To:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.periodTo}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          container
                          style={{
                            margin: "20px",
                            marginLeft: "0px",
                            maxWidth: "500px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    marginRight: "10px",
                                    color: "#108DCD",
                                  }}
                                >
                                  Date From:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.dateFrom}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    color: "#108DCD",
                                    marginRight: "10px",
                                  }}
                                >
                                  Date To:
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    color: "#108DCD",
                                  }}
                                >
                                  {this.state.filterFields.dateTo}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ) : null}

                {this.state.loading ? (
                  <Grid
                    container
                    justify="center"
                    style={{ marginTop: "50px" }}
                  >
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid container justify="center">
                      <div
                        className="ag-theme-balham"
                        style={{
                          height: "65vh",
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <AgGridReact
                          columnDefs={this.state.columnDefs}
                          rowData={this.state.rowData}
                          onFirstDataRendered={this.firstDataRendered}
                          onGridReady={this.onGridReady}
                          overlayLoadingTemplate={
                            this.state.overlayLoadingTemplate
                          }
                          overlayNoRowsTemplate={
                            this.state.overlayNoRowsTemplate
                          }
                        ></AgGridReact>
                      </div>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(InventoryDetails);
