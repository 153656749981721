import React, { Component } from 'react'
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import { Grid, Card, CardContent, withStyles, TextField, Button, Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import Axios from 'axios';

const styles = (theme) => ({
  containerWidth: {
    padding: "15px",
    marginTop: "50px",
    width: "90%",
    margin: "auto",
  },
  root: {
    paddingTop: "10px",
    paddingBottom: "10px",
    margin: "auto",
    marginTop: "30px",
    minHeight: "35vh",
    width: "80%",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
  },
});

class CostTrail extends Component {
  constructor(props) {
    super(props)
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      averageOrLastRateLabel: "",
      averageOrLastRate:0,
      totalQuantity: 0,
      totalAmount: 0,
      dropdownList: [],
      code: "",
      codeError: false,
      rowData: [],
      columnDefs: [
        {
          headername: "S.N.",
          field: "sn",
          sortable: true,
          resizable: true,
          //valueGetter: rowIndex,
          width: 80,
          minWidth: 100,
        },
        {
          headername: "Date",
          field: "date",
          sortable: true,
          resizable: true,
          width: 150,
          minWidth: 200,
        },
        {
          headerName: "Item Code/Item Name",
          field: "itemCode",
          sortable: true,
          resizable: true,
          editable: true,
          width: 300,
          minWidth: 300,
        },
        {
          headername: "Voucher Number",
          field: "voucherNumber",
          sortable: true,
          resizable: true,
          width: 200,
          minWidth: 200,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          resizable: true,
          width: 150,
          minWidth: 200,
        },
        {
          headerName: "Purchase Price",
          field: "cost",
          resizable: true,
          width: 150,
          minWidth: 200,
        },
        {
          headerName: "Total",
          field: "total",
          resizable: true,
          width: 150,
          minWidth: 200
        }
      ],
      footerRow:[],
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      open: false,
    };
  }

   componentDidMount() {
 
     Axios.get("/hrm/getGeneralCompanyInformation")
       .then(async(res) => {
        let stockValuation = res.data[0].stockValuation;
        let label = "Average or Last Price";
        if(stockValuation==="Average Method"){
          label= "Average Rate"
        } else if(stockValuation===("Last Purchase")){
          label="Last Purchase Rate"
        }
       
        await this.setState((prevState)=>({ 
          ...prevState, 
          averageOrLastRateLabel: label  }));
       })
       .catch((err) => {
         console.log(err);
       });
   } 

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.removeEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  /*   customSearch = (_options, query) => {
      let queryText = query.toLowerCase();
      console.log(query);
  
      Axios.get("pos/costPriceTrail/getCostPriceTrail/" + query)
        .then(res => {
          if (res.data.length < 1) {
  
            this.setState({
              rowData: [],
              overlayNoRowsTemplate: "<span>No rows to show</span>",
            });
            try {
              this.gridApi.hideOverlay();
              if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
            } catch (error) {
              console.log(error);
            }
          } else {
            this.setState({ rowData: res.data })
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            overlayNoRowsTemplate: "<span>No rows to show</span>",
          });
          try {
            this.gridApi.hideOverlay();
            this.gridApi.showNoRowsOverlay();
          } catch (error) {
            console.log(error);
          }
        });
    }; */

  /* changeCode = async (e, data) => {
    let value = data.value;
    console.log(value);

    await this.setState({ code: value });
    // setSubAccountCode("");
    console.log(this.state.code);
    //getSubAccountList(value);
  }; */

  changeItem = async (e) => {
    await this.setState({ code: e.target.value })
  }

  handleClick = () => {
    if (this.state.code === "" || undefined) {
      this.setState({ codeError: true })
    } else { this.setState({ codeError: false }) }

    let list = [];
    let totalQuantity=0;
    let totalAmount=0;
    Axios.get("pos/costPriceTrail/getCostPriceTrail/" + this.state.code)
      .then((res) => {
        res.data.costPriceTrailDetails.forEach((el, i)=> {
          let obj={
            sn: "",
            date: "",
            itemCode: "",
            itemName: "",
            voucherNumber: "",
            quantity: 0,
            cost: "",
            total: "",
          };
          obj.sn= i+1;
          obj.date= el.voucherDate;
          obj.itemCode= res.data.itemCode + '/' + res.data.itemName;
          obj.itemName= res.data.itemName;
          obj.voucherNumber=el.voucherNumber;
          obj.quantity=el.quantity;
          obj.cost=el.cost;
          obj.total=el.total;
          totalQuantity= totalQuantity+parseFloat(el.quantity);
          totalAmount= totalAmount+parseFloat(el.total);

          list.push(obj);
        }); 

        let lastRow=[{
          sn: "Total",
          quantity: totalQuantity,
          total: totalAmount
        }];

        this.setState((prevState)=>({
          ...prevState,
          rowData: list,
          totalQuantity:totalQuantity,
          totalAmount: totalAmount,
          averageOrLastRate: res.data.averageOrLastRate,
          footerRow:lastRow
        }))

      })

      .catch((err) => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
          rowData: [],
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <HeaderTitle title="Cost Trail" />
        <Grid container justify="center">
          <Card className={classes.root}>

            <CardContent>
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={10}>
                <Grid container spacing={2} style={{ padding: 10 }}>
                <Grid item xs={5}>
                  {this.state.codeError ? (
                    <TextField
                      error
                      helperText="Field should not be empty"
                      type="search"
                      variant="outlined"
                      placeholder="Search by Item Code"
                      fullWidth
                      onChange={this.changeItem}
                      size="small"
                    ></TextField>
                  ) : (
                      <TextField
                        type="search"
                        variant="outlined"
                        placeholder="Search by Item Code"
                        fullWidth
                        onChange={this.changeItem}
                        size="small"
                      ></TextField>
                    )}
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={this.handleClick}
                  >Search</Button>

                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={2} style={{ paddingTop: 10 }} >
                <Grid container style={{ padding: 10 }} justify="flex-end">
                  <Typography
                  variant="body1"
                  > 
                  {this.state.averageOrLastRateLabel}: {this.state.averageOrLastRate}
                  </Typography>
                </Grid>
                </Grid>
              </Grid>
              {/*  <Grid container style={{ padding: 10 }}>
                <Grid item xs={6}>
                  <Dropdown
                    noResultsMessage="e.g.: 101.001"
                    text="Search by Item Code"
                    fluid
                    labeled
                    options={false}
                    style={{
                      border: "1px solid ",
                      borderRadius: "5px",
                      borderColor: "#8c8a84",
                      height: 45,
                      padding: 10,
                      margin: "auto"
                    }}
                    selectOnBlur={false}
                    search={this.customSearch}
                    onChange={this.changeCode}
                  />
                </Grid>
              </Grid> */}
              <Grid container style={{ marginTop: "10px" }} justify="center">
                <div
                  className="ag-theme-balham"
                  style={{
                    height: "57vh",
                    width: "100%",
                    margin: "10px",
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    pinnedBottomRowData={this.state.footerRow}
                    rowData={this.state.rowData}
                    aggFunc={this.state.totalAmount}
                    onFirstDataRendered={this.firstDataRendered}
                    onGridReady={this.onGridReady}
                  ></AgGridReact>
                </div>
              </Grid>
            </CardContent>

          </Card>
        </Grid>
      </div>
    )
  }
}
export default withStyles(styles)(CostTrail);
