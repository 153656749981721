import React, { Component } from "react";
import { Tabs, Tab, Grid, withStyles } from "@material-ui/core";
import AddItem from "../components/ItemMaster/AddItem";
import TaxCodeDetails from "../components/ItemMaster/Tax Code/TaxCodeDetails";
import WarehouseDetails from "../components/ItemMaster/Warehouse/WarehouseDetails";
import Reorder from "./Reorder";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";

const styles = theme => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down('md')]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up('md')]: {
      marginTop: "0px",
    }
  },
});

class ItemMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Item Master" />

          <Grid container justify="center">
            <Tabs
              variant="scrollable"
              style={{ marginBottom: "7px" }}
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabMargin}
              indicatorColor="primary"
            >
              <Tab style={{ fontSize: "12px" }} label="Add Item" {...a11yProps(0)} />
              <Tab style={{ fontSize: "12px" }} label="Tax Code" {...a11yProps(1)} />
              <Tab style={{ fontSize: "12px" }} label="Warehouse" {...a11yProps(2)} />
              <Tab style={{ fontSize: "12px" }} label="Reorder" {...a11yProps(3)} />
            </Tabs>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={11} sm={11} md={9}>
              <AddItem
                value={this.state.value}
                index={0}>
              </AddItem>
              <TaxCodeDetails
                value={this.state.value}
                index={1}
              ></TaxCodeDetails>
              <WarehouseDetails
                value={this.state.value}
                index={2}
              ></WarehouseDetails>
              <Reorder
                value={this.state.value}
                index={3}
              ></Reorder>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default (withStyles(styles)(ItemMaster));
