import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle,
  faPlusCircle,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import { withRouter } from "react-router-dom";

class SettingFinancialPeriod extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      tableList: [],
      dateTo: ""
    };
  }

  componentDidMount() {
    var today = new Date();

    var month = (today.getMonth() + 1).toString();

    var day = today.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    // var date = today.getFullYear() + "-" + month + "-" + day;

    // tableList.push({
    //   id: 1,
    //   period: "",
    //   code: "",
    //   name: "",
    //   dateFrom: this.props.companyInfo.fiscalYearStart,
    //   dateTo: date,
    //   fiscalYear: ""
    // });

    // this.setState(prevState => ({
    //   ...prevState,

    //   tableList: tableList
    // }));
  }

  deleteRow = index => {
    var currentRows = this.state.tableList;
    currentRows.splice(
      currentRows.findIndex(e => e.id === index),
      1
    );

    this.setState(prevState => ({
      ...prevState,
      tableList: currentRows
    }));
  };

  addNewRow = () => {
    var today = new Date();

    var month = (today.getMonth() + 1).toString();

    var day = today.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    var date = today.getFullYear() + "-" + month + "-" + day;

    var newRows = this.state.tableList;
    let tableList = newRows;

    if (tableList.length === 0) {
      tableList.push({
        id: this.state.tableList.length + 1,
        period: "",
        code: "",
        name: "",
        dateFrom: this.props.companyInfo.fiscalYearStart,
        dateTo: this.props.companyInfo.fiscalYearEnd,
        fiscalYear: ""
      });
    } else {
      tableList.push({
        id: this.state.tableList.length + 1,
        period: "",
        code: "",
        name: "",
        dateFrom: date,
        dateTo: this.props.companyInfo.fiscalYearEnd,
        fiscalYear: ""
      });
    }

    this.setState(prevState => ({
      ...prevState,
      tableList: tableList
    }));
  };

  onChanged = e => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    var id = targetName.split(".")[1];
    var name = targetName.split(".")[0];

    console.log(id);

    var tableList = this.state.tableList;

    var arrIndex = tableList.findIndex(e => e.id === parseInt(id));

    var currentRow = tableList[arrIndex];
    console.log(currentRow);

    currentRow[name] = value;

    console.log(currentRow);

    tableList[arrIndex] = currentRow;

    this.setState({
      tableList: tableList
    });
  };

  handleSubmit = () => {
    console.log(this.props.companyInfo);
    console.log(this.state.tableList);

    const token = this.context;

    //to save company info
    Axios.post("/hrm/saveGeneralCompanyInformation", this.props.companyInfo)
      .then(res => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });

        //to save financial period
        Axios.post("/period/savePeriod", this.state.tableList)
          .then(res => {
            token.setCompanyInfoSet({ value: true });
            console.log(res);
            var snackbarProps = {
              open: true,
              variant: "success",
              message: res.data,
              autoHideDuration: 2000
            };
            token.setSnackbarProps({ snackbarProps: snackbarProps });

            this.props.history.push("/dashboard");
          })
          .catch(err => {
            console.log(err);
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
            var snackbarProps = {
              open: true,
              variant: "error",
              message: message,
              autoHideDuration: 2000
            };
            token.setSnackbarProps({ snackbarProps: snackbarProps });
          });
      })
      .catch(err => {
        console.log(err);

        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  render() {
    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5"
    };
    const addTableCell = {
      paddingLeft: "18px",
      paddingRight: "18px",
      paddingTop: "10px",
      paddingBottom: "10px",
      backgroundColor: "#E8E8E8"
    };

    return (
      <Grid container justify="center">
        <Grid container>
          <div style={{ marginTop: "60px" }}></div>
        </Grid>
        <Card
          style={{
            margin: 21,
            padding: "20px",
            width: "70vw",
            marginBottom: "200px"
          }}
          hidden={this.props.hidden}
        >
          <CardContent>
            <Grid
              container
              style={{ paddingTop: "0px", paddingBottom: "20px" }}
            >
              <Typography
                variant="h6"
                onClick={this.props.displayCompany}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon> Company
                Information
              </Typography>
            </Grid>
            <Grid container>
              <Typography
                variant="h6"
                component="h2"
                style={{ fontWeight: "bold" }}
              >
                {" "}
                Setting Financial Period{" "}
              </Typography>
              <Table
                aria-label="customized table"
                //   fixedHeader={false} style={{ width: "auto", tableLayout: "auto",  marginTop: "10px", marginBottom: "15px"  }}
                fixedHeader={false}
                style={{ marginTop: "10px", marginBottom: "15px" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={styledTableCell} align="center">
                      {" "}
                      S. No.{" "}
                    </TableCell>
                    <TableCell style={styledTableCell}>Period</TableCell>
                    <TableCell style={styledTableCell}>Code</TableCell>
                    <TableCell style={styledTableCell}>Name</TableCell>

                    <TableCell style={styledTableCell}>Date From</TableCell>
                    <TableCell style={styledTableCell}>Date To</TableCell>
                    <TableCell style={styledTableCell}>Fiscal Year</TableCell>
                    <TableCell style={styledTableCell} align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.tableList.map((value, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">{value.id}</TableCell>
                      <TableCell>
                        <TextField
                          label="Period"
                          name={"period." + value.id}
                          value={value.period}
                          onChange={this.onChanged}
                          variant="outlined"
                          margin="dense"
                        ></TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Code"
                          value={value.code}
                          onChange={this.onChanged}
                          name={"code." + value.id}
                          variant="outlined"
                          margin="dense"
                        ></TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Name"
                          value={value.name}
                          onChange={this.onChanged}
                          name={"name." + value.id}
                          variant="outlined"
                          margin="dense"
                        ></TextField>
                      </TableCell>

                      <TableCell>
                        <TextField
                          label="Date From"
                          value={value.dateFrom}
                          type="date"
                          onChange={this.onChanged}
                          name={"dateFrom." + value.id}
                          variant="outlined"
                          margin="dense"
                        ></TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Date To"
                          value={value.dateTo}
                          type="date"
                          onChange={this.onChanged}
                          name={"dateTo." + value.id}
                          variant="outlined"
                          margin="dense"
                        ></TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Fiscal Year"
                          value={value.fiscalYear}
                          onChange={this.onChanged}
                          name={"fiscalYear." + value.id}
                          variant="outlined"
                          margin="dense"
                        ></TextField>
                      </TableCell>
                      <TableCell align="center">
                        <span>
                          <FontAwesomeIcon
                            icon={faMinusCircle}
                            size="2x"
                            style={{ color: "#FF8070", cursor: "pointer" }}
                            onClick={() => this.deleteRow(value.id, "credit")}
                          ></FontAwesomeIcon>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>
                      <div style={addTableCell}>
                        <span style={{ color: "#AAAAAA" }}>SN</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={addTableCell}>
                        <span style={{ color: "#AAAAAA" }}>Period</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={addTableCell}>
                        <span style={{ color: "#AAAAAA" }}>Code</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={addTableCell}>
                        <span style={{ color: "#AAAAAA" }}>Name</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={addTableCell}>
                        <span style={{ color: "#AAAAAA" }}>Date From</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={addTableCell}>
                        <span style={{ color: "#AAAAAA" }}>Date To</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div style={addTableCell}>
                        <span style={{ color: "#AAAAAA" }}>Fiscal Year</span>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <span>
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          size="2x"
                          style={{ color: "#3F51B5", cursor: "pointer" }}
                          onClick={() => {
                            this.addNewRow("credit");
                          }}
                        ></FontAwesomeIcon>
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid container justify="center">
              <Button
                onClick={this.handleSubmit}
                variant="contained"
                size="large"
                color="primary"
                style={{
                  margin: "15px",
                  marginTop: "50px",
                  paddingLeft: "47px",
                  paddingRight: "47px",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                Submit
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withRouter(SettingFinancialPeriod);
