import React from "react";
import {
  Grid,
  Typography,
  Card,
  IconButton,
  Button,
  TextField,
  CircularProgress,
  TableContainer,
  TableBody,
} from "@material-ui/core";
import Axios from "axios";
import Close from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../utils/UserContext";
import { Table, Input } from "semantic-ui-react";

const ViewBatchInputVoucherAuthorization = React.memo(
  class extends React.Component {
    static contextType = UserContext;

    constructor(props) {
      super(props);

      this.state = {
        data: {
          batchInputVoucherDetails: [],
        },
        addData: {
          accountCode: "",
          accountCodeName: "",
          accountGroupCode: "",
          accountGroupName: "",
          amount: 0,
          costCenter: "",
          id: 0,
          month: "",
          narration: "",
          primaryGroupCode: "",
          primaryGroupName: "",
          subAccount: "",
          subAccountName: "",
          transactionDateEnglish: "",
          transactionDateNepali: "",
          voucherType: "",
        },
        mounted: false,
        editable: false,
        addRow: false,
        loading: false,
        clicked: "#0b5f8a",
        buttonColor: "#4aa5d8",
      };
    }

    getMaxId = () => {
      let maxId = 0;

      this.state.data.batchInputVoucherDetails.map((val) =>
        val.id > maxId ? (maxId = val.id) : null
      );

      return maxId;
    };

    handleDelete = (id) => {
      var batchInputVoucherDetails = this.props.data.batchInputVoucherDetails;

      batchInputVoucherDetails = batchInputVoucherDetails.filter(
        (val) => val.id !== id
      );

      console.log(batchInputVoucherDetails);

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          batchInputVoucherDetails: batchInputVoucherDetails,
        },
      }));
    };

    componentDidUpdate() {
      if (
        this.props.data.batchInputVoucherDetails.length > 0 &&
        !this.state.mounted
      ) {
        this.setState({
          data: this.props.data,
          mounted: true,
        });
      }
    }

    onRowTextChange = (id) => (e, obj) => {
      let tableList = this.state.data.batchInputVoucherDetails;

      console.log({ obj });

      let dataValue = obj.value;

      if (obj.name === "amount") {
        dataValue = parseInt(dataValue);
      }

      tableList.map((val) =>
        val.id === id ? (val[obj.name] = dataValue) : null
      );

      console.log({ tableList });

      this.setState({
        data: {
          batchInputVoucherDetails: tableList,
        },
      });
    };

    addNewRow = () => {
      let tableList = this.state.data.batchInputVoucherDetails;

      let dataObj = this.state.addData;

      dataObj.id = this.getMaxId() + 1;

      tableList.unshift(dataObj);

      this.setState({
        data: {
          batchInputVoucherDetails: tableList,
        },
      });
    };

    onChangedNewRow = (_e, obj) => {
      let dataValue = obj.value;

      if (obj.name === "amount") {
        dataValue = parseInt(dataValue);
      }

      this.setState((prevState) => ({
        ...prevState,
        addData: {
          ...prevState.addData,
          [obj.name]: dataValue,
        },
      }));
    };

    handleUpdate = () => {
      let obj = this.props.data;

      const token = this.context;

      obj.batchInputVoucherDetails = this.state.data.batchInputVoucherDetails;

      this.setState({
        loading: true,
      });

      Axios.put("pos/batchInput/update/" + this.props.data.id, obj)
        .then((res) => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          this.setState({
            loading: false,
          });

          token.setSnackbarProps({ snackbarProps: snackbarProps });

          this.props.handleViewCounter(this.props.data.id);
          this.props.getTableDataForAuth();
          this.props.getTableDataForList();

          this.setState({
            mounted: false,
          });
        })
        .catch((err) => {
          var message = err.message;

          if (err.response !== undefined) {
            message = err.response.data;
          }

          this.setState({
            loading: false,
          });

          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    };

    handleStatus = (status) => {
      const token = this.context;

      var id = this.props.data.id;

      this.setState({
        loading: true,
      });

      Axios.put("pos/batchInput/updateStatus/" + id, { status: status })
        .then((res) => {
          console.log(res);

          this.setState({
            loading: false,
          });

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          this.props.handleViewCounter(id);
          this.props.getTableDataForAuth();
          this.props.getTableDataForList();
          if (status === "Approved" || status === "Rejected") {
            this.props.animateOut();
          }
        })
        .catch((err) => {
          console.log(err);

          var message = err.message;

          if (err.response !== undefined) {
            message = err.response.data;
          }

          this.setState({
            loading: false,
          });

          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    render() {
      const styledTableCell = {
        backgroundColor: "#F1F8FC",
        color: "#3F51B5",
        position: "sticky",
        top: 0,
        zIndex: 19,
      };

      const styledAddHeader = {
        position: "sticky",
        top: 0,
        zIndex: 19,
      };

      const buttonStyle = {
        padding: "10px",
        margin: "20px",
        marginTop: "25px",
        marginRight: "50px",
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingRight: "50px",
        paddingLeft: "50px",
      };

      const {
        status,
        period,
        fiscalYear,
        voucherNumber,
        createdAt,
      } = this.props.data;

      return (
        <>
          <Card
            style={{
              height: "90%",
              width: "95vw",
              padding: "10px",
              marginBottom: "100px",
            }}
            hidden={this.props.hidden}
          >
            <Grid container justify="space-between">
              <Grid item>
                <Typography
                  variant="h6"
                  onClick={this.props.animateOut}
                  style={{ cursor: "pointer", padding: "10px" }}
                >
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>{" "}
                  Voucher List
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.props.animateOut}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ padding: "20px", paddingBottom: "0px" }}>
              <Grid
                container
                justify="flex-end"
                spacing={2}
                style={{ marginBottom: "20px" }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        editable: !this.state.editable,
                      });
                    }}
                    style={{
                      backgroundColor: this.state.editable
                        ? this.state.clicked
                        : this.state.buttonColor,
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        addRow: !this.state.addRow,
                      });
                    }}
                    style={{
                      backgroundColor: this.state.addRow
                        ? this.state.clicked
                        : this.state.buttonColor,
                    }}
                  >
                    Add Row
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ padding: "20px", paddingTop: "0px" }}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    label="Created Date"
                    value={createdAt}
                    size="small"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    label="Voucher Number"
                    value={voucherNumber}
                    size="small"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    label="Fiscal Year"
                    value={fiscalYear}
                    size="small"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    label="Period"
                    value={period}
                    size="small"
                  />
                </Grid>
              </Grid>

              {this.props.loading || this.state.loading ? (
                <Grid container justify="center" style={{ marginTop: "50px" }}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                  <TableContainer style={{ marginTop: "20px", height: "35vh" }}>
                    <Table compact="very" singleLine>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell style={styledTableCell}>
                            {this.state.editable ? "Action" : "S.N."}
                          </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Transaction Date English
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Transaction Date Nepali
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Month
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Narration
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Cost Center
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Account Code
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Account Code Name
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Account Group Code
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Account Group Name
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            S.N. Primary Group Code
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Primary Group Name
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Sub Account Code
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Sub Account Name
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Amount
                        </Table.HeaderCell>
                          <Table.HeaderCell style={styledTableCell}>
                            Type
                        </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      {this.state.addRow ? (
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell
                              textAlign="center"
                              verticalAlign="middle"
                              style={{
                                position: "sticky",
                                top: 0,
                                zIndex: 19,
                                cursor: "pointer",
                              }}
                              onClick={this.addNewRow}
                            >
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                size="lg"
                                style={{ color: "#28A745" }}
                              ></FontAwesomeIcon>
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="transactionDateEnglish"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="transactionDateNepali"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="month"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="narration"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="costCenter"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="accountCode"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="accountCodeName"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="accountGroupCode"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="accountGroupName"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="primaryGroupCode"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="primaryGroupName"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="subAccount"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="subAccountName"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="amount"
                                type="number"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell style={styledAddHeader}>
                              <Input
                                name="voucherType"
                                onChange={this.onChangedNewRow}
                              />
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                      ) : null}

                      <TableBody>
                        {this.state.data.batchInputVoucherDetails.map(
                          (value, i) =>
                            this.state.editable ? (
                              <Table.Row>
                                <Table.Cell
                                  verticalAlign="middle"
                                  textAlign="center"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e, obj) =>
                                    this.handleDelete(value.id)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faMinusCircle}
                                    size="lg"
                                    style={{ color: "#FF8070" }}
                                  ></FontAwesomeIcon>
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.transactionDateEnglish}
                                    name="transactionDateEnglish"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.transactionDateNepali}
                                    name="transactionDateNepali"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.month}
                                    name="month"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.narration}
                                    name="narration"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.costCenter}
                                    name="costCenter"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.accountCode}
                                    name="accountCode"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.accountCodeName}
                                    name="accountCodeName"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.accountGroupCode}
                                    name="accountGroupCode"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.accountGroupName}
                                    name="accountGroupName"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.primaryGroupCode}
                                    name="primaryGroupCode"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.primaryGroupName}
                                    name="primaryGroupName"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.subAccount}
                                    name="subAccount"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.subAccountName}
                                    name="subAccountName"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.amount}
                                    name="amount"
                                    type="number"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  <Input
                                    value={value.voucherType}
                                    name="voucherType"
                                    onChange={this.onRowTextChange(value.id)}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ) : (
                                <Table.Row>
                                  <Table.Cell>{i + 1}</Table.Cell>
                                  <Table.Cell>
                                    {value.transactionDateEnglish}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {value.transactionDateNepali}
                                  </Table.Cell>
                                  <Table.Cell>{value.month}</Table.Cell>
                                  <Table.Cell>{value.narration}</Table.Cell>
                                  <Table.Cell>{value.costCenter}</Table.Cell>
                                  <Table.Cell>{value.accountCode}</Table.Cell>
                                  <Table.Cell>{value.accountCodeName}</Table.Cell>
                                  <Table.Cell>{value.accountGroupCode}</Table.Cell>
                                  <Table.Cell>{value.accountGroupName}</Table.Cell>
                                  <Table.Cell>{value.primaryGroupCode}</Table.Cell>
                                  <Table.Cell>{value.primaryGroupName}</Table.Cell>
                                  <Table.Cell>{value.subAccount}</Table.Cell>
                                  <Table.Cell>{value.subAccountName}</Table.Cell>
                                  <Table.Cell>{value.amount}</Table.Cell>
                                  <Table.Cell>{value.voucherType}</Table.Cell>
                                </Table.Row>
                              )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
            </Grid>
            <Grid container justify="center">
              {this.state.editable || this.state.addRow ? (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={this.handleUpdate}
                  style={buttonStyle}
                >
                  Update
                </Button>
              ) : (
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => { }}
                    style={buttonStyle}
                    disabled
                  >
                    Update
                  </Button>
                )}

              {status === "Pending" ? (
                <Button
                  variant="outlined"
                  size="large"
                  color="inherit"
                  onClick={() => {
                    this.handleStatus("Checked");
                  }}
                  style={buttonStyle}
                >
                  Check
                </Button>
              ) : null}
              {status === "Checked" ? (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => {
                    this.handleStatus("Approved");
                  }}
                  style={buttonStyle}
                >
                  Approve
                </Button>
              ) : null}

              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={() => {
                  this.handleStatus("Rejected");
                }}
                style={buttonStyle}
              >
                Reject
              </Button>
            </Grid>
          </Card>
        </>
      );
    }
  }
);

export default ViewBatchInputVoucherAuthorization;
