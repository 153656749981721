import React, { Component } from "react";
import { Card, CardContent, Container, withStyles } from "@material-ui/core";

import PurchaseOrderForm from "../components/PurchaseOrderForm";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";

const styles = theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "170px",
      marginTop: "40px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
      marginTop: "10px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "10px",
      marginBottom: "100px"
    }
  }
});

class PurchaseOrder extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Purchase Order" />

          <Container>
            <Card className={classes.root}>
              <CardContent>
                <PurchaseOrderForm />
              </CardContent>
            </Card>
          </Container>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PurchaseOrder);
