import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import UserContext from "../../utils/UserContext";
import SettingFinancialPeriod from "../../views/SettingFinancialPeriod";
import Axios from "axios";

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      marginBottom: "80px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },
  },
  tabMargin: {
    padding: "20px",
    width: "100%",
    maxWidth: "700px",
    margin: "10px",
    marginRight: "10px",
    marginLeft: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "2px",
    },
  },
});

class CompanyInformation extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: "",
        branch: "",
        city: "",
        companyId: "",
        contactNumber: "",
        contactPerson: "",
        country: "",
        email: "",
        faxNumber: "",
        financialPeriod: "",
        fiscalYear: "",
        fiscalYearEnd: "",
        fiscalYearStart: "",
        invoicingMethod: "",
        name: "",
        natureOfBusiness: "",
        panNumber: "",
        province: "",
        registrationNumber: "",
        invoicingFormat: "",
        stockValuation: "",
        street: "",
        website: "",
      },
      cardValue: 0,
      editable: false,
      clicked: "#0b5f8a",
      buttonColor: "#4aa5d8",
    };
  }

  componentDidMount() {
    this.getCompanyInfo();
  }

  getCompanyInfo = () => {
    Axios.get("/hrm/getGeneralCompanyInformation").then(async (res) => {
      console.log(res.data);
      await this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          id: res.data[0].id,
          name: res.data[0].name,
          branch: res.data[0].branch,
          city: res.data[0].city,
          companyId: res.data[0].companyId,
          contactNumber: res.data[0].contactNumber,
          contactPerson: res.data[0].contactPerson,
          country: res.data[0].country,
          email: res.data[0].email,
          faxNumber: res.data[0].faxNumber,
          financialPeriod: res.data[0].financialPeriod,
          fiscalYear: res.data[0].fiscalYear,
          fiscalYearEnd: res.data[0].fiscalYearEnd,
          fiscalYearStart: res.data[0].fiscalYearStart,
          invoicingMethod: res.data[0].invoicingMethod,
          natureOfBusiness: res.data[0].natureOfBusiness,
          panNumber: res.data[0].panNumber,
          province: res.data[0].province,
          registrationNumber: res.data[0].registrationNumber,
          invoicingFormat: res.data[0].invoicingFormat,
          stockValuation: res.data[0].stockValuation,
          street: res.data[0].street,
          website: res.data[0].website,
        },
      }));
    });
  };

  handleCompanyUpdate = () => {
    const token = this.context;

    const id = this.state.data.id;

    console.log(this.state.data);

    Axios.put("/hrm/updateGeneralCompanyInformation/" + id, this.state.data)
      .then((res) => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        token.setCompanyInfoSet({ value: true });
      })
      .catch((err) => {
        console.log(err);

        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  onChanged = (e) => {
    e.stopPropagation();

    if (!this.state.editable) return;

    var targetName = e.target.name;
    var value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  render() {
    const inputStyles = {
      fontSize: 14,
    };

    const { classes } = this.props;

    var today = new Date();

    var month = (today.getMonth() + 1).toString();

    var day = today.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    var date = today.getFullYear() + "-" + month + "-" + day;

    return (
      <Grid container justify="center" className={classes.root}>
        <Grid container>
          <div style={{ marginTop: "60px" }}></div>
        </Grid>
        <Card className={classes.tabMargin} hidden={this.state.cardValue !== 0}>
          <CardContent style={{ padding: "0px" }}>
            <Grid container justify="space-between">
              <Grid item sm={4} lg>
                <Typography
                  variant="h6"
                  component="h2"
                  style={{ fontWeight: "bold" }}
                >
                  Company Information
                </Typography>
              </Grid>
              <Grid item sm={8} lg={2}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    this.setState({
                      editable: !this.state.editable,
                      buttonColor: this.state.clicked,
                    });
                  }}
                  style={{
                    backgroundColor: this.state.buttonColor,
                    marginBottom: "20px",
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={9}>
                <TextField
                  id="standard-with-placeholder"
                  label="Name of Company"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="name"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.name}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Company ID"
                  style={{ width: "100%" }}
                  placeholder=""
                  name="companyId"
                  variant="filled"
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.companyId}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="PAN"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="panNumber"
                  variant="filled"
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.panNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Registration Number"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="registrationNumber"
                  variant="filled"
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.registrationNumber}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Nature of Business"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="natureOfBusiness"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.natureOfBusiness}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Branch"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="branch"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.branch}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "45px" }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Country"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="country"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.country}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Province"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="province"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.province}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="City"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="city"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.city}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Street"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="street"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.street}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Contact Number"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="contactNumber"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.contactNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Contact Email"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="email"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.email}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fax Number"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="faxNumber"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.faxNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Website"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="website"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.website}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "45px" }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Invoicing Method"
                  placeholder=""
                  select
                  value={this.state.data.invoicingMethod}
                  style={{ width: "100%" }}
                  name="invoicingMethod"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                >
                  <MenuItem key={1} value="PAN">
                    PAN
                  </MenuItem>
                  <MenuItem key={2} value="VAT">
                    VAT
                  </MenuItem>
                  <MenuItem key={3} value="Abbreviated Tax Invoice">
                    Abbreviated Tax Invoice
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Stock Valuation"
                  value={this.state.data.stockValuation}
                  placeholder=""
                  select
                  style={{ width: "100%" }}
                  name="stockValuation"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                >
                  <MenuItem key={1} value="Last Purchase">
                    Last Purchase
                  </MenuItem>
                  <MenuItem key={2} value="Average Method">
                    Average Method
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Invoicing Format"
                  value={this.state.data.invoicingFormat}
                  placeholder=""
                  select
                  style={{ width: "100%" }}
                  name="invoicingFormat"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                >
                  <MenuItem key={1} value="3' inch">
                    3' inch
                  </MenuItem>
                  <MenuItem key={2} value="4' inch">
                    4' inch
                  </MenuItem>
                  <MenuItem key={3} value="A4">
                    A4
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fiscal Year"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="fiscalYear"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.fiscalYear}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fiscal Year Start"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="fiscalYearStart"
                  defaultValue={date}
                  type="date"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.fiscalYearStart}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fiscal Year End"
                  placeholder=""
                  type="date"
                  style={{ width: "100%" }}
                  defaultValue={date}
                  name="fiscalYearEnd"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.fiscalYearEnd}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Financial Period"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="financialPeriod"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                  value={this.state.data.financialPeriod}
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Button
                onClick={this.handleCompanyUpdate}
                variant="contained"
                size="large"
                color="primary"
                style={{
                  marginTop: "30px",
                  paddingLeft: "47px",
                  paddingRight: "47px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                UPDATE
              </Button>
            </Grid>
          </CardContent>
        </Card>
        <SettingFinancialPeriod
          hidden={this.state.cardValue !== 1}
          companyInfo={this.state.data}
          displayCompany={() => {
            this.setState({
              cardValue: 0,
            });
          }}
        />
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(CompanyInformation));
