import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  Menu,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
  withStyles,
  Button,
  IconButton
} from "@material-ui/core";
import UserContext from "../../utils/UserContext";
import Axios from "axios";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  cardHeight: {
    minWidth: "300px",
    width: "30vw",

    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "90%"
    },
    [theme.breakpoints.up("md")]: {
      height: "480px"
    },
    [theme.breakpoints.up("lg")]: {
      height: "480px"
    }
  }
});

class VoucherNumberingSchemeButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      modalOpen: false,
      statusModalOpen: false,
      inactiveList: [],
      inactiveSelect: "",
      fontColor: "#5FE3A1"
    };
  }

  handleView = () => {
    // this.setState({ data: this.props.node.data });
    this.setState({ anchorEl: null, open: false });
    this.setState({ modalOpen: true });
  };

  handleChangeStatus = () => {
    const token = this.context;

    // Axios.put("hrm/terminate/" + this.props.node.data.id)
    //   .then(res => {
    //     console.log(res);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });

    Axios.get(
      "pos/voucherNumbering/findInactiveVoucher/" + this.props.data.name
    )
      .then(res => {
        var inactiveList = [];
        res.data.forEach(element => {
          inactiveList.push({
            id: element.id,
            value: element.name + " (FY" + element.fiscalYear + ")"
          });
        });

        this.setState({
          inactiveList
        });
      })
      .catch(err => {
        console.log(err);
      });

    if (this.props.data.status === "ACTIVE") {
      this.setState({
        statusModalOpen: true
      });
    } else {
      var snackbarProps = {
        open: true,
        variant: "error",
        message: "Cannot change status for non-ACTIVE schemes",
        autoHideDuration: 2000
      };

      token.setSnackbarProps({ snackbarProps: snackbarProps });
    }
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget)
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  onChanged = event => {
    var val = event.target.value;

    this.setState({
      inactiveSelect: val
    });
  };

  handleSubmit = () => {
    const token = this.context;

    if (
      this.state.inactiveSelect === "" ||
      this.state.inactiveSelect === "undefined"
    ) {
      var snackbarProps = {
        open: true,
        variant: "error",
        message:
          "Please add a numbering scheme for " +
          this.props.data.name +
          " to close this numbering scheme.",
        autoHideDuration: 2000
      };

      token.setSnackbarProps({ snackbarProps: snackbarProps });
      return;
    }

    Axios.put("pos/voucherNumbering/update/" + this.props.data.id, {
      id: this.state.inactiveSelect
    })
      .then(res => {
        this.setState({
          fontColor: "#FF5733"
        });

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        this.props.populateTable();

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      })
      .catch(err => {
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  render() {
    const { classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const inputStyles = {
      fontSize: 14
    };

    function colorizedSpan(mode) {
      console.log(mode);

      if (mode === "ACTIVE") {
        return <span style={{ color: "#5FE3A1" }}>{mode}</span>;
      } else if (mode === "INACTIVE") {
        return <span style={{ color: "#FCA130" }}>{mode}</span>;
      } else {
        return <span style={{ color: "#FF5733" }}>{mode}</span>;
      }
    }

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faEllipsisV}
            color="#3F51B5"
            onClick={this.handleClick}
            style={{ cursor: "pointer" }}
          ></FontAwesomeIcon>
        </span>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: 200
            }
          }}
        >
          <MenuItem key={1} onClick={this.handleView}>
            View
          </MenuItem>
          <MenuItem key={2}>
            <span
              style={{ color: "#FF5733" }}
              onClick={this.handleChangeStatus}
            >
              Change Status
            </span>
          </MenuItem>
        </Menu>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.statusModalOpen}
          onClose={() => {
            this.setState({ statusModalOpen: false, fontColor: "#5FE3A1" });
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.statusModalOpen}>
            <div>
              <Card className={classes.cardHeight}>
                <Typography
                  gutterBottom
                  variant="h5"
                  style={{ fontWeight: "bold" }}
                >
                  Change Status
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  style={{ marginTop: "20px" }}
                >
                  <span style={{ fontWeight: "bold" }}>Voucher Name:</span>{" "}
                  {this.props.data.name}
                </Typography>
                <Typography gutterBottom variant="h6">
                  <span style={{ fontWeight: "bold" }}>
                    Voucher Description:
                  </span>{" "}
                  {this.props.data.voucherDescription}
                </Typography>
                <Typography gutterBottom variant="h6">
                  <span style={{ fontWeight: "bold" }}>Fiscal Year:</span>{" "}
                  {this.props.data.fiscalYear}
                </Typography>
                <Typography gutterBottom variant="h6">
                  <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
                  <span style={{ color: this.state.fontColor }}>
                    {this.props.data.status}
                  </span>
                </Typography>
                <Grid container style={{ marginTop: "20px" }}>
                  <Grid item xs>
                    <TextField
                      id="standard-with-placeholder"
                      label="Activate an inactive scheme to proceed"
                      placeholder=""
                      style={{ width: "100%" }}
                      select
                      name="name"
                      variant="filled"
                      value={this.state.inactiveSelect}
                      onChange={this.onChanged}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      margin="normal"
                    >
                      {this.state.inactiveList.map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Button
                    onClick={this.handleSubmit}
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{
                      margin: "20px",
                      marginTop: "50px",
                      paddingLeft: "27px",
                      paddingRight: "27px",
                      paddingTop: "15px",
                      paddingBottom: "15px"
                    }}
                  >
                    Close Scheme
                  </Button>
                </Grid>
              </Card>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div>
              <Card className={classes.cardHeight}>
                <CardContent style={{ padding: "0px" }}>
                  <Grid container justify="flex-end">
                    <IconButton onClick={this.handleModalClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid container style={{ padding: "20px" }}>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {this.props.data.name}
                    </Typography>
                    <Typography variant="h6" style={{ color: "#969799" }}>
                      {this.props.code}
                    </Typography>

                    <Divider style={{ marginTop: "25px" }} />
                    <Grid
                      container
                      style={{
                        paddingTop: "25px",
                        paddingRight: "25px",
                        paddingBottom: "25px"
                      }}
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography style={{ fontSize: 15, color: "#969799" }}>
                          Status:
                      </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {colorizedSpan(this.props.data.status)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        paddingRight: "25px",
                        paddingBottom: "25px"
                      }}
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography style={{ fontSize: 15, color: "#969799" }}>
                          Fiscal Year:
                      </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {this.props.data.fiscalYear}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        paddingRight: "25px",
                        paddingBottom: "25px"
                      }}
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography style={{ fontSize: 15, color: "#969799" }}>
                          Number Length:
                      </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {this.props.data.numberLength}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        paddingRight: "25px",
                        paddingBottom: "25px"
                      }}
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography style={{ fontSize: 15, color: "#969799" }}>
                          Total character:
                      </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {this.props.data.totalCharacter} (Automatic)
                      </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      style={{
                        paddingRight: "25px",
                        paddingBottom: "25px"
                      }}
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography style={{ fontSize: 15, color: "#969799" }}>
                          Fill Character:
                      </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {this.props.data.fillCharacter}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        paddingRight: "25px",
                        paddingBottom: "20px"
                      }}
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography style={{ fontSize: 15, color: "#969799" }}>
                          Description:
                      </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {this.props.data.voucherDescription}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(VoucherNumberingSchemeButton);
