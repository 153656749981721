import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  withStyles,
  Button,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  CardActionArea,
  CardMedia,
  CardActions,
  LinearProgress,
  Typography,
  MenuItem,
} from "@material-ui/core";
import UserContext from "../utils/UserContext";
import Axios from "axios";

const styles = (theme) => ({
    tabMargin: {
        height: "auto",
        padding: 10,
        paddingRight: "7px",
        paddingLeft: "7px",
        width: "100%",
        [theme.breakpoints.down("md")]: {
          marginTop: "93px",
        },
        [theme.breakpoints.up("md")]: {
          marginTop: "53px",
        },
      },
      root: {
        paddingTop: "10px",
        paddingBottom: "25px",
        [theme.breakpoints.up("xs")]: {
          marginBottom: "170px",
          width: "85%",
          margin: "auto",
        },
        [theme.breakpoints.up("sm")]: {
          marginBottom: "100px",
          width: "80%",
          margin: "auto",
        },
        [theme.breakpoints.up("lg")]: {
          marginBottom: "100px",
          width: "70%",
          margin: "auto",
        },
      },
});

class InventoryCF extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
        
        fiscalYear: "",
        currentPeriod: "",
        resData: [],
        fyList: [],
        periodList: [],
        resErr : false,
        err: "Not Found",
        shouldDisableApply:true

    };
  }

    componentDidMount(){
    this.getAllPeriodListing();
    }

    getAllPeriodListing = () => {
        Axios.get("/fy/getAllActivePeriods")
          .then(async (res) => {
            var fiscalYearLists = [];
            res.data.forEach((element) => {
              var fy = Object.keys(element);
    
              fiscalYearLists.push(fy[0]);
            });
            await this.setState({
              fyList: fiscalYearLists,
              resData: res.data,
            });
    
            this.getCurrentPeriod();
          })
          .catch((err) => {
            console.log(err);
          });
      };

      getCurrentPeriod() {
        Axios.get("/fy/getCurrentPeriod")
          .then(async (res) => {
            await this.setState((prevState) => ({
              ...prevState,
              fiscalYear: res.data.fiscalYear,
              currentPeriod: res.data.name,
            }));
            this.getPeriodList();
          })
          .catch((err) => {
            console.log(err);
          });
      }

      getPeriodList() {
        var pLists = [];
        this.state.resData.map((val) =>
          Object.keys(val).includes(this.state.fiscalYear)
            ? (pLists = val[this.state.fiscalYear])
            : null
        );
        this.setState((prevState)=>({
            ...prevState,
          periodList: pLists,
          currentPeriod: pLists[pLists.length-1]
        }));
      }
      
      handlePeriodChange = async (e) => {
        let Py = e.target.value;
    
        await this.setState((prevState) => ({
          ...prevState,
            currentPeriod: Py,
        }));
    
      };

      handleFYChange = async (e) => {
        let Fy = e.target.value;
        await this.setState((prevState) => ({
          ...prevState,
            fiscalYear: Fy,
        }));
        this.getPeriodList();
      };

      handleClick =()=> {
          
        let obj={
            fiscalYear: this.state.fiscalYear,
            period: this.state.currentPeriod,
        };

        Axios.post("/pos/inventoryPhysicalCount/findByPeriodAndFiscalYear", obj).then((res)=>{

        if(res.data.inventoryPhysicalCountChilds===null){
            
            this.setState({
               resErr: true,
               shouldDisableApply:true,
            });
        }else{
           
            this.setState({
                shouldDisableApply: false,
            })
        }
              
        })

        .catch((err) => {
            var message = err.message;
            if (err.response !== undefined) {
              message = err.response.data;
            }
           
          });
      }

        handleApply=()=>{
        const token = this.context;

            let obj={
                fiscalYear: this.state.fiscalYear,
                period: this.state.currentPeriod,
            };

            Axios.post("/pos/grnBatch/fetchOpeningInventoryFromPhysicalCount", obj).then((res)=>{

               var snackbarProps = {
                   open: true,
                   variant: "success",
                   message: res.data,
                   autoHideDuration: 2000,
               };
               token.setSnackbarProps({snackbarProps: snackbarProps});
            })
            .catch((err) => {
                var message = err.message;
                if (err.response !== undefined) {
                  message = err.response.data;
                }
                var snackbarProps = {
                  open: true,
                  variant: "error",
                  message: "Failed",
                  autoHideDuration: 2000,
                };
                token.setSnackbarProps({ snackbarProps: snackbarProps });
              });
        }

  render() {
    const { classes } = this.props;

    return (
        <div
        style={{
            backgroundColor: "#EAEDEF",
          }}
          >
           <Grid container justify="center" className={classes.root}>
                <Card className={classes.tabMargin}>
                    <CardContent style={{ padding: "30px"}}>
                    <Grid container justify="center">
                        <Typography
                        variant="h5"
                        component="h2"
                        style={{ fontSize: "20px" }}
                        >
                        Inventory Carry Forward
                        </Typography>
                    </Grid>

                        <Grid container spacing={2} style={{marginTop: "25px"}}>
                            <Grid item xs={6} md={3} sm={3}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Fiscal Year"
                                    name="fiscalYear"
                                    onChange={this.handleFYChange}
                                    value={this.state.fiscalYear}
                                    >
                                    {this.state.fyList.map((item, i) => (
                                        <MenuItem key={i} value={item}>
                                        {item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6} md={3} sm={3}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Period"
                                    name="currentPeriod"
                                    value={this.state.currentPeriod}
                                    onChange={this.handlePeriodChange}
                                >
                                    {this.state.periodList.map((item, i) => (
                                        <MenuItem key={i} value={item}>
                                        {item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6} md={2} sm={2}>
                            {this.state.resErr ? (
                                <>
                                 <Button
                                 style={{
                                    marginTop: "10px",
                                    padding: "7px",
                                    
                                }}
                                variant="contained"
                                color="secondary"
                                >
                                Find Closing Stock
                            </Button>
                            <Typography
                              style={{
                                  color: "#d61c1c",
                                  margin: "10px",
                                  paddingLeft: "10px"
                              }}
                            >
                                Not Found
                            </Typography>
                            </>
                            ) : (
                                <>
                                <Button
                                style={{
                                   marginTop: "10px",
                                   padding: "7px",
                                   
                               }}
                               variant="contained"
                               color="primary"
                               onClick={this.handleClick}
                               >
                               Find Closing Stock
                           </Button>
                           </>
                            )}
                            </Grid>
                            <Grid item xs={6} md={3} sm={3}>
                                <Button
                                    style={{
                                    marginTop: "10px",
                                    padding: "7px",
                                        
                                    }}
                                    variant="contained"
                                    color="primary"
                                    disabled= {this.state.shouldDisableApply}
                                    onClick={this.handleApply}
                                >
                                Apply 
                                </Button>
                                
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </div>   
    );
}
}
export default withStyles(styles)(InventoryCF);