import React, { Component } from "react";
import {
  Card,
  CardContent,
  Container,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  withStyles,
  Grid,
  TableBody
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ViewDepreciationVoucherList from "../components/DepreciationVoucher/ViewDepreciationVoucher";

const styles = theme => ({});

class DepreciationVoucher extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      rowData: [],
      dataToSend: {
        fiscalYear: "",
        createdAt: "",
        voucherNumber: "",
        debitSide: [],
        creditSide: []
      },
      visible: true,
      hidden: false,
      secondVisible: false,
      secondHidden: true,
      animationIn: ""
    };
  }

  animate = val => {
    let debitSide = [];
    let creditSide = [];

    val.depreciationVoucherDetails.map(element =>
      element.voucherType === "Credit"
        ? debitSide.push(element)
        : creditSide.push(element)
    );

    let newVal = {
      ...val,
      debitSide,
      creditSide
    };

    this.setState(prevState => ({
      ...prevState,
      dataToSend: newVal,
      visible: false,
      animationIn: "zoomIn"
    }));

    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        secondVisible: true,
        hidden: true,
        secondHidden: false
      }));
    }, 150);
  };

  animateOut = () => {
    this.setState(prevState => ({
      ...prevState,
      secondVisible: false
    }));

    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        visible: true,
        hidden: false,
        secondHidden: true
      }));
    }, 150);
  };

  componentDidMount() {
    Axios.get("pos/depreciationVoucher/getAll")
      .then(res => {
        console.log(res.data);
        this.setState(prevState => ({
          ...prevState,
          rowData: res.data
        }));
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5"
    };

    return (
      <>

        <HeaderTitle title="Quotation" />

        <Container>
          {/* <Grid container spacing={1}>
              <Grid item md={4} lg={4} sm={4}>
                <TextField select fullWidth label="Fiscal Year"></TextField>
              </Grid>
              <Grid item xs>
                <Button
                  color="primary"
                  style={{ marginTop: "15px" }}
                  onClick={() => {
                    this.setState({ filterShow: !this.state.filterShow });
                  }}
                >
                  
                    <Typography variant="button">
                      <FontAwesomeIcon
                        icon={faSlidersH}
                        style={{ marginRight: "5px" }}
                      ></FontAwesomeIcon>
                      Filter
                    </Typography>
                  
                </Button>
              </Grid>
            </Grid>

            {this.state.filterShow ? (
              <Grid
                container
                spacing={4}
                style={{
                  marginLeft: "0px",
                  marginTop: 20,
                  marginBottom: 20,
                  border: "#87CEFA 2px solid",
                  borderRadius: "25px",
                  width: "67vw"
                }}
              >
                <div
                  style={{
                    position: "relative",
                    top: "-0.9em",
                    marginLeft: "2em",
                    height: "10px",
                    display: "inline",
                    backgroundColor: "#fff",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom: "15px"
                  }}
                >
                  <Typography variant="h6">Filter By</Typography>
                </div>
                <Grid container spacing={4} style={{padding: 20}}>
                  <Grid item xs={7} md={6} sm={6}>
                    <Dropdown
                      placeholder="Search by Voucher Code"
                      fluid
                      search
                      ref={search => {
                        this.searchInput = search;
                      }}
                      selection
                      value={this.props.customer}
                      onChange={this.handleCustomerSelect}
                      options={this.state.customerRowRefs}
                      style={{ marginTop: "33px" }}
                    ></Dropdown>
                  </Grid>
                  <Grid item xs sm={3}>
                    <TextField
                      id="date-picker-inline"
                      label="Date From"
                      name="dateFrom"
                      defaultValue={date}
                      type="date"
                      onChange={this.handleFromDate}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      style={textFieldStyle}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs sm={3}>
                    <TextField
                      id="date-picker-inline"
                      label="Date To"
                      name="dateTo"
                      defaultValue={date}
                      type="date"
                      onChange={this.handleToDate}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      style={textFieldStyle}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null} */}

          <Grid container justify="center">
            <Grid item xs={11}>
              <Animated
                animationIn={this.state.animationIn}
                animationOut="zoomOut"
                isVisible={this.state.visible}
                animationInDuration={500}
                animationOutDuration={500}
              >
                <Card hidden={this.state.hidden}>
                  <CardContent>
                    <TableContainer>
                      <Table
                        aria-label="customized table"
                        style={{ marginTop: "20px", marginBottom: "15px" }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell style={styledTableCell}>
                              S. No.
                            </TableCell>
                            <TableCell style={styledTableCell}>
                              Fiscal Year
                            </TableCell>
                            <TableCell style={styledTableCell}>
                              Voucher Number
                            </TableCell>
                            <TableCell style={styledTableCell}>
                              Created Date
                            </TableCell>
                            <TableCell align="center" style={styledTableCell}>
                              View
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.rowData.map(val => (
                            <TableRow>
                              <TableCell>{val.id}</TableCell>
                              <TableCell>{val.fiscalYear}</TableCell>
                              <TableCell>{val.voucherNumber}</TableCell>
                              <TableCell>{val.createdAt}</TableCell>
                              <TableCell
                                align="center"
                                onClick={() => {
                                  this.animate(val);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                ></FontAwesomeIcon>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Animated>
            </Grid>
            <Grid item xs={11}>
              <Animated
                animationIn="zoomIn"
                animationOut="zoomOut"
                isVisible={this.state.secondVisible}
                animationInDuration={500}
                animationOutDuration={500}
              >
                <ViewDepreciationVoucherList
                  data={this.state.dataToSend}
                  hidden={this.state.secondHidden}
                  animateOut={this.animateOut}
                />
              </Animated>
            </Grid>
          </Grid>
        </Container>

      </>
    );
  }
}

export default withStyles(styles)(DepreciationVoucher);
