import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import {
  Modal,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Backdrop,
  withStyles,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import UserContext from "../../utils/UserContext";

const styles = (theme) => ({
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "600px",
    },
  },
});

class ViewSubCategoryButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      data: {
        subCategoryName: "",
        category: {
          id: "",
        },
        under: "",
      },
      categoryData: [],
      open: false,
      editable: false,
      confirmOpen: false,
      unclicked: "#108dcd",
      clicked: "#1044CD",
      buttonColor: "#108dcd",
      openStatus: false,
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.setState({
      data: {
        subCategoryName: this.props.node.data.subCategoryName,
        under: this.props.node.data.under,
      },
    });

    Axios.get("pos/category/getAllCategories")
      .then((res) => {
        this.categoryData = res.data;
        console.log(res);
        this.setState({ categoryData: this.categoryData });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, editable: false });
  };

  handleStatusClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      openStatus: Boolean(event.currentTarget),
      open: false,
    });
  };

  handleStatusTrue = async () => {
    var id = this.props.node.data.id;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        status: true,
      },
    }));
    console.log(this.state.data.status);
    Axios.put(
      "pos/subCategory/updateStatus/" + id + "/" + this.state.data.status
    )
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        this.props.setSnackbar(snackbarProps);
        this.props.updateData();
        this.handleStatusClose();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000,
        };

        this.props.setSnackbar(snackbarProps);
      });
    this.setState({ anchorEl: null, openStatus: false });
  };

  handleStatusFalse = async () => {
    var id = this.props.node.data.id;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        status: false,
      },
    }));
    console.log(this.state.data.status);
    Axios.put(
      "pos/subCategory/updateStatus/" + id + "/" + this.state.data.status
    )
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        this.props.setSnackbar(snackbarProps);
        this.props.updateData();
        this.handleStatusClose();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000,
        };

        this.props.setSnackbar(snackbarProps);
      });
    this.setState({ anchorEl: null, openStatus: false });
  };

  onChanged = async (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  handleUpdate = () => {
    var id = this.props.node.data.id;
    let obj = {
      subCategoryName: this.state.data.subCategoryName,
    };
    Axios.put("pos/subCategory/update/" + id, obj)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        this.props.setSnackbar(snackbarProps);
        this.props.updateData();
        this.handleClose();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000,
        };

        this.props.setSnackbar(snackbarProps);
      });
  };

  render() {
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const inputStyles = {
      fontSize: 14,
    };

    const textFieldStyle = {
      width: "100%",
    };
    const { classes } = this.props;
    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>
        <span>
          <FontAwesomeIcon
            icon={faEllipsisV}
            color="#3F51B5"
            size="lg"
            onClick={this.handleClick}
            style={{ cursor: "pointer", width: "100%" }}
          ></FontAwesomeIcon>
        </span>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.openStatus}
          onClose={() => this.handleStatusClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem key={1} onClick={this.handleStatusTrue}>
            OPEN
          </MenuItem>
          <MenuItem key={2} onClick={this.handleStatusFalse}>
            <span style={{ color: "#FF5733" }}>CLOSE</span>
          </MenuItem>
        </Menu>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container style={{ padding: "20px" }}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          editable: true,
                          buttonColor: this.state.clicked,
                        });
                      }}
                      style={{
                        margin: "15px",
                        backgroundColor: this.state.buttonColor,
                      }}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Name"
                        placeholder=""
                        name="subCategoryName"
                        onChange={this.onChanged}
                        value={this.state.data.subCategoryName}
                        inputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="standard-select-currency"
                        select
                        value={this.state.data.under}
                        InputProps={{ style: inputStyles }}
                        InputLabelProps={{ style: inputStyles }}
                        label="Under"
                        name="under"
                        // onChange={this.onChanged}
                        style={textFieldStyle}
                        margin="normal"
                        // disabled
                      >
                        {this.state.categoryData.map((item, i) => (
                          <MenuItem key={i} value={item.categoryName}>
                            {item.id + " - " + item.categoryName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={10}
                    justify="center"
                    style={{ width: "70%", margin: "auto", marginTop: "25px" }}
                  >
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        {this.state.editable ? (
                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={this.handleUpdate}
                            style={{
                              width: "100%",
                              maxWidth: "150px",
                              minWidth: "100px",
                              marginTop: "15px",
                            }}
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            disabled
                            onClick={this.handleUpdate}
                            style={{
                              width: "100%",
                              maxWidth: "150px",
                              minWidth: "100px",
                              marginTop: "15px",
                            }}
                          >
                            Update
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          onClick={this.handleDelete}
                          style={{
                            width: "100%",
                            maxWidth: "150px",
                            minWidth: "100px",
                            marginTop: "15px",
                          }}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewSubCategoryButton);
