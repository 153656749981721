import React from "react";
import { useCallback } from "react";
import { TableRow, TableCell, TextField, Chip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CrossIcon from "@material-ui/icons/Close";

function RowComponent({
  sn,
  itemName,
  itemCode,
  quantity,
  warehouse,
  physicalCount,
  physicalCountError,
  comment,
  commentError,
  status,
  difference,
  handleChange,
  handleComment,
}) {
  const handlePhysicalCount = useCallback(
    (e) => {
      handleChange(itemCode, e.target.value);
    },
    [handleChange, itemCode]
  );

  const handleCommentChange = useCallback(
    (e) => {
      handleComment(itemCode, e.target.value);
    },
    [handleComment, itemCode]
  );

  return (
    <TableRow key={itemCode}>
      <TableCell>{sn}</TableCell>
      <TableCell>{itemCode}</TableCell>
      <TableCell>{itemName}</TableCell>
      <TableCell>{warehouse}</TableCell>
      <TableCell>{quantity}</TableCell>
      <TableCell>
        {physicalCountError ? (
          <TextField
            fullWidth
            error
            variant="outlined"
            margin="dense"
            type="number"
            value={physicalCount}
            name={itemCode}
            onChange={handlePhysicalCount}
          />
        ) : (
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            type="number"
            value={physicalCount}
            name={itemCode}
            onChange={handlePhysicalCount}
          />
        )}
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          type="number"
          value={difference}
        />
      </TableCell>
      <TableCell>
        {status === "OK" ? (
          <Chip
            size="medium"
            icon={<CheckIcon style={{ color: "#fff" }} />}
            label="OK"
            style={{
              backgroundColor: "#4BB543",
              color: "#fff",
            }}
          />
        ) : (
          <Chip
            size="medium"
            icon={<CrossIcon style={{ color: "#fff" }} />}
            label="VARIANCE"
            style={{
              backgroundColor: "#f44336",
              color: "#fff",
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {commentError ? (
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            error
            helperText="* Required"
            value={comment}
            onChange={handleCommentChange}
          />
        ) : (
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            value={comment}
            onChange={handleCommentChange}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

export default React.memo(RowComponent);
