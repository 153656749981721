import React, { Component } from "react";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import AddSupplier from "../components/SupplierMaster/AddSupplier";
import SupplierDetails from "../components/SupplierMaster/SupplierDetails";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class SupplierMaster extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      data: {
        productType: "Product Type 1",
        supplierType: "",
        accountType: "Normal Saving",
        supplierIdNumber: "",
      },
      supplierData: [],
      errorStatus: {},
    };
  }

  onChanged = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  onSupplierIdNumberChanged = (e) => {
    e.stopPropagation();
    console.log("SupplierIdNumberChanged");
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  setGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getTableData = () => {
    Axios.get("pos/supplier/getAllSuppliers")
      .then((res) => {

        console.log("data")
        console.log(res.data)

        var rowData = [];
        res.data.forEach((element) => {
          var obj = element;
          obj["address"] =
            (element.street === null ? "" : element.street) +
            " " +
            (element.city === null ? "" : element.city) +
            " " +
            (element.country === null ? "" : element.country);
          rowData.push(obj);
        });
        this.setState({ supplierData: rowData });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  };

  componentDidMount() {
    this.getTableData();
    this.getSupplierId();

  }

  getSupplierId = () => {
    Axios.get("/pos/supplier/getSupplierId").then(async (res) => {
      await this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          supplierIdNumber: res.data.supplierId,
        },
      }));
    });
  }

  showSnackbar = (snackbarProps) => {
    const token = this.context;

    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  validateForm() {
    var toCheck = [
      "fullName",
      "mobileNumber",
      "registrationNumber",
      "panNumber",
      "contactPerson",
      "country",
      "state",
      "city",
      "bankName",
      "accountName",
      "accountNumber",
      "bankCity",
      "bankCountry",
      "bankState",
    ];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  handleSubmit = () => {
    var objChild = {
      accountName: this.state.data.accountName,
      accountNumber: this.state.data.accountNumber,
      accountType: this.state.data.accountType,
      bankCode: this.state.data.bankCode,
      bankName: this.state.data.bankName,
      branchCode: this.state.data.branchCode,
      city: this.state.data.bankCity,
      country: this.state.data.bankCountry,
      currency: this.state.data.currency,
      email: this.state.data.email,
      fax: this.state.data.fax,
      ibanNumber: this.state.data.ibanNumber,
      postBoxNumber: this.state.data.postBox,
      state: this.state.data.bankState,
      street: this.state.data.bankStreet,
      swiftCode: this.state.data.swiftCode,
      telephone: this.state.data.telephone,
    };
    var objParent = {
      city: this.state.data.city,
      contactPerson: this.state.data.contactPerson,
      country: this.state.data.country,
      creditTerm: this.state.data.creditTerm,
      emailAddress: this.state.data.emailAddress,
      faxNumber: this.state.data.faxNumber,
      fullName: this.state.data.fullName,
      supplierIdNumber: this.state.data.supplierIdNumber,
      mobileNumber: this.state.data.mobileNumber,
      otherReference: this.state.data.otherReference,
      panNumber: this.state.data.panNumber,
      postBoxNumber: this.state.data.postBoxNumber,
      productType: this.state.data.productType,
      registrationNumber: this.state.data.registrationNumber,
      state: this.state.data.state,
      street: this.state.data.street,
      supplierBankDetails: objChild,
      supplierType: this.state.data.supplierType,
      telephoneNumber: this.state.data.telephoneNumber,
      website: this.state.data.website,
    };

    const token = this.context;
    this.validateForm();
    var status = this.validateForm();

    if (status) {
      Axios.post("pos/supplier/addSuppler", objParent)
        .then((res) => {
          console.log(res);

          this.setState((prevState) => ({
            ...prevState.data,
            data: {
              accountName: "",
              accountNumber: "",
              accountType: "",
              bankCode: "",
              bankName: "",
              branchCode: "",
              bankCity: "",
              bankCountry: "",
              currency: "",
              email: "",
              fax: "",
              ibanNumber: "",
              postBox: "",
              bankState: "",
              bankStreet: "",
              swiftCode: "",
              telephone: "",
              city: "",
              contactPerson: "",
              country: "",
              creditTerm: "",
              emailAddress: "",
              faxNumber: "",
              fullName: "",
              mobileNumber: "",
              otherReference: "",
              panNumber: "",
              postBoxNumber: "",
              productType: "",
              registrationNumber: "",
              state: "",
              street: "",
              supplierBankDetails: "",
              supplierType: "",
              telephoneNumber: "",
              website: "",
            },
          }));

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          this.getSupplierId();
          this.getTableData();
        })
        .catch((err) => {
          var snackbarProps = {
            open: true,
            variant: "error",
            message: err.message,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Supplier Master" />

          <Grid container justify="center">
            <Tabs
              className={classes.tabMargin}
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
            >
              <Tab
                style={{ fontSize: "12px" }}
                label="Supplier Details"
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Add Supplier"
                {...a11yProps(1)}
              />
            </Tabs>
            <Grid container justify="center">
              <Grid item xs={11} sm={11} md={9}>
                <SupplierDetails
                  value={this.state.value}
                  index={0}
                  supplierData={this.state.supplierData}
                  getTableData={this.getTableData}
                  setGridApi={this.setGridApi}
                  showSnackbar={this.showSnackbar}
                ></SupplierDetails>
                <AddSupplier
                  value={this.state.value}
                  index={1}
                  handleSubmit={this.handleSubmit}
                  data={this.state.data}
                  onChange={this.onChanged}
                  onSupplierIdNumberChange={this.onSupplierIdNumberChanged}
                  errorStatus={this.state.errorStatus}
                  productType={this.state.data.productType}
                  supplierType={this.state.data.supplierType}
                  accountType={this.state.data.accountType}
                ></AddSupplier>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SupplierMaster);
