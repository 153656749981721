import React, { Component } from "react";
import {
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { Label } from "semantic-ui-react";
import Axios from "axios";

class CreditorReportPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountName: "",
      subAccountName: "",
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    this.getAccountName(nextProps.accountCode, nextProps.subAccountCode);
  }

  getAccountName(accountCode, subAccountCode) {
    Axios.get("pos/ledgerMaster/getAllActiveAccountGroup")
      .then((res) => {
        res.data.map((val) => {
          if (val.code === accountCode) {
            this.setState({ accountName: val.name });
          }
          return null;
        });

        if (accountCode !== "") {
          Axios.get(
            "/pos/subLedger/getAllActiveSubLedgerByUnderCode/" + accountCode
          ).then((res) => {
            res.data.map((val) => {
              if (val.code === subAccountCode) {
                this.setState({ subAccountName: val.name });
              }
              return null;
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    return (
      <div>
        <Grid
          container
          justify="center"
          style={{ marginTop: 50, fontSize: 18 }}
        >
          <Label>Creditor Report</Label>
        </Grid>
        {this.props.filterTerm === "fiscalYear" ? (
          <>
            <Grid
              container
              justify="start"
              style={{ marginTop: 30, fontSize: 18 }}
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        marginLeft: "70px",
                        marginBottom: "10px",
                      }}
                    >
                      Fiscal Year and Periods
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        marginRight: "20px",
                      }}
                    >
                      Fiscal Year:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography style={{ fontStyle: "italic" }}>
                      {this.props.fiscalYear}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        marginRight: "20px",
                      }}
                    >
                      Period From
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography style={{ fontStyle: "italic" }}>
                      {this.props.periodFrom}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        marginRight: "20px",
                      }}
                    >
                      Period To:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography style={{ fontStyle: "italic" }}>
                      {this.props.periodTo}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              justify="start"
              style={{ marginTop: 30, fontSize: 18 }}
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        marginRight: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Date Range
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        marginRight: "20px",
                      }}
                    >
                      Date From:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography style={{ fontStyle: "italic" }}>
                      {this.props.dateFrom}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textAlign: "right",
                        marginRight: "20px",
                      }}
                    >
                      Date To:
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography style={{ fontStyle: "italic" }}>
                      {this.props.dateTo}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid
          container
          justify="center"
          style={{ marginTop: 30, fontSize: 18 }}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textAlign: "right",
                    marginRight: "20px",
                  }}
                >
                  Account Code:
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography style={{ fontStyle: "italic" }}>
                  {this.props.accountCode} - {this.state.accountName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textAlign: "right",
                    marginRight: "20px",
                  }}
                >
                  Sub Account Code:
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography style={{ fontStyle: "italic" }}>
                  {this.props.subAccountCode} - {this.state.subAccountName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          style={{ marginTop: 30, fontSize: 18 }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="left">
                    Period
                  </TableCell>
                  <TableCell component="th" align="left">
                    Date
                  </TableCell>
                  <TableCell component="th" align="left">
                    Miti
                  </TableCell>
                  <TableCell component="th" align="left">
                    Voucher Number
                  </TableCell>
                  <TableCell component="th" align="left" style={{width: "40%"}}>
                    Particulars
                  </TableCell>
                  <TableCell component="th" align="left">
                    Debit Amount
                  </TableCell>
                  <TableCell component="th" align="left">
                    Credit Amount
                  </TableCell>
                  <TableCell component="th" align="left">
                    Running Balance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.data.period}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.data.openingDate}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.data.openingDateNepali}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Opening Balance
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ fontWeight: "bold", textAlign: "right" }}
                    >
                      {format.format(this.props.data.openingDebitAmount)}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {format.format(this.props.data.openingDebitAmount)}
                  </TableCell>
                </TableRow>
                {this.props.data.booksOfAccountDetails.map((value) => (
                  <TableRow>
                    <TableCell>{this.props.data.period}</TableCell>
                    <TableCell>{value.transactionDate}</TableCell>
                    <TableCell>{value.transactionDateNepali}</TableCell>
                    <TableCell>{value.voucherNumber}</TableCell>
                    <TableCell style={{maxWidth: "150px"}}>
                      {value.particulars.slice(0,100) + "..."}
                      </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {format.format(value.debitAmount)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {format.format(value.creditAmount)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {format.format(value.runningBalance)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.data.period}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.data.closingDate}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.data.closingDateNepali}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography
                      style={{ fontWeight: "bold", textAlign: "left" }}
                    >
                      Closing Balance
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography
                      style={{ fontWeight: "bold", textAlign: "right" }}
                    >
                      {format.format(this.props.data.runningBalance)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {format.format(this.props.data.runningBalance)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography
                      style={{ fontWeight: "bold", textAlign: "left" }}
                    >
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ fontWeight: "bold", textAlign: "right" }}
                    >
                      {format.format(this.props.data.totalDebitAmount)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{ fontWeight: "bold", textAlign: "right" }}
                    >
                      {format.format(
                        this.props.data.totalCreditAmount 
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    );
  }
}
export default CreditorReportPrint;
