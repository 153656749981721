import React, { Component } from "react";
import {
  Card,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  TablePagination,
  TableContainer,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import ViewBatchInputVoucherList from "./ViewBatchInputVoucherList";

const styles = (theme) => ({
  root: {
    padding: 20,
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95vw",
      margin: "auto",
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
      margin: "auto",
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70vw",
      margin: "auto",
      marginBottom: "100px",
    },
  },
});

class BatchInputVoucherList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorArr: {},
      filterShow: false,
      page: 0,
      rowsPerPage: 5,
      rowData: [],
      dataToSend: {},
      auth: {
        visible: false,
        hidden: true,
        secondVisible: false,
        secondHidden: true,
        animationIn: "",
      },
      fyList: [],
      fiscalYear: "",
    };
  }

  generateList() {
    let arr = [];
    let data = this.props.dataList;

    console.log({ data });

    for (let index = 0; index < data.length; index++) {
      arr.push(
        <TableRow hover>
          <TableCell>{index + 1}</TableCell>
          <TableCell>{data[index].createdAt}</TableCell>
          <TableCell>{data[index].approvedDate}</TableCell>
          <TableCell>{data[index].voucherNumber}</TableCell>
          <TableCell>{data[index].fiscalYear}</TableCell>
          <TableCell>{data[index].period}</TableCell>
          <TableCell>{data[index].status}</TableCell>
          <TableCell
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => this.props.animate(data[index].id)}
          >
            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
          </TableCell>
        </TableRow>
      );
    }

    return arr;
  }

  handleRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const {
      hidden,
      secondHidden,
      animationIn,
      visible,
      secondVisible,
    } = this.props.visible;

    const { classes } = this.props;

    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
    };

    return (
      <>
        <Grid container justify="center">
          <Grid item>
            <Animated
              animationIn={animationIn}
              animationOut="zoomOut"
              isVisible={visible}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <Card role="tabpanel" hidden={hidden} className={classes.root}>
                <Typography
                  gutterBottom
                  variant="h5"
                  style={{ fontWeight: "bolder", marginBottom: "10px" }}
                >
                  Authorized Batch Input Voucher List
                </Typography>

                <TableContainer>
                  <Table
                    aria-label="customized table"
                    style={{ marginTop: "20px", marginBottom: "15px" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={styledTableCell}>S.N.</TableCell>
                        <TableCell style={styledTableCell}>
                          Created date
                        </TableCell>
                        <TableCell style={styledTableCell}>Post date</TableCell>
                        <TableCell style={styledTableCell}>
                          Voucher Number
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          Fiscal Year
                        </TableCell>
                        <TableCell style={styledTableCell}>Period</TableCell>
                        <TableCell style={styledTableCell}>Status</TableCell>
                        <TableCell align="center" style={styledTableCell}>
                          View
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.generateList().slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={this.props.dataList.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={(event, newPage) => {
                    this.setState({ page: newPage });
                  }}
                  onChangeRowsPerPage={this.handleRowsPerPage}
                />
              </Card>
            </Animated>
          </Grid>
          <Grid item>
            <Animated
              animationIn="zoomIn"
              animationOut="zoomOut"
              isVisible={secondVisible}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <ViewBatchInputVoucherList
                hidden={secondHidden}
                animateOut={this.props.animateOut}
                data={this.props.data}
                updateData={this.getTableData}
                loading={this.props.loading}
              />
            </Animated>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(BatchInputVoucherList);
