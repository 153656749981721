import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

function ValidatedTextField(props) {
  const {
    error,
    name,
    type,
    label,
    value,
    textFieldStyle,
    inputStyles,
    onChange
  } = props;

  var errorText = label + " should not be empty";

  if (error) {
    return (
      <TextField
        id="standard-with-placeholder"
        label={label}
        placeholder=""
        name={name}
        error
        type={type}
        helperText={errorText}
        value={value}
        onChange={onChange}
        inputProps={{ style: inputStyles }}
        InputLabelProps={{ style: inputStyles }}
        style={textFieldStyle}
        {...props}
        margin="normal"
      />
    );
  } else {
    return (
      <TextField
        id="standard-with-placeholder"
        label={label}
        placeholder=""
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        {...props}
        inputProps={{ style: inputStyles }}
        InputLabelProps={{ style: inputStyles }}
        style={textFieldStyle}
        margin="normal"
      />
    );
  }
}

ValidatedTextField.propTypes = {
  error: PropTypes.bool
};

export default ValidatedTextField;
