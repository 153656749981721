import React, { Component } from "react";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import AddScheme from "../components/VoucherNumberingScheme/AddScheme";
import ManageScheme from "../components/VoucherNumberingScheme/ManageScheme";
import Axios from "axios";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class VoucherNumberingScheme extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      data: [],
      rowRefs: [],
      statusRefs: [],
      origData: [],
      fyList: [],
      distinctFyList: [],
      currentFy: "",
      fiscalYearRefs: [],
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    this.getFiscalYear();
  }

  getFiscalYear() {
    Axios.get("fy/findTillCurrentPeriod").then((res) => {
      var data = [];
      var fyList = [];
      var nonDistinctFyList = [];
      var distinctFyList = [];
      res.data.forEach((element, i) => {
        var item = {
          status: element.currentStatus,
          fiscalYear: element.fiscalYear,
        };
        fyList.push(item);
        nonDistinctFyList.push(item.fiscalYear);
      });

      this.getCurrentFiscalYear(fyList);

      distinctFyList.push(...new Set(nonDistinctFyList));

      distinctFyList.forEach((element, i) => {
        var item = {
          key: i,
          text: element,
          value: element,
        };
        data.push(item);

        this.setState((prevState) => ({
          ...prevState,
          fiscalYearRefs: data,
        }));
      });
    });
  }

  populateTable = (currentFy) => {
    var rowRefs = [];
    var statusRefs = [];
    var rowData = [];

    Axios.get("pos/voucherNumbering/findAll")
      .then((res) => {
        rowRefs.push({
          key: 0,
          text: "All Schemes....",
          value: "All Schemes....",
        });

        let statusValues = ["All Status....", "ACTIVE", "INACTIVE", "CLOSED"];

        statusValues.forEach((element, i) => {
          statusRefs.push({
            key: i,
            text: element,
            value: element,
          });
        });

        console.log(res);

        res.data.forEach((element) => {
          rowRefs.push({
            key: element.id,
            text: element.name,
            value: element.name,
          });
        });

        var unique = {};
        var distinct = [];

        for (var i in rowRefs) {
          if (typeof unique[rowRefs[i].text] === "undefined")
            distinct.push(rowRefs[i]);

          unique[rowRefs[i].text] = 0;
        }

        this.setState({
          origData: res.data,
          rowRefs: distinct,
          statusRefs,
        });

        this.state.origData.forEach((element) => {
          if (element.fiscalYear === currentFy) rowData.push(element);
        });

        this.setState({
          data: rowData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentFiscalYear(fyList) {
    var currentFy = "";

    fyList.forEach((element) => {
      if (element.status === true) {
        currentFy = element.fiscalYear;
        this.setState({ currentFy });
      }
    });

    this.populateTable(currentFy);
  }

  changeData = (data) => {
    this.setState({ data });
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <div>
        <HeaderTitle title="User Master" />

        <Grid container>
          <Grid container justify="center" className={classes.tabMargin}>
            <Tabs
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
            >
              <Tab
                style={{ fontSize: "12px" }}
                label="Manage Numbering Scheme"
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Add Numbering Scheme"
                {...a11yProps(1)}
              />
            </Tabs>
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <ManageScheme
                value={this.state.value}
                index={0}
                data={this.state.data}
                changeData={this.changeData}
                origData={this.state.origData}
                rowRefs={this.state.rowRefs}
                statusRefs={this.state.statusRefs}
                populateTable={this.populateTable}
                fiscalYearRefs={this.state.fiscalYearRefs}
                currentFy={this.state.currentFy}
              ></ManageScheme>
              <AddScheme
                value={this.state.value}
                index={1}
                populateTable={() => this.populateTable(this.state.currentFy)}
                fiscalYearRefs={this.state.fiscalYearRefs}
              ></AddScheme>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(VoucherNumberingScheme);
