import React, { Component } from "react";
import { Grid, Card, CardContent, withStyles } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";
import UserContext from "../../utils/UserContext";
import ViewGoodsReceivedButton from "../InterfaceElements/ViewGoodsReceivedButton";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class GoodsReceivedNoteAuthorization extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headerName: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
          width: 50,
        },
        {
          headerName: "GRN No.",
          field: "grnNumber",
          sortable: true,
          resizable: true,
          minWidth: 125,
          filter:true
        },
        {
          headerName: "GRN Date",
          field: "grnDate",
          sortable: true,
          resizable: true,
          minWidth: 100,
          filter:true
        },
        {
          headerName: "PO Number",
          field: "purchaseOrderNumber",
          sortable: true,
          resizable: true,
          minWidth: 100,
          filter:true
        },
        {
          headerName: "Received From",
          field: "receivedFrom",
          resizable: true,
          sortable: true,
          minWidth: 130,
          filter:true
        },
        {
          headerName: "Received By",
          field: "receivedBy",
          sortable: true,
          resizable: true,
          minWidth: 130,
          filter:true
        },
        {
          headerName: "Invoice Number",
          field: "invoiceNumber",
          resizable: true,
          sortable: true,
          minWidth: 115,
          filter:true
        },
        {
          headerName: "Status",
          field: "status",
          resizable: true,
          sortable: true,
          minWidth: 80,
          width: 80,
        },
        {
          headerName: "Grand Total",
          field: "grandTotal",
          resizable: true,
          sortable: true,
          minWidth: 90,
          filter:true
        },
        {
          headerName: "View",
          cellRendererFramework: ViewGoodsReceivedButton,
          cellRendererParams: {
            showSnackbar: this.showSnackbar,
            getListData: this.getListData,
            getGRNData: this.props.getGrnDataForAuth,
          },
          minWidth: 53,
          width: 53,
        },
      ],
      rowAllData: [],
      overlayLoadingTemplate:
      '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
      '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  getListData = () => {
    this.props.getListData();
  };

  showSnackbar = (snackbarProps) => {
    const token = this.context;

    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

//   componentDidMount() {
//     this.setState({
//       overlayLoadingTemplate: "<span></span>"
//     })
//     Axios.get("/pos/grnMaster/findAllApproved")
//     .then((res) => {

//       this.setState((prevState) => ({
//         ...prevState,
//         rowAllData: res.data,
//       }));

//       this.gridApi.sizeColumnsToFit();
      
//       try {
//         this.gridApi.hideOverlay();
//         if (res.data.length === 0){

//           this.setState({
//             //overlayLoadingTemplate: "<span></span>"
//             overlayNoRowsTemplate: "<span>No rows to show</span>"
//           })
//         } this.gridApi.showNoRowsOverlay();
//       } catch (error) {
//         console.log(error);
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//       this.setState({
//         overlayNoRowsTemplate: "<span>No rows to show</span>",
//       });
//       try {
//         this.gridApi.hideOverlay();
//         this.gridApi.showNoRowsOverlay();
//       } catch (error) {
//         console.log(error);
//       }
//     });
// }

  componentDidUpdate() {
    if (!this.gridApi) return;
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render() {
    const { classes, value, index, ...other } = this.props;
    return (
      <div style={{ backgroundColor: "#EAEDEF" }}>
        <Grid container>
          <Card
            className={classes.root}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
          >
            <CardContent>
              <Grid container justify="center">
                <div
                  className="ag-theme-balham"
                  style={{
                    height: "60vh",
                    width: "100%",
                    marginTop: "0px",
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={this.props.data}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                  ></AgGridReact>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(GoodsReceivedNoteAuthorization);
