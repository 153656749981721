import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Divider,
  Button,
  MenuItem,
  withStyles,
  Typography,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { Search, Dropdown } from "semantic-ui-react";
import dateFormat from "dateformat";
import { parseISO, isAfter, isBefore, format } from "date-fns/esm";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Axios from "axios";
import DeleteButton from "../InterfaceElements/DeleteButton";
import UserContext from "../../utils/UserContext";
import VoucherNumberRestriction from "../../components/InterfaceElements/VoucherNumberRestriction";
import _ from "lodash";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "5px",
    paddingBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
  },
});

class AddGoodsIssuedNote extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.timeout = 0;

    this.state = {
      allData: [],
      data: {
        period: "",
        fiscalYear: "",
        attention: "",
        comment: "",
        companyAddress: "",
        companyPan: "",
        customerAddress: "",
        customerContact: "",
        customerPan: "",
        deliveryLocation: "",
        ginDate: "",
        ginNumber: "",
        grandTotal: 0,
        invoiceNumber: "",
        ginDetails: [],
        issuedTo: "",
        requestTo: 0,
        salesOrderNumber: "",
        soldByCompany: "",
        soldByUser: "",
        warehouse: "",
        transportNumber: "",
        chalanNumber: "",
        periodDateFrom: "",
        periodDateTo: "",
      },
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          width: 60,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          sortable: true,
          resizable: true,
          width: 100,
        },
        {
          headerName: "Item Name",
          field: "itemName",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Unit",
          field: "unit",
          width: 100,
          sortable: true,
          resizable: true,
          editable: true,
        },
        {
          headerName: "Purchase Price",
          field: "price",
          sortable: true,
          editable: true,
          width: 120,
        },
        {
          headerName: "Selling Price",
          field: "sellingPrice",
          width: 120,
        },
        {
          headerName: "Order Quantity",
          field: "orderQuantity",
          sortable: true,
          editable: true,
          width: 100,
        },
        {
          headerName: "Sales Quantity",
          field: "salesQuantity",
          sortable: true,
          editable: true,
          width: 100,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
          width: 150,
        },
        {
          headerName: "Comments",
          field: "comments",
          sortable: true,
          editable: true,
        },
        {
          headerName: "Delete",
          width: 80,
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
          },
        },
      ],
      rowData: [],
      orderQuantity: 1,
      salesQuantity: 1,
      rowRefs: [],
      resData: [],
      itemDetails: {
        quantity: "",
      },
      sn: 1,
      periodList: [],
      fyList: [],
      voucherNoList: [],
      voucherNumberError: false,
      voucherNumber: "",
      itemValue: "",
      customerList: [],
      customerOptionList: [],
      isLoading: false,
      emptyWarehouse: false,
    };
  }

  handleSearch = (e, { value }) => {
    this.setState({ isLoading: true, itemValue: value });

    let newOptions = [];

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(async () => {
      await Axios.get("pos/inventory/searchItems/" + value)
        .then(async (res) => {
          res.data.map((val) =>{
           if(val.quantity>0){
            newOptions.push({
              title: val.itemCode,
              description: val.itemName,
            })
          }}
          );

          await this.setState((prevState) => ({
            ...prevState,
            isLoading: false,
            rowRefs: newOptions,
            allData: res.data,
          }));
        })

        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };

  handleItemSelect = async (event, data) => {
    const { result } = data;

    this.setState((prevState) => ({
      ...prevState,
      itemValue: result.title,
    }));

    const itemDetails = this.state.allData;

    const getRowData = itemDetails.find(
      ({ itemCode }) => itemCode === result.title
    );

    await this.setState((prevState) => ({
      ...prevState,
      itemDetails: getRowData,
    }));

    /*   Axios.get("pos/inventory/getInventoryByItemCode/" + result.title)
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          itemDetails: res.data,
        }));
      })
      .catch((err) => console.log(err)); */
  };

  deleteRow = (data) => {
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      var obj = rowAllData[i];

      if (obj.sn === data.sn) {
        finalRows.splice(i, 1);

        break;
      }
    }

    this.setState({
      rowData: finalRows,
    });
    this.updateGrandTotal();
  };

  updateGrandTotal() {
    var gTotal = 0.0;
    this.state.rowData.forEach((element) => {
      gTotal += parseFloat(Math.round(element.lineTotal * 100) / 100);
    });
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grandTotal: parseFloat(Math.round(gTotal*100)/100),
      },
    }));
  }

  onCellValueChanged = (params) => {
    console.log(params.data);

    var rowAllData = this.state.rowData;

    let grandTotal = 0;

    rowAllData.forEach((element) => {
      if (element.sn === params.data.sn) {
        var obj = element;

        if (isNaN(params.data.price)) obj.price = 0;

        obj.lineTotal = parseFloat(
          Math.round(obj.price * obj.salesQuantity * 100) / 100
        );
      }

      grandTotal += parseFloat(Math.round(element.lineTotal * 100) / 100);
    });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grandTotal: parseFloat(Math.round(grandTotal*100)/100),
      },
    }));

    //this.setState({ rowData: rowAllData });
    this.gridApi.setRowData(rowAllData);
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handle0Index = (rowAllData, itemData) => {
    var rowItemData = {
      sn: this.state.sn,
      itemName: itemData.itemName,
      itemCode: itemData.itemCode,
      salesQuantity: this.state.salesQuantity,
      orderQuantity: this.state.orderQuantity,
      lineTotal:
        Math.round(
          parseFloat(itemData.rate) * parseFloat(this.state.salesQuantity) * 100
        ) / 100,
      price: itemData.rate,
      sellingPrice: itemData.markedPrice,
      comments: "",
      unit: itemData.unit,
    };
    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.itemName === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
    }

    this.setState({ sn: this.state.sn + 1 });
  };

  handleItemAdd = () => {
    var rows = this.getAllRows();
    var itemData = this.state.itemDetails;

    if (
      this.state.salesQuantity === "0" ||
      this.state.orderQuantity === "0" ||
      itemData.itemName === undefined ||
      this.state.salesQuantity === 0 ||
      this.state.orderQuantity === 0
    ) {
      return;
    }

    var rowAllData = this.state.rowData;

    if (rows.length === 0) {
      this.handle0Index(rowAllData, itemData);
    } else {
      for (let index = 0; index < rows.length; index++) {
        const element = rowAllData[index];

        if (element.itemName === itemData.itemName) {
          var rowSpecificData = {
            ...element,
            salesQuantity:
              parseFloat(element.salesQuantity) +
                parseFloat(this.state.salesQuantity) >
              itemData.quantity
                ? itemData.quantity
                : parseFloat(element.salesQuantity) +
                  parseFloat(this.state.salesQuantity),
            orderQuantity:
              parseFloat(element.orderQuantity) +
              parseFloat(this.state.orderQuantity),
            lineTotal:
              Math.round(
                parseFloat(element.price) *
                  (parseFloat(element.salesQuantity) +
                    parseFloat(this.state.salesQuantity)) *
                  100
              ) / 100,
            price: element.price,
            comments: "",
            unit: element.unit,
          };

          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              grandTotal: parseFloat(
                Math.round(
                  prevState.data.grandTotal +
                    element.price * this.state.salesQuantity * 100
                ) / 100
              ),
            },
          }));

          rowAllData[index] = rowSpecificData;
        } else {
          this.handle0Index(rowAllData, itemData);
        }
      }
    }

    this.setState({ rowData: rowAllData });

    let grandTotal = 0;

    rowAllData.forEach((element) => {
      grandTotal +=element.lineTotal;
    });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grandTotal: parseFloat(Math.round(grandTotal*100)/100),
      },
    }));

    this.gridApi.setRowData(rowAllData);
  };

  componentDidMount= async()=> {
  
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        console.log("res");
        console.log(res.data);

        this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            soldByCompany: res.data[0].name,
            companyAddress: res.data[0].street + ", " + res.data[0].city,
            companyPan: res.data[0].panNumber,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });

      var today = new Date();

      var month = (today.getMonth() + 1).toString();
  
      var day = today.getDate().toString();
  
      if (month.length === 1) {
        month = "0" + month;
      }
  
      if (day.length === 1) {
        day = "0" + day;
      }
  
      var date = today.getFullYear() + "-" + month + "-" + day;
     await this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          ginDate: date,
        },
      }));

    this.getAllPeriodListing();
    // this.getCurrentPeriod();
    // this.getInventoryItems();
    this.getCustomerList();
    
  }
  getVoucherNumbering(fiscalYear){
   
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", { "fiscalYear":fiscalYear, "name":"Good Issued Note"})
    .then((res) =>{
      if (res.data === "Not Found") {
        this.setState({ voucherNumberError: true });
      } else {
        this.setState((prevState)=>({
          ...prevState,
          data:{
            ...prevState.data,
            ginNumber:res.data[0]
          }
      }))
      }
      
  })
  }
  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            fiscalYear: res.data.fiscalYear,
            period: res.data.name,
          },
        }));
        this.getPeriodList();
        this.getVoucherNumbering(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getCustomerList = () => {
    Axios.get("pos/customer/getAllCustomers").then((res) => {
      let customers = [];
      let customerOptionList = [{ key: "None", value: "None", text: "None" }];
      res.data.forEach((element) => {
        customers.push(element);

        const item = {
          key: element.customerIdNumber,
          value: element.fullName,
          text: element.fullName,
        };
        customerOptionList.push(item);
      });
      this.setState({
        customerList: customers,
        customerOptionList: customerOptionList,
      });
    });
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data:{
        ...prevState.data,
        ginNumber:val,
      },
    }));
  };

  handleFYChange = async (e) => {
    let fy = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        fiscalYear: fy,
      },
    }));

    this.getPeriodList();
    this.getVoucherNumbering(fy);
  };

  getPeriodList() {
    var pLists = [];

    this.state.resData.map((val) =>
      Object.keys(val).includes(this.state.data.fiscalYear)
        ? (pLists = val[this.state.data.fiscalYear])
        : null
    );

    this.setState((prevState)=>({
      ...prevState,
      periodList: pLists,
      data: {
        ...prevState.data,
        period:pLists[pLists.length-1]
      },
    }));
    this.getPeriodDateFromTo(this.state.data.fiscalYear,pLists[pLists.length-1])
  }

  handlePeriodChange = async (e) => {
    let py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        period: py,
      },
    }));
    this.getPeriodDateFromTo(this.state.data.fiscalYear, py);
  };

  
  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };

  handleTextChangeIssuedTo = (event, data) => {
    const { value } = data;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        issuedTo: value,
      },
    }));

    if (value === "None") {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          customerAddress: "None",
          customerPan: "None",
        },
      }));
    } else {
      this.state.customerList.map((val) => {
        if (val.fullName === value) {
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              customerAddress:
                (val.street === null ? "" : val.street + "-") +
                (val.city === null ? "" : val.city),
              customerPan: val.pan || "",
            },
          }));
        }
        return null;
      });
    }
  };
  handleQuantityChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var tempValue = parseFloat(Math.round(e.target.value * 100) / 100);
    var value = 0.0;
    let itemCode  =  this.state.itemDetails.itemCode;

    if (targetName === "salesQuantity") {
      // Axios.post("/pos/ginMaster/getQuantityByItemCode" , {"itemCode": itemCode} ).then((res)=>{
      //   this.setState((prevState)=>({
      //     ...prevState,
      //     itemDetails: {
      //       ...prevState.itemDetails,
      //       quantity: res.data.quantity,
      //     },
      //   }));
      // })
      // .catch((err)=>{
      //   console.log(err);
      // });

      if (tempValue > this.state.itemDetails.quantity) {
        value = parseFloat(
          Math.round(this.state.itemDetails.quantity * 100) / 100
        );
      } else {
        value = tempValue;
      }
    } else value = tempValue;

    this.setState((prevState) => ({
      ...prevState,
      [targetName]: value,
    }));
  };

  handleSubmit = () => {
    let data = this.state.data;
    data["ginDetails"] = this.state.rowData;
    const token = this.context;
    if (data.warehouse === "" || undefined) {
      this.setState({ emptyWarehouse: true });
      return;
    } else {
      this.setState({ emptyWarehouse: false });
    }
    if (data.ginDetails.length === 0) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No item selected",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
      return;
    }
    Axios.post("pos/ginMaster/save", data)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.resetData();
        this.props.getGinDataForAuth();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  resetData() {
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        attention: "",
        comment: "",
        customerAddress: "",
        customerContact: "",
        customerPan: "",
        deliveryLocation: "",
        grandTotal: 0,
        invoiceNumber: "",
        ginDetails: [],
        issuedTo: "",
        requestTo: 0,
        salesOrderNumber: "",
        soldByUser: "",
        warehouse: "",
        chalanNumber: "",
        transportNumber: "",
      },
      rowData: [],
      orderQuantity: 1,
      salesQuantity: 1,
      rowRefs: [],
      resData: [],
      itemDetails: {},
      sn: 1,
      voucherNumberError: false,
      itemValue: "",
    }));
    this.getVoucherNumbering(this.state.data.fiscalYear)
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleTextChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };
  handleDateChange = (date) => {
    let formattedDate = format(new Date(date), "yyyy-MM-dd");
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        ginDate: formattedDate,
      },
    }));
  };

  shouldDisableDate = (date) => {
    let periodFrom = parseISO(this.state.periodDateFrom);
    let periodTo = parseISO(this.state.periodDateTo);
    let currentDate = new Date(date);

    if (isAfter(currentDate, periodTo) || isBefore(currentDate, periodFrom)) {
      return true;
    } else {
      return false;
    }
  };
  // getInventoryItems() {
  //   var rowDetails = [];
  //   Axios.get("pos/inventory/getInventories")
  //     .then((res) => {
  //       console.log(res);

  //       res.data.forEach((element) => {
  //         var item = {
  //           key: element.itemCode,
  //           value: element.itemCode,
  //           text: element.itemName + "  #" + element.itemCode,
  //           label: { content: "#" + element.itemCode, tag: true },
  //         };
  //         rowDetails.push(item);
  //       });
  //       this.setState({ rowRefs: rowDetails, resData: res.data });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  render() {
    const { children, value, index, classes, ...other } = this.props;

    return (
      <div>
        <Card
          className={classes.root}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Fiscal Year"
                  name="fiscalYear"
                  onChange={this.handleFYChange}
                  value={this.state.data.fiscalYear}
                >
                  {this.state.fyList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  label=" GIN Voucher No."
                  fullWidth
                  value={this.state.data.ginNumber}
                  //onChange={this.handleChange}
                  name="ginNumber"
                >
                </TextField>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Period"
                  name="period"
                  onChange={this.handlePeriodChange}
                  value={this.state.data.period}
                >
                  {this.state.periodList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                    id="outlined-dense"
                    label="GRN Date"
                    name="grnDate"
                    autoOk={true}
                    format="yyyy-MM-dd"
                    fullWidth
                    margin="dense"
                    inputVariant="outlined"
                    value={this.state.data.ginDate}
                    onChange={this.handleDateChange}
                    InputLabelProps={{ shrink: true }} 
                    minDate={this.state.periodDateFrom}
                    maxDate={this.state.periodDateTo}
                    shouldDisableDate={this.shouldDisableDate}
                    animateYearScrolling
                  />
              </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Sold By - Company"
                  name="soldByCompany"
                  value={this.state.data.soldByCompany}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Company Address"
                  name="companyAddress"
                  value={this.state.data.companyAddress}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Company PAN"
                  name="companyPan"
                  value={this.state.data.companyPan}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  placeholder="Issued To - Customer"
                  fluid
                  search
                  style={{ marginTop: "8px", width: "100%" }}
                  selection
                  floating
                  value={this.state.data.issuedTo}
                  onChange={this.handleTextChangeIssuedTo}
                  options={this.state.customerOptionList}
                ></Dropdown>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Customer Address"
                  name="customerAddress"
                  value={this.state.data.customerAddress}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Customer PAN"
                  name="customerPan"
                  value={this.state.data.customerPan}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-dense"
                  label="Sales Invoice Number"
                  name="invoiceNumber"
                  value={this.state.data.invoiceNumber}
                  onChange={this.handleTextChange}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-dense"
                  label="Sales Order Number"
                  name="salesOrderNumber"
                  value={this.state.data.salesOrderNumber}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-dense"
                  label="Transport No."
                  name="transportNumber"
                  value={this.state.data.transportNumber}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-dense"
                  label="Chalan Number"
                  name="chalanNumber"
                  value={this.state.data.chalanNumber}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Delivery Location"
                  name="deliveryLocation"
                  value={this.state.data.deliveryLocation}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Contact"
                  name="customerContact"
                  onChange={this.handleTextChange}
                  value={this.state.data.customerContact}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Attention"
                  name="attention"
                  value={this.state.data.attention}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {this.state.emptyWarehouse ? (
                  <>
                    <TextField
                      id="outlined-dense"
                      label="Warehouse"
                      name="warehouse"
                      value={this.state.data.warehouse}
                      onChange={this.handleTextChange}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      select
                      error
                      helperText="field should not empty"
                    >
                      {this.props.warehouseList.map((val, id) => (
                        <MenuItem key={val.id} value={val.name}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                ) : (
                  <>
                    <TextField
                      id="outlined-dense"
                      label="Warehouse"
                      name="warehouse"
                      value={this.state.data.warehouse}
                      onChange={this.handleTextChange}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      select
                    >
                      {this.props.warehouseList.map((val, id) => (
                        <MenuItem key={val.id} value={val.name}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Sold By - User"
                  name="soldByUser"
                  value={this.state.data.soldByUser}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />

            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Search
                  input={{ fluid: true }}
                  loading={this.state.isLoading}
                  placeholder="Search by Item Code or Item Name"
                  style={{ marginTop: "15px", maxHeight: "500px" }}
                  onSearchChange={_.debounce(this.handleSearch, 500, {
                    leading: true,
                  })}
                  results={this.state.rowRefs}
                  onResultSelect={this.handleItemSelect}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={2}>
                <TextField
                  placeholder="Order Quantity"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    paddingBottom: "0px",
                  }}
                  type="number"
                  label="Order Qty"
                  onChange={this.handleQuantityChange}
                  name="orderQuantity"
                  value={this.state.orderQuantity}
                  min={1}
                ></TextField>
              </Grid>

              <Grid item xs={4} sm={4} md={2}>
                <TextField
                  placeholder="Sales Quantity"
                  style={{ width: "100%", marginTop: "10px" }}
                  type="number"
                  name="salesQuantity"
                  label="Sales Qty"
                  onChange={this.handleQuantityChange}
                  value={this.state.salesQuantity}
                  min={1}
                ></TextField>
              </Grid>

              <Grid item xs={4} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={this.handleItemAdd}
                  style={{
                    minWidth: "60px",
                    margin: "auto",
                    height: "38px",
                    marginTop: "15px",
                  }}
                >
                  +Add
                </Button>
              </Grid>

              <div
                className="ag-theme-balham"
                style={{
                  height: "24vh",
                  width: "100%",
                  margin: "10px",
                }}
              >
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  animateRows={true}
                  rowSelection={this.state.rowSelection}
                  onGridReady={this.onGridReady}
                  onCellValueChanged={this.onCellValueChanged.bind(this)}
                  enableCellChangeFlash={true}
                ></AgGridReact>
              </div>
            </Grid>
            <Grid container justify="flex-end">
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                Grand Total: {this.state.data.grandTotal}
              </Typography>
            </Grid>
            <Grid container justify="center" style={{ marginTop: "25px" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleSubmit}
                style={{
                  minWidth: "100px",
                  width: "20vw",
                  margin: "auto",
                  height: "50px",
                }}
              >
                Save
              </Button>
            </Grid>
          </CardContent>
        </Card>
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withStyles(styles)(AddGoodsIssuedNote);
