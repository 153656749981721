import React from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  MenuItem,
  Divider,
  Button,
  withStyles,
  Typography,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { Search, Dropdown } from "semantic-ui-react";
import { Component } from "react";

import dateFormat from "dateformat";
import { parseISO, isAfter, isBefore, format } from "date-fns/esm";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Axios from "axios";
import DeleteButton from "../InterfaceElements/DeleteButton";
import UserContext from "../../utils/UserContext";
import VoucherNumberRestriction from "../../components/InterfaceElements/VoucherNumberRestriction";
import _ from "lodash";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "5px",
    paddingBottom: "5px",
    overflow: "visible",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 150,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: 100,
    },
  },
});
class AddGoodsReceivedNote extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.timeout = 0;

    this.state = {
      data: {
        comment: "",
        transportNumber: "",
        chalanNumber: "",
        fiscalYear: "",
        grnDate: "",
        grnDetails: [],
        grnNumber: "",
        invoiceNumber: "",
        grandTotal: 0,
        period: "",
        purchaseOrderNumber: "",
        receivedAddress: "",
        receivedBy: "",
        receivedByName: "",
        receivedFrom: "",
        receivedPan: "",
        supplierAddress: "",
        supplierPan: "",
        warehouse: "",
      },
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          width: 60,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Item Name",
          field: "itemName",
          sortable: true,
          resizable: true,
        },
        {
          headerName: "Unit",
          field: "unit",
          sortable: true,
          resizable: true,
          editable: true,
        },
        {
          headerName: "Purchase Price",
          field: "price",
          sortable: true,
          editable: true,
        },
        {
          headerName: "Order Quantity",
          field: "orderQuantity",
          sortable: true,
          editable: true,
        },
        {
          headerName: "Received Quantity",
          field: "receivedQuantity",
          sortable: true,
          editable: true,
        },
        {
          headerName: "Line Total",
          field: "lineTotal",
          sortable: true,
        },
        {
          headerName: "Comments",
          field: "comments",
          sortable: true,
          editable: true,
        },
        {
          headerName: "Delete",
          cellRendererFramework: DeleteButton,
          cellRendererParams: {
            deleteRow: this.deleteRow,
          },
        },
      ],
      rowData: [],
      orderQuantity: 1,
      receivedQuantity: 1,
      rowRefs: [],
      resData: [],
      itemDetails: {},
      sn: 1,
      periodList: [],
      periodDateFrom: "",
      periodDateTo: "",
      fyList: [],
      currentPeriod: "",
      fiscalYear: "",
      voucherNumberError: false,
      itemValue: "",
      supplierList: [],
      supplierOptionList: [],
      isLoading: false,
      emptyWarehouse: false
    };
  }

  handleSearch = (e, { value }) => {
    this.setState({ isLoading: true, itemValue: value });

    let newOptions = [];

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(async () => {
      await Axios.get("pos/inventory/searchItems/" + value)
        .then(async (res) => {
          res.data.map((val) =>
            newOptions.push({
              title: val.itemCode,
              description: val.itemName,
            })
          );

          await this.setState((prevState) => ({
            ...prevState,
            isLoading: false,
            rowRefs: newOptions,
            allData: res.data,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };

  handleItemSelect = async (event, data) => {
    const { result } = data;

    this.setState((prevState) => ({
      ...prevState,
      itemValue: result.title,
    }));

    const itemDetails = this.state.allData;

    const getRowData = itemDetails.find(
      ({ itemCode }) => itemCode === result.title
    );

    await this.setState((prevState) => ({
      ...prevState,
      itemDetails: getRowData,
    }));
    //this.state.itemDetails.forEach(el=>{})

    /*  await Axios.get("pos/inventory/getInventoryByItemCode/" + result.title)
      .then((res) => {
        console.log("hello detective");
        console.log(res.data);
        console.log("hello detective");
        this.setState((prevState) => ({
          ...prevState,
          itemDetails: res.data,
        }));
      })
      .catch((err) => console.log(err)); */
  };

  deleteRow = (data) => {
    var rowAllData = this.state.rowData;

    var length = rowAllData.length;

    var finalRows = [];

    finalRows = rowAllData;

    for (var i = 0; i < length; i++) {
      var obj = rowAllData[i];

      if (obj.sn === data.sn) {
        finalRows.splice(i, 1);
        break;
      }
    }

    this.setState({
      rowData: finalRows,
    });
    this.updateGrandTotal();
  };

  updateGrandTotal() {
    var gTotal = 0.0;
    this.state.rowData.forEach((element) => {
      gTotal += parseFloat(Math.round(element.lineTotal * 100) / 100);
    });
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grandTotal: gTotal,
      },
    }));
  }

  onCellValueChanged = (params) => {
    var rowAllData = this.state.rowData;

    let grandTotal = 0;

    rowAllData.forEach((element) => {
      if (element.sn === params.data.sn) {
        var obj = element;

        if (isNaN(params.data.price)) obj.price = 0;

        obj.lineTotal = parseFloat(
          Math.round(obj.price * obj.receivedQuantity * 100) / 100
        );
      }

      grandTotal += parseFloat(Math.round(element.lineTotal * 100) / 100);
    });

    this.setState({ rowData: rowAllData });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grandTotal,
      },
    }));

    this.gridApi.setRowData(rowAllData);
  };

  getAllRows = () => {
    let rowData = [];
    this.gridApi.forEachNode((node) => rowData.push(node.data));
    return rowData;
  };

  handle0Index = (rowAllData, itemData) => {
    var rowItemData = {
      sn: this.state.sn,
      itemName: itemData.itemName,
      itemCode: itemData.itemCode,
      receivedQuantity: this.state.receivedQuantity,
      orderQuantity: this.state.orderQuantity,
      lineTotal:
        Math.round(
          itemData.rate * parseFloat(this.state.receivedQuantity) * 100
        ) / 100,
      price: itemData.rate,
      comments: "",
      unit: itemData.unit,
    };
    var toSave = true;

    rowAllData.forEach((element) => {
      if (element.itemName === itemData.itemName) {
        toSave = false;
      }
    });

    if (toSave) {
      rowAllData.push(rowItemData);
    }
    console.log({ rowItemData });
    this.setState((prevState) => ({
      ...prevState,
      sn: this.state.sn + 1,
    }));
  };

  handleItemAdd = async () => {
    var rows = this.getAllRows();
    var itemData = this.state.itemDetails;
    console.log({ itemData });

    if (
      this.state.receivedQuantity === "0" ||
      this.state.orderQuantity === "0" ||
      itemData.itemName === undefined ||
      this.state.receivedQuantity === 0 ||
      this.state.orderQuantity === 0
    ) {
      return;
    }

    var rowAllData = this.state.rowData;

    if (rows.length === 0) {
      this.handle0Index(rowAllData, itemData);
    } else {
      for (let index = 0; index < rows.length; index++) {
        const element = rowAllData[index];

        if (element.itemName === itemData.itemName) {
          var rowSpecificData = {
            ...element,
            receivedQuantity:
              parseFloat(Math.round(element.receivedQuantity * 100) / 100) +
              parseFloat(Math.round(this.state.receivedQuantity * 100) / 100),
            orderQuantity:
              parseFloat(Math.round(element.orderQuantity * 100) / 100) +
              parseFloat(Math.round(this.state.orderQuantity * 100) / 100),
            lineTotal:
              Math.round(
                parseFloat(element.price) *
                (parseFloat(element.receivedQuantity) +
                  parseFloat(this.state.receivedQuantity)) *
                100
              ) / 100,
            price: element.price,
            comments: "",
            unit: element.unit,
          };

          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              grandTotal: parseFloat(
                Math.round(
                  prevState.data.grandTotal +
                  element.price * this.state.receivedQuantity * 100
                ) / 100
              ),
            },
          }));

          rowAllData[index] = rowSpecificData;
        } else {
          this.handle0Index(rowAllData, itemData);
        }
      }
    }

    await this.setState((prevState) => ({
      ...prevState,
      rowData: rowAllData,
    }));

    let grandTotal = 0;

    rowAllData.forEach((element) => {
      grandTotal += parseFloat(Math.round(element.lineTotal * 100) / 100);
    });

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grandTotal,
      },
    }));

    this.gridApi.setRowData(rowAllData);
  };

  componentDidMount = async () => {
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            receivedBy: res.data[0].name,
            receivedAddress: res.data[0].street + ", " + res.data[0].city,
            receivedPan: res.data[0].panNumber,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;
    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grnDate: date,
      },
    }));
    this.getAllPeriodListing();
    this.getSupplierList();
  }

  getSupplierList = () => {
    Axios.get("pos/supplier/getAllSuppliers").then((res) => {
      let suppliers = [];
      let supplierOptionList = [{ key: "None", value: "None", text: "None" }];
      res.data.forEach((element) => {
        suppliers.push(element);
        const item = {
          key: element.supplierIdNumber,
          value: element.fullName,
          text: element.fullName,
        };
        supplierOptionList.push(item);
      });

      this.setState({
        supplierList: suppliers,
        supplierOptionList: supplierOptionList,
      });
    });
  };

  handleChange = (e) => {
    let val = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grnNumber: val,
      },
    }));
  };

  getAllPeriodListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          resData: res.data,
        });
        this.getCurrentPeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentPeriod() {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            fiscalYear: res.data.fiscalYear,
            period: res.data.name,
          },
        }));
        this.getPeriodList();
        this.getVoucherNumbering(res.data.fiscalYear);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handlePeriodChange = async (e) => {
    let Py = e.target.value;

    await this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        period: Py,
      },
    }));
    this.getPeriodDateFromTo(this.state.data.fiscalYear, Py);
  };

  getPeriodDateFromTo = (fiscalYear, period) => {
    Axios.post("fy/findByNameAndFiscalYear", { period, fiscalYear }).then(
      (res) =>
        this.setState((prevState)=>({
          ...prevState,
          periodDateFrom: res.data.dateFrom,
          periodDateTo: res.data.dateTo,
        }))
    );
  };

  handleFYChange = async (e) => {
    let Fy = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      fiscalYear: Fy,
      data: {
        ...prevState.data,
        fiscalYear: Fy
      }
    }));
    this.getPeriodList();
    this.getVoucherNumbering(Fy);
  };

  getPeriodList() {
    var pLists = [];
    this.state.resData.map((val) =>
      Object.keys(val).includes(this.state.data.fiscalYear)
        ? (pLists = val[this.state.data.fiscalYear])
        : null
    );
    this.setState((prevState)=>({
      ...prevState,
      periodList: pLists,
      data: {
        ...prevState.data,
        period:pLists[pLists.length-1]
      },
    }));
    this.getPeriodDateFromTo(this.state.data.fiscalYear,pLists[pLists.length-1])
  }

  handleQuantityChange = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = parseFloat(Math.round(e.target.value * 100) / 100);

    this.setState((prevState) => ({
      ...prevState,
      [targetName]: value,
    }));
  };

  handleSubmit = () => {
    let data = this.state.data;
    data["grnDetails"] = this.state.rowData;

    const token = this.context;

    if (data.warehouse === "" || undefined) {
      this.setState({ emptyWarehouse: true })
      return;
    } else {
      this.setState({ emptyWarehouse: false })
    }

    if (data.grnDetails.length === 0) {
      var errorSnackbarProps = {
        open: true,
        variant: "error",
        message: "No item selected",
        autoHideDuration: 2000,
      };
      token.setSnackbarProps({ snackbarProps: errorSnackbarProps });
      return;
    }

    Axios.post("pos/grnMaster/save", data)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        this.resetFormValues();
        this.props.getGrnDataForAuth();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  resetFormValues() {
  this.getAllPeriodListing();

    this.setState((prevState) => ({
      prevState,
      data: {
        ...prevState.data,
        comment: "",
        transportNumber: "",
        chalanNumber: "",
        grnDetails: [],
        grandTotal: 0,
        invoiceNumber: "",
        purchaseOrderNumber: "",
        receivedByName: "",
        receivedFrom: "",
        supplierAddress: "",
        supplierPan: "",
        warehouse: "",
        itemValue: "",
      },
      rowData: [],
    }));
  }

  getVoucherNumbering(fiscalYear){
    Axios.post("/pos/voucherNumbering/getVoucherNumbering", { "fiscalYear":fiscalYear, "name":"Good Received Note"})
    .then((res) =>{
      if (res.data === "Not Found") {
        this.setState({ voucherNumberError: true });
      } else {
        this.setState((prevState)=>({
          ...prevState,
          data:{
            ...prevState.data,
            grnNumber:res.data[0]
          }
      }))
      }
      
  })
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  handleTextChangeSupplier = (event, data) => {
    const { value } = data;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        receivedFrom: value,
      },
    }));
    if (value === "None") {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          supplierAddress: "None",
          supplierPan: "None",
        },
      }));
    } else {
      this.state.supplierList.map((val) => {
        if (val.fullName === value) {
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              supplierAddress:
                (val.street === null ? "" : val.street + "-") +
                (val.city == null ? "" : val.city),
              supplierPan: val.panNumber || "",
            },
          }));
        }
        return null;
      });
    }
  };

  handleTextChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };
  handleDateChange = (date) => {

    let formattedDate = format(new Date(date), "yyyy-MM-dd");
    //alert(formattedDate)
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        grnDate: formattedDate,
      },
    }));
  };
  shouldDisableDate = (date) => {
    let periodFrom = parseISO(this.state.periodDateFrom);
    let periodTo = parseISO(this.state.periodDateTo);
    let currentDate = new Date(date);

    if (isAfter(currentDate, periodTo) || isBefore(currentDate, periodFrom)) {
      return true;
    } else {
      return false;
    }
  };
  render() {
    const { children, value, index, classes, ...other } = this.props;

    return (
      <div>
        <Card
          className={classes.root}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Fiscal Year"
                  name="fiscalYear"
                  onChange={this.handleFYChange}
                  value={this.state.data.fiscalYear}
                >
                  {this.state.fyList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  label=" GRN Voucher No."
                  fullWidth
                  value={this.state.data.grnNumber}
                  //onChange={this.handleChange}
                  name="grnNumber"
                >
                </TextField>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  select
                  margin="dense"
                  fullWidth
                  label="Period"
                  name="period"
                  onChange={this.handlePeriodChange}
                  value={this.state.data.period}
                >
                  {this.state.periodList.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    id="outlined-dense"
                    label="GRN Date"
                    name="grnDate"
                    autoOk={true}
                    format="yyyy-MM-dd"
                    fullWidth
                    margin="dense"
                    inputVariant="outlined"
                    value={this.state.data.grnDate}
                    onChange={this.handleDateChange}
                    InputLabelProps={{ shrink: true }} 
                    minDate={this.state.periodDateFrom}
                    maxDate={this.state.periodDateTo}
                    shouldDisableDate={this.shouldDisableDate}
                    animateYearScrolling
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Received By - Company"
                  name="receivedBy"
                  value={this.state.data.receivedBy}
                  // onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Company Address"
                  name="receivedAddress"
                  // onChange={this.handleTextChange}
                  value={this.state.data.receivedAddress}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Company PAN"
                  name="receivedPan"
                  value={this.state.data.receivedPan}
                  // onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Dropdown
                  placeholder="Received From - Supplier"
                  fluid
                  search
                  style={{ marginTop: "8px", width: "100%" }}
                  selection
                  value={this.state.data.receivedFrom}
                  onChange={this.handleTextChangeSupplier}
                  options={this.state.supplierOptionList}
                ></Dropdown>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Supplier Address"
                  name="supplierAddress"
                  onChange={this.handleTextChange}
                  value={this.state.data.supplierAddress}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Supplier PAN"
                  name="supplierPan"
                  onChange={this.handleTextChange}
                  value={this.state.data.supplierPan}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-dense"
                  label="Supplier Invoice No."
                  onChange={this.handleTextChange}
                  name="invoiceNumber"
                  value={this.state.data.invoiceNumber}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-dense"
                  label="Purchase Order Number"
                  name="purchaseOrderNumber"
                  value={this.state.data.purchaseOrderNumber}
                  onChange={this.handleTextChange}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-dense"
                  label="Transport No."
                  name="transportNumber"
                  value={this.state.data.transportNumber}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="outlined-dense"
                  label="Chalan Number"
                  name="chalanNumber"
                  value={this.state.data.chalanNumber}
                  onChange={this.handleTextChange}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {this.state.emptyWarehouse ?
                  <>
                    <TextField
                      id="outlined-dense"
                      select
                      label="Warehouse"
                      name="warehouse"
                      value={this.state.data.warehouse}
                      onChange={this.handleTextChange}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      error
                      helperText="field should not be empty"
                    >
                      {this.props.warehouseList.map((val, id) => (
                        <MenuItem key={val.id} value={val.name}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                  :
                  <>
                    <TextField
                      id="outlined-dense"
                      select
                      label="Warehouse"
                      name="warehouse"
                      value={this.state.data.warehouse}
                      onChange={this.handleTextChange}
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    >
                      {this.props.warehouseList.map((val, id) => (
                        <MenuItem key={val.id} value={val.name}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </TextField></>}

              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="outlined-dense"
                  label="Received By - User"
                  onChange={this.handleTextChange}
                  name="receivedByName"
                  value={this.state.data.receivedByName}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: "15px", marginBottom: "5px" }} />

            <Grid
              container
              justify="center"
              spacing={2}
            //className={classes.bottomContainerWidth}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Search
                  input={{ fluid: true }}
                  loading={this.state.isLoading}
                  placeholder="Search by Item Code or Item Name"
                  style={{ marginTop: "15px", maxHeight: "500px" }}
                  onSearchChange={_.debounce(this.handleSearch, 500, {
                    leading: true,
                  })}
                  results={this.state.rowRefs}
                  onResultSelect={this.handleItemSelect}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={2}>
                <TextField
                  placeholder="Order Quantity"
                  label="Order Qty."
                  style={{ width: "100%", marginTop: "10px" }}
                  type="number"
                  name="orderQuantity"
                  value={this.state.orderQuantity}
                  onChange={this.handleQuantityChange}
                ></TextField>
              </Grid>

              <Grid item xs={4} sm={4} md={2}>
                <TextField
                  placeholder="Received Quantity"
                  label="Received Qty."
                  style={{ width: "100%", marginTop: "10px" }}
                  type="number"
                  name="receivedQuantity"
                  value={this.state.receivedQuantity}
                  onChange={this.handleQuantityChange}
                ></TextField>
              </Grid>

              <Grid item xs={4} sm={4} md={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={this.handleItemAdd}
                  style={{
                    minWidth: "60px",
                    margin: "auto",
                    height: "38px",
                    marginTop: "15px",
                  }}
                >
                  +Add
                </Button>
              </Grid>

              <div
                className="ag-theme-balham"
                style={{
                  height: "24vh",
                  width: "100%",
                  margin: "10px",
                }}
              >
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  animateRows={true}
                  rowSelection={this.state.rowSelection}
                  onGridReady={this.onGridReady}
                  onCellValueChanged={this.onCellValueChanged.bind(this)}
                  enableCellChangeFlash={true}
                ></AgGridReact>
              </div>
            </Grid>
            <Grid container justify="flex-end">
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                Grand Total: {this.state.data.grandTotal}
              </Typography>
            </Grid>
            <Grid container justify="center" style={{ marginTop: "25px" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleSubmit}
                style={{
                  minWidth: "100px",
                  width: "20vw",
                  margin: "auto",
                  height: "50px",
                }}
              >
                Save
              </Button>
            </Grid>
          </CardContent>
        </Card>
        <VoucherNumberRestriction
          open={this.state.voucherNumberError}
        ></VoucherNumberRestriction>
      </div>
    );
  }
}

export default withStyles(styles)(AddGoodsReceivedNote);
