import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox } from "@material-ui/core";

function StateCheckbox(props) {
  const { label, editable, name, checked, onChange} = props;

  return editable ? (
    <FormControlLabel
      control={
        <Checkbox color="primary" onChange={onChange} name={name} checked={checked}/>
      }
      label={<span style={{ fontSize: 11 }}>{label}</span>}
      labelPlacement="end"
      style={{ color: "#000", marginBottom: "10px" }}
    ></FormControlLabel>
  ) : (
    <FormControlLabel
      control={
        <Checkbox color="primary" onChange={onChange} name={name} checked={checked} disabled />
      }
      label={<span style={{ fontSize: 11 }}>{label}</span>}
      labelPlacement="end"
      style={{ color: "#000", marginBottom: "10px" }}
    ></FormControlLabel>
  );
}

StateCheckbox.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool
};

export default StateCheckbox;
