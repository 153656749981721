import React, { useContext } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import ErrorIcon from "@material-ui/icons/Error";
import UserContext from "../utils/UserContext";

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
};
const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: red[600]
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));
function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

function AppSnackbar(props) {
  const snackbarProps = useContext(UserContext);

  const {
    open,
    autoHideDuration,
    variant,
    message
  } = snackbarProps.snackbarProps;

  const changeSnackbarProps = () => {

    var snackProps = {
      open: false,
      autoHideDuration: 2000,
      variant: variant,
      message: ""
    };

    snackbarProps.setSnackbarProps({ snackbarProps: snackProps });
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={changeSnackbarProps}
    >
      <MySnackbarContentWrapper
        variant={variant}
        message={message}
        onClose={changeSnackbarProps}
      />
    </Snackbar>
  );
}

export default AppSnackbar;
