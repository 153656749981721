import React, { Component } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  withStyles,
  Button,
  IconButton
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../../utils/UserContext";
import ValidatedTextField from "../../InterfaceElements/ValidatedTextField";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "90%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "500px"
    }
  }
});

class AddWarehouseModal extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        code: "",
        remarks: "",
        address: ""
      },
      errorStatus: {}
    };
  }

  validateForm() {
    var toCheck = ["name", "address"];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach(element => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState(prevState => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus
      }
    }));

    return status;
  }

  handleSubmit = () => {
    const token = this.context;

    let status = this.validateForm();

    let obj = {
      name: this.state.data.name,
      code: this.props.data.code,
      remarks: this.state.data.remarks,
      address: this.state.data.address
    }

    if (status) {
      Axios.post("/pos/warehouse/addWarehouse", obj)
        .then(res => {
          console.log(res);
          this.props.handleClose();
          this.props.updateTable();
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });

          this.setState({
            data: {}
          });
        })
        .catch(err => {
          console.log(err);
          var snackbarProps = {
            open: true,
            variant: "error",
            message: err.message,
            autoHideDuration: 2000
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  handleValueChange = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  render() {
    const { open, handleClose, classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const textFieldStyle = {
      width: "100%"
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid container style={{ padding: "20px" }}>
                  <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Code"
                        name="code"
                        value={this.props.data.code}
                        //onChange={this.handleValueChange}
                        textFieldStyle={textFieldStyle}
                        margin="normal"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ValidatedTextField
                        label="Name"
                        name="name"
                        error={this.state.errorStatus.name}
                        value={this.state.data.name}
                        onChange={this.handleValueChange}
                        textFieldStyle={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ValidatedTextField
                        label="Address"
                        name="address"
                        error={this.state.errorStatus.address}
                        value={this.state.data.address}
                        onChange={this.handleValueChange}
                        textFieldStyle={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Remarks"
                        name="remarks"
                        value={this.state.data.remarks}
                        onChange={this.handleValueChange}
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container justify="flex-end">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleSubmit}
                      style={{
                        margin: "20px",
                        marginTop: "25px"
                      }}
                    >
                      Add
                  </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(AddWarehouseModal);
