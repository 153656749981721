import React from "react";
import {
  Grid,
  Typography,
  CardContent,
  Button,
  MenuItem,
  withStyles
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import ValidatedTextField from "../InterfaceElements/ValidatedTextField";
const styles = theme => ({
  root: {

    paddingTop: "5px",
    paddingBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
      width: "80%",
      margin: "auto"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
      width: "75%",
      margin: "auto"
    }
  },

  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%"
    }
  }
});

class AddManufacturer extends React.Component {
  render() {
    const {
      onChange,
      handleSubmit,
      data,
      errorStatus,
      value,
      classes,
      index,
      ...other
    } = this.props;

    const longTextFieldStyle = {
      marginTop: "0px",
      width: "100%"
    };
    const inputStyles = {
      fontSize: 14
    };

    return (
      <div>
        <Card
          justify="center"
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>

            <Grid
              container
              justify="center"
              style={{ marginTop: "20px" }}
              spacing={2}
              className={classes.containerWidth}
            >
              <Grid item>
                <Grid container justify="center">
                  <Typography component="h6">
                    {" "}
                  Manufacturer/Distributor Details{" "}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item sm={12} xs={12} style={{ marginTop: "20px" }}>
                <TextField
                  size="small"
                  fullWidth
                  select
                  value={this.props.data.type}
                  label="Select Manufacturer or Distributor"
                  name="type"
                  onChange={onChange}
                >
                  <MenuItem value={"Manufacturer"}>Manufacturer</MenuItem>
                  <MenuItem value={"Distributor"}>Distributor</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <ValidatedTextField
                  label="Full Name"
                  name="fullName"
                  error={errorStatus.fullName}
                  onChange={onChange}
                  value={data.fullName}
                  placeholder=""
                  fullWidth
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12}>
                <ValidatedTextField
                  label="Street"
                  placeholder=""
                  error={errorStatus.street}
                  onChange={onChange}
                  value={data.street}
                  name="street"
                  fullWidth
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12}>
                <ValidatedTextField
                  label="City"
                  error={errorStatus.city}
                  placeholder=""
                  value={data.city}
                  name="city"
                  onChange={onChange}
                  fullWidth
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12}>
                <ValidatedTextField
                  label="State"
                  placeholder=""
                  fullWidth
                  value={data.state}
                  error={errorStatus.state}
                  onChange={onChange}
                  name="state"
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12}>
                <ValidatedTextField
                  label="Country"
                  placeholder=""
                  name="country"
                  value={data.country}
                  error={errorStatus.country}
                  onChange={onChange}
                  fullWidth
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid container justify="center" style={{ marginTop: "25px" }}>
                <Typography component="h6"> Supplier Details </Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  label="License No."
                  placeholder=""
                  fullWidth
                  value={data.licenseNumber}
                  // error={errorStatus.liscenseNo}
                  name="licenseNumber"
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  label="Telephone"
                  placeholder=""
                  // error={errorStatus.telephone}
                  fullWidth
                  value={data.telephone}
                  name="telephone"
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ValidatedTextField
                  label="Mobile"
                  placeholder=""
                  fullWidth
                  value={data.mobile}
                  name="mobile"
                  error={errorStatus.mobile}
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  label="P.O.Box"
                  placeholder=""
                  fullWidth
                  name="poBox"
                  value={data.poBox}
                  // error={errorStatus.poBox}
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  label="Fax"
                  placeholder=""
                  fullWidth
                  // error={errorStatus.fax}
                  name="fax"
                  value={data.fax}
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  label="Email"
                  // error={errorStatus.email}
                  placeholder=""
                  onChange={onChange}
                  fullWidth
                  value={data.email}
                  name="email"
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Website"
                  placeholder=""
                  fullWidth
                  value={data.website}
                  // error={errorStatus.liscenseNo}
                  name="website"
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid container justify="center" style={{ marginTop: "25px" }}>
                <Typography component="h6"> Product Details </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Product Type"
                  placeholder=""
                  fullWidth
                  name="typeOfProduct"
                  // error={errorStatus.productType}
                  onChange={onChange}
                  value={data.typeOfProduct}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Other Reference"
                  placeholder=""
                  fullWidth
                  // error={errorStatus.otherReference}
                  name="otherReference"
                  value={data.otherReference}
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                  margin="dense"
                />
              </Grid>

            </Grid>

            <Grid container justify="center">
              <Button
                onClick={handleSubmit}
                variant="outlined"
                size="large"
                color="primary"
                style={{
                  marginTop: "30px",
                  width: "100px",
                  fontSize: "13px"
                }}
              >
                save
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}
export default withStyles(styles)(AddManufacturer);
