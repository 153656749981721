import React, { Component } from "react";
import {
  Grid,
  Card,
  TextField,
  withStyles,
  Button,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  CardActionArea,
  CardMedia,
  CardActions,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import UserContext from "../utils/UserContext";
import Axios from "axios";
import file from "../assets/soaltee_batch.xlsx";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";

const styles = (theme) => ({
  containerWidth: {
    padding: "15px",
    marginTop: "50px",
    width: "90%",
    margin: "auto",
  },
  root: {
    paddingTop: "10px",
    paddingBottom: "10px",
    margin: "auto",
    minHeight: "35vh",
    width: "100%",
    maxWidth: "700px",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
  },
  modalCardHeight: {
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      width: "70%",
      maxHeight: 500,
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      maxHeight: 300,
    },
  },
});

class OpeningInventory extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      imageOpen: false,
      file: null,
      errorStatus: false,
      showLoading: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleImageOpen = () => {
    this.setState({ imageOpen: true });
  };

  handleImageClose = () => {
    this.setState({ imageOpen: false });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChanged = async (e) => {
    var value = e.target.files[0];
    await this.setState((prevState) => ({
      ...prevState,
      file: value,
    }));
  };

  handleSubmit = async () => {
    this.setState({ showLoading: true });

    const token = this.context;
    var file = new FormData();

    file.append("file", this.state.file);

    console.log(this.state.file);

    Axios.post("/pos/batchInput/saveInventory", file)
      .then((res) => {
        this.setState({ showLoading: false });

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuaratiion: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      })
      .catch((err) => {
        this.setState({ showLoading: false });

        console.log(err);

        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  render() {
    const { value, index, classes } = this.props;
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    return (
      <>
        <HeaderTitle title="Opening Inventory" />
        <Grid container justify="center">
          <Card
            role="tabpanel"
            hidden={value !== index}
            className={classes.root}
          >
            <Grid container spacing={1} className={classes.containerWidth}>
              <Grid item xs={10}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  style={{ width: "100%" }}
                  type="file"
                  onChange={this.onChanged}
                  size="medium"
                  name="uploadFile"
                ></TextField>
              </Grid>
              <Grid item xs={2}>
                <Button
                  style={{
                    marginTop: "10px",
                    padding: "7px",
                  }}
                  variant="contained"
                  color="inherit"
                  onClick={this.handleOpen}
                >
                  sample
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{
                    marginTop: "10px",
                    padding: "7px",
                    width: "100px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  upload
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.modalCardHeight}>
              <Grid container justify="flex-end">
                <IconButton onClick={this.handleClose}>
                  <Close />
                </IconButton>
              </Grid>
              <CardActionArea style={{ padding: 10 }}>
                <CardMedia
                  style={{ height: 350 }}
                  image={require("../assets/img/openingInventory.png")}
                  title="Contemplative Reptile"
                />
              </CardActionArea>
              <CardActions style={{ margin: "10px 0px" }}>
                <Button
                  size="small"
                  color="inherit"
                  variant="contained"
                  onClick={() => this.handleImageOpen()}
                >
                  View Image
                </Button>

                <Button
                  size="small"
                  color="inherit"
                  variant="contained"
                  href={file}
                  download
                >
                  Download Format
                </Button>
              </CardActions>
            </Card>
          </Fade>
        </Modal>
        <Modal
          open={this.state.imageOpen}
          style={modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          onClose={this.handleImageClose}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.imageOpen}>
            <Card
              style={{
                width: "95vw",
                height: "90vh",
              }}
            >
              <Grid container justify="flex-end">
                <IconButton onClick={this.handleImageClose}>
                  <Close />
                </IconButton>
              </Grid>
              <img
                src={require("../assets/img/openingInventory.png")}
                alt="Batch Input Voucher"
                width="100%"
                height="100%"
              ></img>
            </Card>
          </Fade>
        </Modal>

        <Modal
          open={this.state.showLoading}
          style={modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.showLoading}>
            <div style={{ width: "50%" }}>
              <LinearProgress variant="query" value={0} valueBuffer={5} />
              <Typography style={{ marginTop: "5px", color: "#fff" }}>
                Uploading...
              </Typography>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
}

export default withStyles(styles)(OpeningInventory);
