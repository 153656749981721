import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  withStyles,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Form, Input } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ReactExport from "react-export-excel";
import Axios from "axios";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = (theme) => ({
  tabMargin: {
    height: "auto",
    padding: 10,
    paddingRight: "7px",
    paddingLeft: "7px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "93px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "53px",
    },
  },
  root: {
    paddingTop: "10px",
    paddingBottom: "25px",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "170px",
      width: "95%",
      margin: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
      width: "90%",
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
      width: "80%",
      margin: "auto",
    },
  },
});

class ClosingStockValue extends Component {
  constructor(props) {
    super(props);
    //const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      excelData: "",
      period: "",
      fiscalYear: "",
      columnDefs: [
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          minWidth: 50,
          maxWidth: 50,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          resizable: true,
          sortable: true,
          minWidth: 75,
        },
        {
          headerName: "Item Name",
          field: "itemName",
          resizable: true,
          minWidth: 150,
        },
        {
          headerName: "Difference",
          field: "difference",
          resizable: true,
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "Comment",
          field: "comment",
          resizable: true,
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "Category",
          field: "category",
          resizable: true,
          sortable: true,
          minWidth: 90,
        },
        {
          headerName: "Purchase Price",
          field: "purchasePrice",
          resizable: true,
          sortable: true,
          minWidth: 50,
        },
        {
          headerName: " Avg/Last Cost",
          field: "averageOrLastCost",
          resizable: true,
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          resizable: true,
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Closing Stock Value",
          field: "remainingStock",
          resizable: true,
          sortable: true,
          minWidth: 75,
        },
      ],
      open: false,
      search: "",
      propertyTypeList: [
        { key: 0, value: "None", text: "None" },
        { key: 1, value: "Stock", text: "Stock" },
        { key: 2, value: "Inventory", text: "Inventory" },
        { key: 3, value: "Capital Assets", text: "Capital Assets" },
      ],
      resData: [],
      responseData: [],
      footerRow: [],
      totalAmount: 0,
      itemCount: 0,
      todaysDate: "",
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
    // this.props.setGridApi(this.gridApi);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.gridApi.showLoadingOverlay();
    // this.props.setGridApi(this.gridApi);
  };

  handleSearch = async (evt) => {
    let search = evt.target.value;
    await this.setState({ search });
    this.gridApi.setQuickFilter(this.state.search);
  };

  componentDidMount() {
    var date = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    var arr = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.setState({
      todaysDate: date + " " + arr[month] + " " + year,
    });

    Axios.get("fy/getCurrentPeriod").then(async (res) => {
      await this.setState((prevState) => ({
        ...prevState,
        period: res.data.name,
        fiscalYear: res.data.fiscalYear,
      }));
      this.getCLosingStockData();
    });
  }

  getCLosingStockData() {
    let object = {
      period: "Opening",
      fiscalYear: this.state.fiscalYear,
    };
    let total = 0;
    let row = [];
    Axios.post(
      "pos/inventoryPhysicalCount/findClosingStockByPeriodAndFiscalYear",
      object
    )
      .then((res) => {
        let resData = res.data;
        resData.forEach((el, i) => {
          let obj = {
            sn: "",
            itemCode: "",
            itemName: "",
            difference: "",
            comment: "",
            category: "",
            purchasePrice: "",
            averageOrLastCost: "",
            quantity: "",
            remainingStock: "",
          };
          obj.sn = i + 1;
          obj.itemCode = el.itemCode;
          obj.itemName = el.itemName;
          obj.difference = el.difference;
          obj.comment = el.comment;
          obj.category = el.category;
          obj.purchasePrice = el.purchasePrice;
          obj.averageOrLastCost = el.averageOrLastCost;
          obj.quantity = el.quantity;
          obj.remainingStock = el.remainingStock;
          total = total + el.remainingStock;

          row.push(obj);
        });

        this.setState((prevState) => ({
          ...prevState,
          responseData: row,
          itemCount: res.data.length,
          totalAmount: total,
        }));

        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
        let footerRow = [{ sn: "Total", remainingStock: total }];

        this.setState((prevState) => ({
          ...prevState,
          footerRow: footerRow,
        }));
        // ---------------EXCEL DATA-----------------
        let excel = [];

        let object = {
          sn: "",
          itemCode: "Closing Stock Value",
          itemName: "",
          difference: "",
          comment: "",
          category: "",
          purchasePrice: "",
          averageOrLastCost: "",
          quantity: "",
          remainingStock: "",
        };
        excel.push(object);

        object = {
          sn: "",
          itemCode: "",
          itemName: "",
          difference: "",
          comment: "",
          category: "",
          purchasePrice: "",
          averageOrLastCost: "",
          quantity: "",
          remainingStock: "",
        };
        excel.push(object);

        object = {
          sn: "S.No",
          itemCode: "Item Code",
          itemName: "Item Name",
          difference: "Difference",
          comment: "Comment",
          category: "Category",
          purchasePrice: "Purchase Price",
          averageOrLastCost: "Avg/Last Cost",
          quantity: "Quantity",
          remainingStock: "Closing Stock Value",
        };
        excel.push(object);

        res.data.map((item, i) => {
          let object1 = {
            sn: i + 1,
            itemCode: item.itemCode,
            itemName: item.itemName,
            difference: item.difference,
            comment: item.comment,
            category: item.category,
            purchasePrice: item.purchasePrice,
            averageOrLastCost: item.averageOrLastCost,
            quantity: item.quantity,
            remainingStock: item.remainingStock,
          };
          excel.push(object1);
          return null;
        });

        object = {
          sn: "",
          itemCode: "",
          itemName: "",
          difference: "",
          comment: "",
          category: "",
          purchasePrice: "",
          averageOrLastCost: "",
          quantity: "",
          remainingStock: "",
        };
        excel.push(object);

        object = {
          sn: "Total",
          itemCode: "",
          itemName: "",
          difference: "",
          comment: "",
          category: "",
          purchasePrice: "",
          averageOrLastCost: "",
          quantity: "",
          remainingStock: total,
        };
        excel.push(object);

        this.setState({
          excelData: excel,
        });
      })
      .catch((err) => {
        console.log(err);

        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  generatePdf = () => {
    function header() {
      doc.setFont("arial", "bold");
      doc.setFontSize(12);
      doc.text(300, 20, "Closing Stock Value", {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("arial", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);
    }

    var doc = new jsPDF("p", "pt", "a4", true);
    doc.setFont("arial", "normal");
    doc.setFontSize(9);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    doc.text(doc.internal.pageSize.width - 120, 20, "Printed on: " + today);
    header();

    let closingStock = [];
    let i = 1;

    this.state.responseData.map((element) =>
      closingStock.push([
        i++,
        element.itemCode,
        element.itemName,
        element.difference,
        element.comment,
        element.category,
        element.purchasePrice,
        element.averageOrLastCost,
        element.quantity,
        element.remainingStock,
      ])
    );

    let footerRow = [
      { SN: "Total", "Closing Stock Value": this.state.totalAmount },
    ];

    var y = 30;
    var x = 180;
    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        font: "helvetica",
      },
      startY: y,
      styles: { fontSize: 6 },
      coloumnStyles: { 0: { fontStyle: "bold", cellWidth: "auto" } },
      head: [
        [
          "SN",
          "Item Code",
          "Item Name",
          "Difference",
          "Comment",
          "Category",
          "Purchase Price",
          "Avg/Last Cost",
          "Quantity",
          "Closing Stock Value",
        ],
      ],
      body: closingStock,

      foot: [
        [
          {
            styles: { font: "helvetica", fontStyle: "bold" },
            content: "Total: ",
          },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          {
            styles: { font: "helvetica", fontStyle: "bold" },
            content: this.state.totalAmount,
          },
        ],
      ],
    });
    footer();
    doc.save("Closing Stock Value");
  };

  render(params) {
    const { classes } = this.props;

    return (
      <div
        style={{
          backgroundColor: "#EAEDEF",
        }}
      >
        <Grid container className={classes.root} justify="center">
          <Card className={classes.tabMargin}>
            <CardContent style={{ padding: "10px" }}>
              <Grid container justify="center">
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ fontSize: "20px" }}
                >
                  Closing Stock Value
                </Typography>
              </Grid>

              <Grid container justify="space-between">
                <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <h6 style={{ fontWeight: "bold", margin: "0px" }}>
                        {" "}
                        Item Count : {this.state.itemCount}
                      </h6>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        {" "}
                        Closing Stock as of {this.state.todaysDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                  <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                      <Form>
                        <Form.Field>
                          <Input
                            placeholder="Search by Item Code"
                            fluid
                            value={this.state.search}
                            style={{
                              margin: "5px",
                              minWidth: "190px",
                              height: "38px",
                            }}
                            onChange={this.handleSearch}
                          ></Input>
                        </Form.Field>
                      </Form>
                    </Grid>

                    <Grid item>
                      <ExcelFile
                        filename="ClosingStockValue.xlsx"
                        element={
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              minWidth: "100px",
                              width: "6vw",
                              margin: "5px",
                              height: "38px",
                              fontSize: "16px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              color="#3f51b5"
                              size="30px"
                              style={{
                                marginRight: "10px",
                              }}
                            ></FontAwesomeIcon>
                            Excel
                          </Button>
                        }
                      >
                        <ExcelSheet data={this.state.excelData} name="Sheet A">
                          <ExcelColumn value="sn" />
                          <ExcelColumn value="itemCode" />
                          <ExcelColumn value="itemName" />
                          <ExcelColumn value="difference" />
                          <ExcelColumn value="comment" />
                          <ExcelColumn value="category" />
                          <ExcelColumn value="purchasePrice" />
                          <ExcelColumn value="averageOrLastCost" />
                          <ExcelColumn value="quantity" />
                          <ExcelColumn value="remainingStock" />
                        </ExcelSheet>
                      </ExcelFile>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          minWidth: "100px",
                          width: "6vw",
                          margin: "5px",
                          height: "38px",
                          fontSize: "16px",
                        }}
                        onClick={this.generatePdf}
                      >
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          color="#3f51b5"
                          size="lg"
                          style={{
                            marginRight: "10px",
                          }}
                        ></FontAwesomeIcon>
                        Pdf
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justify="center" style={{ padding: "0px" }}>
                <div
                  className="ag-theme-balham"
                  style={{
                    height: "48vh",
                    width: "100%",
                    marginTop: "15px",
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    rowData={this.state.responseData}
                    onFirstDataRendered={this.firstDataRendered}
                    onGridReady={this.onGridReady}
                    overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                    pinnedBottomRowData={this.state.footerRow}
                  ></AgGridReact>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ClosingStockValue);
