import React, { Component } from "react";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import Axios from "axios";
import UserDetails from "../components/UserMaster/UserDetails";
import AddUser from "../components/UserMaster/AddUser";
import UserContext from "../utils/UserContext";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },
});

class UserMaster extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data: {
        paymentMode: "Mode 1",
        customerType: "Customer Type 1",
      },
      userData: [],
    };
  }

  redirectToRole = () => {
    this.props.history.push("/role/master");
  };

  setGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  componentDidMount() {
    this.getUserListing();
  }

  getUserListing = () => {
    Axios.get("hrm/findAllActiveUsers")
      .then((res) => {
        this.setState({ userData: res.data });
        this.gridApi.setRowData(res.data);
        try {
          this.gridApi.hideOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChanged = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  setSnackBar = (snackbarProps) => {
    const token = this.context;
    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  render(params) {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }
    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="User Master" />

          <Grid container>
            <Grid container justify="center" className={classes.tabMargin}>
              <Tabs
                variant="scrollable"
                value={this.state.value}
                onChange={this.handleChange}
                aria-label="Vertical tabs example"
                indicatorColor="primary"
              >
                <Tab
                  style={{ fontSize: "12px" }}
                  label="User Details"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{ fontSize: "12px" }}
                  label="Add User"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <UserDetails
                  value={this.state.value}
                  index={0}
                  setGridApi={this.setGridApi}
                  userData={this.state.userData}
                  setSnackBar={this.setSnackBar}
                  getUserData={this.getUserListing}
                  redirectToRole={this.redirectToRole}
                ></UserDetails>
                <AddUser
                  value={this.state.value}
                  index={1}
                  onChange={this.onChanged}
                  handleSubmit={this.handleSubmit}
                  customerType={this.state.data.customerType}
                  paymentMode={this.state.data.paymentMode}
                  getUserData={this.getUserListing}
                ></AddUser>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(UserMaster));
