import React, { Component } from 'react'
import {
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Typography
} from "@material-ui/core";
import { Label } from "semantic-ui-react";

class TrialBalancePrint extends Component {
    constructor(props) {
        super(props)

        this.state = {};
    }

    getFormattedVal = (val) => {
        let realVal = val;

        if (isNaN(val) && val.startsWith("(")) {
            realVal = val.substring(1, val.length - 1);

            var format = new Intl.NumberFormat("ne-NP", {
                minimumFractionDigits: 2,
            });

            return "(" + format.format(realVal) + ")";
        } else {
            var formatInt = new Intl.NumberFormat("ne-NP", {
                minimumFractionDigits: 2,
            });

            return formatInt.format(realVal);
        }
    };

    render() {
        const styledTableCell = {
            minWidth: "100px",
            fontWeight: "bold"

        };
        let i = 1;

        return (
            <div>
                <Grid
                    container
                    justify="center"
                    style={{ marginTop: 50, fontSize: 18 }}
                >
                     
                    <Label>Trial Balance</Label>
                </Grid>
                {this.props.filterTerm === "fiscalYear" ?
                    <>
                        <Grid container
                            style={{ marginTop: 30, fontSize: 18 }}
                        >
                           {/*  <Grid item xs={12} >
                                <Grid container >
                                        <Typography style={{ fontWeight: "bold", }}>
                                            Fiscal Year and Periods
                                     </Typography>
                                </Grid>
                            </Grid> */}
                          
                          <Grid item xs={8}  >
                              <Grid container justify="space-between" spacing={2} >
                                    <Grid item  >
                                        <Typography style={{ fontWeight: "bold",}}>
                                            Fiscal Year: <span>   {this.props.fiscalYear} </span>
                                     </Typography>
                                    </Grid>
                              
                                    <Grid item >
                                        <Typography style={{ fontWeight: "bold",}}>
                                            Period From: <span> {this.props.periodFrom}</span>
                                </Typography>
                                    </Grid>
                                 
                          
                                    <Grid item >
                                        <Typography style={{ fontWeight: "bold", }}>
                                            Period To: <span>  {this.props.periodTo}</span>
                                </Typography>
                                    </Grid>
                                    </Grid>
                                    </Grid>
                        </Grid>
                    </>
                    :
                    <>
                       <Grid container
                            style={{ marginTop: 30, fontSize: 18 }}
                        >
                           {/*  <Grid item xs={12} >
                                <Grid container >
                                        <Typography style={{ fontWeight: "bold", }}>
                                            Fiscal Year and Periods
                                     </Typography>
                                </Grid>
                            </Grid> */}
                          
                          <Grid item xs={8}  >
                              <Grid container justify="space-between" spacing={2} >
                                    <Grid item >
                                        <Typography style={{ fontWeight: "bold",}}>
                                            Date From: <span> {this.props.dateFrom}</span>
                                </Typography>
                                    </Grid>                          
                                    <Grid item >
                                        <Typography style={{ fontWeight: "bold", }}>
                                            Date To: <span>  {this.props.dateTo}</span>
                                </Typography>
                                    </Grid>
                                    </Grid>
                                    </Grid>
                        </Grid>
                    </>
                }

                <Grid
                    container
                    justify="center"
                    style={{ marginTop: 30, fontSize: 18 }}
                >
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead >
                                <TableRow>
                                    <TableCell component="th" align="center">S.No.</TableCell>
                                    <TableCell component="th" align="left">Account Code</TableCell>
                                    <TableCell component="th" align="left">Account Name</TableCell>
                                    <TableCell component="th" align="left">Opening Balance</TableCell>
                                    <TableCell component="th" align="left">Debit Current Period</TableCell>
                                    <TableCell component="th" align="left">Credit Current Period</TableCell>
                                    <TableCell component="th" align="left">Movement Current Period</TableCell>
                                    <TableCell component="th" align="left">Balance To Period</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.props.data.map(row => (
                                    < TableRow key={row.id} >
                                        <TableCell align="center"> {i++} </TableCell>
                                        <TableCell align="left">{row.accountCode}</TableCell>
                                        <TableCell align="left">{row.accountName}</TableCell>
                                        <TableCell align="center">{this.getFormattedVal(row.openingBalance)}</TableCell>
                                        <TableCell align="center">{this.getFormattedVal(row.debitCurrentPeriod)}</TableCell>
                                        <TableCell align="center">{this.getFormattedVal(row.creditCurrentPeriod)}</TableCell>
                                        <TableCell align="center">{this.getFormattedVal(row.movementCurrentPeriod)}</TableCell>
                                        <TableCell align="center">{this.getFormattedVal(row.balanceToPeriod)}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableHead>
                                {this.props.totalData.map((val) => (
                                    <TableRow>
                                        <TableCell style={styledTableCell}>

                                        </TableCell>
                                        <TableCell style={styledTableCell}>
                                            {val.accountCode}
                                        </TableCell>
                                        <TableCell style={styledTableCell}>
                                            {val.accountName}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(val.openingBalance)}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(val.debitCurrentPeriod)}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(
                                                val.creditCurrentPeriod
                                            )}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(
                                                val.movementCurrentPeriod
                                            )}
                                        </TableCell>
                                        <TableCell align="right" style={styledTableCell}>
                                            {this.getFormattedVal(val.balanceToPeriod)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableHead>

                        </Table>
                    </TableContainer>
                </Grid>
            </div >
        )
    }
}
export default TrialBalancePrint;