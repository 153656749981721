import React, { Component } from "react";
import { Tabs, Tab, Grid, withStyles } from "@material-ui/core";
import PropertyType from "../components/InventoryCatalogueMaster/PropertyType";
import Axios from "axios";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import MajorCategory from "../components/InventoryCatalogueMaster/MajorCategory";
import Category from "../components/InventoryCatalogueMaster/Category";
import SubCategory from "../components/InventoryCatalogueMaster/SubCategory";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class InventoryCatalogueMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      propertyData: [],
      majorCategoryData: [],
      categoryData: [],
    };
  }

  getPropertyData = () => {
    Axios.get("pos/propertyType/getAll")
      .then((res) => {
        this.propertyData = res.data;
        console.log(res);
        this.setState({ propertyData: this.propertyData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getMajorCategoryData = () => {
    Axios.get("pos/majorCategory/getAllMajorCategories")
      .then(async (res) => {
        this.majorCategoryData = res.data;
        await this.setState({ majorCategoryData: this.majorCategoryData });

        /*  res.data.forEach(element => {
           let obj = {
             id: element.majorCategory.id,
             majorCategoryName: element.majorCategory.majorCategoryName
           }
           majorCategoryName.push(obj);
         });
 
         let distinctMajorCategoryList = [...new Set(majorCategoryName)]
         console.log("---res data---");
         console.log(res.data);
         console.log(majorCategoryName);
         console.log(distinctMajorCategoryList);
         console.log("---res data---");
         this.majorCategoryData = distinctMajorCategoryList;
         await this.setState({ majorCategoryData: this.majorCategoryData }); */
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCategoryData = () => {
    Axios.get("pos/category/getAllCategories")
      .then((res) => {
        this.categoryData = res.data;
        console.log(res);
        this.setState({ categoryData: this.categoryData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.getMajorCategoryData();
    this.getCategoryData();
    this.getPropertyData();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Account Master" />

          <Grid container justify="center">
            <Tabs
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
              className={classes.tabMargin}
              style={{ marginBottom: "7px" }}
            >
              <Tab
                style={{ fontSize: "12px" }}
                label="Property Type"
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Major Category"
                {...a11yProps(1)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Category"
                {...a11yProps(2)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Sub-Category"
                {...a11yProps(3)}
              />
            </Tabs>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={11} sm={11} md={9}>
              <PropertyType value={this.state.value} index={0}></PropertyType>
              <MajorCategory
                value={this.state.value}
                index={1}
                propertyData={this.state.propertyData}
                getAccountGroupData={this.getAccountGroupData}
              ></MajorCategory>
              <Category
                value={this.state.value}
                index={2}
                majorCategoryData={this.state.majorCategoryData}
                getLedgerMasterData={this.getLedgerMasterData}
              ></Category>
              <SubCategory
                value={this.state.value}
                index={3}
                categoryData={this.state.categoryData}
              ></SubCategory>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InventoryCatalogueMaster);
