import React, { Component } from "react";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import AddCustomer from "../components/CustomerMaster/AddCustomer";
import CustomerDetails from "../components/CustomerMaster/CustomerDetails";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import dateFormat from "dateformat";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class CustomerMaster extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      totalItemCount: "",
      value: 0,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      data: {
        paymentMethod: "Cash",
        customerType: "New Customer",
        city: "",
        country: "",
        creditTerm: "",
        customerIdNumber: "",
        effectiveDate: "",
        emailAddress: "",
        fullName: "",
        mobileNumber: "",
        otherReference: "",
        pan: "",
        state: "",
        street: "",
      },
      customerData: [],
      errorStatus: {},
    };
  }

  getCustomerListing = () => {
    Axios.get("pos/customer/getAllCustomers")
      .then((res) => {
        var rowData = [];
        res.data.forEach((element) => {
          var obj = {
            id: element.id,
            name: element.fullName,
            address:
              element.street + " " + element.city + " " + element.country,
            mobileNum: element.mobileNumber,
            email: element.emailAddress,
            customerIdNumber: element.customerIdNumber,
            effectiveDate: element.effectiveDate,
            customerType: element.customerType,
            paymentMethod: element.paymentMethod,
            creditTerm: element.creditTerm,
            otherReference: element.otherReference,
            country: element.country,
            state: element.state,
            city: element.city,
            street: element.street,
          };
          rowData.push(obj);
        });
        try {
          this.setState({
            customerData: rowData,
            totalItemCount: res.data.length,
          });
          this.setState({
            overlayNoRowsTemplate: "<span>No rows to show</span>",
          });
          if (res.data.length === 0) {
            this.gridApi.overlayNoRowsTemplate();
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        this.gridApi.overlayNoRowsTemplate();

        try {
          this.gridApi.hideOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  };

  getCustomerIdNumber() {
    Axios.get("/pos/customer/getCustomerId").then(async (res) => {
      await this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          customerIdNumber: res.data.customerId,
        },
      }));
    });
  }

  componentDidMount() {
    this.getCustomerListing();

    this.getCustomerIdNumber();
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var todayDate = dateFormat(date, "yyyy-mm-dd");

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        effectiveDate: todayDate,
      },
    }));
  }

  setGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  onChanged = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  validateForm() {
    var toCheck = [
      "fullName",
      "customerIdNumber",
      "country",
      "city",
      "state",
      "street",
    ];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleSearch = (evt) => {
    let search = evt.target.value;

    let newData = [];

    this.state.customerData.forEach((element) => {
      if ((element.customerIdNumber !== null) & (element.name !== null)) {
        if (
          element.customerIdNumber
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1 ||
          element.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        )
          newData.push(element);
      }
    });

    this.gridApi.setRowData(newData);
  };

  resetFormValues() {
    this.getCustomerIdNumber();
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var todayDate = dateFormat(date, "yyyy-mm-dd");
    this.setState({
      data: {
        city: "",
        country: "",
        creditTerm: "",
        customerIdNumber: "",
        customerType: "",
        effectiveDate: todayDate,
        emailAddress: "",
        fullName: "",
        mobileNumber: "",
        otherReference: "",
        pan: "",
        paymentMethod: "",
        state: "",
        street: "",
      },
    });
  }
  handleSubmit = () => {
    const token = this.context;

    var status = this.validateForm();

    var toSave = this.state.data;
    toSave.customerIdNumber = this.state.data.customerIdNumber;

    if (status) {
      Axios.post("pos/customer/addCustomer", toSave)
        .then((res) => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });

          this.getCustomerListing();
          this.resetFormValues();
        })
        .catch((err) => {
          let msg="Failed"
          try{
            msg=err.response.data
          }catch{
            msg = err.message
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: msg,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Costumer Master" />

          <Grid container justify="center">
            <Tabs
              className={classes.tabMargin}
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
            >
              <Tab
                style={{ fontSize: "12px" }}
                label="Customer Details"
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Add Customer"
                {...a11yProps(1)}
              />
            </Tabs>
            <Grid container justify="center">
              <Grid item xs={11} sm={11} md={9}>
                <CustomerDetails
                  value={this.state.value}
                  index={0}
                  customerData={this.state.customerData}
                  setGridApi={this.setGridApi}
                  getCustomerListing={this.getCustomerListing}
                  overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                  overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                  totalItemCount={this.state.totalItemCount}
                  handleSearch={this.handleSearch}
                ></CustomerDetails>
                <AddCustomer
                  value={this.state.value}
                  index={1}
                  errorStatus={this.state.errorStatus}
                  data={this.state.data}
                  onChange={this.onChanged}
                  handleSubmit={this.handleSubmit}
                  customerType={this.state.data.customerType}
                  paymentMethod={this.state.data.paymentMethod}
                ></AddCustomer>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CustomerMaster);
