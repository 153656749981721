import React, { Component } from "react";
import {
  Modal,
  Backdrop,
  withStyles,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import ValidatedTextField from "../InterfaceElements/ValidatedTextField";
import Close from "@material-ui/icons/Close";
import Axios from "axios";
import UserContext from "../../utils/UserContext";

const styles = (theme) => ({
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
    },
  },
});

class AddLedger extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        code: "",
        under: "",
        underCode: "",
        description: "",
      },
      errorStatus: {},
      open: true,
      dataShow: {
        under: "",
        underCode: "",
      },
      totalItemCount: 0,
      accountGroups: [],
    };
  }

  componentDidMount() {
    this.getAccountGroups();
  }

  getAccountGroups = () => {
    Axios.get("pos/accountGroup/getAllAccountGroups")
      .then((res) => {
        this.primaryGroupData = res.data;
        console.log(res);
        this.setState({ accountGroups: this.primaryGroupData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChanged = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;
    var code = "";

    if (targetName === "under") {
      this.state.accountGroups.forEach((element) => {
        if (element.name === value) {
          code = element.code;
        }
      });

      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          underCode: code,
          [targetName]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [targetName]: value,
        },
      }));
    }
  };

  validateForm() {
    var toCheck = ["name", "code", "under"];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  handleSubmit = () => {
    const token = this.context;

    var status = this.validateForm();

    if (status) {
      Axios.post("pos/ledgerMaster/addLedgerMaster", this.state.data)
        .then((res) => {
          console.log(res);

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
          console.log(token);

          //   this.props.getAccountGroupData();
          this.props.getAccountGroups();
          this.setState({
            data: {
              name: "",
              code: "",
              under: "",
              underCode: "",
              description: "",
            },
          });
        })
        .catch((err) => {
          var snackbarProps = {
            open: true,
            variant: "error",
            message: err.response.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  render(params) {
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const inputStyles = {
      fontSize: 14,
    };

    const textFieldStyle = {
      width: "100%",
    };

    const { classes, open, handleClose } = this.props;

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          errorStatus={this.state.errorStatus}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ overflowY: "auto" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container spacing={2} justify="center">
                  <Grid item xs={12} sm={6}>
                    <ValidatedTextField
                      label="Name"
                      name="name"
                      error={this.state.errorStatus.name}
                      value={this.state.data.name}
                      onChange={this.onChanged}
                      textFieldStyle={textFieldStyle}
                      inputStyles={inputStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValidatedTextField
                      label="Code"
                      name="code"
                      value={this.state.data.code}
                      error={this.state.errorStatus.code}
                      onChange={this.onChanged}
                      textFieldStyle={textFieldStyle}
                      inputStyles={inputStyles}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {this.state.errorStatus.under ? (
                      <TextField
                        name="under"
                        label="Under"
                        error
                        select
                        fullWidth
                        value={this.state.data.under}
                        helperText={"Under should be selected"}
                        style={textFieldStyle}
                        onChange={this.onChanged}
                        InputProps={{ style: { fontSize: 13 } }}
                        InputLabelProps={{ style: inputStyles }}
                        SelectProps={{
                          MenuProps: {
                            style: { width: 200 },
                          },
                        }}
                        margin="normal"
                      >
                        {this.state.accountGroups.map((item, i) => (
                          <MenuItem key={i} value={item.name}>
                            {item.code + " - " + item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        name="under"
                        label="Under"
                        select
                        value={this.state.data.under}
                        style={textFieldStyle}
                        onChange={this.onChanged}
                        InputProps={{ style: { fontSize: 13 } }}
                        InputLabelProps={{ style: inputStyles }}
                        SelectProps={{
                          MenuProps: {
                            style: { width: 200 },
                          },
                        }}
                        margin="normal"
                      >
                        {this.state.accountGroups.map((item, i) => (
                          <MenuItem key={i} value={item.name}>
                            {item.code + " - " + item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Under Code"
                      name="under_code"
                      disabled={true}
                      placeholder=""
                      onChange={this.onChanged}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      style={textFieldStyle}
                      value={this.state.data.underCode}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Description"
                      placeholder=""
                      name="description"
                      onChange={this.onChanged}
                      value={this.state.data.description}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                      style={textFieldStyle}
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={this.handleSubmit}
                    style={{
                      margin: "20px",
                      marginTop: "25px",
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(AddLedger);
