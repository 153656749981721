import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  TextField,
  Grid,
  Backdrop,
  Fade,
  Modal,
  Card,
  CardContent,
  withStyles,
  Button,
  IconButton
} from "@material-ui/core";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "97%",
      height: "400px",
      margin: "auto"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      height: "372px",
      margin: "auto"
    },
    [theme.breakpoints.up("md")]: {
      width: "750px",
      height: "420px"
    }
  }
});

class ViewReorderButton extends PureComponent {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      onOrderError: false,
      data: {
        orderNumber: "",
        orderDate: "",
        supplier: "",
        safetyStock: 0,
        maxDailyUsageRate: 0,
        leadTime: 0,
        reorderLevel: 0,
        onOrder: 0,
        itemCode: ""
      }
    };
  }

  componentDidMount() {
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        supplier: this.props.node.data.supplier,
        safetyStock: this.props.node.data.safetyStock,
        maxDailyUsageRate: this.props.node.data.maxDailyUsageRate,
        leadTime: this.props.node.data.leadTime,
        reorderLevel: this.props.node.data.reorderLevel,
        itemCode: this.props.node.data.itemCode
      }
    }));

    Axios.get("/pos/reorder/getReorderDetails")
      .then(res => {
        console.log(res);
        this.setState(prevState => ({
          ...prevState,
          data: {
            ...prevState.data,
            orderNumber: res.data.reorderNumber,
            orderDate: res.data.reorderDate
          }
        }));
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleValueChange = e => {
    e.stopPropagation();
    var value = e.target.value;
    var name = e.target.name;

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value
      }
    }));
    if (name === "onOrder") {
      this.setState(prevState => ({
        ...prevState,
        onOrderError: false
      }));
    }
  };

  handleSubmit = () => {
    console.log({ order: this.state.data.onOrder });

    var obj = {
      orderNumber: this.state.data.orderNumber,
      supplier: this.state.data.supplier,
      orderQuantity: this.state.data.onOrder,
      orderDate: this.state.data.orderDate,
      itemCode: this.state.data.itemCode
    };

    if (
      this.state.data.onOrder !== undefined &&
      this.state.data.onOrder !== 0 &&
      this.state.data.onOrder !== ""
    ) {
      Axios.post("/pos/reorder/save", obj)
        .then(res => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000
          };
          this.props.setSnackbar(snackbarProps);
        })
        .catch(err => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000
          };

          this.props.setSnackbar(snackbarProps);
        });
    } else {
      this.setState({ onOrderError: true });
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    const inputStyles = {
      fontSize: 14
    };

    const textFieldStyle = {
      width: "100%"
    };

    const { classes } = this.props;

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.open}>
            <div>
              <Card className={classes.cardHeight}>
                <CardContent style={{ padding: "0px" }}>
                  <Grid container justify="flex-end">
                    <IconButton onClick={this.handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid container style={{ padding: "20px" }}>
                    <Grid container spacing={2} justify="center">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-with-placeholder"
                          label="Order Number"
                          name="orderNumber"
                          value={this.state.data.orderNumber}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-with-placeholder"
                          label="Order Date"
                          name="orderDate"
                          value={this.state.data.orderDate}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-with-placeholder"
                          label="Max/ Avg Usage Rate"
                          name="maxDailyUsageRate"
                          value={this.state.data.maxDailyUsageRate}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-with-placeholder"
                          label="Lead Time"
                          name="leadTime"
                          value={this.state.data.leadTime}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-with-placeholder"
                          label="Safety Stock"
                          name="safetyStock"
                          value={this.state.data.safetyStock}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-with-placeholder"
                          label="Reorder Level"
                          name="reorderLevel"
                          value={this.state.data.reorderLevel}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {this.state.onOrderError ? (
                          <TextField
                            id="standard-with-placeholder"
                            label="On Order"
                            name="onOrder"
                            error
                            helperText="On Order is Required"
                            value={this.state.data.onOrder}
                            onChange={this.handleValueChange}
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        ) : (
                            <TextField
                              type="number"
                              id="standard-with-placeholder"
                              label="On Order"
                              name="onOrder"
                              onChange={this.handleValueChange}
                              value={this.state.data.onOrder}
                              inputProps={{ style: inputStyles }}
                              InputLabelProps={{ style: inputStyles }}
                              style={textFieldStyle}
                              margin="normal"
                            />
                          )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="standard-with-placeholder"
                          label="Supplier"
                          name="supplier"
                          type="number"
                          value={this.state.data.supplier}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                    <Grid container justify="center">
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={this.handleSubmit}
                        style={{
                          width: "100px",
                          margin: "15px",
                          fontSize: "13px"
                        }}
                      >
                        Reorder
                    </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewReorderButton);
