import React, { Component } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

export default class DeleteButton extends Component {

    

    buttonClick = (e) => {        
        console.log(e);
            //     this.setState({
            //         visible:true
            //     })
            //     let deletedRow = this.props.node.data;
            //     e.gridApi.updateRowData({ remove: [deletedRow] })  // It will update the row
            this.props.deleteRow(this.props.node.data)
            
            let deletedRow = this.props.node.data;
            e.gridApi.updateRowData({ remove: [deletedRow] }); 

             };

        

    render() {
        return (
            <span><FontAwesomeIcon
            icon={faMinusCircle} style={{color: "#FF8070"}} onClick={() => this.buttonClick(this.props.node)}
          ></FontAwesomeIcon>
            </span>
        );
    }
}