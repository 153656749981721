import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  withStyles,
  MenuItem,
  FormControlLabel,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  RadioGroup,
  Radio,
  Divider,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dateFormat from "dateformat";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import adbs from "ad-bs-converter";
import jsPDF from "jspdf";
import "jspdf-autotable";
import TransactionDetailsPrint from "../components/PrintableComponents/TransactionDetailsPrint";

const styles = (theme) => ({
  tabMargin: {
    height: "auto",
    width: "100%",
    maxWidth: "1300px",
    marginRight: "10px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "5px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "5px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class TransactionDetails extends Component {
  constructor(props) {
    super(props);

    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headerName: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
        },
        {
          headerName: "Period",
          field: "period",
          resizable: true,
          sortable: true,
        },

        {
          headerName: "Fiscal Year",
          field: "fiscalyear",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Transaction Date",
          field: "transactionDate",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Transaction Miti",
          field: "transactionMiti",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Approved By",
          field: "approvedBy",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Voucher Number",
          field: "voucherNumber",
          resizable: true,
        },
        {
          headerName: "Voucher Type",
          field: "voucherType",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Narration",
          field: "particulars",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Cost Center",
          field: "costCenter",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Account Code",
          field: "accountCode",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Account Name",
          field: "accountName",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Sub Acccount Code",
          field: "subAccountCode",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Sub Account Name",
          field: "subAccountName",
          resizable: true,
          sortable: true,
        },
        {
          headerName: "Dr. Amount",
          field: "debitAmount",
          resizable: true,
          sortable: true,
          type: "numericColumn",
          valueFormatter: addCommas,
        },
        {
          headerName: "Cr. Amount",
          field: "creditAmount",
          resizable: true,
          sortable: true,
          type: "numericColumn",
          valueFormatter: addCommas,
        },
        {
          headerName: "Running Balance",
          field: "runningBalance",
          resizable: true,
          sortable: true,
          type: "numericColumn",
        },
      ],
      companyName: "",
      companyAddress: "",
      companyContact: "",
      rowData: [],
      origData: [],
      filterShow: false,
      open: false,
      sliderVal: [20, 47],
      fromDate: "11/11/2019",
      toDate: "11/11/2019",
      filterFields: {
        accountCode: "",
        dateFrom: "",
        dateTo: "",
        periodFrom: "",
        periodTo: "",
      },
      filterTerm: "",
      checkStatus: [],
      quantityMax: 0,
      dropdownList: [],
      fyList: [],
      distinctFyList: [],
      distinctPeriodList: [],
      currentPeriod: "",
      periodList: [],
      currentFy: "",
      period: "",
      panelExpanded: true,
      showLoading: false,
      showDetails: false,
    };
  }

  handleCustomerSelect = (_event, data) => {
    const { value } = data;
    var dataList = this.state.origData;
    var newDataList = [];
    dataList.forEach((element) => {
      if (element.customerName === value) {
        newDataList.push(element);
      }
    });

    this.setState({ rowData: newDataList });
  };

  onFilterTermChange = (e) => {
    let value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      filterTerm: value,
    }));
  };

  getAllFiscalYear() {
    Axios.get("/fy/findTillCurrentPeriod")
      .then((res) => {
        var data = [];
        var nonDistinctPeriodList = [];
        var nonDistinctFyList = [];
        var periodData = [];
        res.data.forEach((element) => {
          var item = {
            status: element.currentStatus,
            fiscalYear: element.fiscalYear,
            name: element.name,
          };
          var periodItem = {
            status: element.currentStatus,
            period: element.name,
          };
          data.push(item);
          nonDistinctFyList.push(item.fiscalYear);
          periodData.push(periodItem);
          nonDistinctPeriodList.push(periodItem.period);
        });

        this.setState((prevState) => ({
          ...prevState,
          fyList: data,
          distinctFyList: [...new Set(nonDistinctFyList)],
          periodList: periodData,
          distinctPeriodList: [...new Set(nonDistinctPeriodList)],
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    this.getCurrentPeriod();
  }
  getCurrentPeriod = () => {
    Axios.get("/fy/getCurrentPeriod")
      .then(async (res) => {
        await this.setState((prevState) => ({
          ...prevState,
          currentFy: res.data.fiscalYear,
          filterFields: {
            ...prevState.filterFields,
            periodFrom: res.data.name,
            periodTo: res.data.name,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCurrentFiscalYear() {
    this.state.fyList.forEach((element) => {
      if (element.status === true) {
        this.setState((prevState) => ({
          ...prevState,
          currentFy: element.fiscalYear,
        }));
      }
    });
    this.state.periodList.forEach((element) => {
      if (element.status === true) {
        this.setState((prevState) => ({
          ...prevState,
          period: element.period,
        }));
      }
    });
    this.getInvoices();
  }

  getInvoices() {
    var obj = { fiscalYear: this.state.currentFy };

    this.setState({ showLoading: true });

    Axios.post("/pos/transactionDetails/getTransactionDetails", obj)
      .then((res) => {
        let data = res.data;

        this.setState({ showLoading: false });

        let runningBalance = 0;

        var format = new Intl.NumberFormat("ne-NP", {
          minimumFractionDigits: 2,
        });

        data.forEach((element) => {
          let transactionDate = element.transactionDate.split("-");

          let miti = adbs.ad2bs(
            transactionDate[0] +
              "/" +
              transactionDate[1] +
              "/" +
              transactionDate[2]
          );
          element.transactionMiti =
            miti.en.year + "-" + miti.en.month + "-" + miti.en.day;

          if (element.debitAmount > 0) {
            runningBalance += element.debitAmount;
          }

          if (element.creditAmount > 0) {
            runningBalance -= element.creditAmount;
          }

          element.runningBalance = format.format(runningBalance);
          if (element.runningBalance < 0)
            element.runningBalance =
              "(" + Math.abs(element.runningBalance) + ")";
        });

        this.setState((prevState) => ({
          ...prevState,
          rowData: data,
          origData: data,
        }));

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }

        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, true);
      })
      .catch((err) => {
        console.log(err);

        this.setState({ showLoading: false });

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  handleFiscalYearChange = async (e) => {
    e.stopPropagation();
    await this.setState((prevState) => ({
      ...prevState,
      currentFy: e.target.value,
    }));
  };

  handlePeriodFromChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodFrom: period,
      },
    }));
  };

  handlePeriodToChange = async (e) => {
    e.stopPropagation();
    var period = e.target.value;
    await this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        periodTo: period,
      },
    }));
  };

  handlePanelChange = (_e, isExpanded) => {
    this.setState({
      panelExpanded: isExpanded,
    });
  };

  componentDidMount() {
    this.getAllFiscalYear();

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: todayDate,
        dateTo: todayDate,
      },
    }));

    var rowDetails = [];
    Axios.get("pos/ledgerMaster/getAllActiveAccountGroup")
      .then(async (res) => {
        res.data.forEach((element) => {
          var item = {
            key: element.code,
            value: element.code,
            text: element.code + "-" + element.name,
          };
          rowDetails.push(item);
        });
        this.setState((prevState)=>({
          ...prevState,
            dropdownList: rowDetails,
        }));
      });

    this.getCompanyName();
  }

  changeAccountCode = async(e, data) => {
    let value = data.value;

    this.setState((prevState)=>({
      ...prevState,
      filterFields:{
        ...prevState.filterFields,
        accountCode: value,
      },
    }))
  }
  handleClick = () => {
    var rowAllData = [];

    this.state.rowData.forEach((element) => {
      if (element.status === "Inactive") {
        rowAllData.push(element);
      }
    });
    this.setState({ rowData: rowAllData });
  };

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
  };

  handleSliderChange = (_event, newValue) => {
    this.setState({ sliderVal: newValue });
  };

  handleFromDate = (event) => {
    var date = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateFrom: date,
      },
    }));
  };

  handleToDate = (event) => {
    var date = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      filterFields: {
        ...prevState.filterFields,
        dateTo: date,
      },
    }));
  };

 /*  handleFiscalYearApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.getTransactionByDate({
      fiscalYear: this.state.currentFy,
      periodFrom: this.state.filterFields.periodFrom,
      periodTo: this.state.filterFields.periodTo,
    });
    // this.getExcelData();
  };

  handleDateRangeApply = () => {
    this.setState({
      panelExpanded: false,
    });

    this.getTransactionByDate({
      dateFrom: this.state.filterFields.dateFrom,
      dateTo: this.state.filterFields.dateTo,
    });
  }; */

  getTransactionByDate=()=> {

    this.setState({ 
      showDetails: true,
      showLoading: true,
      panelExpanded: false,
    });

    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    let obj;
    
    if(this.state.filterFields.accountCode !== "")
    {
      if (this.state.filterTerm === "fiscalYear") {
      obj = {
        accountCode: this.state.filterFields.accountCode,
        fiscalYear: this.state.currentFy,
        periodFrom: this.state.filterFields.periodFrom,
        periodTo: this.state.filterFields.periodTo,
      };
    } else {

      obj = {
        accountCode: this.state.filterFields.accountCode,
        dateFrom: this.state.filterFields.dateFrom,
        dateTo: this.state.filterFields.dateTo,
      };
    }
    } else {
      
    if (this.state.filterTerm === "fiscalYear") {
      obj = {
        fiscalYear: this.state.currentFy,
        periodFrom: this.state.filterFields.periodFrom,
        periodTo: this.state.filterFields.periodTo,
      };
    } else {
      obj = {
        dateFrom: this.state.filterFields.dateFrom,
        dateTo: this.state.filterFields.dateTo,
      };
    }
    }

    Axios.post("/pos/transactionDetails/getTransactionDetails", obj)
      .then(async (res) => {
        let data = res.data;

        this.setState({ showLoading: false });
        let runningBalance = 0;

        data.forEach((element) => {
          let transactionDate = element.transactionDate.split("-");

          let miti = adbs.ad2bs(
            transactionDate[0] +
              "/" +
              transactionDate[1] +
              "/" +
              transactionDate[2]
          );

          element.transactionMiti =
            miti.en.year + "-" + miti.en.month + "-" + miti.en.day;

          element.runningBalance = 0;

          if (element.debitAmount > 0) {
            element.runningBalance += element.debitAmount;
          }

          if (element.creditAmount > 0) {
            element.runningBalance -= element.creditAmount;
          }

          if (element.debitAmount > 0) {
            runningBalance += element.debitAmount;
          }

          if (element.creditAmount > 0) {
            runningBalance -= element.creditAmount;
          }

          element.runningBalance = format.format(runningBalance);
          if (runningBalance < 0) {
            let num = Math.abs(runningBalance);
            element.runningBalance = "(" + format.format(num) + ")";
          }
        });

        await this.setState((prevState) => ({
          ...prevState,
          rowData: data,
        }));

        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, true);

        //---------EXCE DATA ---------
        let excel = [];
        if (this.state.filterTerm === "fiscalYear") {
          let object = {
            sn: "",
            period: "Fiscal Year",
            month: this.state.currentFy,
            fiscalYear: "",
            transactionDate: "",
            transactionMiti: "",
            voucherNumber: "",
            voucherType: "",
            particulars: "",
            costCenter: "",
            accountCode: "",
            accountName: "",
            subAccountCode: "",
            subAccountName: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            period: "Period From",
            month: this.state.filterFields.periodFrom,
            fiscalYear: "",
            transactionDate: "",
            transactionMiti: "",
            voucherNumber: "",
            voucherType: "",
            particulars: "",
            costCenter: "",
            accountCode: "",
            accountName: "",
            subAccountCode: "",
            subAccountName: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            period: "Period To",
            month: this.state.filterFields.periodTo,
            fiscalYear: "",
            transactionDate: "",
            transactionMiti: "",
            voucherNumber: "",
            voucherType: "",
            particulars: "",
            costCenter: "",
            accountCode: "",
            accountName: "",
            subAccountCode: "",
            subAccountName: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          };
          excel.push(object);
        } else if (this.state.filterTerm === "date") {
          let object = {
            sn: "",
            period: "Date Range",
            month: "",
            fiscalYear: "",
            transactionDate: "",
            transactionMiti: "",
            voucherNumber: "",
            voucherType: "",
            particulars: "",
            costCenter: "",
            accountCode: "",
            accountName: "",
            subAccountCode: "",
            subAccountName: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            period: "Date From",
            month: this.state.filterFields.dateFrom,
            fiscalYear: "",
            transactionDate: "",
            transactionMiti: "",
            voucherNumber: "",
            voucherType: "",
            particulars: "",
            costCenter: "",
            accountCode: "",
            accountName: "",
            subAccountCode: "",
            subAccountName: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          };
          excel.push(object);

          object = {
            sn: "",
            period: "Date",
            month: this.state.filterFields.dateTo,
            fiscalYear: "",
            transactionDate: "",
            transactionMiti: "",
            voucherNumber: "",
            voucherType: "",
            particulars: "",
            costCenter: "",
            accountCode: "",
            accountName: "",
            subAccountCode: "",
            subAccountName: "",
            debitAmount: "",
            creditAmount: "",
            runningBalance: "",
          };
          excel.push(object);
        }

        let object = {
          sn: "",
          period: "",
          month: "",
          fiscalYear: "",
          transactionDate: "",
          transactionMiti: "",
          voucherNumber: "",
          voucherType: "",
          particulars: "",
          costCenter: "",
          accountCode: "",
          accountName: "",
          subAccountCode: "",
          subAccountName: "",
          debitAmount: "",
          creditAmount: "",
          runningBalance: "",
        };
        excel.push(object);

        object = {
          sn: "S.No.",
          period: "Period",
          month: "Month",
          fiscalYear: "Fiscal Year",
          transactionDate: "Transaction Date",
          transactionMiti: "Transaction Miti",
          voucherNumber: "Voucher Number",
          voucherType: "Voucher Type",
          particulars: "Narration",
          costCenter: "Cost Center",
          accountCode: "Account Code",
          accountName: "Account Name",
          subAccountCode: "Sub Account Code",
          subAccountName: "Sub Account Name",
          debitAmount: "Dr.Amount",
          creditAmount: "Cr.Amount",
          runningBalance: "Running Balance",
        };
        excel.push(object);

        data.map((item, i) => {
          let object = {
            sn: i + 1,
            period: item.period,
            month: item.month,
            fiscalYear: item.fiscalyear,
            transactionDate: item.transactionDate,
            transactionMiti: item.transactionMiti,
            voucherNumber: item.voucherNumber,
            voucherType: item.voucherType,
            particulars: item.particulars,
            costCenter: item.costCenter,
            accountCode: item.accountCode,
            accountName: item.accountName,
            subAccountCode: item.subAccountCode,
            subAccountName: item.subAccountName,
            debitAmount:
              item.debitAmount === null || undefined ? "" : item.debitAmount,
            creditAmount:
              item.creditAmount === null || undefined ? "" : item.creditAmount,
            runningBalance: item.runningBalance,
          };
          excel.push(object);

          return null;
        });
        await this.setState({
          excelData: excel,
        });
        //--------EXCEL DATA ENDS--------
      })
      .catch((err) => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>",
          rowData: [],
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  handleCheckbox = (checkbox) => {
    var val = this.state.checkStatus[checkbox];

    this.setState((prevState) => ({
      ...prevState,
      checkStatus: {
        ...prevState.checkStatus,
        [checkbox]: !val,
      },
    }));

    var dataList = this.state.origData;

    var newDataList = [];

    dataList.forEach((element) => {
      if (
        element.paymentMode === checkbox &&
        !this.state.checkStatus[checkbox]
      ) {
        newDataList.push(element);
      }
    });

    this.setState({ rowData: newDataList });
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  getCompanyName() {
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        res.data.forEach((element) => {
          this.setState({
            companyName: element.name,
            companyAddress: element.street + ", " + element.city,
            companyContact: element.contactNumber,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generatePdf = () => {
    let company = this.state.companyName;
    let companyAddress = this.state.companyAddress;
    let companyContactNumber = this.state.companyContact;
    function header() {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(300, 90, company, {
        align: "center",
      });

      doc.text(300, 115, companyAddress, {
        align: "center",
      });

      doc.text(300, 140, companyContactNumber, {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("arial", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);
    }

    var doc = new jsPDF("p", "pt", "a4", true);
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    doc.text(480, doc.internal.pageSize.height - 20, "Printed on: " + today);
    header();

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(40, 190, "Transaction Details");

    var y = 200;

    let batchDetails = [];
    let i = 1;
    this.state.rowData.map((value) =>
      batchDetails.push([
        i++,
        value.transactionDate,
        value.particulars,
        value.costCenter,
        value.accountCode + "-" + value.accountName,
        value.subAccountCode + "-" + value.subAccountName,
        value.voucherNumber,
        value.voucherType,
        value.runningBalance,
        value.debitAmount,
        value.creditAmount,
      ])
    );

    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },

      bodyStyles: { valign: "top" },
      startY: y + 30,
      theme: "grid",
      columnStyles: {
        0: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 25,
          halign: "center",
        },
        1: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 45,
          halign: "left",
        },
        2: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 75,
          halign: "left",
        },
        3: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 35,
          halign: "center",
        },
        4: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 55,
          halign: "left",
        },
        5: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 50,
          halign: "left",
        },
        6: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 55,
          halign: "left",
        },
        7: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 50,
          halign: "center",
        },
        8: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 50,
          halign: "right",
        },
        9: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 47,
          halign: "right",
        },
        10: {
          font: "helvetica",
          fontSize: 10,
          fontStyle: "normal",
          columnWidth: 47,
          halign: "right",
        },
      },
      head: [
        [
          "S.No.",
          "Transaction Date",
          "Narration",
          "Cost Center",
          "Account",
          "Sub Account",
          "Number",
          "Type",
          "Running balance",
          "Debit Amount",
          "credit Amount",
        ],
      ],
      body: batchDetails,
    });

    footer();
    doc.save("Transactoin Details");
  };

  render(_params) {
    const { classes } = this.props;

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var todayDate = dateFormat(date, "yyyy-mm-dd");

    return (
      <div
        style={{
          backgroundColor: "#EAEDEF",
          marginTop: "0px",
        }}
      >
        <HeaderTitle title="Managing Invoices" />

        <Grid container justify="center" className={classes.root}>
          <Card className={classes.tabMargin} style={{marginBottom:"100px"}}>
            <CardContent>
              <Grid
                container
                style={{
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={12} sm={7}>
                    <ExpansionPanel
                      expanded={this.state.panelExpanded}
                      onChange={this.handlePanelChange}
                      style={{ maxWidth: "880px", marginBottom: "20px" }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Report By
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                          <RadioGroup
                            column
                            value={this.state.filterTerm}
                            onChange={this.onFilterTermChange}
                          >
                            <FormControlLabel
                              value="fiscalYear"
                              control={<Radio color="primary" />}
                              label="Fiscal Year and Period"
                            />
                            <FormControlLabel
                              value="date"
                              control={<Radio color="primary" />}
                              label="Date Range"
                            />
                          </RadioGroup>

                          {this.state.filterTerm === "fiscalYear" ? (
                            <>
                              <Grid
                                container
                                justify="flex-start"
                                spacing={2}
                                style={{ marginTop: "20px" }}
                              >
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Fiscal Year"
                                    name="fiscalYear"
                                    onChange={this.handleFiscalYearChange}
                                    value={this.state.currentFy}
                                  >
                                    {this.state.distinctFyList.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                justify="flex-start"
                                spacing={2}
                                style={{ marginTop: "20px" }}
                              >
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Period From"
                                    name="periodFrom"
                                    value={this.state.filterFields.periodFrom}
                                    onChange={this.handlePeriodFromChange}
                                  >
                                    {this.state.distinctPeriodList.map(
                                      (item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      )
                                    )}
                                  </TextField>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    select
                                    style={{
                                      width: "15vw",
                                      minWidth: "120px",
                                    }}
                                    label="Period To"
                                    name="periodTo"
                                    value={this.state.filterFields.periodTo}
                                    onChange={this.handlePeriodToChange}
                                  >
                                    {this.state.distinctPeriodList.map(
                                      (item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      )
                                    )}
                                  </TextField>
                                </Grid>
                              </Grid>

                              {/* <Grid container style={{ marginTop: "27px" }}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleFiscalYearApply}
                                >
                                  Apply
                                </Button>
                              </Grid> */}
                            </>
                          ) : null}

                          {this.state.filterTerm === "date" ? (
                            <>
                              <Grid container justify="flex-start" spacing={2}>
                                <Grid item>
                                  <TextField
                                    placeholder="Date From"
                                    label="Date From"
                                    name="dateFrom"
                                    defaultValue={todayDate}
                                    style={{
                                      width: "15vw",
                                      minWidth: "130px",
                                      marginTop: "20px",
                                    }}
                                    type="date"
                                    onChange={this.handleFromDate}
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    placeholder="Date To"
                                    label="Date To"
                                    name="dateTo"
                                    defaultValue={todayDate}
                                    style={{
                                      width: "15vw",
                                      minWidth: "130px",
                                      marginTop: "20px",
                                    }}
                                    type="date"
                                    onChange={this.handleToDate}
                                  />
                                </Grid>
                              </Grid>
                             {/*  <Grid container style={{ marginTop: "27px" }}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleDateRangeApply}
                                >
                                  Apply
                                </Button>
                              </Grid> */}
                            </>
                          ) : null}
                        </Grid>
                        
                      <Hidden smDown>
                        <Grid xs={1}>
                          <Divider
                            orientation="vertical"
                            inset
                            style={{
                              minHeight: "100px",
                              marginBottom: "10px",
                            }}
                          />
                        </Grid>
                      </Hidden>
                      <Hidden mdUp>
                        <Grid xs={12}>
                          <Divider
                            style={{
                              marginTop: "15px",
                              marginBottom: "10px",
                            }}
                          />
                        </Grid>
                      </Hidden>

                      <Grid item md={5} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Dropdown
                              clearable
                              placeholder="Account "
                              floating
                              fluid
                              fullWidth
                              selectOnBlur={false}
                              //search={customSearch}
                              style={{
                                borderTop: "0px",
                                borderRight: 0,
                                borderLeft: 0,
                                borderBottom: "1px solid ",
                                borderRadius: "0px",
                                borderColor: "#8c8a84",
                              }}
                              selection
                              value={this.state.filterFields.accountCode}
                              onChange={this.changeAccountCode}
                              options={[...this.state.dropdownList]}
                            ></Dropdown>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        justify="flex-end"
                        container
                        style={{ marginTop: "27px" }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.getTransactionByDate}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                  <Grid item xs>
                    <Grid
                      container
                      spacing={2}
                      justify="flex-end"
                      style={{ marginBottom: "7px" }}
                    >
                      <Grid item>
                        <ExcelFile
                          filename="TransactionDetails"
                          element={
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                minWidth: "100px",
                                width: "6vw",
                                margin: "auto",
                                marginTop: "10px",
                                marginLeft: "20px",
                                height: "40px",
                                fontSize: "16px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                color="#3f51b5"
                                size="30px"
                                style={{
                                  marginRight: "10px",
                                }}
                              ></FontAwesomeIcon>
                              Excel
                            </Button>
                          }
                        >
                          <ExcelSheet
                            data={this.state.excelData}
                            name="Sheet A"
                          >
                            <ExcelColumn value="sn" />
                            <ExcelColumn value="period" />
                            <ExcelColumn value="month" />
                            <ExcelColumn value="fiscalYear" />
                            <ExcelColumn value="transactionDate" />
                            <ExcelColumn value="transactionMiti" />
                            <ExcelColumn value="voucherNumber" />
                            <ExcelColumn value="voucherType" />
                            <ExcelColumn value="particulars" />
                            <ExcelColumn value="costCenter" />
                            <ExcelColumn value="accountCode" />
                            <ExcelColumn value="accountName" />
                            <ExcelColumn value="subAccountCode" />
                            <ExcelColumn value="subAccountName" />
                            <ExcelColumn value="debitAmount" />
                            <ExcelColumn value="creditAmount" />
                            <ExcelColumn value="runningBalance" />
                          </ExcelSheet>
                        </ExcelFile>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {this.state.showDetails ? (
                <>
                  {this.state.filterTerm === "fiscalYear" ? (
                    <Grid
                      container
                      style={{ margin: "20px", maxWidth: "700px" }}
                    >
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                marginRight: "10px",
                                color: "#108DCD",
                              }}
                            >
                              Fiscal Year:
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "#108DCD",
                              }}
                            >
                              {this.state.currentFy}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                marginRight: "10px",
                                color: "#108DCD",
                              }}
                            >
                              Period From:
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "#108DCD",
                              }}
                            >
                              {this.state.filterFields.periodFrom}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={4}>
                        <Grid container>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                marginRight: "10px",
                                color: "#108DCD",
                              }}
                            >
                              Period To:
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: 400,
                                color: "#108DCD",
                              }}
                            >
                              {this.state.filterFields.periodTo}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        container
                        style={{ margin: "20px", maxWidth: "500px" }}
                      >
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  marginRight: "10px",
                                  color: "#108DCD",
                                }}
                              >
                                Date From:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.dateFrom}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  color: "#108DCD",
                                  marginRight: "10px",
                                }}
                              >
                                Date To:
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: "15px",
                                  fontWeight: 400,
                                  color: "#108DCD",
                                }}
                              >
                                {this.state.filterFields.dateTo}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              ) : null}

              <Grid container justify="center">
                {this.state.showLoading ? (
                  <>
                    <Grid
                      container
                      justify="center"
                      style={{ margin: "50px 0px" }}
                    >
                      <CircularProgress thickness="2" size="60px" />
                    </Grid>
                  </>
                ) : (
                  <>
                    <div
                      className="ag-theme-balham"
                      style={{
                        height: "50vh",
                        width: "100%",
                        padding: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        onGridReady={this.onGridReady}
                        onFirstDataRendered={this.firstDataRendered}
                      ></AgGridReact>
                    </div>
                  </>
                )}

                <div style={{ display: "none" }}>
                  <TransactionDetailsPrint
                    ref={(el) => (this.componentRef = el)}
                    data={this.state.rowData}
                  />
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </div>
    );
  }
}

function addCommas(params) {
  var format = new Intl.NumberFormat("ne-NP", {
    minimumFractionDigits: 2,
  });
  if (params.value !== undefined) {
    return format.format(params.value);
  }
}

export default withStyles(styles)(TransactionDetails);
