import React, { Component } from "react";
import {
  Card,
  CardContent,
  Button,
  Grid,
  TextField,
  Fade,
  Modal,
  Backdrop,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Close from "@material-ui/icons/Close";
import ValidatedTextField from "../../InterfaceElements/ValidatedTextField";
import Axios from "axios";
import UserContext from "../../../utils/UserContext";

const styles = (theme) => ({
  cardHeight: {
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
  },
});

class ViewTaxCode extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      errorStatus: {},
      data: {
        itemCode: "",
        itemName: "",
        rate: "",
        remarks: "",
      },
    };
  }

  componentDidMount() {
    this.setState({
      data: {
        itemCode: this.props.node.data.code,
        itemName: this.props.node.data.name,
        rate: this.props.node.data.rate,
        remarks: this.props.node.data.remarks,
      },
    });
  }

  handleOpen = (id) => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleValueChange = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  handleUpdate = (id) => {
    var obj = {
      code: this.state.data.itemCode,
      name: this.state.data.itemName,
      rate: this.state.data.rate,
      remarks: this.state.data.remarks,
    };
    const token = this.context;
    let status = this.validateForm();

    if (status) {
      Axios.put("pos/taxCode/update/" + id, obj)
        .then((res) => {
          console.log(res.data);
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuaratiion: 2000,
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
          this.props.updateData();
          this.handleClose();
        })
        .catch((err) => {
          console.log(err);
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  validateForm() {
    var toCheck = ["itemName", "itemCode", "rate"];

    var newErrStatus = this.state.errorStatus;
    var status = true;

    toCheck.forEach((element) => {
      let val = this.state.data[element];

      console.log(val);

      if (val === "" || val === undefined) {
        newErrStatus[element] = true;
        status = false;
      } else {
        newErrStatus[element] = false;
      }
    });

    console.log(newErrStatus);

    this.setState((prevState) => ({
      ...prevState,
      errorStatus: {
        ...prevState.errorStatus,
        newErrStatus,
      },
    }));

    return status;
  }

  render() {
    const { classes } = this.props;
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const textFieldStyle = {
      width: "100%",
    };

    return (
      <div>
        <span>
          <Button onClick={() => this.handleOpen(this.props.node.data.id)}>
            <FontAwesomeIcon
              icon={faEdit}
              color="#43a047"
              size="1.5x"
              style={{ cursor: "pointer" }}
            >
              {" "}
            </FontAwesomeIcon>
          </Button>
        </span>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  justify="center"
                  style={{ padding: "20px" }}
                >
                  <Grid item xs={12} sm={6}>
                    <ValidatedTextField
                      label="Code"
                      name="itemCode"
                      error={this.state.errorStatus.itemCode}
                      value={this.state.data.itemCode}
                      onChange={this.handleValueChange}
                      textFieldStyle={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValidatedTextField
                      label="Name"
                      name="itemName"
                      error={this.state.errorStatus.itemName}
                      value={this.state.data.itemName}
                      onChange={this.handleValueChange}
                      textFieldStyle={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ValidatedTextField
                      label="Rate"
                      name="rate"
                      error={this.state.errorStatus.rate}
                      value={this.state.data.rate}
                      onChange={this.handleValueChange}
                      textFieldStyle={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Remarks"
                      name="remarks"
                      value={this.state.data.remarks}
                      onChange={this.handleValueChange}
                      placeholder=""
                      style={textFieldStyle}
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <Grid container justify="flex-end">
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => this.handleUpdate(this.props.node.data.id)}
                    style={{
                      margin: "20px",
                      marginTop: "25px",
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}
export default withStyles(styles)(ViewTaxCode);
