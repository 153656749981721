import React, { Component } from "react";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import MyProfile from "../components/ProfileMaster/MyProfile";
import ChangePassword from "../components/ProfileMaster/ChangePassword";
import EditDetails from "../components/ProfileMaster/EditDetails";
import Axios from "axios";
import UserContext from "../utils/UserContext";

const styles = theme => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px"
    }
  }
});

class Profile extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data: {
        firstName: "",
        middleName: "",
        surname: "",
        roles: [],
        phoneNumber: "",
        email: "",
        address: "",
        identificationNumber: ""
      },
      employeeId: 0
    };
  }
  onChanged = e => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleSubmit = () => {
    console.log("called");

    const token = this.context;

    const employeeId = this.state.employeeId;

    Axios.put("hrm/updateProfile/" + employeeId, this.state.data)
      .then(res => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      })
      .catch(err => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  componentDidMount() {
    Axios.get("hrm/findEmployeeDetails")
      .then(res => {
        console.log(res);
        this.setState({ data: res.data, employeeId: res.data.id });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
      };
    }
    return (
      <div style={{ backgroundColor: "#EAEDEF" }}>
        <Grid container>
          <div style={{ marginTop: "60px" }}></div>
        </Grid>

        <Grid container>
          <Grid container justify="center" className={classes.tabMargin}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Edit Details" {...a11yProps(1)} />
              <Tab label="Change Password" {...a11yProps(2)} />
            </Tabs>
          </Grid>

          <MyProfile
            value={this.state.value}
            index={0}
            data={this.state.data}
          ></MyProfile>

          <EditDetails
            value={this.state.value}
            index={1}
            employeeData={this.state.data}
            employeeId={this.state.employeeId}
            onChange={this.onChanged}
            handleSubmit={this.handleSubmit}
          ></EditDetails>

          <ChangePassword value={this.state.value} index={2}></ChangePassword>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Profile);
