import React, { Component } from "react";
import { Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import Axios from "axios";
import UserContext from "../utils/UserContext";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import AddManufacturer from "../components/ManufactureMaster/AddManufacturer";
import ManufacturerDetails from "../components/ManufactureMaster/ManufacturerDetails";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class ManufacturerMaster extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      data: {
        type: "Manufacturer",
        fullName: "",
        street: "",
        city: "",
        state: "",
        country: "",
        licenseNumber: "",
        telephone: "",
        mobile: "",
        email: "",
        poBox: "",
        fax: "",
        typeOfProduct: "",
        otherReference: "",
        website: "",
      },
      manufacturerData: [],
      errorStatus: {},
    };
  }

  onChanged = (e) => {
    e.stopPropagation();
    var targetName = e.target.name;
    var value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  setGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getTableData = () => {
    Axios.get("pos/manufacturer/findAll")
      .then((res) => {
        var rowData = [];
        res.data.forEach((element) => {
          var obj = element;
          obj["address"] =
            (element.street === null ? "" : element.street) +
            " " +
            (element.city === null ? "" : element.city) +
            " " +
            (element.state === null ? "" : element.state) +
            " " +
            (element.country === null ? "" : element.country);
          rowData.push(obj);
        });
        this.setState({ manufacturerData: rowData });
        try {
          this.gridApi.hideOverlay();
        } catch (error) {}
      })
      .catch((err) => {
        console.log(err);
        try {
          this.gridApi.hideOverlay();
        } catch (error) {}
      });
  };

  componentDidMount() {
    this.getTableData();
  }

  showSnackbar = (snackbarProps) => {
    const token = this.context;
    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  handleSubmit = () => {
    const token = this.context;

    Axios.post("pos/manufacturer/save", this.state.data)
      .then((res) => {
        console.log(res);

        this.setState({
          data: {
            type: "Manufacturer",
            fullName: "",
            street: "",
            city: "",
            state: "",
            country: "",
            licenseNumber: "",
            telephone: "",
            mobile: "",
            email: "",
            poBox: "",
            fax: "",
            typeOfProduct: "",
            otherReference: "",
            website: "",
          },
        });
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      })
      .catch((err) => {
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000,
        };

        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <div>
        <div style={{ backgroundColor: "#EAEDEF" }}>
          <HeaderTitle title="Supplier Master" />

          <Grid container justify="center">
            <Tabs
              className={classes.tabMargin}
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              indicatorColor="primary"
            >
              <Tab
                style={{ fontSize: "12px" }}
                label="Manufacturer Details"
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: "12px" }}
                label="Add Manufacturer"
                {...a11yProps(1)}
              />
            </Tabs>
            <Grid container style={{ width: "100vw" }} justify="center">
              <Grid item xs={12}>
                <ManufacturerDetails
                  value={this.state.value}
                  index={0}
                  manufacturerData={this.state.manufacturerData}
                  getTableData={this.getTableData}
                  setGridApi={this.setGridApi}
                  showSnackbar={this.showSnackbar}
                ></ManufacturerDetails>
                <AddManufacturer
                  data={this.state.data}
                  handleSubmit={this.handleSubmit}
                  onChange={this.onChanged}
                  errorStatus={this.state.errorStatus}
                  value={this.state.value}
                  index={1}
                ></AddManufacturer>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ManufacturerMaster);
