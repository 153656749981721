import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  MenuItem,
  withStyles
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SettingFinancialPeriod from "./SettingFinancialPeriod";
import UserContext from "../utils/UserContext";
import Axios from "axios";

const styles = theme => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      marginBottom: "80px"
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "50px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px"
    }
  },
  tabMargin: {
    padding: "20px",
    width: "100%",
    maxWidth: "700px",
    margin: "10px",
    marginRight: "10px",
    marginLeft: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "2px",
    }
  }
});

class CompanyInformation extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      data: {
        branch: "",
        city: "",
        companyId: "",
        contactNumber: "",
        contactPerson: "",
        country: "",
        email: "",
        faxNumber: "",
        financialPeriod: "",
        fiscalYear: "",
        fiscalYearEnd: "",
        fiscalYearStart: "",
        invoicingMethod: "",
        name: "",
        natureOfBusiness: "",
        panNumber: "",
        province: "",
        registrationNumber: "",
        invoicingFormat: "",
        stockValuation: "",
        street: "",
        website: ""
      },
      cardValue: 0
    };
  }

  handleCompanySave = () => {
    const token = this.context;

    Axios.post("/hrm/saveGeneralCompanyInformation", this.state.data)
      .then(res => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
        token.setCompanyInfoSet({ value: true });

        setTimeout(() => {
          this.props.history.push("/Dashboard");
        }, 100);
      })
      .catch(err => {
        console.log(err);

        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  onChanged = e => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
  };

  render() {
    const inputStyles = {
      fontSize: 14
    };


    const { classes } = this.props;

    var today = new Date();

    var month = (today.getMonth() + 1).toString();

    var day = today.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    var date = today.getFullYear() + "-" + month + "-" + day;

    return (
      <Grid container justify="center" className={classes.root}>
        <Grid container>
          <div style={{ marginTop: "60px" }}></div>
        </Grid>
        <Card
          className={classes.tabMargin}
          hidden={this.state.cardValue !== 0}
        >
          <CardContent>
            <Grid container>
              <Typography
                variant="h6"
                component="h2"
                style={{ fontWeight: "bold" }}
              >
                {" "}
                Company Information{" "}
              </Typography>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={9}>
                <TextField
                  id="standard-with-placeholder"
                  label="Name of Company"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="name"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Company ID"
                  style={{ width: "100%" }}
                  placeholder=""
                  name="companyId"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="PAN"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="panNumber"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Registration Number"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="registrationNumber"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Nature of Business"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="natureOfBusiness"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Branch"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="branch"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "45px" }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Country"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="country"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Province"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="province"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="City"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="city"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Street"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="street"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Contact Number"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="contactNumber"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Contact Email"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="email"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fax Number"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="faxNumber"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Website"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="website"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "45px" }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Invoicing Method"
                  placeholder=""
                  select
                  value={this.state.data.invoicingMethod}
                  style={{ width: "100%" }}
                  name="invoicingMethod"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                >
                  <MenuItem key={1} value="PAN">
                    PAN
                  </MenuItem>
                  <MenuItem key={2} value="VAT">
                    VAT
                  </MenuItem>
                  <MenuItem key={3} value="Abbreviated Tax Invoice">
                    Abbreviated Tax Invoice
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Stock Valuation"
                  value={this.state.data.stockValuation}
                  placeholder=""
                  select
                  style={{ width: "100%" }}
                  name="stockValuation"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                >
                  <MenuItem key={1} value="Last Purchase">
                    Last Purchase
                  </MenuItem>
                  <MenuItem key={2} value="Average Method">
                    Average Method
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  id="standard-with-placeholder"
                  label="Invoicing Format"
                  value={this.state.data.invoicingFormat}
                  placeholder=""
                  select
                  style={{ width: "100%" }}
                  name="invoicingFormat"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                >
                  <MenuItem key={1} value="3' inch">
                    3' inch
                  </MenuItem>
                  <MenuItem key={2} value="4' inch">
                    4' inch
                  </MenuItem>
                  <MenuItem key={3} value="A4">
                    A4
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fiscal Year"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="fiscalYear"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fiscal Year Start"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="fiscalYearStart"
                  defaultValue={date}
                  type="date"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fiscal Year End"
                  placeholder=""
                  type="date"
                  style={{ width: "100%" }}
                  defaultValue={date}
                  name="fiscalYearEnd"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="standard-with-placeholder"
                  label="Financial Period"
                  placeholder=""
                  style={{ width: "100%" }}
                  name="financialPeriod"
                  variant="filled"
                  onChange={this.onChanged}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  margin="dense"
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Button
                onClick={this.handleCompanySave}
                variant="contained"
                size="large"
                color="primary"
                style={{
                  marginTop: "30px",
                  paddingLeft: "47px",
                  paddingRight: "47px",
                  paddingTop: "15px",
                  paddingBottom: "15px"
                }}
              >
                Save
              </Button>
            </Grid>
          </CardContent>
        </Card>
        <SettingFinancialPeriod
          hidden={this.state.cardValue !== 1}
          companyInfo={this.state.data}
          displayCompany={() => {
            this.setState({
              cardValue: 0
            });
          }}
        />
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(CompanyInformation));
