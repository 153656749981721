import React, { Component } from "react";
import {
  Grid,
  Card,
  Typography,
  Divider,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactToPrint from "react-to-print";
import Close from "@material-ui/icons/Close";
import { toWords } from "number-to-words";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class ViewDepreciationVoucherList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: "",
      companyAddress: "",
      companyContact: "",
    };
  }

  componentDidMount() {
    this.getCompanyName();
  }

  async getExcelData(data) {
    let excel = [];

    let obj = {
      sn: "",
      voucherType: "created At",
      narration: data.createdAt,
      costCenter: "",
      accountCode: "",
      subAccount: "",
      currency: "",
      amount: "",
    };

    excel.push(obj);

    obj = {
      sn: "",
      voucherType: "Fiscal Year",
      narration: data.fiscalYear,
      costCenter: "",
      accountCode: "",
      subAccount: "",
      currency: "",
      amount: "",
    };
    excel.push(obj);

    obj = {
      sn: "",
      voucherType: "Voucher No.",
      narration: data.voucherNumber,
      costCenter: "",
      accountCode: "",
      subAccount: "",
      currency: "",
      amount: "",
    };

    excel.push(obj);

    obj = {
      sn: "",
      voucherType: "",
      narration: "",
      costCenter: "",
      accountCode: "",
      subAccount: "",
      currency: "",
      amount: "",
    };
    excel.push(obj);

    obj = {
      sn: "SN",
      voucherType: "Voucher Type",
      narration: "Narration",
      costCenter: "Cost Center",
      accountCode: "Account Code",
      subAccount: "Cash/Bank Id/Sub Account",
      currency: "Currency",
      amount: "Amount",
    };
    excel.push(obj);

    data.debitSide.map((item, i) => {
      let obj = {
        sn: i + 1,
        voucherType: "Debit",
        narration: item.narration,
        costCenter: item.costCenter,
        accountCode: item.accountCode,
        subAccount: item.subAccount,
        currency: item.analysis,
        amount: item.amount,
      };
      excel.push(obj);

      return null;
    });
    data.creditSide.map((item, i) => {
      let obj = {
        sn: data.debitSide.length + i + 1,
        voucherType: "Credit",
        narration: item.narration,
        costCenter: item.costCenter,
        accountCode: item.accountCode,
        subAccount: item.subAccount,
        currency: item.analysis,
        amount: item.amount,
      };
      excel.push(obj);

      return null;
    });

    console.log("excel");
    console.log(excel);

    await this.setState({
      excelData: excel,
    });

    console.log("stateexcel");
    console.log(this.state.excelData);
  }

  componentWillReceiveProps(nextProps) {
    console.log({ nextProps });

    this.getExcelData(nextProps.data);
  }

  getCompanyName() {
    Axios.get("/hrm/getGeneralCompanyInformation")
      .then((res) => {
        res.data.forEach((element) => {
          this.setState({
            companyName: element.name,
            companyAddress: element.street + ", " + element.city,
            companyContact: element.contactNumber,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  generatePdf = () => {
    let company = this.state.companyName;
    let companyAddress = this.state.companyAddress;
    let companyContactNumber = this.state.companyContact;
    function header() {
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text(300, 90, company, {
        align: "center",
      });

      doc.text(300, 115, companyAddress, {
        align: "center",
      });

      doc.setFontSize(11);
      doc.text(300, 140, companyContactNumber, {
        align: "center",
      });
    }
    function footer() {
      doc.setFont("arial", "italic");
      doc.setFontSize(9);
      doc.text(40, doc.internal.pageSize.height - 20, window.location.href);
    }

    var doc = new jsPDF("p", "pt", "a4", true);
    //current date
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(9);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    doc.text(480, doc.internal.pageSize.height - 20, "Printed on: " + today);
    header();

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(12);
    doc.text(40, 190, "Depreciation Voucher(DV)");

    var y = 230;
    var x = 40;

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(x, y, "Voucher Number:");
    doc.setFont("Helvetica", "normal");
    doc.text(x + 100, y, this.props.data.voucherNumber);

    x = x + 300;
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(x, y, "Fiscal Year:");
    doc.setFont("Helvetica", "normal");
    doc.text(x + 65, y, this.props.data.fiscalYear);

    x = x - 300;
    y = y + 30;
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(x, y, "Period:");
    doc.setFont("Helvetica", "normal");
    doc.text(x + 40, y, this.props.data.period);

    /* prepared, checked and approvedBy start */

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(11);
    doc.text(40, 580, "Prepared By");

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(40, 610, "Name: ");
    doc.text(40, 640, "Position:");
    doc.text(40, 670, "Date:");

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(78, 610, "");
    doc.text(88, 640, "");
    doc.text(
      73,
      670,
      this.props.data.createdAt === null || undefined
        ? ""
        : this.props.data.createdAt
    );

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(11);
    doc.text(230, 580, "Checked By");

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(230, 610, "Name:");
    doc.text(230, 640, "Position:");
    doc.text(230, 670, "Date:");

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(268, 610, "");
    doc.text(280, 640, "");
    doc.text(263, 670, "");

    doc.setFont("Helvetica", "bold");
    doc.setFontSize(11);
    doc.text(415, 580, "Approved By");

    doc.setFont("Helvetica", "normal");
    doc.setFontSize(11);
    doc.text(415, 610, "Name:");
    doc.text(415, 640, "Position:");
    doc.text(415, 670, "Date:");

    doc.setFont("Helvetica", "normal");
    doc.text(455, 610, "");
    doc.text(463, 640, "");
    doc.text(448, 670, "");

    let debitValues = [];
    let i = 1;
    this.props.data.debitSide.map((element) =>
      debitValues.push([
        i++,
        element.narration,
        element.costCenter,
        element.accountCode,
        element.subAccount,
        element.currency,
        element.amount,
      ])
    );

    this.props.data.creditSide.map((element) =>
      debitValues.push([
        i++,
        element.narration,
        element.costCenter,
        element.accountCode,
        element.subAccount,
        element.currency,
        element.amount,
      ])
    );

    this.props.data.creditSide.map((element) =>
      debitValues.push(["", "", "", "", "", "Total", element.amount])
    );

    let totalAmountInWords = 0;

    try {
      totalAmountInWords = toWords(
        parseInt(this.props.data.creditSide[0].amount)
      );
    } catch (error) {
      console.log(error);
    }

    doc.autoTable({
      styles: {
        overflow: "linebreak",
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
        halign: "center",
        font: "helvetica",
      },
      foot: [
        [
          {
            styles: { font: "helvetica", fontStyle: "italic", halign: "left" },
            content: "Amount in words: " + totalAmountInWords + " only.",
            colSpan: 9,
          },
        ],
      ],
      didParseCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = 'bold';
        }
      },
      bodyStyles: { valign: "top" },
      startY: y + 60,
      theme: "grid",
      columnStyles: {
        0: {
          font: "helvetica",
          fontSize: 11,
          fontStyle: "normal",
          columnWidth: 25,
          halign: "center",
        },
        1: {
          font: "helvetica",
          fontSize: 11,
          fontStyle: "normal",
          columnWidth: 150,
          halign: "left",
        },
        2: {
          font: "helvetica",
          fontSize: 11,
          fontStyle: "normal",
          columnWidth: 60,
          halign: "left",
        },
        3: {
          font: "helvetica",
          fontSize: 11,
          fontStyle: "normal",
          columnWidth: 60,
          halign: "left",
        },
        4: {
          font: "helvetica",
          fontSize: 11,
          fontStyle: "normal",
          columnWidth: 100,
          halign: "left",
        },
        5: {
          font: "helvetica",
          fontSize: 11,
          fontStyle: "normal",
          columnWidth: 60,
          halign: "center",
        },
        6: {
          font: "helvetica",
          fontSize: 11,
          fontStyle: "normal",
          columnWidth: 60,
          halign: "right",
        },
      },
      head: [
        [
          "SN",
          "Narration",
          "Cost Center",
          "Account Code",
          "Sub Account",
          "Currency",
          "Amount",
        ],
      ],
      body: debitValues,
    });

    footer();
    doc.save("Depreciation Voucher");
  };

  render() {
    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
    };

    const {
      createdAt,
      debitSide,
      creditSide,
      fiscalYear,
      voucherNumber,
    } = this.props.data;

    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    return (
      <div>
        <Grid container justify="center">
          <Card
            hidden={this.props.hidden}
            style={{
              width: "60vw",
              padding: "0px",
              marginBottom: "100px",
            }}
          >
            <Grid container justify="space-between">
              <Grid item>
                <Grid container>
                  <Typography
                    variant="h6"
                    onClick={this.props.animateOut}
                    style={{ cursor: "pointer", padding: "10px" }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>{" "}
                    Voucher List
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.props.animateOut}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ padding: "20px" }}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      minWidth: "100px",
                      width: "6vw",
                      margin: "auto",
                      height: "40px",
                      fontSize: "16px",
                    }}
                    onClick={this.generatePdf}
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      color="#3f51b5"
                      size="lg"
                      style={{
                        marginRight: "10px",
                      }}
                    ></FontAwesomeIcon>
                    Pdf
                  </Button>
                </Grid>
                <Grid item>
                  <ExcelFile
                    filename="TrialBalance"
                    element={
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          minWidth: "100px",
                          width: "6vw",
                          margin: "auto",
                          height: "38px",
                          fontSize: "16px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faFileExcel}
                          color="#3f51b5"
                          size="30px"
                          style={{
                            marginRight: "10px",
                          }}
                        ></FontAwesomeIcon>
                        Excel
                      </Button>
                    }
                  >
                    <ExcelSheet
                      name="ReceivedVoucher"
                      data={this.state.excelData}
                    >
                      <ExcelColumn value="sn" />
                      <ExcelColumn value="voucherType" />
                      <ExcelColumn value="narration" />
                      <ExcelColumn value="costCenter" />
                      <ExcelColumn value="accountCode" />
                      <ExcelColumn value="subAccount" />
                      <ExcelColumn value="currency" />
                      <ExcelColumn value="amount" />
                    </ExcelSheet>
                  </ExcelFile>
                </Grid>
                <Grid item>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          minWidth: "100px",
                          width: "6vw",
                          margin: "auto",
                          height: "40px",
                          fontSize: "16px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPrint}
                          color="#3f51b5"
                          size="lg"
                          style={{
                            marginRight: "10px",
                          }}
                        ></FontAwesomeIcon>
                        Print
                      </Button>
                    )}
                    content={() => this.componentRef}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: "25px" }}>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    value={voucherNumber}
                    fullWidth
                    label="Voucher Number"
                  ></TextField>
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    value={fiscalYear}
                    fullWidth
                    label="Fiscal Year"
                  ></TextField>
                </Grid>
                <Grid item xs>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    value={createdAt}
                    fullWidth
                    label="Created At"
                  ></TextField>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: "25px", marginBottom: "20px" }} />
              <Typography
                variant="h6"
                style={{ fontWeight: "bold", marginTop: "20px" }}
              >
                Debit Side
              </Typography>
              <TableContainer>
                <Table
                  aria-label="customized table"
                  //   fixedHeader={false} style={{ width: "auto", tableLayout: "auto",  marginTop: "10px", marginBottom: "15px"  }}
                  fixedHeader={false}
                  style={{ marginTop: "10px", marginBottom: "15px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={styledTableCell} align="center">
                        {" "}
                        S. No.{" "}
                      </TableCell>
                      <TableCell style={styledTableCell}>
                        Voucher Type
                      </TableCell>
                      <TableCell style={styledTableCell}>Narration</TableCell>
                      <TableCell style={styledTableCell}>Cost Center</TableCell>
                      <TableCell style={styledTableCell}>
                        Account Code
                      </TableCell>
                      <TableCell style={styledTableCell}>
                        Cash/Bank ID/Sub Account
                      </TableCell>
                      <TableCell style={styledTableCell}>Currency</TableCell>
                      <TableCell style={styledTableCell}>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {debitSide.map((value) => (
                      <TableRow>
                        <TableCell align="center">{value.id}</TableCell>
                        <TableCell>
                          <TextField
                            value="Debit"
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.narration}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.costCenter}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.accountCode}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.subAccount}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.currency}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={format.format(value.amount)}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  <TableBody>
                    {creditSide.map((value) => (
                      <TableRow>
                        <TableCell align="center">{value.id}</TableCell>
                        <TableCell>
                          <TextField
                            value="Credit"
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.narration}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.costCenter}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.accountCode}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.subAccount}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={value.analysis}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={format.format(value.amount)}
                            variant="outlined"
                            margin="dense"
                          ></TextField>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default ViewDepreciationVoucherList;
