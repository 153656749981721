import React, { PureComponent } from "react";
import {
  CardContent,
  Grid,
  Card,
  withStyles,
  InputAdornment
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ValidatedTextField from "../InterfaceElements/ValidatedTextField";

const styles = theme => ({
  root: {
    padding: 7,
    paddingTop: "5px",
    paddingBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  },
  containerWidth: {
    margin: "auto",
    marginTop: "0px",
    marginBottom: "0px",
    [theme.breakpoints.up("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%"
    },
    [theme.breakpoints.up("md")]: {
      width: "90%"
    }
  }
});

class AddSupplier extends PureComponent {
  render() {
    const {
      children,
      value,
      index,
      data,
      errorStatus,
      onChange,
      onSupplierIdNumberChange,
      productType,
      supplierType,
      accountType,
      handleSubmit,
      classes,
      ...other
    } = this.props;

    const textFieldStyle = {
      width: "100%"
    };
    const longTextFieldStyle = {
      width: "100%"
    };
    const inputStyles = {
      fontSize: 14
    };

    return (
      <div>
        <Card
          className={classes.root}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center" style={{ height: "10px", marginBottom: "60px" }}>
              <h6>Supplier Details</h6>
            </Grid>

            <Grid container spacing={4} className={classes.containerWidth}>
              <Grid item xs={12} md={6} sm={6}>
                <ValidatedTextField
                  label="Supplier Name"
                  name="fullName"
                  error={errorStatus.fullName}
                  value={data.fullName}
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Supplier ID Number"
                  placeholder=""
                  value={data.supplierIdNumber}
                  onChange={onSupplierIdNumberChange}
                  name="supplierIdNumber"
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">200-</InputAdornment>
                    ),
                    style: inputStyles
                  }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Mobile Number"
                  name="mobileNumber"
                  error={errorStatus.mobileNumber}
                  value={data.mobileNumber}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Telephone Number"
                  name="telephoneNumber"
                  placeholder=""
                  value={data.telephoneNumber}
                  onChange={onChange}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fax Number"
                  name="faxNumber"
                  value={data.faxNumber}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Post Box Number"
                  name="postBoxNumber"
                  value={data.postBoxNumber}
                  placeholder=""
                  onChange={onChange}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Registration Number"
                  name="registrationNumber"
                  error={errorStatus.registrationNumber}
                  value={data.registrationNumber}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="PAN Number"
                  name="panNumber"
                  error={errorStatus.panNumber}
                  value={data.panNumber}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  label="Email Address"
                  name="emailAddress"
                  value={data.emailAddress}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  label="website"
                  name="website"
                  value={data.website}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <ValidatedTextField
                  label="Contact Person"
                  name="contactPerson"
                  error={errorStatus.contactPerson}
                  value={data.contactPerson}
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ height: "10px", marginBottom: "60px" }}>
              <h6>Full Address</h6>
            </Grid>

            <Grid container spacing={2} className={classes.containerWidth}>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Country"
                  name="country"
                  error={errorStatus.country}
                  value={data.country}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="State"
                  name="state"
                  error={errorStatus.state}
                  value={data.state}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="City"
                  name="city"
                  error={errorStatus.city}
                  value={data.city}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Street"
                  name="street"
                  error={errorStatus.street}
                  value={data.street}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
            </Grid>

            <Grid container justify="center" style={{ height: "10px", marginBottom: "60px" }}>
              <h6>Supplier Type</h6>
            </Grid>

            <Grid container spacing={2} className={classes.containerWidth}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  label="Product Type"
                  name="productType"
                  onChange={onChange}
                  style={textFieldStyle}
                  value={productType}
                  margin="normal"
                >
                  <MenuItem key={1} value={"Product Type 1"}>
                    Product Type 1
                  </MenuItem>
                  <MenuItem key={2} value={"Product Type 2"}>
                    Product type 2
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-select-currency"
                  label="Supplier Type"
                  name="supplierType"
                  onChange={onChange}
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  value={supplierType}
                  margin="normal"
                >
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Credit Term"
                  name="creditTerm"
                  value={data.creditTerm}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Other Reference"
                  placeholder=""
                  name="otherReference"
                  value={data.otherReference}
                  onChange={onChange}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ height: "10px", marginBottom: "60px" }} >
              <h6>Supplier Bank Details</h6>
            </Grid>
            <Grid container spacing={2} className={classes.containerWidth}>
              <Grid item xs={12}>
                <ValidatedTextField
                  label="Bank Name"
                  name="bankName"
                  error={errorStatus.bankName}
                  value={data.bankName}
                  onChange={onChange}
                  textFieldStyle={longTextFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Bank Account Name"
                  name="accountName"
                  error={errorStatus.accountName}
                  value={data.accountName}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Bank Account Number"
                  name="accountNumber"
                  error={errorStatus.accountNumber}
                  value={data.accountNumber}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Bank Code"
                  name="bankCode"
                  value={data.bankCode}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Branch Code"
                  name="branchCode"
                  value={data.branchCode}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  label="Account Type"
                  name="accountType"
                  onChange={onChange}
                  style={textFieldStyle}
                  value={accountType}
                  margin="normal"
                >
                  <MenuItem key={1} value={"Normal Saving"}>
                    Normal Saving
                  </MenuItem>
                  <MenuItem key={2} value={"Employee Saving"}>
                    Employee Saving
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="SWIFT Code"
                  name="swiftCode"
                  value={data.swiftCode}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Currency"
                  name="currency"
                  value={data.currency}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="IBAN no."
                  name="ibanNumber"
                  value={data.ibanNumber}
                  placeholder=""
                  onChange={onChange}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ height: "10px", marginBottom: "60px" }}>
              <h6>Bank Address</h6>
            </Grid>
            <Grid container spacing={2} className={classes.containerWidth}>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="Country"
                  name="bankCountry"
                  error={errorStatus.bankCountry}
                  value={data.bankCountry}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="State"
                  name="bankState"
                  error={errorStatus.bankState}
                  value={data.bankState}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ValidatedTextField
                  label="City"
                  name="bankCity"
                  error={errorStatus.bankCity}
                  value={data.bankCity}
                  onChange={onChange}
                  textFieldStyle={textFieldStyle}
                  inputStyles={inputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Street"
                  placeholder=""
                  value={data.bankStreet}
                  name="bankStreet"
                  onChange={onChange}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Fax"
                  name="fax"
                  value={data.fax}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Post Box"
                  placeholder=""
                  name="postBox"
                  value={data.postBox}
                  onChange={onChange}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Telephone"
                  name="telephone"
                  value={data.telephone}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  placeholder=""
                  onChange={onChange}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="standard-with-placeholder"
                  label="Email"
                  placeholder=""
                  value={data.email}
                  name="email"
                  onChange={onChange}
                  inputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  style={textFieldStyle}
                  margin="normal"
                />
              </Grid>
            </Grid>

            <Grid container justify="center">
              <Button
                variant="outlined"
                onClick={handleSubmit}
                size="large"
                color="primary"
                style={{
                  margin: "20px",
                  marginTop: "25px"
                }}
              >
                Add Supplier
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(AddSupplier);
