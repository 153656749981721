import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  withStyles,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Button,
  TablePagination,
  TextField,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import RowComponent from "../../components/InventoryCount/RowComponent";
import MuiAlert from "@material-ui/lab/Alert";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import Close from "@material-ui/icons/Close";
import UserContext from "../../utils/UserContext";

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

function AddInventoryCount(props) {
  const [tableData, setTableData] = useState(
    props.data.inventoryPhysicalCountChilds
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [allEntered, setAllEntered] = useState(true);
  const [unsetCount, setUnSetCount] = useState(0);
  const [origData, setOrigData] = useState([]);
  const [fyList, setFyList] = useState([]);
  const [resData, setResData] = useState([]);
  const [fiscalYear, setFiscalYear] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState("");
  const [periodList, setPeriodList] = useState([]);
  const [editable, setEditable] = useState(false);

  const { setSnackbarProps } = useContext(UserContext);

  useEffect(() => {
    let noneCount = 0;

    props.data.inventoryPhysicalCountChilds.map((val) =>
      val.physicalCount === "" ? noneCount++ : null
    );

    if (noneCount > 0) {
      setAllEntered(false);
    }

    setUnSetCount(noneCount);
    setTableData(props.data.inventoryPhysicalCountChilds);
    setOrigData(props.data.inventoryPhysicalCountChilds);

    Axios.get("/fy/getAllActivePeriods")
      .then((res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });

        setFyList(fiscalYearLists);
        setResData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.data.inventoryPhysicalCountChilds]);

  const handleStatus = (status) => {
    Axios.put(
      "pos/inventoryPhysicalCount/updateAuthorization/" + props.data.id,
      { status: status }
    )
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        setSnackbarProps({ snackbarProps: snackbarProps });

        props.getTableDataForAuth();
        props.getTableDataForList();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  const handleUpdate = () => {
    let inventoryPhysicalCountChilds = [];

    tableData.map((val) =>
      inventoryPhysicalCountChilds.push({
        comment: val.comment,
        difference: val.difference,
        id: val.id,
        itemCode: val.itemCode,
        itemName: val.itemName,
        physicalCount: val.physicalCount,
        quantity: val.quantity,
        status: val.status,
      })
    );

    let obj = {
      countedBy: countedBy,
      fiscalYear: fiscalYear,
      period: currentPeriod,
      inventoryPhysicalCountChilds: inventoryPhysicalCountChilds,
    };

    Axios.put("pos/inventoryPhysicalCount/update/" + props.data.id, obj)
      .then((res) => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        setSnackbarProps({ snackbarProps: snackbarProps });
      })
      .catch((err) => {
        console.log(err);

        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  useEffect(() => {
    Axios.get("/fy/getCurrentPeriod")
      .then((res) => {
        setFiscalYear(res.data.fiscalYear);
        setCurrentPeriod(res.data.name);

        setFiscalYear(props.data.fiscalYear);
        setCurrentPeriod(props.data.period);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fyList, resData, props.data.period, props.data.fiscalYear]);

  useEffect(() => {
    var pLists = [];

    resData.map((val) =>
      Object.keys(val).includes(fiscalYear) ? (pLists = val[fiscalYear]) : null
    );

    setPeriodList(pLists);

    Axios.post("pos/inventoryPhysicalCount/findByPeriodAndFiscalYear", {
      fiscalYear: fiscalYear,
      currentPeriod: currentPeriod,
    })
      .then((res) => {})
      .catch((err) => {});
  }, [fiscalYear, currentPeriod, resData]);

  const handlePeriodChange = (e) => {
    let Py = e.target.value;

    setCurrentPeriod(Py);
  };

  const handleFYChange = (e) => {
    let Fy = e.target.value;

    setFiscalYear(Fy);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generateList = () => {
    let arr = [];
    let data = tableData;

    data.map((val, i) =>
      arr.push(
        <RowComponent
          key={i}
          sn={i + 1}
          itemCode={val.itemCode}
          itemName={val.itemName}
          quantity={val.quantity}
          physicalCount={val.physicalCount}
          handleChange={handlePhysicalCountChange}
          handleComment={handleComment}
          physicalCountError={val.physicalCountError}
          difference={val.difference}
          status={val.status}
          warehouse={val.warehouse}
          comment={val.comment}
          commentError={val.commentError}
        />
      )
    );

    return arr;
  };

  const handleFilter = (e, { value }) => {
    if (!editable) {
      return;
    }

    console.log(value);

    let newData = [];

    if (value === "All Status") {
      newData = origData;
    } else {
      origData.map((val) => (val.status === value ? newData.push(val) : null));
    }

    setTableData(newData);
  };

  const handlePhysicalCountChange = (itemCode, value) => {
    if (!editable) {
      return;
    }

    let targetName = itemCode;
    let targetValue = value;

    let globalStatus = true;
    let unsetCount = 0;

    if (parseInt(targetValue) < 0) return;

    let newData = [];

    tableData.forEach((element) => {
      let newElement = element;

      if (newElement.itemCode === targetName) {
        newElement.physicalCount = targetValue;

        if (targetValue !== "") {
          newElement.physicalCountError = false;

          newElement.difference = newElement.quantity - targetValue;

          newElement.status = newElement.difference === 0 ? "OK" : "NOT OK";

          newElement.commentError = newElement.status === "OK" ? false : true;
        } else {
          newElement.physicalCountError = true;
          newElement.status = "NOT OK";
          newElement.difference = "";
        }
      }

      if (newElement.physicalCount === "") {
        globalStatus = false;
        unsetCount++;
      }

      newData.push(newElement);
    });

    setTableData(newData);
    setOrigData(newData);
    setAllEntered(globalStatus);
    setUnSetCount(unsetCount);
  };

  const handleComment = (itemCode, value) => {
    if (!editable) {
      return;
    }

    let targetName = itemCode;
    let targetValue = value;

    let newData = [];

    tableData.forEach((element) => {
      let newElement = element;

      if (element.itemCode === targetName) {
        newElement.comment = targetValue;
        if (targetValue !== "") {
          newElement.commentError = false;
        } else {
          newElement.commentError = true;
        }
      }

      newData.push(newElement);
    });

    setTableData(newData);
  };

  const buttonStyle = {
    padding: "10px",
    margin: "20px",
    marginTop: "25px",
    marginRight: "50px",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingRight: "50px",
    paddingLeft: "50px",
  };

  const { status, countedBy } = props.data;

  return (
    <div>
      <Grid container style={{ width: "100vw" }} justify="center">
        <Grid
          item
          xs={12}
          lg={12}
          md={12}
          sm={12}
          style={{ paddingRight: "10px", paddingLeft: "10px" }}
        >
          <Card className={props.classes.root} hidden={props.hidden}>
            <Grid container justify="space-between">
              <Grid item>
                <Grid container>
                  <Typography
                    variant="h6"
                    onClick={props.animateOut}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      fontSize: "15px",
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>{" "}
                    Physical Inventory Count List
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton onClick={props.animateOut}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <CardContent style={{ padding: "0px" }}>
              <Grid container style={{ padding: "20px" }}>
                <Grid
                  container
                  style={{ width: "100%" }}
                  justify="space-between"
                >
                  <Grid item xs={12} md={7} style={{ marginTop: "20px" }}>
                    <Grid container justify="flex-start">
                      <Dropdown
                        placeholder="Filter By Status"
                        fluid
                        style={{
                          maxWidth: "400px",
                          minWidth: "150px",
                          width: "100%",
                          fontSize: "14px",
                        }}
                        search
                        selection
                        options={[
                          {
                            key: 0,
                            text: "All Status....",
                            value: "All Status",
                          },
                          {
                            key: 1,
                            text: "OK",
                            value: "OK",
                          },
                          {
                            key: 2,
                            text: "VARIANCE",
                            value: "VARIANCE",
                          },
                        ]}
                        onChange={handleFilter}
                      ></Dropdown>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <Grid container justify="flex-end" spacing={1}>
                      <Grid item>
                        {editable ? (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              width: "100px",
                              height: "38px",
                              fontSize: "16px",
                              marginTop: "20px",
                            }}
                            onClick={() => setEditable(!editable)}
                          >
                            Edit
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              width: "100px",
                              height: "38px",
                              fontSize: "16px",
                              marginTop: "20px",
                            }}
                            onClick={() => setEditable(!editable)}
                          >
                            Edit
                          </Button>
                        )}
                      </Grid>
                      <Grid item>
                        {allEntered ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              width: "100px",
                              height: "38px",
                              fontSize: "16px",
                              marginTop: "20px",
                            }}
                            onClick={handleUpdate}
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled
                            style={{
                              width: "100px",
                              height: "38px",
                              fontSize: "16px",
                              marginTop: "20px",
                            }}
                          >
                            Update
                          </Button>
                        )}
                      </Grid>
                      <Grid item>
                        {allEntered ? (
                          <ExcelFile
                            filename="Inventory Physical Count"
                            element={
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                  width: "100px",
                                  height: "38px",
                                  fontSize: "16px",
                                  marginTop: "20px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faFileExcel}
                                  color="#3f51b5"
                                  size="30px"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                ></FontAwesomeIcon>
                                Excel
                              </Button>
                            }
                          >
                            <ExcelSheet
                              data={tableData}
                              name="Inventory Physical Count"
                            >
                              <ExcelColumn label="ID" value="id" />
                              <ExcelColumn label="Item Code" value="itemCode" />
                              <ExcelColumn label="Item Name" value="itemName" />
                              <ExcelColumn label="Quantity" value="quantity" />
                              <ExcelColumn
                                label="Physical Count"
                                value="physicalCount"
                              />
                              <ExcelColumn
                                label="Difference"
                                value="difference"
                              />
                              <ExcelColumn label="Status" value="status" />

                              <ExcelColumn label="Comment" value="comment" />
                            </ExcelSheet>
                          </ExcelFile>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled
                            style={{
                              minWidth: "100px",
                              width: "6vw",
                              margin: "auto",
                              height: "38px",
                              fontSize: "16px",
                              marginTop: "20px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              color="#3f51b5"
                              size="30px"
                              style={{
                                marginRight: "10px",
                              }}
                            ></FontAwesomeIcon>
                            Excel
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="space-between"
                  style={{ marginTop: "10px" }}
                >
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        style={{ marginTop: "20px" }}
                      >
                        <TextField
                          variant="outlined"
                          select
                          margin="dense"
                          fullWidth
                          label="Fiscal Year"
                          name="fiscalYear"
                          value={fiscalYear}
                          onChange={handleFYChange}
                        >
                          {fyList.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        style={{ marginTop: "20px" }}
                      >
                        <TextField
                          variant="outlined"
                          select
                          margin="dense"
                          fullWidth
                          label="Period"
                          name="period"
                          value={currentPeriod}
                          onChange={handlePeriodChange}
                        >
                          {periodList.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        style={{ marginTop: "20px" }}
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          label="Sheet Number"
                        ></TextField>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ marginTop: "20px" }}
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          value={countedBy}
                        ></TextField>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ marginTop: "20px" }}
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          label="Counted By #2"
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {allEntered ? null : (
                    <Grid item xs={12} md={4}>
                      <Alert
                        severity="error"
                        style={{ marginTop: "20px", maxWidth: "500px" }}
                      >
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            {unsetCount}
                          </span>
                          <span>
                            {" "}
                            inventory items do not have physical count set!
                          </span>
                        </p>
                      </Alert>
                    </Grid>
                  )}
                </Grid>

                <Grid container style={{ marginTop: "15px" }}>
                  <TableContainer>
                    <Table aria-label="customized table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              width: "70px",
                            }}
                          >
                            SN
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              width: "90px",
                            }}
                          >
                            Item Code
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              minWidth: "200px",
                            }}
                          >
                            Item Name
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              minWidth: "200px",
                            }}
                          >
                            Warehouse
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              width: "130px",
                            }}
                          >
                            Quantity
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              width: "130px",
                            }}
                          >
                            Physical Count
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              width: "130px",
                            }}
                          >
                            Difference
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              width: "90px",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#F1F8FC",
                              color: "#3F51B5",
                              minWidth: "200px",
                            }}
                          >
                            Comment
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {generateList().slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Grid container justify="center">
                    {status === "Pending" ? (
                      <Button
                        variant="outlined"
                        size="large"
                        color="inherit"
                        onClick={() => {
                          handleStatus("Checked");
                        }}
                        style={buttonStyle}
                      >
                        Check
                      </Button>
                    ) : null}
                    {status === "Checked" ? (
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={() => {
                          handleStatus("Approved");
                        }}
                        style={buttonStyle}
                      >
                        Approve
                      </Button>
                    ) : null}

                    <Button
                      variant="outlined"
                      size="large"
                      color="secondary"
                      onClick={() => {
                        handleStatus("Rejected");
                      }}
                      style={buttonStyle}
                    >
                      Reject
                    </Button>
                  </Grid>
                </Grid>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={(event, newPage) => {
                    setPage(newPage);
                  }}
                  onChangeRowsPerPage={handleRowsPerPage}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default withStyles(styles)(AddInventoryCount);
