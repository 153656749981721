import React, { Component } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

class DashboardView extends Component {
  render() {
    const rootStyle = {
      right: "20px",
      width: "18vw",
      padding: "5px",
      position: "fixed",
    };

    return (
      <div style={rootStyle}>
        <Card style={{ marginTop: "60px", height: "75vh", overflow: "auto" }}>
          <CardContent>
            <div style={{ paddingTop: "0px" }}>
              <Typography variant="h6">
                <strong>Daily:</strong>
              </Typography>

              {this.props.dailyData.map((val, i) => (
                <React.Fragment key={i}>
                  <div
                    style={{
                      paddingTop: "10px",
                      fontSize: "13px",
                      color: "#929292",
                    }}
                  >
                    {val.for}
                  </div>
                  <div>
                    <Grid container justify="space-between">
                      <Grid item xs>
                        <strong>Rs. {val.amountToday}</strong>
                      </Grid>
                      <Grid item xs>
                        <Grid container justify="flex-end">
                          <Grid item>
                            {val.amountToday < val.amountYesterday ? (
                              <span
                                style={{
                                  borderRadius: "10px",
                                  backgroundColor: "#FFEDEB",
                                  padding: "7px",
                                  color: "#FF8070",
                                }}
                              >
                                {val.percentageChange.toFixed(2)}%
                                {
                                  <FontAwesomeIcon
                                    icon={faCaretDown}
                                    style={{
                                      marginLeft: "5px",
                                      color: "#FF8070",
                                    }}
                                  ></FontAwesomeIcon>
                                }
                              </span>
                            ) : (
                              <span
                                style={{
                                  borderRadius: "10px",
                                  backgroundColor: "#DFFAEC",
                                  padding: "7px",
                                  color: "#5FE3A1",
                                }}
                              >
                                {val.percentageChange === 0
                                  ? val.percentageChange
                                  : val.percentageChange.toFixed(2)}
                                %
                                {
                                  <FontAwesomeIcon
                                    icon={faCaretUp}
                                    style={{
                                      marginLeft: "5px",
                                      color: "#5FE3A1",
                                    }}
                                  ></FontAwesomeIcon>
                                }
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <Divider style={{ marginTop: "25px" }} />
            <div style={{ paddingTop: "10px" }}>
              <Typography variant="h6">
                <strong>Weekly:</strong>
              </Typography>

              {this.props.weeklyData.map((val, i) => (
                <React.Fragment key={i}>
                  <div
                    style={{
                      paddingTop: "10px",
                      fontSize: "13px",
                      color: "#929292",
                    }}
                  >
                    {val.for}
                  </div>
                  <div>
                    <Grid container justify="space-between">
                      <Grid item xs>
                        <strong>Rs. {val.amountThisWeek}</strong>
                      </Grid>
                      <Grid item xs>
                        <Grid container justify="flex-end">
                          <Grid item>
                            {val.amountThisWeek < val.amountLastWeek ? (
                              <span
                                style={{
                                  borderRadius: "10px",
                                  backgroundColor: "#FFEDEB",
                                  padding: "7px",

                                  color: "#FF8070",
                                }}
                              >
                                {val.percentageChange.toFixed(2)}%
                                {
                                  <FontAwesomeIcon
                                    icon={faCaretDown}
                                    style={{
                                      marginLeft: "5px",
                                      color: "#FF8070",
                                    }}
                                  ></FontAwesomeIcon>
                                }
                              </span>
                            ) : (
                              <span
                                style={{
                                  borderRadius: "10px",
                                  backgroundColor: "#DFFAEC",
                                  padding: "7px",

                                  color: "#5FE3A1",
                                }}
                              >
                                {val.percentageChange === 0
                                  ? val.percentageChange
                                  : val.percentageChange.toFixed(2)}
                                %
                                {
                                  <FontAwesomeIcon
                                    icon={faCaretUp}
                                    style={{
                                      marginLeft: "5px",
                                      color: "#5FE3A1",
                                    }}
                                  ></FontAwesomeIcon>
                                }
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default DashboardView;
