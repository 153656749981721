import React, { Component } from "react";
import { Tab, Tabs, Grid, withStyles, Badge } from "@material-ui/core";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import AddBatchInputVoucher from "../components/BatchInputVoucher/AddBatchInputVoucher";
import BatchInputVoucherAuthorization from "../components/BatchInputVoucher/BatchInputVoucherAuthorization";
import BatchInputVoucherList from "../components/BatchInputVoucher/BatchInputVoucherList";
import Axios from "axios";
import UserContext from "../utils/UserContext";

const styles = (theme) => ({
  tabMargin: {
    marginBottom: "7px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

class BatchInputVoucher extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      authList: [],
      batchInputVoucherList: [],
      listData: {
        voucherNumber: "",
        createdAt: "",
        approvedDate: "",
        period: "",
        fiscalYear: "",
        batchInputVoucherDetails: [],
      },
      authData: {
        voucherNumber: "",
        createdAt: "",
        period: "",
        fiscalYear: "",
        batchInputVoucherDetails: [],
      },
      authDataLoading: false,
      listDataLoading: false,
      dataToShow: [],
      auth: {
        visible: false,
        hidden: true,
        secondVisible: false,
        secondHidden: true,
        animationIn: "",
      },
      list: {
        visible: false,
        hidden: true,
        secondVisible: false,
        secondHidden: true,
        animationIn: "",
      },
    };
  }

  handleListViewCounter = (id) => {
    this.setState({
      listDataLoading: true,
    });

    Axios.get("/pos/batchInput/findById/" + id)
      .then((res) =>
        this.setState({
          listData: res.data,
          listDataLoading: false,
        })
      )
      .catch((err) => console.log(err));
  };

  handleViewCounter = (id) => {
    this.setState({
      authDataLoading: true,
    });

    Axios.get("/pos/batchInput/findById/" + id)
      .then((res) =>
        this.setState({
          authDataLoading: false,
          authData: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  handleChange = (event, value) => {
    const token = this.context;
    token.setVoucherNo({ value: "" });
    token.setVoucherType({ value: "" });

    this.setState({ value });

    console.log({ handleChange: value });

    if (value === 1) {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          hidden: true,
          secondHidden: true,
        },
        auth: {
          ...prevState.auth,
          animationIn: "",
          secondVisible: false,
          visible: true,
          hidden: false,
          secondHidden: true,
        },
      }));
    } else if (value === 2) {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          animationIn: "",
          secondVisible: false,
          visible: true,
          hidden: false,
          secondHidden: true,
        },
        auth: {
          ...prevState.auth,
          hidden: true,
          secondHidden: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        auth: {
          ...prevState.auth,
          hidden: true,
          secondHidden: true,
        },
        list: {
          ...prevState.list,
          hidden: true,
          secondHidden: true,
        },
      }));
    }
  };

  authAnimate = (id) => {
    this.handleViewCounter(id);
    this.setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        visible: false,
        animationIn: "zoomIn",
      },
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        auth: {
          ...prevState.auth,
          secondVisible: true,
          hidden: true,
          secondHidden: false,
        },
      }));
    }, 150);
  };

  authAnimateOut = () => {
    console.log({ "animate out called": true });

    this.setState((prevState) => ({
      ...prevState,
      auth: {
        ...prevState.auth,
        secondVisible: false,
      },
    }));
    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        auth: {
          ...prevState.auth,
          visible: true,
          hidden: false,
          secondHidden: true,
        },
      }));
    }, 150);
  };

  listAnimate = (id) => {
    this.handleListViewCounter(id);

    this.setState((prevState) => ({
      ...prevState,
      list: {
        ...prevState.list,
        visible: false,
        animationIn: "zoomIn",
      },
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          secondVisible: true,
          hidden: true,
          secondHidden: false,
        },
      }));
    }, 150);
  };

  listAnimateOut = (voucherNumber) => {
    this.handleListViewCounter(voucherNumber);
    this.setState((prevState) => ({
      ...prevState,
      list: {
        ...prevState.list,
        secondVisible: false,
      },
    }));
    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          visible: true,
          hidden: false,
          secondHidden: true,
        },
      }));
    }, 150);
  };

  getTableAuthList = () => {
    Axios.get("/pos/batchInput/findMyRequests").then((res) => {
      var dataRow = [];
      var ids = [];
      res.data.forEach((element) => {
        const data = {
          id: element.id,
          transactionDate: element.createdAt,
          period: element.period,
          voucherNumber: element.voucherNumber,
          status: element.status,
          fiscalYear: element.fiscalYear,
        };
        dataRow.push(data);
        ids.push(element.id);
      });

      this.setState((prevState) => ({
        ...prevState,
        authList: dataRow,
        dataIds: ids,
      }));
    });
  };

  getTableApprovedData = () => {
    var batchVoucherList = [];
    Axios.get("/pos/batchInput/getAllApproved").then((res) => {
      console.log(res);

      var data = res.data;

      data.map((element) => {
        var obj = {
          createdDate: element.createdAt,
          postDate: element.approvedDate,
          voucherNumber: element.voucherNumber,
          fiscalYear: element.fiscalYear,
          period: element.period,

          id: element.id,
        };
        batchVoucherList.push(obj);

        return null;
      });
      this.setState((prevState) => ({
        ...prevState,
        batchInputVoucherList: batchVoucherList,
        dataToShow: data,
      }));
    });
  };

  componentDidMount() {
    this.getTableAuthList();
    this.getTableApprovedData();
    const token = this.context;

    if (token.voucherNo !== "" && token.voucherType === "Batch Input Voucher") {
      this.listAnimateFromRedirect(token.voucherNo);
    }
  }

  listAnimateFromRedirect = (voucherNo) => {
    Axios.get("pos/batchInput/getAllApproved")
      .then((res) => {
        console.log(res);

        res.data.forEach((element, i) => {
          if (voucherNo === element.voucherNumber) {
            this.setState({
              dataToSend: element,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState((prevState) => ({
      ...prevState,
      value: 2,
      list: {
        ...prevState.list,
        visible: false,
        animationIn: "zoomIn",
      },
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        list: {
          ...prevState.list,
          secondVisible: true,
          hidden: true,
          secondHidden: false,
        },
      }));
    }, 150);
  };

  render() {
    const { classes } = this.props;

    function a11yProps(index) {
      return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
      };
    }

    return (
      <>
        <HeaderTitle title="Batch Input Voucher" />
        <Grid container justify="center" className={classes.tabMargin}>
          <Tabs
            variant="scrollable"
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="Vertical tabs example"
            indicatorColor="primary"
            centered
          >
            <Tab
              label="Add Batch Input"
              {...a11yProps(0)}
              style={{
                minWidth: 50,
                fontSize: "12px",
              }}
            />
            <Tab
              label={
                <Badge
                  badgeContent={this.state.authList.length}
                  color="primary"
                  style={{ paddingRight: "11px" }}
                >
                  Post Batch Input Voucher
                </Badge>
              }
              {...a11yProps(1)}
              style={{
                width: "190px",
                fontSize: "12px",
              }}
            />
            <Tab
              label="Authorized Batch Input Voucher"
              {...a11yProps(2)}
              style={{
                minWidth: 50,
                fontSize: "12px",
              }}
            />
          </Tabs>
        </Grid>

        <AddBatchInputVoucher
          value={this.state.value}
          index={0}
          getTableDataForAuth={this.getTableApprovedData}
        ></AddBatchInputVoucher>

        <BatchInputVoucherAuthorization
          value={this.state.value}
          index={1}
          authList={this.state.authList}
          data={this.state.authData}
          getTableDataForAuth={this.getTableApprovedData}
          getTableDataForList={this.getTableAuthList}
          visible={this.state.auth}
          animate={this.authAnimate}
          handleViewCounter={this.handleViewCounter}
          animateOut={this.authAnimateOut}
          loading={this.state.authDataLoading}
        ></BatchInputVoucherAuthorization>

        <BatchInputVoucherList
          value={this.state.value}
          index={2}
          data={this.state.listData}
          batchInputVoucherList={this.state.batchInputVoucherList}
          dataList={this.state.dataToShow}
          visible={this.state.list}
          animate={this.listAnimate}
          animateOut={this.listAnimateOut}
          loading={this.state.listDataLoading}
        ></BatchInputVoucherList>
      </>
    );
  }
}

export default withStyles(styles)(BatchInputVoucher);
