import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export default function MinimalDashboardView(props) {
  return (
    <div>
      <Grid container style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Card style={{ marginTop: "60px", width: "100%" }}>
          <CardContent>
            <Typography variant="h6">
              <strong>Daily:</strong>
            </Typography>
            <Grid container>
              {props.dailyData.map((val, i) => (
                <Grid item xs={6} key={i}>
                  <div
                    style={{
                      paddingTop: "10px",
                      fontSize: "13px",
                      color: "#929292",
                    }}
                  >
                    {val.for}
                  </div>
                  <div>
                    <strong>Rs. {val.amountToday}</strong>
                    {val.amountToday < val.amountYesterday ? (
                      <span
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#FFEDEB",
                          padding: "7px",
                          marginLeft: "10px",
                          right: "25px",
                          color: "#FF8070",
                        }}
                      >
                        {val.percentageChange.toFixed(2)} %
                        {
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ marginLeft: "5px", color: "#FF8070" }}
                          ></FontAwesomeIcon>
                        }
                      </span>
                    ) : (
                      <span
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#DFFAEC",
                          padding: "7px",
                          marginLeft: "10px",
                          right: "25px",
                          color: "#5FE3A1",
                        }}
                      >
                        {val.percentageChange === 0
                          ? val.percentageChange
                          : val.percentageChange.toFixed(2)}{" "}
                        %
                        {
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            style={{ marginLeft: "5px", color: "#5FE3A1" }}
                          ></FontAwesomeIcon>
                        }
                      </span>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid container style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Card style={{ marginTop: "10px", width: "100%" }}>
          <CardContent>
            <Typography variant="h6">
              <strong>Weekly:</strong>
            </Typography>
            <Grid container>
              {props.weeklyData.map((val, i) => (
                <Grid item xs={6} key={i}>
                  <div
                    style={{
                      paddingTop: "10px",
                      fontSize: "13px",
                      color: "#929292",
                    }}
                  >
                    {val.for}
                  </div>
                  <div>
                    <strong>Rs. {val.amountThisWeek}</strong>
                    {val.amountThisWeek < val.amountLastWeek ? (
                      <span
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#FFEDEB",
                          padding: "7px",
                          marginLeft: "10px",
                          right: "25px",
                          color: "#FF8070",
                        }}
                      >
                        {val.percentageChange.toFixed(2)} %
                        {
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ marginLeft: "5px", color: "#FF8070" }}
                          ></FontAwesomeIcon>
                        }
                      </span>
                    ) : (
                      <span
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "#DFFAEC",
                          padding: "7px",
                          marginLeft: "10px",
                          right: "25px",
                          color: "#5FE3A1",
                        }}
                      >
                        {val.percentageChange === 0
                          ? val.percentageChange
                          : val.percentageChange.toFixed(2)}{" "}
                        %
                        {
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            style={{ marginLeft: "5px", color: "#5FE3A1" }}
                          ></FontAwesomeIcon>
                        }
                      </span>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}
