import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  withStyles,
} from "@material-ui/core";
import ViewMoreButton from "../InterfaceElements/ViewMoreButton";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
      marginRight: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class UserDetails extends Component {
  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 40,
        },
        {
          headerName: "Full Name",
          field: "fullName",
          resizable: true,
          minWidth: 140,
        },
        {
          headerName: "Mobile No.",
          field: "phoneNumber",
          resizable: true,
          minWidth: 90,
        },
        {
          headerName: "Email Address",
          field: "Email",
          resizable: true,
          minWidth: 110,
        },
        {
          headerName: "Address",
          field: "address",
          resizable: true,
          minWidth: 90,
        },
        {
          headerName: "Role",
          field: "roles",
          resizable: true,
          minWidth: 65,
        },
        {
          headerName: "Action",
          cellRendererFramework: ViewMoreButton,
          cellRendererParams: {
            setSnackbar: this.props.setSnackBar,
            getUserData: this.props.getUserData,
            redirectToRole: this.props.redirectToRole,
          },
          minWidth: 60,
        },
      ],
      rowData: [],
      open: false,
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
    };
  }

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.props.setGridApi(params.api);
  };

  render(params) {
    const {
      children,
      value,
      index,
      classes,
      getUserData,
      ...other
    } = this.props;

    return (
      <div>
        <Grid container style={{ width: "100vw" }} justify="center">
          <Grid item xs={12} lg={6} md={7} sm={10}>
            <Card
              className={classes.root}
              role="tabpanel"
              hidden={value !== index}
              id={`vertical-tabpanel-${index}`}
              aria-labelledby={`vertical-tab-${index}`}
              {...other}
            >
              <CardContent style={{ padding: "10px" }}>
                <Grid container>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ fontSize: "18px" }}
                  >
                    User List
                  </Typography>
                </Grid>

                <Grid container>
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "512px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      onFirstDataRendered={this.firstDataRendered}
                      onGridReady={this.onGridReady}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                      pagination={true}
                      paginationAutoPageSize={true}
                    ></AgGridReact>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(UserDetails);
