import React, { Component } from "react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Backdrop,
  MenuItem,
  withStyles,
  IconButton
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  cardHeight: {
    minWidth: "300px",
    padding: "5px",
    overflowY: "auto",
    height: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    [theme.breakpoints.up("md")]: {
      width: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "66%"
    }
  },
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%"
    }
  }
});

class ViewManufacturer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonColor: "#108dcd",
      open: false,
      editable: false,
      data: {
        id: "",
        type: "",
        fullName: "",
        street: "",
        city: "",
        state: "",
        country: "",
        licenseNumber: "",
        telephone: "",
        mobile: "",
        email: "",
        poBox: "",
        fax: "",
        typeOfProduct: "",
        otherReference: "",
        website: "",
      }
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.node.data
    });
  }

  onChanged = e => {
    e.stopPropagation();

    if (!this.state.editable) return;

    var targetName = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [targetName]: value
      }
    }));
    console.log(value);
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, buttonColor: "#108dcd" });
  };

  handleUpdate = () => {
    var id = this.props.node.data.id;

    Axios.put("pos/manufacturer/update/" + id, this.state.data)
      .then(res => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        };
        this.props.updateData();
        this.props.showSnackbar(snackbarProps);
      })
      .catch(err => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000
        };
        this.props.showSnackbar(snackbarProps);
      });
  };

  handleDelete = () => {
    var id = this.props.node.data.id;
    Axios.delete("/pos/manufacturer/delete/" + id, this.state.data)
      .then(res => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000
        }
        this.props.updateData();
        this.props.showSnackbar(snackbarProps);
      })
      .catch(err => {
        console.log(err);
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000
        }
        this.props.showSnackbar(snackbarProps);
      })
  }

  render() {
    const { classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.open}>
            <Card justify="center" className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>

                <Grid container style={{ padding: "20px" }}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={() => {
                        this.setState({ editable: true, buttonColor: "#1044CD" });
                      }}
                      style={{
                        margin: "5px",
                        backgroundColor: this.state.buttonColor,
                        color: "white"
                      }}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.containerWidth}
                  >
                    <Grid
                      container
                      justify="center"
                      style={{ height: "30px", marginBottom: "50px" }}
                    >
                      <h6>
                        Manufacturer/Distributor Details
                        </h6>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label="Select Manufacturer or Distributor"
                        name="type"
                        value={this.state.data.type}
                        onChange={this.onChanged}
                        margin="dense"
                      >
                        <MenuItem value={"Manufacturer"}>Manufacturer</MenuItem>
                        <MenuItem value={"Distributor"}>Distributor</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Full Name"
                        name="fullName"
                        value={this.state.data.fullName}
                        onChange={this.onChanged}
                        placeholder=""
                        fullWidth
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}>
                      <TextField
                        label="Street"
                        placeholder=""
                        // error={errorStatus.street}
                        value={this.state.data.street}
                        onChange={this.onChanged}
                        name="street"
                        fullWidth
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}>
                      <TextField
                        label="City"
                        // error={errorStatus.city}
                        placeholder=""
                        value={this.state.data.city}
                        onChange={this.onChanged}
                        name="city"
                        fullWidth
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}>
                      <TextField
                        label="State"
                        placeholder=""
                        fullWidth
                        value={this.state.data.state}
                        onChange={this.onChanged}
                        // error={errorStatus.state}
                        name="state"
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}>
                      <TextField
                        label="Country"
                        placeholder=""
                        name="country"
                        value={this.state.data.country}
                        onChange={this.onChanged}
                        // error={errorStatus.country}
                        fullWidth
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid
                      container
                      justify="center"
                      style={{ height: "30px", marginBottom: "50px" }}
                    >
                      <h6>
                        Supplier Details</h6>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="License No."
                        placeholder=""
                        fullWidth
                        value={this.state.data.licenseNumber}
                        onChange={this.onChanged}
                        // error={errorStatus.liscenseNo}
                        name="licenseNumber"
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Telephone"
                        placeholder=""
                        // error={errorStatus.telephone}
                        fullWidth
                        value={this.state.data.telephone}
                        onChange={this.onChanged}
                        name="telephone"
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Mobile"
                        placeholder=""
                        fullWidth
                        value={this.state.data.mobile}
                        onChange={this.onChanged}
                        name="mobile"
                        // error={errorStatus.mobile}
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="P.O.Box"
                        placeholder=""
                        fullWidth
                        onChange={this.onChanged}
                        name="poBox"
                        value={this.state.data.poBox}
                        // error={errorStatus.poBox}
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Fax"
                        placeholder=""
                        fullWidth
                        onChange={this.onChanged}
                        // error={errorStatus.fax}
                        name="fax"
                        value={this.state.data.fax}
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Email"
                        onChange={this.onChanged}
                        // error={errorStatus.email}
                        placeholder=""
                        fullWidth
                        value={this.state.data.email}
                        name="email"
                        size="small"
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Website"
                        placeholder=""
                        fullWidth
                        value={this.state.data.website}
                        onChange={this.onChanged}
                        // error={errorStatus.liscenseNo}
                        name="website"
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid container
                      justify="center"
                      style={{ height: "30px", marginBottom: "50px" }}
                    >
                      <h6>
                        Product Details
                        </h6>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Product Type"
                        placeholder=""
                        fullWidth
                        name="typeOfProduct"
                        onChange={this.onChanged}
                        // error={errorStatus.productType}
                        value={this.state.data.typeOfProduct}
                        size="small"
                        margin="dense"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Other Reference"
                        placeholder=""
                        onChange={this.onChanged}
                        fullWidth
                        // error={errorStatus.otherReference}
                        name="otherReference"
                        value={this.state.data.otherReference}
                        size="small"
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                  <Grid container justify="center">
                    <Grid>
                      {this.state.editable ? (
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          onClick={this.handleUpdate}
                          style={{
                            margin: "5px",
                            marginTop: "20px"
                          }}
                        >
                          Update
                        </Button>
                      ) : (
                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            disabled
                            onClick={this.handleUpdate}
                            style={{
                              margin: "5px",
                              marginTop: "20px"
                            }}
                          >
                            Update
                          </Button>
                        )}
                    </Grid>
                    <Grid>
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={this.handleDelete}
                        style={{
                          margin: "5px",
                          marginTop: "20px"
                        }}
                      >
                        Delete
                        </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewManufacturer);
