import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Modal,
  Backdrop,
  Fade,
  TextField,
  withStyles,
  TableContainer,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faEdit } from "@fortawesome/free-solid-svg-icons";

import Axios from "axios";
import UserContext from "../utils/UserContext";
import Close from "@material-ui/icons/Close";
const styles = (theme) => ({
  modalCardHeight: {
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      width: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  styledTableCell: {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
  },
  tabMargin: {
    width: "100%",
    maxWidth: "700px",
    margin: "10px",
    minHeight: "67vh",
    [theme.breakpoints.down("md")]: {
      marginTop: "105px",
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
      marginBottom: "100px",
    },
  },
});
export class DepartmentMaster extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      rowData: [],
      errorStatus: false,
      id:"",
      data: {
        departmentName: "",
        status: "",
      },
      helperText: "",
      isEditable: false,
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false, isEditable:false,
    data : {
      departmentName : ""
    } });
  };

  onChanged = (e) => {
    var value = e.target.value;
    var name = e.target.name;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [name]: value,        
      },
    }));
  };

  handleEdit = async (id) => {
    await this.setState({
      isEditable: true,
    });
    await Axios.get("hrm/department/getValues/" + id).then((res) => {
      console.log("THe edit", res);
      this.setState((prevState) => ({
        ...prevState,
        id:id,
        data: {
          departmentName: res.data.departmentName,
          status: res.data.status,
        },
      }));
    });
    this.handleOpen();
  };

  validateForm = () => {
    var status = false;
    var helperText = "";
    var val = this.state.data.departmentName;
    if (val === "" || val === undefined) {
      helperText = "Department should be entered!";
      status = true;
    }
    this.setState((prevState) => ({
      ...prevState,
      errorStatus: status,
      helperText,
    }));
    return !status;
  };

  handleUpdate = () => {
    let id = this.state.id;
    let obj = {
      departmentName : this.state.data.departmentName,
      status: this.state.data.status,
    };
    const token = this.context;

    let status = this.validateForm();

    if (status) {
      Axios.put("hrm/department/updateValues/" + id, obj)
        .then((res) => {
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuaratiion: 2000,
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });

          this.getTableData();
          this.handleClose();
        })
        .catch((err) => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  handleDelete = (id) => {
    const token = this.context;

    Axios.delete("hrm/department/deleteValues/" + id)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuaratiion: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });

        this.getTableData();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        token.setSnackbarProps({ snackbarProps: snackbarProps });
      });
  };

  handleSubmit = () => {
    const token = this.context;
    var status = this.validateForm();
    if (status) {
      Axios.post("/hrm/department/saveValues", this.state.data)
        .then((res) => {
          console.log(res);
          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuaratiion: 2000,
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });

          this.getTableData();
          this.handleClose();
        })
        .catch((err) => {
          console.log(err);
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };
          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
      this.setState({
        data: {
          departmentName: "",
        },
      });
    }
  };

  getTableData = () => {
    Axios.get("/hrm/department/getValues").then((res) => {
      //rowDataList = res.data;
      this.setState((prevState) => ({
        ...prevState,
        rowData: res.data,
      }));
    });
  };

  componentDidMount() {
    this.getTableData();
  }

  render() {
    const { classes } = this.props;
    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    return (
      <div>
        <Grid container justify="center">
          <Card className={classes.tabMargin}>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ fontSize: "18px" }}
                  >
                    Department List
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={this.handleOpen}
                    color="primary"
                    style={{
                      minWidth: "100px",
                      fontSize: "13px",
                    }}
                  >
                    Add Department
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                style={{ marginBottom: "50px", marginTop: "20px" }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5",
                        }}
                      >
                        <TableCell
                          style={{ width: 60 }}
                          className={classes.styledTableCell}
                        >
                          S.N.
                        </TableCell>
                        <TableCell className={classes.styledTableCell}>
                          Department
                        </TableCell>
                        <TableCell className={classes.styledTableCell}>
                          Status
                        </TableCell>
                        <TableCell className={classes.styledTableCell}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rowData.map((value, i) => (
                        <TableRow>
                          <TableCell>{i + 1} </TableCell>
                          <TableCell>{value.departmentName}</TableCell>
                          <TableCell align="right">
                            {value.status ?? ""}
                          </TableCell>
                          <TableCell align="right">
                            <Button onClick={() => this.handleEdit(value.id)}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                color="#43a047"
                                size="2x"
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                              </FontAwesomeIcon>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            {this.state.isEditable ? (
              <Card className={classes.modalCardHeight}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <CardContent style={{ padding: 10 }}>
                  <Grid container>
                    <Grid style={{ marginTop: 15 }}>
                      <Typography variant="h5">Edit Department</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                      {this.state.errorStatus ? (
                        <TextField
                          label="Department Name"
                          name="departmentName"
                          value={this.state.data.departmentName}
                          fullWidth
                          onChange={this.onChanged}
                          helperText={this.state.helperText}
                          error
                        />
                      ) : (
                        <TextField
                          label="Department Name"
                          name="departmentName"
                          value={this.state.data.departmentName}
                          fullWidth
                          onChange={this.onChanged}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <InputLabel id="demo-select-small">Status</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={this.state.data.status}
                        name="status"
                        label="Status"
                        fullWidth
                        onChange={this.onChanged}
                      >
                        <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                        <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                        <MenuItem value="CLOSED">CLOSED</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleUpdate}
                      style={{
                        margin: "30px 10px 0px 0px",
                      }}
                    >
                      Update
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Card className={classes.modalCardHeight}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <CardContent style={{ padding: 10 }}>
                  <Grid container>
                    <Grid style={{ marginTop: 15 }}>
                      <Typography variant="h5">Add Department</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                      {this.state.errorStatus ? (
                        <TextField
                          label="Department Name"
                          name="departmentName"
                          fullWidth
                          onChange={this.onChanged}
                          value={this.state.data.departmentName}
                          helperText={this.state.helperText}
                          error
                        />
                      ) : (
                        <TextField
                          label="Department Name"
                          name="departmentName"
                          fullWidth
                          value={this.state.data.departmentName}
                          onChange={this.onChanged}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={this.handleSubmit}
                      style={{
                        margin: "30px 10px 0px 0px",
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Fade>
        </Modal>
      </div>
    );
  }
}
export default withStyles(styles)(DepartmentMaster);
