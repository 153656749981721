import React, { Component } from "react";
import {
  Card,
  CardContent,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Chip,
  Grid,
  Typography,
  Table,
  withStyles
} from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import RoleViewMoreButton from "../InterfaceElements/RoleViewMoreButton";

const styles = theme => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "7px",
    minHeight: "63vh",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  }
});

class RoleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  handleItemSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "All Roles....") {
      rowAllData = this.props.origData;
    } else {
      this.props.origData.forEach(element => {
        if (element.type === value) rowAllData.push(element);
      });
    }

    this.props.changeData(rowAllData);
  };

  render() {
    const { children, value, index, classes, ...other } = this.props;

    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5"
    };

    return (
      <div>
        <Grid container style={{ width: "100vw" }} justify="center">
          <Grid item xs={12} lg={6} md={7} sm={10}>
            <Card
              className={classes.root}
              role="tabpanel"
              hidden={value !== index}
              id={`vertical-tabpanel-${index}`}
              aria-labelledby={`vertical-tab-${index}`}
              {...other}
            >
              <CardContent style={{ padding: "10px" }}>
                <Grid container>
                  <Typography variant="h6" component="h2" style={{ fontSize: "18px" }}>
                    {" "}
                    Role List{" "}
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={6}>
                    <Dropdown
                      placeholder="Search By Role Name"
                      fluid
                      style={{
                        minWidth: "230px",
                        fontSize: "14px"
                      }}
                      search
                      ref={search => {
                        this.searchInput = search;
                      }}
                      selection
                      onChange={this.handleItemSelect}
                      options={this.props.rowRefs}
                    ></Dropdown>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: "15px" }}>
                  <TableContainer>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={styledTableCell}>SN</TableCell>
                          <TableCell style={styledTableCell}>Role</TableCell>
                          <TableCell style={styledTableCell}>
                            Permissions
                        </TableCell>
                          <TableCell style={styledTableCell} align="center">
                            Action
                        </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.data.map((value, i) => (
                          <TableRow>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{value.type}</TableCell>
                            <TableCell>
                              {value.roleTasks.map((value, i) => {
                                if (i === 4) {
                                  return (
                                    <Chip
                                      label={"...."}
                                      style={{
                                        marginRight: 5,
                                        backgroundColor: "#3f51b5",
                                        color: "#fff",
                                        marginBottom: 4
                                      }}
                                    />
                                  );
                                } else if (i > 4) {
                                  return null;
                                } else {
                                  return (
                                    <Chip
                                      label={value.type}
                                      style={{
                                        marginRight: 5,
                                        backgroundColor: "#3f51b5",
                                        color: "#fff",
                                        marginBottom: 4
                                      }}
                                    />
                                  );
                                }
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <RoleViewMoreButton
                                rowData={value}
                                populateData={this.props.populateData}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(RoleDetails);
