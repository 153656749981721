import React from "react";
import { Card, CardContent, Typography, Button, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";

function VoucherNumberRestriction(props) {
  const navigate = () => {
    props.history.push("/Document/numberingScheme");
  };

  return (
    <>
      {props.open ? (
        <Grid
          container
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "fixed",
            height: "100vh",
            width: "100vw"
          }}
          justify="center"
          alignContent="center"
        >
          <Grid item>
            <div
              style={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                position: "fixed",
                height: "100vh",
                width: "100vw",
                backgroundColor: "#000",
                opacity: "50%"
              }}
            ></div>
          </Grid>

          <Grid item style={{ zIndex: 13 }}>
            <div>
              <Card>
                <CardContent>
                  <Grid container="justify">
                    <Grid item xs={12}>
                      <Grid
                        container
                        justify="center"
                        style={{ marginBottom: "20px" }}
                      >
                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                          Document Numbering for this page is not configured
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <Button onClick={navigate} primary variant="outlined">
                          CONFIGURE HERE
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default withRouter(VoucherNumberRestriction);
