import React, { Component } from "react";
import {
  Card,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  withStyles,
  Button,
  MenuItem,
  TableContainer,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";
import ViewInventoryCountAuthorization from "./ViewInventoryCountAuthorization";

const styles = (theme) => ({
  root: {
    padding: 20,
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginBottom: "180px"
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
      margin: "auto",
      marginBottom: "140px",
    },
    [theme.breakpoints.up("md")]: {
      width: "70vw",
      margin: "auto",
      marginBottom: "120px",
    },
  },
});

class Authorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorArr: {},
      filterShow: false,
      page: 0,
      rowsPerPage: 5,
      rowData: [],
      fyList: [],
      fiscalYear: "",
    };
  }

  componentDidMount() {
    this.getAllFYListing();
  }

  handleFyChange = (e) => {
    var val = e.target.value;
    this.props.handleAuthorizationListFyChange(val, "auth");
  };

  handleDateFrom = (e) => {
    var val = e.target.value;

    this.props.handleDateFrom(val, "auth");
  };

  handleDateTo = (e) => {
    var val = e.target.value;
    this.props.handleDateTo(val, "auth");
  };

  handleVoucherChange = (_e, { value }) => {
    this.props.handleVoucherCodeChange(value, "auth");
  };

  getAllFYListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          fiscalYear: fiscalYearLists[0],
        });
        this.props.handleFyChange(this.state.fiscalYear, "auth");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  generateList() {
    let arr = [];
    let data = this.props.dataList;

    for (let index = 0; index < data.length; index++) {
      arr.push(
        <TableRow hover>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <span style={{ fontWeight: "bold" }}>{data[index].fiscalYear}</span>
          </TableCell>
          <TableCell>{data[index].period}</TableCell>
          <TableCell>{data[index].status}</TableCell>
          <TableCell>{data[index].countedBy}</TableCell>
          <TableCell
            align="center"
            onClick={() => {
              this.props.animate(data[index].id);
            }}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
          </TableCell>
        </TableRow>
      );
    }

    return arr;
  }

  handleRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const {
      hidden,
      secondHidden,
      animationIn,
      visible,
      secondVisible,
    } = this.props.visible;

    const masterData = this.props.data;

    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
      width: "150px"
    };

    const inputStyles = {
      fontSize: 14,
    };

    const textFieldStyle = {
      marginBottom: "23px",
      marginTop: "23px",
      width: "100%",
    };

    const { classes } = this.props;

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;

    return (
      <>
        <Grid container justify="center" style={{ width: "100%", paddingRight: "10px", paddingLeft: "10px" }}>
          <Grid item style={{ width: "100%" }}>
            <Animated
              animationIn={animationIn}
              animationOut="zoomOut"
              isVisible={visible}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <Card role="tabpanel" hidden={hidden} className={classes.root}>
                <Typography
                  gutterBottom
                  variant="h5"
                  style={{ fontWeight: "bolder", marginBottom: "10px" }}
                >
                  Inventory Count List
                </Typography>

                <Grid container spacing={1}>
                  <Grid item>
                    <TextField
                      select
                      style={{ width: "130px" }}
                      label="Fiscal Year"
                      name="fiscalYear"
                      onChange={this.handleFyChange}
                      //value={this.state.fiscalYear}
                    >
                      {this.state.fyList.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      style={{ marginTop: "15px" }}
                      onClick={() => {
                        this.setState({ filterShow: !this.state.filterShow });
                      }}
                    >
                      <Typography variant="button">
                        <FontAwesomeIcon
                          icon={faSlidersH}
                          style={{ marginRight: "5px" }}
                        ></FontAwesomeIcon>
                        Filter
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                {this.state.filterShow ? (
                  <Grid
                    container
                    spacing={4}
                    style={{
                      marginLeft: "0px",
                      marginTop: 20,
                      marginBottom: 20,
                      border: "#87CEFA 2px solid",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        marginLeft: "2em",
                        height: "10px",
                        display: "inline",
                        backgroundColor: "#fff",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingBottom: "15px",
                      }}
                    ></div>
                    <Grid container spacing={4} style={{ padding: 10 }}>
                      <Grid item xs={12} md={6}>
                        <Dropdown
                          placeholder="Search by Voucher Code"
                          fluid
                          search
                          ref={(search) => {
                            this.searchInput = search;
                          }}
                          selection
                          value={this.props.customer}
                          onChange={this.handleVoucherChange}
                          options={this.props.voucherList}
                          style={{ marginTop: "33px" }}
                        ></Dropdown>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="date-picker-inline"
                          label="Date From"
                          name="dateFrom"
                          defaultValue={date}
                          type="date"
                          onChange={this.handleDateFrom}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="date-picker-inline"
                          label="Date To"
                          name="dateTo"
                          defaultValue={date}
                          type="date"
                          onChange={this.handleDateTo}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <TableContainer>
                  <Table
                    aria-label="customized table"
                    style={{ marginTop: "20px", marginBottom: "15px" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5",
                          width: "80px"
                        }}> S. No.</TableCell>
                        <TableCell style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5",
                          width: "200px"
                        }}>
                          Fiscal Year
                        </TableCell>
                        <TableCell style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5",
                          width: "200px"
                        }}>Period</TableCell>

                        <TableCell style={styledTableCell}> Status </TableCell>
                        <TableCell style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5"
                        }}>
                          Counted By
                        </TableCell>
                        <TableCell align="center" style={{
                          backgroundColor: "#F1F8FC",
                          color: "#3F51B5",
                          width: "80px"
                        }}>
                          View
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {this.generateList().slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={this.props.dataList.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={(event, newPage) => {
                    this.setState({ page: newPage });
                  }}
                  onChangeRowsPerPage={this.handleRowsPerPage}
                />
              </Card>
            </Animated>
          </Grid>
          <Grid item>
            <Animated
              animationIn="zoomIn"
              animationOut="zoomOut"
              isVisible={secondVisible}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <ViewInventoryCountAuthorization
                hidden={secondHidden}
                animateOut={this.props.animateOut}
                data={masterData}
                handleViewCounter={this.props.handleViewCounter}
                updateData={this.getTableData}
                getTableDataForAuth={this.props.getTableDataForAuth}
                getTableDataForList={this.props.getTableDataForList}
              />
            </Animated>
          </Grid>
        </Grid >
      </>
    );
  }
}

export default withStyles(styles)(Authorization);
