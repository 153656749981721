import React, { Component } from 'react'
import {
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell
} from "@material-ui/core";
import { Label } from "semantic-ui-react";

class TransactionDetailsPrint extends Component {
    constructor(props) {
        super(props)

        this.state = {};
    }

    render() {
        let i = 1;

        return (
            <div>
                <Grid
                    container
                    justify="center"
                    style={{ marginTop: 50, fontSize: 18 }}
                >
                    <Label>Transaction Details</Label>
                </Grid>

                <Grid
                    container
                    justify="center"
                    style={{ marginTop: 30, fontSize: 18 }}
                >
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead >
                                <TableRow>
                                    <TableCell component="th" align="center">S.No</TableCell>
                                    <TableCell component="th" align="left">Transaction Date</TableCell>
                                    <TableCell component="th" align="left">Particulars</TableCell>
                                    <TableCell component="th" align="center">Cost Center</TableCell>
                                    <TableCell component="th" align="left">Account</TableCell>
                                    <TableCell component="th" align="left">Sub Account</TableCell>
                                    <TableCell component="th" align="left">Voucher Type</TableCell>
                                    <TableCell component="th" align="left">Voucher Number</TableCell>
                                    <TableCell component="th" align="left">Running Balance</TableCell>
                                    <TableCell component="th" align="left">Debit Amount</TableCell>
                                    <TableCell component="th" align="left">Credit Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.props.data.map(row => (
                                    < TableRow key={row.id} >
                                        <TableCell align="center"> {i++} </TableCell>
                                        <TableCell align="left">{row.transactionDate}</TableCell>
                                        <TableCell align="left">{row.particulars}</TableCell>
                                        <TableCell align="center">{row.costCenter}</TableCell>
                                        <TableCell align="left">{row.accountCode + "-" + row.accountName}</TableCell>
                                        <TableCell align="left">{row.subAccountCode + "-" + row.subAccountName}</TableCell>
                                        <TableCell align="left">{row.voucherType}</TableCell>
                                        <TableCell align="left">{row.voucherNumber}</TableCell>
                                        <TableCell align="left">{row.runningBalance}</TableCell>
                                        <TableCell align="left">{row.debitAmount}</TableCell>
                                        <TableCell align="left">{row.creditAmount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </div >
        )
    }
}
export default TransactionDetailsPrint;