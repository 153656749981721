import React, { Component } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";

const styles = (theme) => ({
  tabMargin: {
    height: "auto",
    paddingBottom: "15px",
    [theme.breakpoints.down("md")]: {
      marginTop: "98px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "65px",
    },
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
  containerWidth: {
    margin: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
});

class ItemDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        itemCode: "",
        itemName: "",
        propertyType: "",
        majorCategory: "",
        category: "",
        subCategory: "",
        quantityInStock: "",
        warehouse: "",
        taxable: "",
        lastSoldOn: "",
        lastCostPerUnit: "",
        averageCostPerUnit: "",
        regularPrice: "",
        grossMargin: "",
      },
    };
  }

  handleItemChange = (event, data) => {
    const { value } = data;
    const { key } = data.options.find((o) => o.value === value);

    Axios.get("pos/inventory/getInventoryByItemCode/" + key)
      .then((res) => {
        this.setState({
          data: {
            lastSoldOn: "",
          },
        });

        this.setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            itemCode: res.data.itemCode,
            itemName: res.data.itemName,
            propertyType: res.data.propertyType,
            majorCategory: res.data.majorCategory,
            category: res.data.category,
            subCategory: res.data.subCategory,
            quantityInStock: res.data.quantityInStock,
            warehouse: res.data.warehouse,
            taxable: "",
            lastSoldOn: res.data.lastSoldOn,
            lastCostPerUnit: res.data.lastCostPerUnit,
            regularPrice: res.data.sellingPrice,
            grossMargin: res.data.grossMargin,
            averageCostPerUnit: res.data.averageCostPerUnit,
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    Axios.get("pos/inventory/getInventories")
      .then((res) => {
        var rows = [];

        res.data.forEach((element) => {
          const item = {
            key: element.itemCode,
            value: element.itemName + element.itemCode,
            text: element.itemName + " #" + element.itemCode,
            label: {
              content: "#" + element.itemCode,
              tag: true,
            },
          };
          rows.push(item);
        });

        this.setState({ rowRefs: rows });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render(params) {
    const { classes } = this.props;

    const textFieldStyle = {
      width: "100%",
    };

    return (
      <div>
        <div
          style={{
            backgroundColor: "#EAEDEF",
            marginTop: "0px",
          }}
        >
          <Grid container justify="center" className={classes.root}>
            <Grid item xs={11} sm={11} md={7}>
              <Card className={classes.tabMargin}>
                <CardContent style={{ marginBottom: "70px" }}>
                  <Grid container justify="center">
                    <Typography variant="h6" component="h2">
                      Item Details
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    justify="center"
                    className={classes.containerWidth}
                  >
                    <div
                      style={{
                        width: "100%",
                        minWidth: "220px",
                        marginTop: "8px",
                      }}
                    >
                      <Dropdown
                        placeholder="Search by Item Code or Item Name"
                        fluid
                        search
                        onChange={this.handleItemChange}
                        ref={(search) => {
                          this.searchInput = search;
                        }}
                        selection
                        options={this.state.rowRefs}
                      ></Dropdown>
                    </div>

                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Item Code"
                        type="text"
                        name="itemCode"
                        onChange={this.onChanged}
                        placeholder=""
                        value={this.state.data.itemCode}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Item Name"
                        type="text"
                        name="itemName"
                        onChange={this.onChanged}
                        placeholder=""
                        value={this.state.data.itemName}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Property Type"
                        type="text"
                        name="propertyType"
                        onChange={this.onChanged}
                        placeholder=""
                        value={this.state.data.propertyType}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Major Category"
                        name="majorCategory"
                        value={this.state.data.majorCategory}
                        onChange={this.onChanged}
                        type="text"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Category"
                        name="category"
                        value={this.state.data.category}
                        onChange={this.onChanged}
                        type="text"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Sub Category"
                        name="subCategory"
                        value={this.state.data.subCategory}
                        onChange={this.onChanged}
                        type="text"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Quantity in Stock"
                        name="quantityInStock"
                        value={this.state.data.quantityInStock}
                        onChange={this.onChanged}
                        type="number"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Warehouse"
                        name="warehouse"
                        value={this.state.data.warehouse}
                        onChange={this.onChanged}
                        type="text"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Taxable"
                        value={this.state.data.taxable}
                        onChange={this.onChanged}
                        name="taxable"
                        type="text"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Last Sold On"
                        name="lastSoldOn"
                        value={this.state.data.lastSoldOn}
                        onChange={this.onChanged}
                        type="text"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Last Cost Per Unit"
                        type="number"
                        value={this.state.data.lastCostPerUnit}
                        onChange={this.onChanged}
                        name="lastCostPerUnit"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Average Cost Per Unit"
                        type="number"
                        value={this.state.data.averageCostPerUnit}
                        onChange={this.onChanged}
                        name="minimumReorderQuantity"
                        placeholder=""
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Regular Price"
                        name="regularPrice"
                        value={this.state.data.regularPrice}
                        onChange={this.onChanged}
                        type="number"
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Gross Margin"
                        name="grossMargin"
                        value={this.state.data.grossMargin}
                        onChange={this.onChanged}
                        type="number"
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ItemDetails);
