import React, { Component } from "react";
import {
  Card,
  CardContent,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Button,
  Typography,
  Table,
  withStyles,
  TableContainer
} from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VoucherNumberingSchemeButton from "../InterfaceElements/VoucherNumberingSchemeButton";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";

const styles = theme => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "7px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  }
});

class ManageScheme extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: []
    };
  }

  handleItemSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "All Schemes....") {
      rowAllData = this.props.origData;
    } else {
      this.props.origData.forEach(element => {
        if (element.name === value) rowAllData.push(element);
      });
    }

    this.props.changeData(rowAllData);
  };

  handleYearSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];
    if (value === "All Fiscal Year ....") {
      rowAllData = this.props.origData;
    } else {
      this.props.origData.forEach(element => {
        if (element.fiscalYear === value) rowAllData.push(element);
      });
    }

    this.props.changeData(rowAllData);
  };

  handleStatusSelect = (event, data) => {
    const { value } = data;

    var rowAllData = [];

    if (value === "All Status....") {
      rowAllData = this.props.origData;
    } else {
      this.props.origData.forEach(element => {
        if (element.status === value) rowAllData.push(element);
      });
    }

    this.props.changeData(rowAllData);
  };

  render() {
    const { children, value, index, classes, ...other } = this.props;

    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5"
    };

    function returnConcat(prefix, suffix, numberLength) {
      var str = "";
      var placeholder = "";

      for (let i = 0; i < numberLength; i++) {
        placeholder += "*";
      }

      str = prefix + "-" + placeholder + "-" + suffix;

      return str;
    }

    function colorizedSpan(mode) {
      if (mode === "OPEN") {
        return <span style={{ color: "#5FE3A1" }}>{mode}</span>;
      } else if (mode === "INACTIVE") {
        return <span style={{ color: "#FCA130" }}>{mode}</span>;
      } else {
        return <span style={{ color: "#FF5733" }}>{mode}</span>;
      }
    }

    return (
      <div>
        <Grid container style={{ width: "100vw" }} justify="center">
          <Grid item xs={12} lg={6} md={7} sm={10}>
            <Card
              className={classes.root}
              role="tabpanel"
              hidden={value !== index}
              id={`vertical-tabpanel-${index}`}
              aria-labelledby={`vertical-tab-${index}`}
              {...other}
            >
              <CardContent style={{ padding: "10px" }}>
                <Grid container>
                  <Typography variant="h6" component="h2" style={{ fontSize: "18px" }}>
                    {" "}
                    Numbering Scheme List{" "}
                  </Typography>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Button
                      color="primary"
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        this.setState({ filterShow: !this.state.filterShow });
                      }}
                    >
                      <Typography variant="button">
                        <FontAwesomeIcon
                          icon={faSlidersH}
                          style={{ marginRight: "5px" }}
                        ></FontAwesomeIcon>
                        Filter
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                {this.state.filterShow ? (
                  <Grid container>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        border: "#87CEFA 2px solid",
                        marginTop: "10px",
                        marginBottom: "10px",
                        borderRadius: "10px",
                        paddingRight: "5px",
                        paddingLeft: "5px"
                      }}
                    >
                      <Grid item xs={12} md={4}>
                        <div
                          style={{
                            width: "100%",
                            marginTop: "15px",
                            marginBottom: "15px",
                            fontSize: "14px"
                          }}
                        >
                          <Dropdown
                            placeholder="Search By Voucher Name"
                            fluid
                            search
                            ref={search => {
                              this.searchInput = search;
                            }}
                            selection
                            onChange={this.handleItemSelect}
                            options={this.props.rowRefs}
                          ></Dropdown>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <div
                          style={{
                            width: "100%",
                            marginTop: "15px",
                            marginBottom: "15px",
                            fontSize: "14px"
                          }}
                        >
                          <Dropdown
                            placeholder="Search By Fiscal Year"
                            fluid
                            search
                            ref={search => {
                              this.searchInput = search;
                            }}
                            selection
                            value={this.state.selectedFiscalYear}
                            onChange={this.handleYearSelect}
                            options={this.props.fiscalYearRefs}
                          ></Dropdown>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <div style={{
                          width: "100%",
                          marginTop: "15px",
                          marginBottom: "15px",
                          fontSize: "14px"
                        }}>
                          <Dropdown
                            placeholder="Filter By Status"
                            fluid
                            search
                            ref={search => {
                              this.searchInput = search;
                            }}
                            selection
                            onChange={this.handleStatusSelect}
                            options={this.props.statusRefs}
                          ></Dropdown>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid
                  container
                  style={{ marginBottom: "50px", marginTop: "20px" }}
                >
                  <TableContainer>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={styledTableCell}>
                            Document Name
                        </TableCell>
                          <TableCell style={styledTableCell}>
                            Document Code
                        </TableCell>

                          <TableCell style={styledTableCell}>
                            Document Description
                        </TableCell>
                          <TableCell style={styledTableCell}>
                            Fiscal Year
                        </TableCell>
                          <TableCell style={styledTableCell}>Status</TableCell>

                          <TableCell style={styledTableCell} align="center">
                            Action
                        </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.data.map(value => (
                          <TableRow>
                            <TableCell>{value.name}</TableCell>
                            <TableCell>
                              {returnConcat(
                                value.prefix,
                                value.suffix,
                                value.numberLength
                              )}
                            </TableCell>
                            <TableCell>{value.voucherDescription}</TableCell>
                            <TableCell>{value.fiscalYear}</TableCell>
                            <TableCell>{colorizedSpan(value.status)}</TableCell>

                            <TableCell align="center">
                              {/* <RoleViewMoreButton
                          rowData={value}
                          populateData={this.props.populateData}
                        /> */}
                              <VoucherNumberingSchemeButton
                                data={value}
                                code={returnConcat(
                                  value.prefix,
                                  value.suffix,
                                  value.numberLength
                                )}
                                populateTable={this.props.populateTable}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ManageScheme);
