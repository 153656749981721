import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  Menu,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
  Typography,
  Chip,
  Divider,
  withStyles,
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  IconButton,
} from "@material-ui/core";
import Axios from "axios";
import ConfirmationDialog from "./ConfirmationDialog";
import Close from "@material-ui/icons/Close";

const styles = (theme) => ({
  cardHeight: {
    padding: "0px",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "90%",
      width: "90%",
      maxHeight: "660px",
      minWidth: "400px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "90%",
      maxHeight: "840px",
      width: "500px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "90%",
      width: "500px",
    },
  },
});

class ViewMoreButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      modalOpen: false,
      data: {
        id: 0,
        Email: "",
        fullName: "",
        firstName: "",
        LastName: "",
        surName: "",
        identificationNumber: "",
        phoneNumber: "",
        address: "",
        createdBy: "",
        createdAt: "",
        position: "",
        department: "",
        password: "",
        passwordConfirm: "",
        passwordError: false,
        passwordHelper: "",
        roles: [],
      },
      editable: false,
      passwordEnabled: false,
      confirmOpen: false,
      rolesList: [],
      redirect: "/role/master",
      unClicked: "#108dcd",
      clicked: "#1044CD",
      notClicked: "#108dcd",
      departmentList: [],
    };
  }

  componentDidMount() {
    Axios.get("role/findAll")
      .then((res) => {
        var roles = [];
        roles.push({ id: 0, type: "+ Add Role" });
        res.data.forEach((element) => {
          roles.push({ type: element.type, id: element.id });
        });

        this.setState({ rolesList: roles });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("hrm/department/getValues")
      .then((res) => {
        var departmentList = [];
        res.data.forEach((element) => {
          departmentList.push(element.departmentName);
        });

        this.setState({ departmentList });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleView = () => {
    this.setState({ data: this.props.node.data });
    this.setState({ anchorEl: null, open: false });
    this.setState({ modalOpen: true });
  };

  handleDelete = () => {
    Axios.put("hrm/terminate/" + this.props.node.data.id)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        this.props.setSnackbar(snackbarProps);
        this.props.getUserData();
      })
      .catch((err) => {
        console.log(err);
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000,
        };
        this.props.setSnackbar(snackbarProps);
      });
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: Boolean(event.currentTarget),
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      variant: "outlined",
      buttonColor: this.state.unClicked,
      editable: false,
    });
  };

  onFieldsChange = (e) => {
    if (
      !this.state.editable &&
      e.target.name !== "password" &&
      e.target.name !== "passwordConfirm"
    )
      return;

    let targetName = e.target.name;
    let targetValue = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [targetName]: targetValue,
      },
    }));
  };

  onChanged = (e) => {
    e.stopPropagation();
    var value = e.target.value;

    if (value.includes("+ Add Role")) {

      this.props.redirectToRole();
    }

    if (!this.state.editable) return;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        roles: value,
      },
    }));
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleUpdate = () => {
    let roleList = [];

    this.state.data.roles.forEach((element) => {
      this.state.rolesList.map((val) =>
        val.type === element ? roleList.push({ id: val.id }) : null
      );
    });

    let obj = {
      email: this.state.data.Email,
      phoneNumber: this.state.data.phoneNumber,
      address: this.state.data.address,
      identificationNumber: this.state.data.identificationNumber,
      position: this.state.data.position,
      department: this.state.data.department,
      firstName: this.state.data.firstName,
      middleName: this.state.data.middleName,
      surname: this.state.data.surName,
      roles: roleList,
    };


    Axios.put("hrm/updateUserWithRole/" + this.state.data.id, obj)
      .then((res) => {
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        this.props.setSnackbar(snackbarProps);
        this.props.getUserData();

        this.setState({
          editable: false,
          modalOpen: false,
        });
      })
      .catch((err) => {
        var snackbarProps = {
          open: true,
          variant: "error",
          message: err.message,
          autoHideDuration: 2000,
        };

        this.props.setSnackbar(snackbarProps);
      });
  };

  render() {
    const { classes } = this.props;

    const modal = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
    };

    const inputStyles = {
      fontSize: 14,
    };

    const textFieldStyle = {
      width: "100%",
    };

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faEllipsisV}
            color="#3F51B5"
            size="lg"
            onClick={this.handleClick}
            style={{ cursor: "pointer", width: "100%" }}
          ></FontAwesomeIcon>
        </span>
        <ConfirmationDialog
          open={this.state.confirmOpen}
          handleClose={() => {
            this.setState({ confirmOpen: false });
          }}
          handleConfirm={this.handleDelete}
          titleMessage={"Terminate User?"}
          bodyMessage={
            "User will be terminated and his/her access will be void from the application."
          }
        />
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem key={1} onClick={this.handleView}>
            View
          </MenuItem>
          <MenuItem key={3}>
            <span
              style={{ color: "#FF5733" }}
              onClick={() => {
                this.setState({ confirmOpen: true });
              }}
            >
              Terminate
            </span>
          </MenuItem>
        </Menu>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleModalClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid container style={{ padding: "20px" }}>
                  <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={12}>
                      <Grid container justify="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              editable: !this.state.editable,
                            });
                          }}
                          style={{
                            backgroundColor: this.state.editable
                              ? this.state.clicked
                              : this.state.notClicked,
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              passwordEnabled: !this.state.passwordEnabled,
                            });
                          }}
                          style={{
                            backgroundColor: this.state.passwordEnabled
                              ? this.state.clicked
                              : this.state.notClicked,
                            marginLeft: "10px",
                          }}
                        >
                          Change Password
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.editable ? (
                    <>
                      <TextField
                        id="standard-basic"
                        label="First Name"
                        name="firstName"
                        onChange={this.onFieldsChange}
                        value={this.state.data.firstName}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                      />
                      <TextField
                        id="standard-basic"
                        label="Middle Name"
                        name="middleName"
                        onChange={this.onFieldsChange}
                        value={this.state.data.middleName}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                      />
                      <TextField
                        id="standard-basic"
                        label="Last Name"
                        name="surName"
                        onChange={this.onFieldsChange}
                        value={this.state.data.surName}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                      />
                    </>
                  ) : (
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: "bolder",
                        }}
                      >
                        {this.state.data.fullName}
                      </Typography>
                    )}

                  <Grid item xs={12} style={{ marginTop: "10px" }}>
                    <FormControl style={textFieldStyle}>
                      <Select
                        id="demo-simple-select-filled"
                        multiple
                        name="roles"
                        onChange={this.onChanged}
                        value={this.state.data.roles}
                        renderValue={(selected) => (
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                style={{
                                  margin: 2,
                                  backgroundColor: "#3f51b5",
                                  color: "#fff",
                                }}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {this.state.rolesList.map((val) => (
                          <MenuItem key={val.id} value={val.type}>
                            {val.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Divider style={{ marginTop: "10px" }} />

                  <Grid
                    container
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Phone"
                        name="phoneNumber"
                        onChange={this.onFieldsChange}
                        value={this.state.data.phoneNumber}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Email"
                        onChange={this.onFieldsChange}
                        name="Email"
                        value={this.state.data.Email}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>

                    {this.state.passwordEnabled ? (
                      <>
                        {this.state.data.passwordError ? (
                          <Grid item xs={12}>
                            <TextField
                              id="standard-with-placeholder"
                              label="Password"
                              onChange={this.onFieldsChange}
                              name="password"
                              error
                              helperText={this.state.data.passwordHelper}
                              value={this.state.data.password}
                              inputProps={{
                                fontSize: 14,
                              }}
                              InputLabelProps={{ style: inputStyles }}
                              style={textFieldStyle}
                              margin="normal"
                              type="password"
                            />
                          </Grid>
                        ) : (
                            <Grid item xs={12}>
                              <TextField
                                id="standard-with-placeholder"
                                label="Password"
                                onChange={this.onFieldsChange}
                                name="password"
                                value={this.state.data.password}
                                inputProps={{
                                  fontSize: 14,
                                }}
                                InputLabelProps={{ style: inputStyles }}
                                style={textFieldStyle}
                                margin="normal"
                                type="password"
                              />
                            </Grid>
                          )}

                        {this.state.data.passwordError ? (
                          <Grid item xs={12}>
                            <TextField
                              id="standard-with-placeholder"
                              label="Confirm Password"
                              onChange={this.onFieldsChange}
                              name="passwordConfirm"
                              error
                              helperText={this.state.data.passwordHelper}
                              value={this.state.data.passwordConfirm}
                              inputProps={{
                                fontSize: 14,
                              }}
                              InputLabelProps={{ style: inputStyles }}
                              style={textFieldStyle}
                              margin="normal"
                              type="password"
                            />
                          </Grid>
                        ) : (
                            <Grid item xs={12}>
                              <TextField
                                id="standard-with-placeholder"
                                label="Confirm Password"
                                onChange={this.onFieldsChange}
                                name="passwordConfirm"
                                value={this.state.data.passwordConfirm}
                                inputProps={{
                                  fontSize: 14,
                                }}
                                InputLabelProps={{ style: inputStyles }}
                                style={textFieldStyle}
                                margin="normal"
                                type="password"
                              />
                            </Grid>
                          )}
                      </>
                    ) : null}

                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Position"
                        onChange={this.onFieldsChange}
                        name="position"
                        value={
                          this.state.data.position === null
                            ? ""
                            : this.state.data.position
                        }
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        select
                        id="standard-with-placeholder"
                        label="Department"
                        onChange={this.onFieldsChange}
                        name="department"
                        value={this.state.data.department}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      >
                        {this.state.departmentList.map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Address"
                        onChange={this.onFieldsChange}
                        name="address"
                        value={this.state.data.address}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Identification Number"
                        onChange={this.onFieldsChange}
                        name="identificationNumber"
                        value={this.state.data.identificationNumber}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="standard-with-placeholder"
                        label="Created By:"
                        placeholder=""
                        name="createdBy"
                        value={this.state.data.createdBy}
                        inputProps={{
                          fontSize: 14,
                        }}
                        InputLabelProps={{ style: inputStyles }}
                        style={textFieldStyle}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={10}
                    justify="center"
                    style={{ width: "70%", margin: "auto" }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Grid container justify="center">
                        {this.state.editable ? (
                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={this.handleUpdate}
                            style={{
                              width: "100%",
                              maxWidth: "150px",
                              minWidth: "100px",
                              marginTop: "15px",
                            }}
                          >
                            Update
                          </Button>
                        ) : (
                            <Button
                              variant="outlined"
                              size="large"
                              color="primary"
                              disabled
                              style={{
                                width: "120px",
                                minWidth: "100px",
                                marginTop: "15px",
                              }}
                            >
                              Update
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewMoreButton);
