import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Modal,
  Fade,
  Backdrop,
  MenuItem,
  TextField,
  Button,
  withStyles,
  TableContainer,
  IconButton,
  Chip,
  Tooltip,
  Select,
  InputLabel,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Close from "@material-ui/icons/Close";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import UserContext from "../utils/UserContext";

const styles = (theme) => ({
  tabMargin: {
    padding: "0px",
    margin: "10px",
    width: "100%",
    maxWidth: "1111px",
    marginRight: "10px",
    marginLeft: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "105px",
      marginBottom: "170px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "65px",
      marginBottom: "100px",
    },
  },
});

function AuthorizationMaster(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [approvedUser, setApprovedUser] = useState([]);
  const [checkedUser, setCheckedUser] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [approvedData, setApprovedData] = useState([]);
  const [checkedType, setCheckedType] = useState([]);
  const [approvedType, setApprovedType] = useState([]);

  const { setSnackbarProps } = useContext(UserContext);

  const { classes } = props;

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = () => {
    Axios.get("/hrm/authorization/findAll")
      .then((res) => {
        console.log("res");
        console.log(res.data);
        var data = [];

        var typeList = [];

        res.data.map((val) => typeList.push(val.type));

        typeList = new Set([...typeList]);

        console.log(typeList);

        typeList.forEach((element) => {
          let dataElement = {
            type: element,
            checkedData: [],
            approvedData: [],
            approvedToolTip: "",
            checkedToolTip: "",
          };
          res.data.forEach((resElement) => {
            if (
              resElement.type === element &&
              resElement.authority === "Checked"
            ) {
              dataElement.checkedData = resElement.authorizationDetails;
            } else if (
              resElement.type === element &&
              resElement.authority === "Approved"
            ) {
              dataElement.approvedData = resElement.authorizationDetails;
            }
          });

          let approvedToolTip = [];
          let checkedToolTip = [];

          for (
            let index = 4;
            index < dataElement.approvedData.length;
            index++
          ) {
            if (index === dataElement.approvedData.length - 1) {
              approvedToolTip.push(
                <div style={{ padding: "15px" }}>
                  {dataElement.approvedData[index].employeeName}
                </div>
              );
            } else {
              approvedToolTip.push(
                <div
                  style={{ padding: "15px", borderBottom: "1px solid #fff" }}
                >
                  {dataElement.approvedData[index].employeeName}
                </div>
              );
            }
          }

          for (let index = 4; index < dataElement.checkedData.length; index++) {
            if (index === dataElement.checkedData.length - 1) {
              checkedToolTip.push(
                <div style={{ padding: "15px" }}>
                  {dataElement.checkedData[index].employeeName}
                </div>
              );
            } else {
              checkedToolTip.push(
                <div
                  style={{ padding: "15px", borderBottom: "1px solid #fff" }}
                >
                  {dataElement.checkedData[index].employeeName}
                </div>
              );
            }
          }

          let newApprovedToolTip = <>{approvedToolTip}</>;
          let newCheckedToolTip = <>{checkedToolTip}</>;

          dataElement.approvedToolTip = newApprovedToolTip;
          dataElement.checkedToolTip = newCheckedToolTip;

          data.push(dataElement);
        });

        setTableData(data);

        let checkedArr = [];
        let approvedArr = [];
        res.data.forEach((item) => {
          if (item.authority === "Checked") {
            let obj = {
              type: item.type,
              id: item.id,
            };

            checkedArr.push(obj);
          } else {
            if (item.authority === "Approved") {
              let obj = {
                type: item.type,
                id: item.id,
              };

              approvedArr.push(obj);
            }
          }
        });
        console.log("arr");

        setCheckedType(checkedArr);
        setApprovedType(approvedArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeChecked = (value) => {
    let exists = false;

    checkedData.forEach((item) => {
      if (item.employeeId === value) exists = true;
    });
    console.log(exists);

    if (!exists) {
      userList.forEach((val) => {
        if (val.id === value) {
          let obj = {
            employeeName: val.fullName,
            employeeId: val.id,
          };

          let addUser = {
            employeeId: val.id,
          };
          setCheckedData([...checkedData, addUser]);
          setCheckedUser([...checkedUser, obj]);
        }
      });
    }
  };

  const changeApproved = (value) => {
    let exists = false;

    approvedData.forEach((item) => {
      if (item.employeeId === value) exists = true;
    });
    console.log(exists);
    if (!exists) {
      userList.forEach((val) => {
        if (val.id === value) {
          let obj = {
            employeeName: val.fullName,
            employeeId: val.id,
          };
          let addUser = {
            employeeId: val.id,
          };
          setApprovedData([...approvedData, addUser]);
          setApprovedUser([...approvedUser, obj]);
        }
      });
    }
  };

  const modal = {
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const styledTableCell = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    width: "300px",
  };

  const smallStyledCell = {
    backgroundColor: "#F1F8FC",
    color: "#3F51B5",
    width: "40px",
  };

  const buttonClicked = (element) => {
    setModalOpen(true);
    setRowData(element);

    setCheckedUser(element.checkedData);
    setApprovedUser(element.approvedData);


    let arr1 = []
    let arr2 = []

    element.checkedData.forEach(
      (item) => {

        let existingUser = {
          employeeId: item.employeeId
        }

        arr1.push(existingUser)
      }
    )

    setCheckedData(arr1)

    element.approvedData.forEach(
      (item) => {
        let existingUser = {
          employeeId: item.employeeId
        }
        arr2.push(existingUser)

      }
    )

    setApprovedData(arr2)

    Axios.get("hrm/findAllActiveUsers").then((res) => {
      setUserList(res.data);
    });
  };

  const handleClose = () => {
    setCheckedUser([]);
    setApprovedUser([]);
    setCheckedData([]);
    setApprovedData([]);
    setModalOpen(false);
  };

  const handleDeleteChecked = (val) => {
    let filteredArray = checkedUser.filter(item => item.employeeId !== val)
    let filteredData = checkedData.filter(item => item.employeeId !== val)
    setCheckedData(filteredData)
    setCheckedUser(filteredArray)

  };

  const handleDeleteApproved = (val) => {

    let filteredArray = approvedUser.filter(item => item.employeeId !== val)
    let filteredData = approvedData.filter(item => item.employeeId !== val)
    setApprovedData(filteredData)
    setApprovedUser(filteredArray)

  };

  const updateAuthority = (type) => {
    console.log("type");

    let checkedId;
    let approvedId;

    checkedType.forEach((item) => {
      if (item.type === type) {
        checkedId = item.id;
      }
    });

    approvedType.forEach((item) => {
      if (item.type === type) {
        approvedId = item.id;
      }
    });

    let obj = [
      {
        authorizationDetails: checkedData,
        id: checkedId,
      },
      {
        authorizationDetails: approvedData,
        id: approvedId,
      },
    ];

    console.log("obj");
    console.log(checkedData);
    console.log(approvedData);
    console.log(obj);

    Axios.put("/hrm/authorization/update", obj)
      .then((res) => {
        console.log(res);

        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };
        setSnackbarProps({ snackbarProps: snackbarProps });
        getTableData();
        handleClose();
      })
      .catch((err) => {
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }

        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        this.showSnackbar(snackbarProps);
      });
  };

  return (
    <>
      <Grid container justify="center">
        <Card className={classes.tabMargin}>
          <CardContent>
            <Grid container>
              <Grid item>
                <Typography variant="h6" style={{ fontSize: "18px" }}>
                  Authorization Matrix
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "20px" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={smallStyledCell}>ID.</TableCell>
                      <TableCell style={styledTableCell}>Authority</TableCell>
                      <TableCell style={styledTableCell}>
                        Checked By User
                      </TableCell>
                      <TableCell style={styledTableCell}>
                        Approved By User
                      </TableCell>
                      <TableCell style={smallStyledCell}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((value, i) => (
                      <TableRow>
                        <TableCell style={{ width: "30px" }}>{i + 1}</TableCell>
                        <TableCell style={{ width: "300px" }}>
                          {value.type}
                        </TableCell>

                        <TableCell style={{ width: "300px" }}>
                          {value.checkedData.map((dataValue, i) => {
                            if (i === 4) {
                              return (
                                <Tooltip title={value.checkedToolTip}>
                                  <Chip
                                    label={
                                      "+" +
                                      (value.checkedData.length - 4) +
                                      " users"
                                    }
                                    style={{
                                      marginRight: 5,
                                      backgroundColor: "#3f51b5",
                                      color: "#fff",
                                      marginBottom: 4,
                                    }}
                                  />
                                </Tooltip>
                              );
                            } else if (i > 4) {
                              return null;
                            } else {
                              return (
                                <Chip
                                  label={dataValue.employeeName}
                                  style={{
                                    marginRight: 5,
                                    backgroundColor: "#3f51b5",
                                    color: "#fff",
                                    marginBottom: 4,
                                  }}
                                />
                              );
                            }
                          })}
                        </TableCell>
                        <TableCell style={{ width: "300px" }}>
                          {value.approvedData.map((dataValue, i) => {
                            if (i === 4) {
                              return (
                                <Tooltip title={value.approvedToolTip}>
                                  <Chip
                                    label={
                                      "+" +
                                      (value.approvedData.length - 4) +
                                      " users"
                                    }
                                    style={{
                                      marginRight: 5,
                                      backgroundColor: "#3f51b5",
                                      color: "#fff",
                                      marginBottom: 4,
                                    }}
                                  />
                                </Tooltip>
                              );
                            } else if (i > 4) {
                              return null;
                            } else {
                              return (
                                <Chip
                                  label={dataValue.employeeName}
                                  style={{
                                    marginRight: 5,
                                    backgroundColor: "#3f51b5",
                                    color: "#fff",
                                    marginBottom: 4,
                                  }}
                                />
                              );
                            }
                          })}
                        </TableCell>
                        <TableCell style={{ width: "30px" }}>
                          <FontAwesomeIcon
                            icon={faEllipsisV}
                            color="#3F51B5"
                            style={{ cursor: "pointer" }}
                            onClick={() => buttonClicked(value)}
                          ></FontAwesomeIcon>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </CardContent>
        </Card>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={modalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            <Card style={{ width: "500px" }}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid container style={{ padding: "20px" }}>
                  <Grid item xs={12}>
                    <TextField
                      readOnly
                      margin="dense"
                      fullWidth
                      name="authorization"
                      label="Authorization"
                      value={rowData.type}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        fontSize: "13px",
                      }}
                    >
                      Checked User
                    </InputLabel>
                    <Select
                      margin="dense"
                      fullWidth
                      onChange={(e) => changeChecked(e.target.value)}
                      name="checkedUser"
                      label="Checked User"
                      value={checkedUser}
                      renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {selected.map((value) => (
                            <Chip
                              label={value.employeeName}
                              key={value.employeeId}
                              onDelete={() =>
                                handleDeleteChecked(value.employeeId)
                              }
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              style={{
                                margin: 5,
                                backgroundColor: "#3f51b5",
                                color: "#fff",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {userList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.fullName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        fontSize: "13px",
                      }}
                    >
                      Approved User
                    </InputLabel>
                    <Select
                      margin="dense"
                      fullWidth
                      name="approvedUser"
                      label="Approved User"
                      value={approvedUser}
                      onChange={(e) => changeApproved(e.target.value)}
                      renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {selected.map((value) => (
                            <Chip
                              label={value.employeeName}
                              onDelete={() =>
                                handleDeleteApproved(value.employeeId)
                              }
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              style={{
                                margin: 5,
                                backgroundColor: "#3f51b5",
                                color: "#fff",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {userList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.fullName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="flex-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: "15px" }}
                        onClick={() => updateAuthority(rowData.type)}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </Grid>
    </>
  );
}

export default withStyles(styles)(AuthorizationMaster);
