import React, { PureComponent } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import {
  Grid,
  CardContent,
  Typography,
  Card,
  withStyles
} from "@material-ui/core";
import Axios from "axios";
import ViewReorderButton from "../components/InterfaceElements/ViewReorderButton";
import { Dropdown } from "semantic-ui-react";
import UserContext from "../utils/UserContext";

const styles = theme => ({
  root: {
    height: "540px",
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  }
});

class Reorder extends PureComponent {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    const rowIndex = params => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50
        },
        {
          headerName: "ItemCode",
          field: "itemCode",
          sortable: true,
          resizable: true,
          minWidth: 50
        },
        {
          headerName: "Supplier",
          field: "supplier",
          sortable: true,
          resizable: true,
          minWidth: 140
        },
        {
          headerName: "Max Daily Usage",
          field: "maxDailyUsageRate",
          sortable: true,
          editable: true,
          minWidth: 100
        },
        {
          headerName: "Lead Time",
          field: "leadTime",
          sortable: true,
          editable: true,
          minWidth: 110
        },
        {
          headerName: "Reorder Level",
          field: "reorderLevel",
          sortable: true,
          resizable: true,
          editable: true,
          minWidth: 90
        },
        {
          headerName: "View",
          cellRendererFramework: ViewReorderButton,
          cellRendererParams: {
            setSnackbar: this.setSnackBar
          },
          minWidth: 50
        }
      ],
      rowData: [],
      origData: [],
      rowRefs: [],
      rowSelection: "single",
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>'
    };
  }

  setSnackBar = snackbarProps => {
    const token = this.context;
    token.setSnackbarProps({ snackbarProps: snackbarProps });
  };

  handleItemSelect = (event, data) => {
    const { value } = data;
    var newData = [];

    this.state.origData.forEach(element => {
      if (element.itemCode === value) {
        newData.push(element);
      }
    });

    this.setState({ rowData: newData });
  };

  componentDidUpdate() {
    if (!this.gridApi) return;
    this.gridApi.sizeColumnsToFit();
  }

  componentDidMount() {
    Axios.get("/pos/reorderCalculation/getReorderItemList")
      .then(res => {
        var itemList = [];
        res.data.forEach(element => {
          var item = {
            key: element.id,
            value: element.itemCode,
            text: "# " + element.itemCode
          };

          itemList.push(item);
        });

        this.setState({
          rowData: res.data,
          origData: res.data,
          rowRefs: itemList
        });

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>"
        });
        try {
          this.gridApi.hideOverlay();
          if (res.data.length === 0) this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      })
      .catch(err => {
        console.log(err);

        this.setState({
          overlayNoRowsTemplate: "<span>No rows to show</span>"
        });
        try {
          this.gridApi.hideOverlay();
          this.gridApi.showNoRowsOverlay();
        } catch (error) {
          console.log(error);
        }
      });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render() {
    const { children, value, index, classes, ...other } = this.props;
    return (
      <div>
        <Card
          role="tabpanel"
          className={classes.root}
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          <CardContent>
            <Grid container justify="center">
              <Typography variant="h6" component="h2">
                Reorder Items
              </Typography>
            </Grid>
            <Grid container justify="center">
              <div
                style={{
                  width: "60%",
                  minWidth: "220px",
                  marginTop: "20px",
                  marginBottom: "10px",
                  marginRight: "5px",
                  marginLeft: "5px"
                }}
              >
                <Dropdown
                  placeholder="Search by Item Code"
                  fluid
                  search
                  ref={search => {
                    this.searchInput = search;
                  }}
                  selection
                  onChange={this.handleItemSelect}
                  options={this.state.rowRefs}
                ></Dropdown>
              </div>
            </Grid>
            <Grid container justify="center">
              <div
                className="ag-theme-balham"
                style={{
                  height: "400px",
                  width: "100%",
                  padding: 5
                }}
              >
                <AgGridReact
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  animateRows={true}
                  rowSelection={this.state.rowSelection}
                  onGridReady={this.onGridReady}
                  overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                  overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                ></AgGridReact>
              </div>
              <div></div>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(Reorder);
