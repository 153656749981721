import React from "react";

import { SnackbarProvider, useSnackbar } from "notistack";

function Snackbar(props) {
  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (variant, val) => {
    // variant could be success, error, warning, info, or default

    enqueueSnackbar(val.toString(), { variant });
  };

  props.notis.map(val => handleClickVariant("info", val));

  return <div></div>;
}

export default function QueueSnackbar(props) {
  return (
    <SnackbarProvider
      maxSnack={4}
      preventDuplicate={true}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Snackbar notis={props.notis} />
    </SnackbarProvider>
  );
}
