import React, { Component } from "react";
import {
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { Label } from "semantic-ui-react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class BalanceSheetPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {


    const snRow = {
      borderRight: "1px solid ",
      borderLeft: "1px solid ",
      borderBottom: "0.01em solid #838587 ",
      width: "100px",
      height: "35px",
    };

    const particularRow = {
      borderRight: "1px solid ",
      borderLeft: "1px solid ",
      borderBottom: "0.01em solid #838587 ",
      width: "400px",
      height: "35px",
    };

    const amountRow = {
      borderRight: "1px solid ",
      borderLeft: "1px solid ",
      borderBottom: "0.01em solid #838587 ",
      width: "150px",
      height: "35px",
      textAlign: "end",
    };

    var format = new Intl.NumberFormat("ne-NP", {
      minimumFractionDigits: 2,
    });

    return (
      <div>
        <Grid
          container
          justify="center"
          style={{ marginTop: 50, fontSize: 18 }}
        >
          <Label>balance Sheet</Label>
        </Grid>

        <Grid container style={{ marginTop: 50, fontSize: "15px" }}>
          <Grid item >
            <Typography >Fiscal Year: </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 15 }}>
            <Typography style={{ fontStyle: "italic" }}>{this.props.currentFy}</Typography>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 10, fontSize: "12px" }}>
          <Grid item style={{ marginLeft: 30 }}>
            <Typography>As On: </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 15 }}>
            <Typography style={{ fontStyle: "italic" }}>{this.props.date}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          style={{ marginTop: 30, fontSize: 18 }}
        >
          <TableContainer>
            <Table aria-label="simple table" style={{ border: "1px solid" }} >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "#F1F8FC",
                      borderBottom: "1px solid ",
                      borderRight: "1px solid ",
                      width: "100px",
                      minWidth: "100px",
                      fontSize: "15px ",
                      fontWeight: "bold"
                    }}
                  >
                    Account Code
                  </TableCell>
                  <TableCell
                   style={{
                    backgroundColor: "#F1F8FC",
                    borderBottom: "1px solid ",
                    borderRight: "1px solid ",
                    width: "3000px",
                    minWidth: "300px",
                    fontSize: "15px ",
                    fontWeight: "bold"
                  }}>
                    Particulars
                    </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#F1F8FC",
                      borderBottom: "1px solid ",
                      width: "100px",
                      fontSize: "15px ",
                      fontWeight: "bold"
                    }}
                  >
                    Amounts
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow >
                  <TableCell
                    style={{
                      borderBottom: "1px solid ",
                    }}
                  ></TableCell>
                  <TableCell style={{
                    borderBottom: "1px solid ",
                    fontWeight: "bold",
                    fontSize: "15px"
                  }} >
                    EQUITY & LIABILITIES
                    </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "1px solid "
                    }}></TableCell>
                </TableRow>
                {this.props.liabilities.map((val) => (
                  <>
                    <TableRow>
                      <TableCell style={snRow}></TableCell>
                      <TableCell style={particularRow}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {val.name}
                        </Typography>
                      </TableCell>
                      <TableCell style={amountRow}></TableCell>
                    </TableRow>
                    {val.accountList.map((childVal) => (
                      <>
                        <TableRow>
                          <TableCell
                            style={snRow}
                            onDoubleClick={() =>
                              this.onDoubleClick(childVal.code)
                            }
                          >
                            <Typography>{childVal.code}</Typography>
                          </TableCell>
                          <TableCell style={particularRow}>
                            <Grid container spacing={2}>
                              <Grid item>
                                {childVal.expanded ? (
                                  <ExpandMoreIcon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.expandLiabilites(
                                        childVal.code
                                      )
                                    }
                                  />
                                ) : (
                                    <ChevronRightIcon
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.expandLiabilites(
                                          childVal.code
                                        )
                                      }
                                    />
                                  )}
                              </Grid>
                              <Grid item>
                                <Typography
                                  onDoubleClick={() =>
                                    this.onDoubleClick(childVal.code)
                                  }
                                >
                                  {childVal.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell style={amountRow}>
                            <Typography>
                              {format.format(Math.abs(childVal.amount))}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {childVal.expanded
                          ? childVal.childs.map((subAccount) => (
                            <TableRow style={{ fontStyle: "italic" }}>
                              <TableCell
                                style={snRow}
                                onDoubleClick={() =>
                                  this.onDoubleClickSubAccount(
                                    childVal.code,
                                    subAccount.subAccountCode
                                  )
                                }
                              >
                                {subAccount.subAccountCode}
                              </TableCell>

                              <TableCell
                                style={particularRow}
                                onDoubleClick={() =>
                                  this.onDoubleClickSubAccount(
                                    childVal.code,
                                    subAccount.subAccountCode
                                  )
                                }
                              >
                                {subAccount.subAccountName}
                              </TableCell>
                              <TableCell style={amountRow}>
                                {format.format(
                                  Math.abs(subAccount.amount)
                                )}
                              </TableCell>
                            </TableRow>
                          ))
                          : null}
                      </>
                    ))}
                  </>
                ))}
                <TableRow style={{ border: "3px solid " }}>
                  <TableCell style={snRow}></TableCell>
                  <TableCell style={particularRow}>
                    <Grid container justify="flex-end">
                      <Typography style={{ fontWeight: "bold" }}>
                        TOTAL EQUITY & LIABILITIES
                              </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell style={amountRow}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {format.format(
                        Math.abs(this.props.totalLiabilities)
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>


              <TableBody>
                <TableRow >
                  <TableCell
                    style={{
                      borderBottom: "1px solid ",
                    }}
                  ></TableCell>
                  <TableCell style={{
                    borderBottom: "1px solid ",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }} >
                    ASSETS
                    </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "1px solid "
                    }}></TableCell>
                </TableRow>
                {this.props.assets.map((val) => (
                  <>
                    <TableRow>
                      <TableCell style={snRow}></TableCell>
                      <TableCell style={particularRow}>
                        <Typography style={{ fontWeight: "bold" }}>
                          {val.name}
                        </Typography>
                      </TableCell>
                      <TableCell style={amountRow}></TableCell>
                    </TableRow>
                    {val.accountList.map((childVal) => (
                      <>
                        <TableRow>
                          <TableCell
                            style={snRow}
                            onDoubleClick={() =>
                              this.onDoubleClick(childVal.code)
                            }
                          >
                            <Typography>{childVal.code}</Typography>
                          </TableCell>
                          <TableCell style={particularRow}>
                            <Typography>
                              <Grid container spacing={2}>
                                <Grid item>
                                  {childVal.expanded ? (
                                    <ExpandMoreIcon
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.expandAssets(childVal.code)
                                      }
                                    />
                                  ) : (
                                      <ChevronRightIcon
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          this.expandAssets(childVal.code)
                                        }
                                      />
                                    )}
                                </Grid>
                                <Grid item>
                                  <Typography
                                    onDoubleClick={() =>
                                      this.onDoubleClick(childVal.code)
                                    }
                                  >
                                    {childVal.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Typography>
                          </TableCell>
                          <TableCell style={amountRow}>
                            <Typography>
                              {format.format(childVal.amount)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {childVal.expanded
                          ? childVal.childs.map((subAccount) => (
                            <TableRow style={{ fontStyle: "italic" }}>
                              <TableCell
                                style={snRow}
                                onDoubleClick={() =>
                                  this.onDoubleClickSubAccount(
                                    childVal.code,
                                    subAccount.subAccountCode
                                  )
                                }
                              >
                                {subAccount.subAccountCode}
                              </TableCell>
                              <TableCell
                                style={particularRow}
                                onDoubleClick={() =>
                                  this.onDoubleClickSubAccount(
                                    childVal.code,
                                    subAccount.subAccountCode
                                  )
                                }
                              >
                                {subAccount.subAccountName}
                              </TableCell>
                              <TableCell style={amountRow}>
                                {format.format(subAccount.amount)}
                              </TableCell>
                            </TableRow>
                          ))
                          : null}
                      </>
                    ))}
                  </>
                ))}
                <TableRow style={{ border: "3px solid " }}>
                  <TableCell style={snRow}></TableCell>
                  <TableCell style={particularRow}>
                    <Grid container justify="flex-end">
                      <Typography style={{ fontWeight: "bold" }}>
                        TOTAL ASSETS
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell style={amountRow}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {format.format(Math.abs(this.props.totalAssets))}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={snRow}></TableCell>
                  <TableCell style={particularRow}>
                    <Grid container justify="flex-end">
                      <Typography style={{ fontWeight: "bold" }}>
                        {this.props.data.netProfit === 0
                          ? "NET LOSS"
                          : "NET PROFIT"}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell style={amountRow}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {this.props.data.netProfit === 0
                        ? format.format(
                          Math.abs(this.props.data.netLoss)
                        )
                        : format.format(
                          Math.abs(this.props.data.netProfit)
                        )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    );
  }
}
export default BalanceSheetPrint;
