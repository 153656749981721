import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  withStyles
} from "@material-ui/core";
import UserContext from "../../utils/UserContext";
import ViewManufacturer from "../InterfaceElements/ViewManufacturer";

const styles = theme => ({
  root: {
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "170px",
      marginRight: "10px",
      marginLeft: "10px"
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px"
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px"
    }
  }
});

class ManufacturerDetails extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const rowIndex = params => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headerName: "SN",
          field: "sn",
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50
        },
        {
          headerName: "Name",
          field: "fullName",
          sortable: true,
          resizable: true,
          minWidth: 200
        },
        {
          headerName: "Type",
          field: "type",
          resizable: true,
          minWidth: 110
        },
        {
          headerName: "Address",
          field: "address",
          resizable: true,
          minWidth: 150
        },
        {
          headerName: "Mobile Number",
          field: "mobile",
          resizable: true,
          minWidth: 120
        },
        {
          headerName: "Product Type",
          field: "typeOfProduct",
          resizable: true,
          minWidth: 120
        },
        {
          headerName: "View",
          cellRendererFramework: ViewManufacturer,
          cellRendererParams: {
            updateData: this.props.getTableData,
            showSnackbar: this.props.showSnackbar
          },
          minWidth: 50
        }
      ],
      rowData: [],
      overlayLoadingTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>',
      overlayNoRowsTemplate:
        '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>'
    };
  }

  componentDidUpdate() {
    if (this.gridApi !== undefined) {
      this.gridApi.setRowData(this.props.manufacturerData);
    }
  }

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
  }

  firstDataRendered = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.props.setGridApi(params.api);
  };

  render() {
    const { value, index, classes, ...other } = this.props;

    return (
      <div>
        <Grid container justify="center">
          <Grid item xs={12} md={10} lg={9}>
            <Card
              className={classes.root}
              role="tabpanel"
              hidden={value !== index}
              id={`vertical-tabpanel-${index}`}
              aria-labelledby={`vertical-tab-${index}`}
              {...other}
            >
              <CardContent>
                <Grid container justify="center">
                  <Typography variant="h6" component="h2">
                    Manufacturer Details
                  </Typography>
                </Grid>

                <Grid container justify="center">
                  <div
                    className="ag-theme-balham"
                    style={{
                      height: "465px",
                      width: "100%",
                      padding: "10px"
                    }}
                  >
                    <AgGridReact
                      columnDefs={this.state.columnDefs}
                      rowData={this.state.rowData}
                      onFirstDataRendered={this.firstDataRendered}
                      onGridReady={this.onGridReady}
                      overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                      overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                    ></AgGridReact>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ManufacturerDetails);
