import React, { Component } from "react";
import {
  Typography,
  CardContent,
  Grid,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  withStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    padding: 10,
    paddingTop: "7px",
    paddingBottom: "7px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "150px",
      marginRight: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
    },
  },
});

class AddUser extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      departmentList: [],
      rolesSelect: [],
      data: {
        firstName: "",
        middleName: "",
        surname: "",
        email: "",
        address: "",
        password: "",
        phoneNumber: "",
        identificationNumber: "",
        role: [],
        department: "",
        position: "",
      },
      confirmPassword: "",
      password: "",
      helperText: "",
    };
  }

  resetData = () => {
    this.setState({
      data: {
        firstName: "",
        middleName: "",
        surname: "",
        email: "",
        address: "",
        password: "",
        phoneNumber: "",
        identificationNumber: "",
        role: [],
        department: "",
        position: "",
      },
      confirmPassword: "",
    });
  };

  componentDidMount() {
    Axios.get("hrm/department/getValues")
      .then((res) => {
        var departmentList = [];
        res.data.forEach((element) => {
          departmentList.push(element.departmentName);
        });

        this.setState({ departmentList });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("role/findAll")
      .then((res) => {
        console.log(res);

        var roles = [];
        roles.push("+ Add Role");
        res.data.forEach((element) => {
          roles.push(element.type);
        });

        this.setState({ roles });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChanged = async (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;
    console.log({ value });

    if (targetName === "password" || targetName === "confirmPassword") {
      var helperText = "";

      await new Promise((accept) =>
        this.setState(
          {
            [targetName]: value,
          },
          accept
        )
      );

      if (this.state.password === this.state.confirmPassword) {
        helperText = "";
      } else {
        helperText = "Passwords do not match";
      }

      this.setState({
        helperText,
      });
    }

    if (value.includes("+ Add Role")) {
      console.log("Here");
      this.props.history.push("/role/master");
    }

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };

  handleSubmit = () => {
    const token = this.context;

    if (this.state.helperText === "") {
      Axios.post("hrm/save", this.state.data)
        .then((res) => {
          console.log(res);

          this.resetData();
          this.props.getUserData();

          var snackbarProps = {
            open: true,
            variant: "success",
            message: res.data,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        })
        .catch((err) => {
          var message = err.message;
          if (err.response !== undefined) {
            message = err.response.data;
          }
          var snackbarProps = {
            open: true,
            variant: "error",
            message: message,
            autoHideDuration: 2000,
          };

          token.setSnackbarProps({ snackbarProps: snackbarProps });
        });
    }
  };

  render() {
    const {
      children,
      value,
      index,
      customerType,
      paymentMode,
      onChange,
      handleSubmit,
      classes,
      ...other
    } = this.props;

    const textFieldStyle = {
      marginTop: "10px",
      width: "100%",
    };

    const inputStyles = {
      fontSize: 14,
    };

    return (
      <div>
        <Grid container style={{ width: "100vw" }} justify="center">
          <Grid item xs={12} lg={6} md={7} sm={10}>
            <Card
              className={classes.root}
              role="tabpanel"
              hidden={value !== index}
              id={`vertical-tabpanel-${index}`}
              aria-labelledby={`vertical-tab-${index}`}
              {...other}
            >
              <CardContent style={{ padding: "10px" }}>
                <Grid container>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ fontSize: "18px" }}
                  >
                    {" "}
                    Add User{" "}
                  </Typography>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                      id="standard-with-placeholder"
                      label="First Name"
                      placeholder=""
                      fullWidth
                      name="firstName"
                      variant="filled"
                      style={textFieldStyle}
                      value={this.state.data.firstName}
                      onChange={this.onChanged}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Middle Name"
                      placeholder=""
                      fullWidth
                      name="middleName"
                      value={this.state.data.middleName}
                      variant="filled"
                      style={textFieldStyle}
                      onChange={this.onChanged}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Last Name"
                      value={this.state.data.surname}
                      placeholder=""
                      fullWidth
                      name="surname"
                      variant="filled"
                      style={textFieldStyle}
                      onChange={this.onChanged}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Email address"
                      type="email"
                      placeholder=""
                      value={this.state.data.email}
                      variant="filled"
                      name="email"
                      onChange={this.onChanged}
                      style={textFieldStyle}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Password"
                      type="password"
                      value={this.state.data.password}
                      placeholder=""
                      variant="filled"
                      name="password"
                      onChange={this.onChanged}
                      style={textFieldStyle}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Confirm Password"
                      type="password"
                      value={this.state.confirmPassword}
                      helperText={
                        <span style={{ color: "#FD2D00" }}>
                          {this.state.helperText}
                        </span>
                      }
                      placeholder=""
                      variant="filled"
                      name="confirmPassword"
                      InputLabelProps={{ style: inputStyles }}
                      onChange={this.onChanged}
                      style={textFieldStyle}
                      inputProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Position"
                      value={this.state.data.position}
                      placeholder=""
                      fullWidth
                      name="position"
                      variant="filled"
                      style={textFieldStyle}
                      onChange={this.onChanged}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="filled" style={textFieldStyle}>
                      <InputLabel id="demo-simple-select-filled-label">
                        Department
                      </InputLabel>
                      <Select
                        variant="filled"
                        id="demo-simple-select-filled"
                        name="department"
                        InputLabelProps={{ style: inputStyles }}
                        onChange={this.onChanged}
                        inputProps={{ style: inputStyles }}
                        value={this.state.data.department}
                      >
                        {this.state.departmentList.map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Address"
                      name="address"
                      value={this.state.data.address}
                      variant="filled"
                      onChange={this.onChanged}
                      placeholder=""
                      style={textFieldStyle}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Mobile number"
                      value={this.state.data.phoneNumber}
                      placeholder=""
                      name="phoneNumber"
                      variant="filled"
                      onChange={this.onChanged}
                      style={textFieldStyle}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="standard-with-placeholder"
                      label="Citizenship Number"
                      placeholder=""
                      name="identificationNumber"
                      value={this.state.data.identificationNumber}
                      variant="filled"
                      onChange={this.onChanged}
                      style={textFieldStyle}
                      inputProps={{ style: inputStyles }}
                      InputLabelProps={{ style: inputStyles }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl variant="filled" style={textFieldStyle}>
                      <InputLabel id="demo-simple-select-filled-label">
                        Roles
                      </InputLabel>
                      <Select
                        id="demo-simple-select-filled"
                        multiple
                        name="role"
                        onChange={this.onChanged}
                        value={this.state.data.role}
                        renderValue={(selected) => (
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                style={{
                                  margin: 2,
                                  backgroundColor: "#3f51b5",
                                  color: "#fff",
                                }}
                              />
                            ))}
                          </div>
                        )}
                        //   onChange={handleChange}
                      >
                        {this.state.roles.map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container justify="center">
                  <Button
                    onClick={this.handleSubmit}
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{
                      marginTop: "15px",
                      height: "50px",
                      width: "100px",
                      minWidth: "115px",
                      fontSize: "13px",
                    }}
                  >
                    Add User
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(AddUser));
