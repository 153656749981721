import React, { useState, useEffect } from "react";
import { Grid, Tabs, Tab, Badge, withStyles } from "@material-ui/core";
import HeaderTitle from "../components/InterfaceElements/HeaderTitle";
import AddInventoryCount from "../components/InventoryCount/AddInventoryCount";
import Authorization from "../components/InventoryCount/Authorization";
import AuthorizedList from "../components/InventoryCount/AuthorizedList";
import Axios from "axios";

const styles = (theme) => ({
  tabMargin: {
    width: "100%",
    marginBottom: "7px",
    [theme.breakpoints.down("md")]: {
      marginTop: "37px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0px",
    },
  },
});

function InventoryCount(props) {
  const [value, setValue] = useState(0);
  const [approvedFY, setApprovedFY]= useState("2078/2079");
  const [authFY, setAuthFY]= useState("2078/2079");

  useEffect(() => {
    Axios.post("pos/inventoryPhysicalCount/findByStatusAndRequest", {
      fiscalYear: "2078/2079",
      period: "Opening",
    })
      .then((res) => {
        console.log(res);

        setAuthDataList(res.data);
        getSheetNumber();
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.post("pos/inventoryPhysicalCount/getAllApproved", {
      fiscalYear: "2078/2079",
      period: "Chaitra",
    })
      .then((res) => {
        setListDataList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [auth, setAuth] = useState({
    visible: false,
    hidden: true,
    secondVisible: false,
    secondHidden: true,
    animationIn: "",
  });

  const [list, setList] = useState({
    visible: false,
    hidden: true,
    secondVisible: false,
    secondHidden: true,
    animationIn: "",
  });

  const [authDataList, setAuthDataList] = useState([]);
  const [listDataList, setListDataList] = useState([]);
  const [sheetNumber, setSheetNumber] = useState([]);
  const [data, setData] = useState({
    id: 1,
    date: "",
    voucherNumber: "",
    invoiceNumber: "",
    invoiceTotal: "",
    receivedMethod: "",
    receivedReferenceNumber: "",
    receivedReference: "",
    total: "",
    period: "",
    fiscalYear: "",
    customerIdNumber: "",
    customerName: "",
    customer: {},
    receivedVoucherCredit: [],
    receivedVoucherDebit: [],
    inventoryPhysicalCountChilds: [],
  });

  const [dataToSend, setDataToSend] = useState({
    id: "",
    date: "",
    voucherNumber: "",
    invoiceNumber: "",
    invoiceTotal: "",
    receivedMethod: "",
    receivedReferenceNumber: "",
    receivedReference: "",
    total: "",
    requestTo: "",
    remarks: "",
    period: "",
    fiscalYear: "",
    customerIdNumber: "",
    customerName: "",
    receivedVoucherCredit: [],
    receivedVoucherDebit: [],
    inventoryPhysicalCountChilds: [],
    totalColor: "#FF8070",
    gridColor: [],
  });

  const handleListViewCounter = (id) => {
    Axios.post("pos/inventoryPhysicalCount/getAllApproved", {
      fiscalYear:approvedFY,
      period: "Chaitra",
    })
      .then((res) => {
        res.data.forEach((element, i) => {
          if (id === element.id) {
            setDataToSend(element);
          }
        });
        listDataList(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAuthorizedListFyChange=(e)=>{
    setApprovedFY(e);
    getTableDataForList(e)
  }

  const handleAuthorizationListFyChange=(e)=>{
    alert(e)
    setAuthFY(e);
    getTableDataForAuth(e)
  }

  const getTableDataForAuth = (e) => {
    Axios.post("pos/inventoryPhysicalCount/findByStatusAndRequest", {
      fiscalYear: authFY,
      period: "Chaitra",
    })
      .then((res) => {
        console.log(res);

        setAuthDataList(res.data);
        getSheetNumber();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSheetNumber = () => {
    Axios.post("pos/inventoryPhysicalCount/findSheetNumber", {
      fiscalYear: "2076/2077",
      period: "Chaitra",
    }).then((res) => {
      console.log("Sheet Number");
      console.log(res);
      setSheetNumber(res.data.sheetNumber);
    });
  };

  const getTableDataForList =(fy) => {
    Axios.post("pos/inventoryPhysicalCount/getAllApproved", {
      fiscalYear:fy,
      period: "Chaitra",
    })
      .then((res) => {
          setListDataList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewCounter = (id) => {
    Axios.post("pos/inventoryPhysicalCount/findByStatusAndRequest", {
      fiscalYear: authFY,
      period: "Chaitra",
    })
      .then((res) => {
        res.data.map((val) => (val.id === id ? setData(val) : null));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listAnimate = (id) => {
    handleListViewCounter(id);

    setList({ ...list, visible: false, animationIn: "zoomIn" });

    setTimeout(() => {
      setList({
        ...list,
        secondVisible: true,
        hidden: true,
        secondHidden: false,
      });
    }, 150);
  };

  const listAnimateOut = (voucherNumber) => {
    handleListViewCounter(voucherNumber);

    setList({ ...list, secondVisible: false });

    setTimeout(() => {
      setList({ ...list, visible: true, hidden: false, secondHidden: true });
    }, 150);
  };

  const authAnimate = (id) => {
    handleViewCounter(id);

    setAuth({ ...auth, visible: false, animationIn: "zoomIn" });

    setTimeout(() => {
      setAuth({
        ...auth,
        secondVisible: true,
        hidden: true,
        secondHidden: false,
      });
    }, 150);
  };

  const authAnimateOut = () => {
    setAuth({ ...auth, secondVisible: false });
    setTimeout(() => {
      setAuth({ ...auth, visible: true, hidden: false, secondHidden: true });
    }, 150);
  };

  const handleChange = (e, value) => {
    setValue(value);

    let currentList = list;
    let currentAuth = auth;

    if (value === 1) {
      setList({ ...currentList, hidden: true, secondHidden: true });
      setAuth({
        ...currentAuth,
        animationIn: "",
        secondVisible: false,
        visible: true,
        hidden: false,
        secondHidden: true,
      });
    } else if (value === 2) {
      setList({
        ...currentList,
        animationIn: "",
        secondVisible: false,
        visible: true,
        hidden: false,
        secondHidden: true,
      });

      setAuth({ ...currentAuth, hidden: true, secondHidden: true });
    } else {
      setAuth({ ...currentAuth, hidden: true, secondHidden: true });
      setList({ ...currentList, hidden: true, secondHidden: true });
    }
  };

  return (
    <div>
      <HeaderTitle title="Inventory Count" />
      <Grid container justify="center" className={props.classes.tabMargin}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          indicatorColor="primary"
          centered
        >
          <Tab
            label="Inventory Physical Count Entry"
            style={{
              minWidth: 50,
              fontSize: "12px",
            }}
          />
          <Tab
            label={
              <Badge
                badgeContent={null}
                color="primary"
                style={{ paddingRight: "11px" }}
              >
                Post Inventory Physical Count
              </Badge>
            }
            style={{
              minWidth: "190px",
              fontSize: "12px",
            }}
          />
          <Tab
            label="Authorized Inventory Physical Count"
            style={{
              minWidth: 300,
              fontSize: "12px",
            }}
          />
        </Tabs>
      </Grid>

      <AddInventoryCount
        index={0}
        value={value}
        getTableDataForAuth={getTableDataForAuth}
        sheetNumber={sheetNumber}
      />
      <Authorization
        index={1}
        value={value}
        visible={auth}
        animate={authAnimate}
        data={data}
        dataList={authDataList}
        getTableDataForAuth={getTableDataForAuth}
        //getTableDataForList={getTableDataForList}
        handleAuthorizationListFyChange={handleAuthorizationListFyChange}
        handleViewCounter={handleViewCounter}
        animateOut={authAnimateOut}
      />
      <AuthorizedList
        index={2}
        value={value}
        visible={list}
        animate={listAnimate}
        animateOut={listAnimateOut}
        data={dataToSend}
        dataList={listDataList}
        handleAuthorizedListFyChange={handleAuthorizedListFyChange}
      />
    </div>
  );
}

export default withStyles(styles)(InventoryCount);
