import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Fade,
  Card,
  CardContent,
  Grid,
  TextField,
  withStyles,
  Button,
  Backdrop,
  MenuItem,
  FormControl,
  FormLabel,
  IconButton,

} from "@material-ui/core";
import { Dropdown } from "semantic-ui-react";
import Checkbox from '@material-ui/core/Checkbox';

import Close from "@material-ui/icons/Close";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import dateFormat from "dateformat";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import Axios from "axios";
import UserContext from "../../utils/UserContext";
import $ from "jquery";


window.jQuery = require("jquery");

const styles = (theme) => ({
  cardHeight: {
    minWidth: "300px",
    padding: "5px",
    overflowY: "auto",
    height: "99%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "10px",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "66%",
    },
  },
  containerWidth: {
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90%",
    },
  },
  smMargin: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "40px",
    },
  },
  mdMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
});

class ViewInventoryListButton extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editable: false,
      data: {
        itemCode: "",
        priority: false,
        itemName: "",
        majorCategory: "",
        category: "",
        warehouse: "",
        quantity: "",
        status: "",
        assetsType: "",
        assetType: "",
        itemDescription: "",
        custodian: "",
        id: "",
        inventorySubMaster: {
          referenceNumber: "",
          specificationNumber: "",
          model: "",
          goodsReceivedNote: "",
          batchNumber: "",
          invoiceNumber: "",
          transactionNumber: "",
          materialControlId: "",
          invoiceDateNepali: "",
          invoiceDateEnglish: "",
          depreciation: "",
          transactionDateNepali: "",
          transactionDateEnglish: "",
          expiryDate: "",
        },
        propertyType: "",
        manufactureDate: "",
        manufacturerName: "",
        rate: "",
        markedPrice: "",
        subCategory: "",
        supplierName: "",
        total: "",
        totalWithVat: "",
        vatAmount: "",
        vatPercentage: "",
        unit: "",
      },
      majorCategory: [],
      category: [],
      subCategory: [],
      supplier: [],
      manufacturerList: [],
      unitList: [{ "name": "FIT" }, { "name": "KILOGRAM" }, { "name": "LITTER" }, { "name": "MTR" }, { "name": "PACK" }, { "name": "PIC" }, { "name": "PKT" }, { "name": "ROLL" }, { "name": "SET" }, { "name": "SQ.FT" }],
      buttonColor: "#108dcd",
    };
  }

  onTransactionNepaliDateChange = (date) => {
    var newDate = dateFormat(date, "yyyy-mm-dd");

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          transactionDateNepali: newDate,
        },
      },
    }));
  };

  onInvoiceNepaliDateChange = (date) => {
    var newDate = dateFormat(date, "yyyy-mm-dd");

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          invoiceDateNepali: newDate,
        },
      },
    }));
  };

  componentDidMount() {
    Axios.get("pos/category/getAllCategories")
      .then((res) => {
        this.setState({ category: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("/pos/manufacturer/findAll")
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          manufacturerList: res.data,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/supplier/getAllSuppliersName")
      .then((res) => {
        this.setState({ supplier: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get("pos/majorCategory/getAllMajorCategories")
      .then((res) => {
        this.setState({ majorCategory: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

    var rowDetails = [];
    Axios.get("pos/subCategory/getAllSubCategories")
      .then((res) => {
        console.log(res.data)
        res.data.forEach((element) => {
          var item = {
            key: element.id,
            value: element.subCategoryName,
            text: element.subCategoryName,
          };
          rowDetails.push(item);
        });
        this.setState({ subCategory: rowDetails });
      })
      .catch((err) => {
        console.log(err);
      });

    let newData = {
      itemCode: this.props.node.data.itemCode,
      itemName: this.props.node.data.itemName,
      majorCategory: this.props.node.data.majorCategory,
      category: this.props.node.data.category,
      quantity: this.props.node.data.quantity,
      status: this.props.node.data.status,
      assetsType: this.props.node.data.assetsType,
      custodian: this.props.node.data.custodian,
      id: this.props.node.data.id,
      inventorySubMaster: this.props.node.data.inventorySubMaster,
      propertyType: this.props.node.data.propertyType,
      manufactureDate: this.props.node.data.manufactureDate,
      manufacturerName: this.props.node.data.manufactureName,
      rate: this.props.node.data.rate,
      markedPrice: this.props.node.data.markedPrice,
      subCategory: this.props.node.data.subCategory,
      supplierName: this.props.node.data.supplierName,
      total: this.props.node.data.total,
      totalWithVat: this.props.node.data.totalWithVat,
      unit: this.props.node.data.unit,
      itemDescription: this.props.node.data.itemDescription,
      vatAmount: this.props.node.data.vatAmount,
      vatPercentage: this.props.node.data.vatPercentage,
      warehouse: this.props.node.data.warehouse,
      priority: this.props.data.priority
    };

    this.setState({
      data: newData,
    });
  }

  handleInvoiceDateEnglish = (e) => {
    var newDate = dateFormat(e.target.value, "yyyy-mm-dd");
    console.log(newDate);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          invoiceDateEnglish: newDate,
        },
      },
    }));
  };

  handleInvoiceDateNepali = (date, formattedDate) => {
    var newDate = dateFormat(formattedDate, "yyyy-mm-dd");
    console.log(newDate);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          invoiceDateNepali: newDate,
        },
      },
    }));
  };

  handleMfgDate = (e) => {
    let targetValue = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        manufacturerName: targetValue,
      },
    }));
  };

  handleExpiryDate = (date, formattedDate) => {
    var newDate = dateFormat(formattedDate, "yyyy-mm-dd");
    console.log(newDate);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          expiryDate: newDate,
        },
      },
    }));
  };

  handleManufactureDate = (date, formattedDate) => {
    var newDate = dateFormat(formattedDate, "yyyy-mm-dd");

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        manufactureDate: newDate,
      },
    }));
  };

  handleTransactionDateNepali = (date, formattedDate) => {
    var newDate = dateFormat(formattedDate, "yyyy-mm-dd");
    console.log(newDate);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          transactionDateNepali: newDate,
        },
      },
    }));
  };

  handleCalculation = (event, value) => {
    event.stopPropagation();

    var total = this.state.data.total;

    var targetName = event.target.name;
    var val = event.target.value;

    if (targetName === "quantity") {
      if (parseInt(val) <= 0) {
        val = 1;
      }
      total = this.state.data.rate * val;
    } else {
      total = this.state.data.quantity * val;
    }

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        total,
        [targetName]: val,
      },
    }));

    setTimeout(() => this.handleTotal(), 100);
  };

  handleTotal = () => {
    let total = this.state.data.quantity * this.state.data.rate;

    let vatAmount = (this.state.data.vatPercentage / 100) * total;

    let totalWithVat = (vatAmount + total).toFixed(2);

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        totalWithVat,
        vatAmount,
      },
    }));
  };

  handleVatCalculation = (event, value) => {
    event.stopPropagation();

    var val = event.target.value;

    let vatAmount = (val / 100) * this.state.data.total;
    let totalWithVat = vatAmount + this.state.data.total;

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        vatPercentage: val,
        vatAmount,
        totalWithVat,
      },
    }));
  };

  handleTransactionDateEnglish = (e) => {
    var newDate = dateFormat(e.target.value, "yyyy-mm-dd");
    console.log(newDate);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          transactionDateEnglish: newDate,
        },
      },
    }));
  };

  handleOpen = async () => {
    await this.setState({ open: true, editable: false });

    try {
      $(".transactionDateNepaliView").nepaliDatePicker({
        dateFormat: "%D, %M %d, %y",
        closeOnDateSelect: true,
      });

      $(".invoiceDateNepaliView").nepaliDatePicker({
        dateFormat: "%D, %M %d, %y",
        closeOnDateSelect: true,
      });
    } catch (e) {
      console.log(e);
    }

    $(".transactionDateNepaliView").on("click", function (event) {
      $(".transactionDateNepaliLabelView").css({ color: "#3f51b5" });
      $(".transactionDateNepaliView").css({
        borderBottom: "2px solid #3f51b5",
      });
    });

    $(".invoiceDateNepalView").on("click", function (event) {
      $(".invoiceDateNepaliLabelView").css({ color: "#3f51b5" });
      $(".invoiceDateNepaliView").css({ borderBottom: "2px solid #3f51b5" });
    });

    $(".transactionDateNepaliView").focusout((params) => {
      $(".transactionDateNepaliLabelView").css({
        color: "rgba(0, 0, 0, 0.54)",
      });
      $(".transactionDateNepaliView").css({
        borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
      });
      setTimeout(() => {
        var str = $(".transactionDateNepaliView").val();

        this.setState((prevState) => ({
          ...prevState,
          transactionDateNepali: str,
        }));
      }, 100);
    });

    $(".invoiceDateNepaliView").focusout((params) => {
      $(".invoiceDateNepaliLabelView").css({ color: "rgba(0, 0, 0, 0.54)" });
      $(".invoiceDateNepaliView").css({
        borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
      });
      setTimeout(() => {
        var str = $(".invoiceDateNepaliView").val();

        this.setState({ invoiceDateNepali: str });
      }, 100);
    });

    $(".transactionDateNepaliView").on("dateChange", (event) => {
      var dateObj = event.datePickerData;

      var date = new Date(dateObj.bsYear, dateObj.bsMonth - 1, dateObj.bsDate);

      var newDate = dateFormat(date, "yyyy-mm-dd");

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          inventorySubMaster: {
            ...prevState.data.inventorySubMaster,
            transactionDateNepali: newDate,
          },
        },
      }));
    });

    $(".invoiceDateNepaliView").on("dateChange", (event) => {
      var dateObj = event.datePickerData;

      var date = new Date(dateObj.bsYear, dateObj.bsMonth - 1, dateObj.bsDate);

      var newDate = dateFormat(date, "yyyy-mm-dd");

      //var str = $(".invoiceDateNepaliView").val();

      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          inventorySubMaster: {
            ...prevState.data.inventorySubMaster,
            invoiceDateNepali: newDate,
          },
        },
      }));
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      buttonColor: "#108dcd",
    });
  };
  handleUpdate = () => {
    var id = this.props.node.data.id;
    console.log(this.state.data);
    Axios.put("pos/inventory/updateInventory/" + id, this.state.data)
      .then((res) => {
        console.log(res);
        var snackbarProps = {
          open: true,
          variant: "success",
          message: res.data,
          autoHideDuration: 2000,
        };

        this.props.setSnackbar(snackbarProps);
        this.props.inventoryListing();
        this.handleClose();
      })
      .catch((err) => {
        console.log(err);
        var message = err.message;
        if (err.response !== undefined) {
          message = err.response.data;
        }
        var snackbarProps = {
          open: true,
          variant: "error",
          message: message,
          autoHideDuration: 2000,
        };
        this.props.setSnackbar({ snackbarProps: snackbarProps });
      });
  };

  onChangedMaster = async (e, data) => {
    e.stopPropagation();
    if (!this.state.editable) return;
    var targetName = e.target.name;
    var value = e.target.value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [targetName]: value,
      },
    }));
  };
  onChangedBrand = (e, data) => {
    var value = data.value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        subCategory: value,
      },
    }));
  };

  onChangedSubMaster = (e) => {
    e.stopPropagation();

    var targetName = e.target.name;
    var value = e.target.value;

    if (!this.state.editable) return;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        inventorySubMaster: {
          ...prevState.data.inventorySubMaster,
          [targetName]: value,
        },
      },
    }));
  };


  render() {
    const { classes } = this.props;

    const modal = {
      overflowY: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    const textFieldStyle = {
      width: "100%",
      marginTop: "10px",
    };

    const inputStyles = {
      fontSize: 14,
    };
    console.log("The stateetdata", this.state.data)

    return (
      <div>
        <span>
          <FontAwesomeIcon
            icon={faSearch}
            color="#3f51b5"
            onClick={() => this.handleOpen()}
          ></FontAwesomeIcon>
        </span>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Card className={classes.cardHeight}>
              <CardContent style={{ padding: "0px" }}>
                <Grid container justify="flex-end">
                  <IconButton onClick={this.handleClose}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid container justify="flex-end">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      this.setState({ editable: true, buttonColor: "#1044CD" });
                    }}
                    style={{
                      margin: "15px",
                      backgroundColor: this.state.buttonColor,
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid
                  container
                  justify="center"
                  style={{ width: "100%", padding: "0px" }}
                >
                  <Grid
                    container
                    justify="center"
                    spacing={10}
                    className={classes.containerWidth}
                  >
                    <Grid item xs={12} md={6}>
                      <Grid
                        container
                        spacing={2}
                        justify="center"
                        style={{ padding: "0px" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Item Code"
                            value={this.state.data.itemCode}
                            name="itemCode"
                            style={textFieldStyle}
                            onChange={this.onChangedMaster}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Name of Material"
                            value={this.state.data.itemName}
                            name="itemName"
                            style={textFieldStyle}
                            onChange={this.onChangedMaster}
                            placeholder=""
                            inputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-select-currency"
                            select
                            style={textFieldStyle}
                            name="PropertyType"
                            value={this.state.data.propertyType}
                            onChange={this.onChangedMaster}
                            InputProps={{ style: { fontSize: 13 } }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Property Type"
                            SelectProps={{
                              MenuProps: {
                                style: { width: 200 },
                              },
                            }}
                            margin="normal"
                          >
                            <MenuItem key={1} value={"Stock"}>
                              {"Stock"}
                            </MenuItem>
                            <MenuItem key={2} value={"Inventory"}>
                              {"Inventory"}
                            </MenuItem>
                            <MenuItem key={3} value={"Capital Assets"}>
                              {"Capital Assets"}
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-select-currency"
                            select
                            style={textFieldStyle}
                            name="majorCategory"
                            value={this.state.data.majorCategory}
                            onChange={this.onChangedMaster}
                            InputProps={{ style: { fontSize: 13 } }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Major Category"
                            SelectProps={{
                              MenuProps: {
                                style: { width: 200 },
                              },
                            }}
                            margin="normal"
                          >
                            {this.state.majorCategory.map((item, i) => (
                              <MenuItem key={i} value={item.majorCategoryName}>
                                {item.majorCategoryName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-select-currency"
                            select
                            style={textFieldStyle}
                            name="category"
                            value={this.state.data.category}
                            onChange={this.onChangedMaster}
                            InputProps={{ style: { fontSize: 13 } }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Category"
                            SelectProps={{
                              MenuProps: {
                                style: { width: 200 },
                              },
                            }}
                            margin="normal"
                          >
                            {this.state.category.map((item, i) => (
                              <MenuItem key={i} value={item.categoryName}>
                                {item.categoryName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {!this.state.editable ? (
                            <TextField
                              id="standard-search"
                              style={textFieldStyle}
                              InputProps={{ style: inputStyles }}
                              InputLabelProps={{ style: inputStyles }}
                              label="Brand"
                              name="subCategory"
                              value={this.state.data.subCategory}
                              margin="normal"
                            >
                            </TextField>
                          ) : (
                            <Dropdown
                              label="Brand"
                              fluid
                              search
                              style={{
                                width: "240px",
                                borderTop: "0px",
                                borderRight: 0,
                                borderLeft: 0,
                                borderBottom: "1px solid ",
                                borderRadius: "0px",
                                borderColor: "#8c8a84",
                                marginTop: "15px",
                              }}
                              ref={(search) => {
                                this.searchInput = search;
                              }}
                              selection
                              value={this.state.data.subCategory}
                              onChange={this.onChangedBrand}
                              options={[...this.state.subCategory]}
                            ></Dropdown>
                          )}

                          {/*  <TextField
                            id="standard-search"
                            select
                            //type="search"
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Brand"
                            name="subCategory"
                            value={this.state.data.subCategory}
                            onChange={this.onChangedMaster}
                            SelectProps={{
                              MenuProps: {
                                style: { width: 200 },
                              },
                            }}
                            margin="normal"
                          >
                            {this.state.subCategory.map((item, i) => (
                              <MenuItem key={i} value={item.subCategoryName}>
                                {item.subCategoryName}
                              </MenuItem>
                            ))}
                          </TextField> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {this.state.data.inventorySubMaster
                            .transactionDateEnglish === "" ||
                            this.state.data.inventorySubMaster
                              .transactionDateEnglish === null ? (
                            <TextField
                              id="standard-with-placeholder"
                              label="Transaction Date English"
                              name="transactionDateEnglish"
                              value={
                                this.state.data.inventorySubMaster
                                  .transactionDateEnglish
                              }
                              onChange={this.handleTransactionDateEnglish}
                              inputProps={{ style: inputStyles }}
                              InputLabelProps={{ style: inputStyles }}
                              style={textFieldStyle}
                              margin="normal"
                            />
                          ) : (
                            <TextField
                              id="standard-with-placeholder"
                              label="Transaction Date English"
                              name="transactionDateEnglish"
                              type="date"
                              value={
                                this.state.data.inventorySubMaster
                                  .transactionDateEnglish
                              }
                              onChange={this.handleTransactionDateEnglish}
                              inputProps={{ style: inputStyles }}
                              InputLabelProps={{ style: inputStyles }}
                              style={textFieldStyle}
                              margin="normal"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl style={textFieldStyle}>
                            <FormLabel
                              style={{ fontSize: 10 }}
                              className="transactionDateNepaliLabelView"
                            >
                              Transaction Date - Nepali
                            </FormLabel>
                            <input
                              type="text"
                              name="transactionDateNepaliView"
                              className="transactionDateNepaliView"
                              value={
                                this.state.data.inventorySubMaster
                                  .transactionDateNepali
                              }
                              style={{
                                font: "inherit",
                                border: 0,
                                borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
                                margin: 0,
                                paddingBottom: "6px",
                                display: "block",
                                background: "none",
                                boxSizing: "content-box",
                              }}
                            ></input>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.mdMargin}
                        justify="center"
                      >
                        <Grid item xs={12}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Invoice Number"
                            name="invoiceNumber"
                            value={
                              this.state.data.inventorySubMaster.invoiceNumber
                            }
                            onChange={this.onChangedSubMaster}
                            placeholder=""
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          {this.state.data.inventorySubMaster
                            .invoiceDateEnglish === "" ||
                            this.state.data.inventorySubMaster
                              .invoiceDateEnglish === null ? (
                            <TextField
                              id="standard-with-placeholder"
                              label="Invoice Date English"
                              name="invoiceDateEnglish"
                              value={
                                this.state.data.inventorySubMaster
                                  .invoiceDateEnglish
                              }
                              onChange={this.handleInvoiceDateEnglish}
                              inputProps={{ style: inputStyles }}
                              InputLabelProps={{ style: inputStyles }}
                              style={textFieldStyle}
                              margin="normal"
                            />
                          ) : (
                            <TextField
                              id="standard-with-placeholder"
                              label="Invoice Date English"
                              name="invoiceDateEnglish"
                              type="date"
                              value={
                                this.state.data.inventorySubMaster
                                  .invoiceDateEnglish
                              }
                              onChange={this.handleInvoiceDateEnglish}
                              inputProps={{ style: inputStyles }}
                              InputLabelProps={{ style: inputStyles }}
                              style={textFieldStyle}
                              margin="normal"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl style={textFieldStyle}>
                            <FormLabel
                              style={{ fontSize: 10 }}
                              className="invoiceDateNepaliLabelView"
                            >
                              Invoice Date - Nepali
                            </FormLabel>
                            <input
                              type="text"
                              name="invoiceDateNepaliView"
                              className="invoiceDateNepaliView"
                              value={
                                this.state.data.inventorySubMaster
                                  .invoiceDateNepali
                              }
                              style={{
                                font: "inherit",
                                border: 0,
                                borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
                                margin: 0,
                                paddingBottom: "6px",
                                display: "block",
                                background: "none",
                                boxSizing: "content-box",
                              }}
                            ></input>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="standard-select-currency"
                            select
                            name="supplierName"
                            value={this.state.data.supplierName}
                            onChange={this.onChangedMaster}
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Supplier Name"
                            SelectProps={{
                              MenuProps: {
                                style: { width: 200 },
                              },
                            }}
                            margin="normal"
                          >
                            {this.state.supplier.map((item, i) => (
                              <MenuItem key={i} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Batch Number"
                            placeholder=""
                            name="batchNumber"
                            value={
                              this.state.data.inventorySubMaster.batchNumber
                            }
                            onChange={this.onChangedSubMaster}
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Goods Received Note"
                            style={textFieldStyle}
                            name="goodsReceivedNote"
                            value={
                              this.state.data.inventorySubMaster
                                .goodsReceivedNote
                            }
                            onChange={this.onChangedSubMaster}
                            placeholder=""
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Manufacturer Name"
                            name="manufacturerName"
                            value={this.state.data.manufacturerName}
                            select
                            onChange={this.handleMfgDate}
                            placeholder=""
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          >
                            {this.state.manufacturerList.map((item, i) => (
                              <MenuItem key={i} value={item.fullName}>
                                {item.fullName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              style={textFieldStyle}
                              name="expiryDate"
                              value={this.state.data.manufactureDate}
                              onChange={this.onChangedSubMaster}
                              InputProps={{ style: inputStyles }}
                              InputLabelProps={{ style: inputStyles }}
                              format="MM/dd/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="Manufacture Date"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              style={textFieldStyle}
                              InputProps={{ style: inputStyles }}
                              InputLabelProps={{ style: inputStyles }}
                              format="MM/dd/yyyy"
                              margin="normal"
                              name="expiryDate"
                              id="date-picker-inline"
                              label="Expiry Date"
                              value={
                                this.state.data.inventorySubMaster.expiryDate
                              }
                              onChange={this.handleExpiryDate}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.smMargin}
                        justify="center"
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            select
                            fullWidth
                            label="Unit"
                            name="unit"
                            placeholder=""
                            value={this.state.data.unit}
                            onChange={this.onChangedMaster}
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          >
                            {this.state.unitList.map(unit => (
                              <option value={unit.name}>{unit.name}</option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Model"
                            placeholder=""
                            name="model"
                            style={textFieldStyle}
                            value={this.state.data.inventorySubMaster.model}
                            onChange={this.onChangedSubMaster}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Item Description"
                            name="itemDescription"
                            placeholder=""
                            value={this.state.data.itemDescription}
                            onChange={this.onChangedMaster}
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Specification Number"
                            placeholder=""
                            name="specificationNumber"
                            value={
                              this.state.data.inventorySubMaster
                                .specificationNumber
                            }
                            onChange={this.onChangedSubMaster}
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Reference Number"
                            placeholder=""
                            name="referenceNumber"
                            value={
                              this.state.data.inventorySubMaster.referenceNumber
                            }
                            onChange={this.onChangedSubMaster}
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Location/Warehouse"
                            placeholder=""
                            name="warehouse"
                            value={this.state.data.warehouse}
                            onChange={this.onChangedMaster}
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Custodian"
                            name="custodian"
                            value={this.state.data.custodian}
                            onChange={this.onChangedMaster}
                            placeholder=""
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="standard-select-currency"
                            select
                            style={textFieldStyle}
                            name="priority"
                            value={this.state.data.priority}
                            onChange={this.onChangedMaster}
                            InputProps={{ style: { fontSize: 13 } }}
                            InputLabelProps={{ style: inputStyles }}
                            label="Priority"
                            SelectProps={{
                              MenuProps: {
                                style: { width: 200 },
                              },
                            }}
                            margin="normal"
                          >
                            <MenuItem key="LOW" value="LOW">
                              Low
                            </MenuItem>
                            <MenuItem key="MEDIUM" value="MEDIUM">
                              Medium
                            </MenuItem>
                            <MenuItem key="HIGH" value="HIGH">
                              High
                            </MenuItem>
                          </TextField>

                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid
                        container
                        spacing={2}
                        justify="center"
                        className={classes.smMargin}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Purchase Price"
                            name="rate"
                            type="number"
                            value={this.state.data.rate}
                            onChange={this.handleCalculation}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  NRs.
                                </InputAdornment>
                              ),
                              style: inputStyles,
                            }}
                            placeholder=""
                            style={textFieldStyle}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Selling Price"
                            placeholder=""
                            name="markedPrice"
                            type="number"
                            value={this.state.data.markedPrice}
                            onChange={this.onChangedMaster}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  NRs.
                                </InputAdornment>
                              ),
                              style: inputStyles,
                            }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Quantity"
                            name="quantity"
                            type="number"
                            placeholder=""
                            value={this.state.data.quantity}
                            onChange={this.onChangedMaster}
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Total"
                            placeholder=""
                            name="total"
                            type="number"
                            value={Number(this.state.data.total).toFixed(2)}
                            onChange={this.onChangedMaster}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  NRs.
                                </InputAdornment>
                              ),
                              style: inputStyles,
                            }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="VAT Percent"
                            name="vatPercentage"
                            value={this.state.data.vatPercentage}
                            onChange={this.handleVatCalculation}
                            placeholder=""
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                              style: inputStyles,
                            }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="VAT Amount"
                            type="number"
                            name="vatAmount"
                            value={Number(this.state.data.vatAmount).toFixed(2)}
                            onChange={this.onChangedMaster}
                            placeholder=""
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  NRs.
                                </InputAdornment>
                              ),
                              style: inputStyles,
                            }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Total with VAT"
                            placeholder=""
                            name="totalWithVat"
                            value={Number(this.state.data.totalWithVat).toFixed(
                              2
                            )}
                            onChange={this.handleVatCalculation}
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  NRs.
                                </InputAdornment>
                              ),
                              style: inputStyles,
                            }}
                            InputLabelProps={{ style: inputStyles }}
                            style={textFieldStyle}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="standard-with-placeholder"
                            label="Depreciation"
                            name="depreciation"
                            value={
                              this.state.data.inventorySubMaster.depreciation
                            }
                            onChange={this.onChangedSubMaster}
                            placeholder=""
                            style={textFieldStyle}
                            InputProps={{ style: inputStyles }}
                            InputLabelProps={{ style: inputStyles }}
                            margin="normal"
                          />
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justify="center"
                    style={{ marginTop: "50px" }}
                  >
                    {this.state.editable ? (
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={this.handleUpdate}
                        style={{
                          margin: "10px",
                        }}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        disabled
                        onClick={this.handleUpdate}
                        style={{
                          margin: "10px",
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(ViewInventoryListButton);
