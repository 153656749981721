import React, { Component } from "react";
import {
  Card,
  Typography,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  Button,
  withStyles,
  MenuItem,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { Animated } from "react-animated-css";
import { Dropdown } from "semantic-ui-react";
import Axios from "axios";
import ViewSalesVoucherList from "./ViewSalesVoucherList";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import {
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

const styles = (theme) => ({
  root: {
    padding: 20,
    overflowY: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "95vw",
      margin: "auto",
      marginBottom: "170px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
      margin: "auto",
      marginBottom: "100px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70vw",
      margin: "auto",
      marginBottom: "100px",
    },
  },
});

class SalesVoucherList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGettingAllData: false,
      excelData: [],
      colorArr: {},
      filterShow: false,
      page: 0,
      rowsPerPage: 10,
      rowData: [],
      dataToSend: {},
      auth: {
        visible: false,
        hidden: true,
        secondVisible: false,
        secondHidden: true,
        animationIn: "",
      },
      fyList: [],
      fiscalYear: "",
    };
  }

  async componentDidMount() {
    this.getAllFYListing();
    let excel = [];

    let obj = {
      sn: "S.No.",
      period: "Period",
      voucherNumber: "Voucher Number",
      transactionDate: "Transaction Date",
      postDate: "Post Date",
      masterNarration: "Narration"
    }
    this.setState({
      ...this.state,
      isGettingAllData: true,
    })

    Axios("/pos/salesVoucher/findAllApproved").then(res => {
      res.data.forEach((el, i) => {
        let obj = {
          sn: i + 1,
          period: el.period,
          voucherNumber: el.voucherNumber,
          transactionDate: el.createdAt,
          postDate: el.approvedDate,
          masterNarration: el.masterNarration
        }
        excel.push(obj)
      })

      this.setState(prevState => ({
        ...prevState,
        excelData: excel,
        isGettingAllData: false
      }))
    })
  }

  getAllFYListing = () => {
    Axios.get("/fy/getAllActivePeriods")
      .then(async (res) => {
        var fiscalYearLists = [];
        res.data.forEach((element) => {
          var fy = Object.keys(element);

          fiscalYearLists.push(fy[0]);
        });
        await this.setState({
          fyList: fiscalYearLists,
          fiscalYear: fiscalYearLists[0],
        });
        console.log("Fiscal",this.state.fiscalYear )
        this.props.handleFyChange(this.state.fiscalYear, "list");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleFyChange = (e) => {
    var val = e.target.value;
    this.setState({fiscalYear : val})
    this.props.handleFyChange(val, "list");
  };

  handleDateFrom = (e) => {
    var val = e.target.value;

    this.props.handleDateFrom(val, "list");
  };

  handleDateTo = (e) => {
    var val = e.target.value;

    this.props.handleDateTo(val, "list");
  };

  handleVoucherChange = (_e, { value }) => {
    this.props.handleVoucherCodeChange(value, "list");
  };

  generateList() {
    let arr = [];
    let data = this.props.dataList

    for (let index = 0; index < data.length; index++) {
      arr.push(
        <TableRow hover>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            {" "}
            <span style={{ fontWeight: "bold" }}>{data[index].period}</span>
          </TableCell>
          <TableCell>{data[index].voucherNumber}</TableCell>
          <TableCell>{data[index].date}</TableCell>
          <TableCell>{data[index].approvedDate}</TableCell>
          <TableCell>{data[index].masterNarration}</TableCell>
          <TableCell
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => this.props.animate(data[index].id)}
          >
            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
          </TableCell>
        </TableRow>
      );
    }
    return arr;
  }

  handleRowsPerPage = (event) => {
    this.props.getPaginationInfo(0,parseInt(event.target.value, 10))
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  render() {
    const { classes } = this.props;

    const {
      hidden,
      secondHidden,
      animationIn,
      visible,
      secondVisible,
    } = this.props.visible;

    const inputStyles = {
      fontSize: 14,
    };

    var today = new Date();
    var month = (today.getMonth() + 1).toString();
    var day = today.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    var date = today.getFullYear() + "-" + month + "-" + day;

    const textFieldStyle = {
      marginBottom: "23px",
      marginTop: "23px",
      width: "100%",
    };

    const styledTableCell = {
      backgroundColor: "#F1F8FC",
      color: "#3F51B5",
    };

    console.log("isFetching",this.props.isFetchingData)

    return (
      <div>
        <Grid container justify="center">
          <Grid item>
            <Animated
              animationIn={animationIn}
              animationOut="zoomOut"
              isVisible={visible}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <Card className={classes.root} hidden={hidden}>
                <Typography
                  gutterButtom
                  variant="h5"
                  style={{ fontWeight: "bolder", marginBottom: "10px" }}
                >
                  Authorized Sales Voucher List
                </Typography>
                <Grid container justify="space-between">
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <TextField
                          select
                          style={{ width: "130px" }}
                          label="Fiscal Year"
                          name="fiscalYear"
                          onChange={this.handleFyChange}
                          value={this.state.fiscalYear}
                        >
                          {this.state.fyList.map(item => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item >
                        <Button
                          color="primary"
                          style={{ marginTop: "15px" }}
                          onClick={() => {
                            this.setState({ filterShow: !this.state.filterShow });
                          }}
                        >
                          <Typography variant="button">
                            <FontAwesomeIcon
                              icon={faSlidersH}
                              style={{ marginRight: "5px" }}
                            ></FontAwesomeIcon>
                            Filter
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <ExcelFile
                      filename="SV Authorized List"
                      element={
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            minWidth: "100px",
                            // width: "6vw",
                            margin: "3px",
                            height: "38px",
                            fontSize: "16px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            color="#3f51b5"
                            size="30px"
                            style={{
                              marginRight: "10px",
                            }}
                          ></FontAwesomeIcon>
                          {this.state.isGettingAllData ? "Preparing Excel" : "Excel"}
                          
                        </Button>
                      }
                    >
                      <ExcelSheet
                        data={this.state.excelData}
                        name="SV Authorized List"
                      >
                        <ExcelColumn value="sn" />
                        <ExcelColumn value="period" />
                        <ExcelColumn value="voucherNumber" />
                        <ExcelColumn value="transactionDate" />
                        <ExcelColumn value="postDate" />
                        <ExcelColumn value="masterNarration" />
                      </ExcelSheet>
                    </ExcelFile>
                  </Grid>
                </Grid>

                {this.state.filterShow ? (
                  <Grid
                    container
                    spacing={4}
                    style={{
                      marginLeft: "0px",
                      marginTop: 20,
                      marginBottom: 20,
                      border: "#87CEFA 2px solid",
                      borderRadius: "10px",
                      width: "67vw",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        marginLeft: "2em",
                        height: "10px",
                        display: "inline",
                        backgroundColor: "#fff",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingBottom: "15px",
                      }}
                    ></div>
                    <Grid container spacing={4} style={{ padding: 20 }}>
                      <Grid item xs={7} md={6} sm={6}>
                        <Dropdown
                          placeholder="Search by Voucher Code"
                          fluid
                          search
                          ref={(search) => {
                            this.searchInput = search;
                          }}
                          selection
                          value={this.props.customer}
                          options={this.props.voucherList}
                          onChange={this.handleVoucherChange}
                          style={{ marginTop: "33px" }}
                        ></Dropdown>
                      </Grid>
                      <Grid item xs sm={3}>
                        <TextField
                          id="date-picker-inline"
                          label="Date From"
                          name="dateFrom"
                          defaultValue={date}
                          type="date"
                          onChange={this.handleDateFrom}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs sm={3}>
                        <TextField
                          id="date-picker-inline"
                          label="Date To"
                          name="dateTo"
                          defaultValue={date}
                          type="date"
                          onChange={this.handleDateTo}
                          inputProps={{ style: inputStyles }}
                          InputLabelProps={{ style: inputStyles }}
                          style={textFieldStyle}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                <TableContainer>
                  <Table
                    aria-label="customized table"
                    style={{ marginTop: "20px", marginBottom: "15px" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={styledTableCell}>S. No.</TableCell>
                        <TableCell style={styledTableCell}>Period</TableCell>
                        <TableCell style={styledTableCell}>
                          Voucher Number
                        </TableCell>
                        <TableCell style={styledTableCell}>
                          Transaction Date{" "}
                        </TableCell>
                        <TableCell style={styledTableCell}>Post Date</TableCell>
                        <TableCell style={styledTableCell}>Narration</TableCell>
                        <TableCell align="center" style={styledTableCell}>
                          View{" "}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.props.isFetchingData && <div className="loading--parent"><div class="loader"></div><div>
                      <p style={ {margin: "20px 0"}}>Due to large amount of data. It will take some time to load.</p>
                      </div></div>}
                    {this.generateList()}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={this.props.totalApprovedDataCount}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={(event, newPage) => {
                    this.setState({ page: newPage });
                    this.props.getPaginationInfo(newPage,this.state.rowsPerPage)
                  }}
                  onChangeRowsPerPage={this.handleRowsPerPage}
                />
              </Card>
            </Animated>
          </Grid>
          <Grid item>
            <Animated
              animationIn="zoomIn"
              animationOut="zoomOut"
              isVisible={secondVisible}
              animationInDuration={500}
              animationOutDuration={500}
            >
              <ViewSalesVoucherList
                hidden={secondHidden}
                animateOut={this.props.animateOut}
                data={this.props.data}
              />
            </Animated>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SalesVoucherList);
