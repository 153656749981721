import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  withStyles,
  Button,
} from "@material-ui/core";
import ViewInventoryListButton from "../InterfaceElements/ViewInventoryListButton";
import { Dropdown, Form, Input } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = (theme) => ({
  root: {
    paddingTop: "10px",
    paddingBottom: "25px",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "170px",
      width: "95%",
      margin: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
      width: "90%",
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "100px",
      width: "80%",
      margin: "auto",
    },
  },
});

class InventoryList extends Component {
  constructor(props) {
    super(props);
    const rowIndex = (params) => params.node.rowIndex + 1;
    this.state = {
      columnDefs: [
        {
          headername: "Sn",
          field: "sn",
          sortable: true,
          resizable: true,
          valueGetter: rowIndex,
          minWidth: 50,
        },
        {
          headerName: "Item Code",
          field: "itemCode",
          resizable: true,
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Item Name",
          field: "itemName",
          resizable: true,
          minWidth: 210,
        },
        {
          headerName: "Major Category",
          field: "majorCategory",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Category",
          field: "category",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName: "Brand",
          field: "subCategory",
          resizable: true,
          sortable: true,
          minWidth: 110,
        },
        {
          headerName:"Unit",
          field:"unit",
          resizable: true,
          sortable: true,
          minWidth:80
        },
        {
          headerName:"Priority",
          field:"priority",
          resizable: true,
          sortable: true,
          minWidth:80,
          
          // cellRenderer: (params) => {
          //   if(params.value) {
          //     return `<span class="styleCells">${params.value === true ? "High" : "Low"}</span>`
          //   } else {
          //     return `<span>${params.value === true ? "High" : "Low"}</span>`
          //   }

          // }
        },
        {
          headerName: "Purchase Price",
          field: "rate",
          resizable:true,
          sortable: true,
          minWidth:100
        },
        {
          headerName: "Selling Price",
          field: "markedPrice",
          resizable: true,
          sortable: true,
          minWidth: 100,
        },
        {
          headerName: "Quantity",
          field: "quantity",
          resizable: true,
          sortable: true,
          minWidth: 75,
        },
        {
          headerName: "View",
          cellRendererFramework: ViewInventoryListButton,
          cellRendererParams: {
            setSnackbar: this.props.setSnackbarProps,
            inventoryListing: this.props.inventoryListing,
          },
          minWidth: 50,
        },
      ],
      open: false,
      search: "",
      propertyTypeList: [
        { key: 0, value: "None", text: "None" },
        { key: 1, value: "Stock", text: "Stock" },
        { key: 2, value: "Inventory", text: "Inventory" },
        { key: 3, value: "Capital Assets", text: "Capital Assets" },
      ]
     
    };
  }

  componentWillMount() {
    window.removeEventListener("resize", this.daListener);
    this.daListener();
  }

  firstDataRendered = (params) => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    window.addEventListener("resize", this.daListener);
    this.props.setGridApi(this.gridApi);
  };

  daListener = () => {
    if (!this.gridApi) return;
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    }, 100);
  };

  setOpen = (status) => {
    this.setState({ open: status });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    this.props.setGridApi(this.gridApi);
  };

  handleSearch = async (evt) => {
    let search = evt.target.value;
    await this.setState({ search });
    this.gridApi.setQuickFilter(this.state.search);
  };

  handleMarkedPriceSearch = (evt) => {
    let search = evt.target.value;

    let newData = [];

    this.props.rowData.map((val) =>
      val.markedPrice.toString().indexOf(search) !== -1
        ? newData.push(val)
        : null
    );

    this.gridApi.setRowData(newData);
  };

  render(params) {
    const {
      classes,
      rowData,
      totalItemCount,
      handleCategorySelect,
      handleMajorCategorySelect,
      handlePropertyTypeSelect,
      handleSubCategorySelect,
      handleWarehouseSelect,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      categoriesList,
      majorCategoriesList,
      subCategoriesList,
      warehouseList,
    } = this.props;

    return (
      <Card
        role="tabpanel"
        hidden={this.props.value !== this.props.index}
        id={`vertical-tabpanel-${this.props.index}`}
        className={classes.root}
      >
        <CardContent>
          <Grid container justify="center">
            <Typography
              variant="h5"
              component="h2"
              style={{ fontSize: "20px" }}
            >
              Inventory Catalogue
            </Typography>
          </Grid>

          <Grid
            container
            style={{
              border: "#87CEFA 2px solid",
              marginTop: "10px",
              borderRadius: "10px",
              paddingRight: "5px",
              paddingLeft: "5px",
              paddingTop: "10px",
            }}
          >
            <Grid container spacing={1} xs={12} style={{ margin: "auto" }}>
              <Grid item xs={12} md={6} lg={2}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px",
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Property Type</label>
                      <Dropdown
                        placeholder="Property Type"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "15px",
                          fontSize: "14px",
                        }}
                        ref={(search) => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={handlePropertyTypeSelect}
                        options={this.state.propertyTypeList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px",
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Major Category</label>
                      <Dropdown
                        placeholder="Major Category"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                        ref={(search) => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={handleMajorCategorySelect}
                        options={majorCategoriesList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px",
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Category</label>
                      <Dropdown
                        placeholder="Category"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                        ref={(search) => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={handleCategorySelect}
                        options={categoriesList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px",
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Brand</label>
                      <Dropdown
                        placeholder="Brand"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                        ref={(search) => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={handleSubCategorySelect}
                        options={subCategoriesList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <div
                  style={{
                    marginTop: "normal",
                    marginBottom: "25px",
                  }}
                >
                  <Form>
                    <Form.Field>
                      <label>Warehouse</label>
                      <Dropdown
                        placeholder="Warehouse"
                        fluid
                        search
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                        ref={(search) => {
                          this.searchInput = search;
                        }}
                        selection
                        onChange={handleWarehouseSelect}
                        options={warehouseList}
                      ></Dropdown>
                    </Form.Field>
                  </Form>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="space-between">
            <Grid item style={{ marginTop: "20px" }}>
              <h6 style={{ fontWeight: "bold", margin: "0px" }}>
                Item Count : {totalItemCount}
              </h6>
            </Grid>
            <Grid item style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Form>
                    <Form.Field>
                      <label>Search</label>
                      <Input
                        placeholder=""
                        fluid
                        value={this.state.search}
                        style={{
                          minWidth: "190px",
                          height: "38px",
                        }}
                        onChange={this.handleSearch}
                      ></Input>
                    </Form.Field>
                  </Form>
                </Grid>
                <Grid item>
                  <Form>
                    <Form.Field>
                      <label>Search By Selling Price</label>
                      <Input
                        placeholder=""
                        fluid
                        style={{
                          minWidth: "190px",
                          height: "38px",
                        }}
                        onChange={this.handleMarkedPriceSearch}
                      ></Input>
                    </Form.Field>
                  </Form>
                </Grid>
                <Grid item>
                  <Form>
                    <Form.Field>
                      <ExcelFile
                        filename="InventoryList.xlsx"
                        element={
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              minWidth: "100px",
                              width: "6vw",
                              margin: "auto",
                              height: "38px",
                              fontSize: "16px",
                              marginTop: "22px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              color="#3f51b5"
                              size="30px"
                              style={{
                                marginRight: "10px",
                              }}
                            ></FontAwesomeIcon>
                            Excel
                          </Button>
                        }
                      >
                        <ExcelSheet data={this.props.rowData} name="Sheet A">
                          <ExcelColumn label="Item Code" value="itemCode" />
                          <ExcelColumn label="Item Name" value="itemName" />
                          <ExcelColumn
                            label="Major Category"
                            value="majorCategory"
                          />
                          <ExcelColumn label="Category" value="category" />
                          <ExcelColumn label="Brand" value="subCategory" />
                          <ExcelColumn label="Purchase Price" value="rate"/>
                          <ExcelColumn
                            label="selling Price"
                            value="markedPrice"
                          />
                          <ExcelColumn label="Quantity" value="quantity" />
                        </ExcelSheet>
                      </ExcelFile>
                    </Form.Field>
                  </Form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <div
              className="ag-theme-balham"
              style={{
                height: "41vh",
                width: "100%",
                marginTop: "15px",
              }}
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={rowData}
                onFirstDataRendered={this.firstDataRendered}
                onGridReady={this.onGridReady}
                overlayLoadingTemplate={overlayLoadingTemplate}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
              ></AgGridReact>
            </div>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(InventoryList);
