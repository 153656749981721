import React, { Component } from "react";
import UserContext, { UserConsumer } from "../utils/UserContext";
import Axios from "axios";
import adbs from "ad-bs-converter";
import { Grid, Hidden } from "@material-ui/core";

class Footer extends Component {
  static contextType = UserContext;
  constructor() {
    super();
    this.state = {
      footerData: {
        fiscalYear: "",
        period: "",
        todaysDate: "",
        todaysTime: "",
        miti: "",
      },
    };
  }

  componentDidMount() {
    Axios.get("fy/getCurrentPeriod")
      .then((res) => {
        this.setState({
          footerData: res.data,
        });
        console.log(this.state.footerData);
      })
      .catch((err) => {
        console.log(err);
      });

    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();

    this.setState({
      todaysDate: date + "/" + month + "/" + year,
    });

    var miti = adbs.ad2bs(year + "/" + month + "/" + date);
    console.log(miti);
    this.setState({
      miti: miti.en.day + "/" + miti.en.month + "/" + miti.en.year,
    });

    var arr = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    setInterval(() => {
      var day = new Date().getDay();
      var hours = new Date().getHours();
      var min = new Date().getMinutes();
      var sec = new Date().getSeconds();

      this.setState({
        todaysTime: arr[day] + "," + hours + ":" + min + ":" + sec,
      });
    });
  }

  render() {
    const smallText = {
      fontSize: "14px",
      color: "#929292",
    };

    return (
      <UserConsumer>
        {(context) =>
          context.isLoggedIn ? (
            <div className="hideOnPrint">
              <Hidden xsDown>
                <div className="hideOnPrint">
                  <Grid
                    container
                    style={{
                      backgroundColor: "#fff",
                      position: "fixed",
                      zIndex: 19,
                      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 1)",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "13px",
                      paddingBottom: "13px",
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    justify="space-around"
                  >
                    <Grid item xs>
                      <Grid container>
                        <div>
                          <strong>SnigdhTech And Business Solution Pvt Ltd</strong>
                          <div style={smallText}>Jwagal, Lalitpur, Nepal</div>
                          <div style={smallText}>Phone: </div>
                          {/* <div style={smallText}>PAN: 610772173</div> */}
                          {/* <div style={smallText}>info@safal.com.np</div> */}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid container justify="center">
                        <div>
                          <div>
                            <strong style={{ marginLeft: "25px" }}>
                              Fiscal Year {this.state.footerData.fiscalYear}{" "}
                            </strong>
                          </div>

                          <div>{this.state.footerData.period}</div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid item xs>
                      <Grid container justify="flex-end">
                        <div>
                          <div style={smallText}>
                            Date: {this.state.todaysDate}
                          </div>
                          <div style={smallText}>Miti: {this.state.miti}</div>
                          <div style={smallText}>
                            Day: {this.state.todaysTime}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Hidden>
              {/* z-index: 5;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 1); */}
              <Hidden smUp>
                <div className="hideOnPrint">
                  <Grid
                    container
                    style={{
                      backgroundColor: "#fff",
                      position: "fixed",
                      zIndex: 19,
                      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 1)",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "13px",
                      paddingBottom: "13px",
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                    justify="space-around"
                  >
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid container justify="center">
                          <div>
                            <div>
                              <strong style={{ marginLeft: "25px" }}>
                                Fiscal Year {this.state.footerData.fiscalYear}{" "}
                              </strong>
                            </div>

                            <div>{this.state.footerData.period}</div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <div>
                        <strong>Aakrang Shopping Stores</strong>
                        <div style={smallText}>Chhorepatan-17, Pokhara</div>
                        <div style={smallText}>PAN: 610772173</div>
                        {/* <div style={smallText}>info@safal.com.np</div> */}
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container justify="flex-end">
                        <div>
                          <div style={smallText}>
                            Date: {this.state.todaysDate}
                          </div>
                          <div style={smallText}>Miti: {this.state.miti}</div>
                          <div style={smallText}>
                            Day: {this.state.todaysTime}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Hidden>
            </div>
          ) : null
        }
      </UserConsumer>
    );
  }
}

export default Footer;
